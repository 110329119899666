import ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const initialState = {
    user: null,
    error: null,
};

export default function reducer(state = initialState, action) {
    let result;

    switch (action.type) {
    case ACTION_TYPES.AUTH_COMPLETED: {
        const {user, error} = action.payload;

        result = {
            ...state,
            user,
            error
        };
        break;
    }

    case ACTION_TYPES.AUTH_ERROR_RESET: {
        result = {
            ...state,
            error: null
        };
        break;
    }

    case ACTION_TYPES.LOGOUT: {
        result = {
            ...initialState
        };
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
