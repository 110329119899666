// eslint-disable-next-line max-len
import 'annuity_lab/tools/imo/components/standard_view_rider_card/standard_view_rider_card.css.scss';

import PropTypes from 'prop-types';

import Calculator from 'annuity_lab/tools/imo/services/calculator.js';
import NumberFormat from 'common/services/number_format';

const StandardViewRiderCard = ({rider, isPrimary, clientInformation, tableValues, withTitle}) => {
    const {ageAtWithdrawal} = clientInformation;

    const lastTableYear = Number(ageAtWithdrawal) + 29;

    const getTotalIncome = () => {
        const totalIncome = tableValues[lastTableYear].totalIncome;

        return Number(totalIncome.toString());
    };

    const getRiderSummary = () => {
        const summary = Calculator.calculateStandardRiderSummary(rider, clientInformation);

        return {
            ageMoneyPositive: summary.ageMoneyPositive.toString(),
            moneyPerYearPositive: Number(summary.moneyPerYearPositive.toString()),
            moneyPerYearNegative: Number(summary.moneyPerYearNegative.toString())
        };
    };

    const totalIncome = getTotalIncome();
    const summary = getRiderSummary();

    const showOnlyAfterAge = summary.moneyPerYearPositive === summary.moneyPerYearNegative;

    const className = `imo-standard-view-rider-card
        ${isPrimary ? 'primary ' : ''}
        ${showOnlyAfterAge ? 'small' : ''}
    `;

    return (
        <div className={'imo-summary-container' + (showOnlyAfterAge ? ' small' : '')}>
            {withTitle && (<h3 className="standard-summary-title">Standard Income Summary</h3>)}
            <div className={className}>
                <div className="name-container">
                    <span className="small-text">RIDER</span>
                    <span>{isPrimary ? 'A' : 'B'}</span>
                </div>
                <div className="total-income-container">
                    <span className="small-text">
                        Total income after 30 years
                    </span>
                    <span>{NumberFormat.currency(totalIncome)}</span>
                </div>
                <div className="income-container">
                    {!showOnlyAfterAge && (
                        <React.Fragment>
                            <div className="annual-income-container">
                                <span className="small-text">Annual Income</span>
                                <span>{NumberFormat.currency(summary.moneyPerYearPositive)}</span>
                            </div>
                            <div className="age-container">
                                <span className="small-text">Until Age</span>
                                <span>{summary.ageMoneyPositive}</span>
                            </div>
                            <span className="then small-text">THEN</span>
                        </React.Fragment>
                    )}
                    <div className="annual-income-container">
                        <span className="small-text">Annual Income</span>
                        <span>{NumberFormat.currency(summary.moneyPerYearNegative)}</span>
                    </div>
                    <div className="age-container">
                        <span className="small-text">After Age</span>
                        <span>{Number(summary.ageMoneyPositive) + 1}+</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

StandardViewRiderCard.propTypes = {
    rider: PropTypes.object,
    clientInformation: PropTypes.object,
    tableValues: PropTypes.object,
    isPrimary: PropTypes.bool,
    withTitle: PropTypes.bool
};

StandardViewRiderCard.defaultProps = {
    withTitle: false
};

export default StandardViewRiderCard;