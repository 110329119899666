export default {
    CONTEXT_MONEY_LABEL: 'Premium',
    DEFERRAL_PERIOD_LABEL: 'Deferral period',

    DEFAULT_PREMIUM: '100000',
    DEFAULT_DEFERRAL_PERIOD: 10,

    RIDER_TYPE_CORE: 'core',
    RIDER_TYPE_PLUS: 'plus',

    JOINT_RIDER_OPTIONS: [
        {value: 'yes', label: 'Yes'},
        {value: 'no', label: 'No'}
    ],
};
