import 'annuity_lab/components/side_menu/side_menu.css.scss';
import {Link, withRouter} from 'react-router-dom';
import LogoutButton from 'annuity_lab/containers/logout_button/logout_button.js.jsx';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';

const spousalProtectionLink =
    'https://nationwidefinancial.com/#!/spousal-protection-death-benefit-hypothetical-input1';
const incomePromiseLink =
    'https://nationwidefinancial.com/#!/single-premium-immediate-annuity-quick-quote-intro';
const annuityLargeCaseProcessLink =
    'https://s3.amazonaws.com/annuitylab-assets/LargeCaseUnderwritingGrid.xlsx';

const TOOL_LINKS = [
    {
        link: '/lbc',
        title: 'Living Benefit Income Calculator',
    }, {
        link: '/mad',
        title: 'Living Benefit Investment Calculator',
    }, {
        link: '/imo',
        title: 'Income Modeler',
    }
];

const EXTERNAL_LINKS = [
    {
        link: spousalProtectionLink,
        title: 'Spousal Protection Quick Quote',
        clickHandler: () => AnnuityLabAnalytics.trackSpousalProtectionClicks(spousalProtectionLink),
    }, {
        link: incomePromiseLink,
        title: 'Income Promise Select Quick Estimator',
        clickHandler: () => AnnuityLabAnalytics.trackIncomePromiseClicks(incomePromiseLink),
    }, {
        link: annuityLargeCaseProcessLink,
        title: 'Annuity Large Case Process',
        clickHandler:
            () => AnnuityLabAnalytics.trackAnnuityLargeCaseProcess(annuityLargeCaseProcessLink),
    }
];

function SideMenu({user, className, toggleMenu, location}) {
    function getLinkClassName(path) {
        return `btn ${location.pathname.startsWith(path) ? 'disabled' : ''}`;
    }

    const links = TOOL_LINKS.map(({link, title}) => {
        return (
            <Link key={link} to={link} className={getLinkClassName(link)}>
                {title}
            </Link>
        );
    });
    const externalLinks = EXTERNAL_LINKS.map(({link, title, clickHandler}) => {
        return (
            <a
                key={link}
                href={link}
                target="_blank"
                className="external-link"
                onClick={() => {
                    clickHandler();
                    toggleMenu();
                }}>
                {title}
            </a>
        );
    });

    return (
        <div className={`side-menu ${className}`}>
            <div className="navigation">
                <div className="app-logo"></div>
                <label>annuity tools</label>
                {links}
                <label className="additional-resources">additional resources</label>
                {externalLinks}
                {user && user.isAdmin &&
                    <>
                        <Link
                            key="fia-admin"
                            to="/admin/fia"
                            className={getLinkClassName('admin/fia')}>
                            Fixed Indexes Admin Panel
                        </Link>
                        <Link
                            key="lbc-admin"
                            to="/admin/lbc"
                            className={getLinkClassName('admin/lbc')}>
                            Living Benefits Admin Panel
                        </Link>
                    </>
                }
            </div>
            <LogoutButton />
        </div>
    );
}

export default withRouter(SideMenu);
