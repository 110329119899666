import PropTypes from 'prop-types';
import LoadingIndicator
    from 'annuity_lab/components/loading_indicator/loading_indicator.js.jsx';
import Promise from 'bluebird';

const MIN_SYNC_INTERVAL = 1000 * 2;

export default class Sync extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minTimeEllapsed: false,
        };
    }

    UNSAFE_componentWillMount() {
        this._sync();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.online && nextProps.online) {
            this._sync();
        }
    }

    _sync() {
        this.setState({minTimeEllapsed: false});
        Promise.delay(MIN_SYNC_INTERVAL).then(() => {
            this.setState({minTimeEllapsed: true});
        });

        return this.props.sync().then(() => {
            this.props.onSyncCompleted();
        });
    }

    render() {
        let syncElem = null;
        const shouldRender = !this.state.minTimeEllapsed || this.props.isSyncing;

        if (shouldRender) {
            syncElem = (
                <LoadingIndicator
                    loadingText="Syncing Data..." />
            );
        }

        return syncElem;
    }
}

Sync.propTypes = {
    online: PropTypes.bool,
    sync: PropTypes.func.isRequired,
    onSyncCompleted: PropTypes.func.isRequired,
};

Sync.defaultProps = {
    onSyncCompleted: () => {},
};

