import 'annuity_lab/tools/imo/components/rider_card/rider_card.css.scss';

import NumberFormat from 'common/services/number_format.js';
import RiderCardInputs from
    'annuity_lab/tools/imo/components/rider_card_inputs/rider_card_inputs.js.jsx';

const RiderCard = ({rider, handleChange, erroredFields, clientInformation, isPrimary}) => {
    let className = 'imo-rider-card';

    if (isPrimary) {
        className += ' imo-rider-card-primary';
    }

    const {premium, cashValueEqualZeroRate, cashValueGreaterThanZeroRate} = rider;

    const shouldShowDetails = premium !== ''
        && cashValueGreaterThanZeroRate !== ''
        && cashValueEqualZeroRate !== '';

    return (
        <div className={className}>
            <div className="header">
                <span className="name">
                    {isPrimary ? 'A' : 'B'}:{' '}
                </span>

                {!isPrimary
                    && !shouldShowDetails
                    && <span className="optional">(OPTIONAL){' '}</span>}

                {shouldShowDetails && <span className="details">
                    ({NumberFormat.currency(Number(premium))} /{' '}
                    {cashValueGreaterThanZeroRate} /{' '}
                    {cashValueEqualZeroRate})
                </span>
                }
            </div>

            <div className="body">
                <RiderCardInputs
                    rider={rider}
                    handleChange={handleChange}
                    erroredFields={erroredFields}
                    clientInformation={clientInformation}/>
            </div>
        </div>
    );
};

export default RiderCard;