import 'annuity_lab/tools/lbc/components/new_comparison_plus/new_comparison_plus.css.scss';

import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import View from 'common/lib/view';

import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import {updateNewComparisonPlus} from '../../actions/new_comparison_plus';

function NewComparisonPlusHOC(NewComparisonComponent) {
    class PropsProxy extends View {
        constructor(props) {
            super(props);

            this.state = {
                selected: [],
                premium: undefined,
                currentAge: undefined,
                ageAtWithdrawal: undefined,
                jointRider: undefined
            };
        }

        viewEntered() {
            AnnuityLabAnalytics.trackView('New plus comparison');

            var allSelectedExist = _.every(this.state.selected, (card) => {
                return !!_.find(this.props.riderCardsPlus, card);
            });

            if (!allSelectedExist) {
                this.setState({selected: []});
            }
        }

        setCard(index, card) {
            const selected = _.clone(this.state.selected);

            selected[index] = card;

            this.setState({
                selected
            });
        }

        setComparisonsState(fieldName, value) {
            this.setState({
                [fieldName]: value
            });
        }

        cardOptionsFor(fieldIndex) {
            const available = _.difference(this.props.riderCardsPlus, this.state.selected);

            if (this.state.selected[fieldIndex]) {
                available.unshift(this.state.selected[fieldIndex]);
            }

            return available;
        }

        compareRiderCards(cards) {
            const {premium, currentAge, ageAtWithdrawal, jointRider} = this.state;

            const comparisonsState = {
                premium,
                currentAge,
                ageAtWithdrawal,
                jointRider
            };

            this.props.updateNewComparisonPlus({
                cardIds: cards.map(c => c.id),
                ageAtWithdrawal: Number(ageAtWithdrawal),
                currentAge: Number(currentAge),
                premium: Number(premium),
                jointRiders: jointRider === 'yes'
            });

            this.props.history.push(
                this.props.location.pathname + '/new/standard',
                {
                    baseCardId: cards[0].id,
                    cardIds: _(cards).compact().map('id').value(),
                    comparisonsState
                }
            );
        }

        render() {
            const comparisonsState = {
                premium: this.state.premium,
                currentAge: this.state.currentAge,
                ageAtWithdrawal: this.state.ageAtWithdrawal,
                jointRider: this.state.jointRider
            };

            const newProps = {
                selected: this.state.selected,
                comparisonsState,
                setCard: this.setCard.bind(this),
                setComparisonsState: this.setComparisonsState.bind(this),
                cardOptionsFor: this.cardOptionsFor.bind(this),
                compareRiderCards: this.compareRiderCards.bind(this),
            };

            return <NewComparisonComponent {...this.props} {...newProps}/>;
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            updateNewComparisonPlus: (newComparisonPlus) => {
                dispatch(updateNewComparisonPlus(newComparisonPlus));
            }
        };
    };

    return withRouter(connect(null, mapDispatchToProps)(PropsProxy));
}

export default NewComparisonPlusHOC;