/* eslint-disable max-len */
import 'annuity_lab/tools/lbc/containers/product_differential_plus/product_differential_plus.css.scss';

import PropTypes from 'prop-types';

import SmallCardPlus from 'annuity_lab/tools/lbc/components/small_card_plus/small_card_plus.js.jsx';

export default class ProductDifferentialPlus extends React.Component {
    static propTypes = {
        cards: PropTypes.array.isRequired,
        comparison: PropTypes.shape({
            name: PropTypes.string,
            premium: PropTypes.number,
            currentAge: PropTypes.number,
            ageAtWithdrawal: PropTypes.number,
        })
    }

    render() {
        const {
            cards,
            comparison,
        } = this.props;

        return (
            <div className="product-differential-plus">
                <div className="products">
                    {_.map(cards, card =>
                        (<div
                            className={'product'}
                            key={card.id}>

                            <SmallCardPlus
                                card={card}
                                comparison={comparison} />
                        </div>)
                    )}
                </div>
                <p className="disclaimer">This income comparison does not include any fees or investment experience</p>
            </div>
        );
    }
}
