import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';

export function updateRiderType(riderType) {
    return {
        type: ACTION_TYPES.SELECT_RIDER,
        payload: {riderType}
    };
}

export function updateShowSelectRiderError(errorVisible) {
    return {
        type: ACTION_TYPES.SHOW_SELECT_RIDER_ERROR,
        payload: {errorVisible}
    };
}