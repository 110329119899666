import Promise from 'bluebird';

import ACTIONS from 'annuity_lab/actions/action_types.js';
import Auth from 'annuity_lab/services/auth.js';
import LegacyMigrator from 'annuity_lab/tools/lbc/services/legacy_migrator.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
// import {ROLES} from 'common/domain/users';

export function authenticate() {
    return (dispatch, getState) => {
        const online = getState().apiStatus.online;

        let user;

        return Auth.authenticate(online)
            .then(currentUser => {
                user = currentUser;
                if (!currentUser) {
                    return Promise.resolve();
                }

                return LegacyMigrator.migrate(currentUser);
            })
            .then(() => {
                if (user) {
                    // Uncomment if USER ID policy is activated in google analytics
                    // AnnuityLabAnalytics.set('userId', user.id);
                    // AnnuityLabAnalytics.setDimension(1, ROLES.WHOLESALER);
                }

                dispatch({
                    type: ACTIONS.AUTH_COMPLETED,
                    payload: {user},
                });
            });
    };
}

export function login(email, password, rememberMe, nextPath) {
    let user;

    return dispatch => {
        return Auth.login(email, password, rememberMe)
            .then(currentUser => {
                user = currentUser;

                return LegacyMigrator.migrate(currentUser);
            })
            .then(() => {
                // Uncomment if USER ID policy is activated in google analytics
                // AnnuityLabAnalytics.set('userId', user.id);
                // AnnuityLabAnalytics.setDimension(1, ROLES.WHOLESALER);
                AnnuityLabAnalytics.trackLogin(email);

                dispatch({
                    type: ACTIONS.AUTH_COMPLETED,
                    payload: {
                        user,
                        error: null,
                    },
                    meta: {
                        transition: () => {
                            return {
                                pathname: `/${nextPath}`,
                            };
                        },
                    }
                });
            }, error => {
                dispatch({
                    type: ACTIONS.AUTH_COMPLETED,
                    payload: {
                        user: null,
                        error: error.message,
                    },
                });
            });
    };
}

export function logout() {
    return (dispatch) => {
        return Auth.logout().then(() => {
            dispatch({
                type: ACTIONS.LOGOUT,
                payload: {},
            });
        });
    };
}

export function resetAuthError() {
    return {
        type: ACTIONS.AUTH_ERROR_RESET,
        payload: {},
    };
}
