import CardsUtil from 'common/services/cards_core_util.js';

class ComparisonUtil {
    hasInvalidCards(comparison, cards) {
        if (!comparison || !cards) {
            return false;
        }
        const completedCards = _(cards)
            .values()
            .filter(CardsUtil.isComplete)
            .map(card => card.id)
            .value();

        return _.some(comparison.cardIds, (comparisonCardId) => {
            return !_.find(completedCards, completedCardId => comparisonCardId === completedCardId);
        });
    }
}

const comparisonUtil = new ComparisonUtil();

export default comparisonUtil;
