/* global ENV_CONFIG:false */

import NumberFormat from 'common/services/number_format';

function rangeOptions(from, to, step, labelFn, valueFn = (value) => value) {
    // The `/ 2` part is to correct floating point errors from
    // making the end argument inclusive.
    return _.range(from, to + step / 2, step).map((value) => {
        return {value: valueFn(value), label: labelFn(value)};
    });
}

// TODO: Extract tools related constants in separate files.
// --georgi.ivanov@2016-11-28
export default {
    DEFERRAL_PERIOD_OPTIONS: rangeOptions(1, 15, 1,
        (value) => `${value} Year${value > 1 ? 's' : ''}`
    ),
    ROLLUP_PERCENTAGE_OPTIONS: rangeOptions(
        0, 0.10, 0.0005,
        NumberFormat.percentage,
        (value) => value.toFixed(4)
    ),
    ROLLUP_PERCENTAGE_PLUS_OPTIONS: rangeOptions(
        0, 0.10, 0.0025,
        NumberFormat.percentage,
        (value) => value.toFixed(4)
    ),
    PAYOUT_PERCENTAGE_OPTIONS: rangeOptions(
        0.03, 0.2, 0.0005,
        NumberFormat.percentage,
        (value) => value.toFixed(4)
    ),

    INTEREST_TYPE_OPTIONS: [
        {value: 'simple', label: 'Simple'},
        {value: 'compound', label: 'Compound'}
    ],

    GOOGLE_ANALYTICS_ID: ENV_CONFIG.GOOGLE_ANALYTICS_ID,

    FIA_PRODUCT_COLORS: {
        MAIN: '#279989',
        SECONDARY: ['#403a60', '#a83d72'],
        INACTIVE: '#9e9e9e',
    },
    FIA_MAX_REASONABLE_CAP_RATE: 19.5,

    CAP_RATE: {
        MIN: 0,
        MAX: 10,
        DEFAULT: 0,
    },

    TIME_FRAMES: {
        ALL_TIME: 'All time',
        LAST_1_YEAR: 'The Last 1 Year',
        LAST_5_YEARS: 'The Last 5 Years',
        LAST_7_YEARS: 'The Last 7 Years',
        CUSTOM: 'Custom'
    },

    DEFAULT_ACCOUNT_VALUE: 100000,
    UPDATE_API_STATUS_INTERVAL: 1000 * 3,

    COMPARISON_SHARE_STATUS: {
        IN_PROGRESS: 'in_progress',
        PENDING: 'pending',
        CANCELED: 'canceled',
        SUCCESS: 'succeeded',
        FAIL: 'failed'
    },

    COMPARISONS_BUTTON_LABELS: {
        REMOVE: {
            full: 'Remove This Comparison',
            short: 'Remove',
        },
        SAVE: {
            full: 'Save This Comparison',
            short: 'Save'
        },
        SHARE: {
            full: 'Share This Comparison',
            short: 'Share'
        },
        NEW: {
            full: 'Start a New Comparison',
            short: 'New'
        },
    },

    MODELS_BUTTON_LABELS: {
        REMOVE: {
            full: 'Remove This Model',
            short: 'Remove',
        },
        SAVE: {
            full: 'Save This Model',
            short: 'Save'
        },
        SHARE: {
            full: 'Share This Model',
            short: 'Share'
        },
        NEW: {
            full: 'Start a New Model',
            short: 'New'
        },
    },

    CONFIRM_TITLE: 'Please confirm',
    COMPARISONS_CONFIRM_TITLE: 'Please confirm',
    COMPARISONS_CONFIRM_REMOVE_TEXT:
        'Are you sure you want to remove this comparison from all devices?',
    MODELS_CONFIRM_REMOVE_TEXT:
        'Are you sure you want to remove this model from all devices?',

    DEFAULT_TOOL: 'lbc',
    EMAIL_BODY_TEXT: {
        lbc: 'Thank you for allowing us the opportunity to compete for your business. ' +
            'I hope you find this calculator useful as you identify ' +
            'potential income solutions for your client. \n\n' +

            'As a reminder, this tool is built to compare only the income ' +
            'outcomes of riders by focusing on:\n' +

            '    1.    Guaranteed income\n' +
            '    2.    Required sub-account performance to outpace ' +
                                        'the contractual guarantee\n\n' +

            'The outcome reflects the minimum withdrawal amount your client may ' +
            'receive each year. Remember, it’s important to consider ' +
            'all aspects of the annuities in addition to the rider features.\n\n' +

            'Thank you again for your partnership. ' +
            'Please let me know if you have any additional questions.\n\n',
        mad: 'Thank you for allowing us the opportunity to compete for your business. ' +
            'I hope you find this calculator useful as you identify ' +
            'potential income solutions for your client. \n\n' +

            'As a reminder, this tool is designed to compare the premium needed by ' +
            'different income riders to generate the same annual income. This ' +
            'calculation only considers the guaranteed income features of the rider ' +
            'and does not include the potential impact of sub-account performance. \n\n' +

            'The link below will take you to the Rider Comparison that shows the premium ' +
            'your client would need to invest in each rider to generate the desired ' +
            'income at the end of the deferral period. Remember, it\'s important to ' +
            'consider all aspects of the annuities and riders when deciding the best ' +
            'option for your client. \n\n' +

            'Thank you again for your partnership. ' +
            'Please let me know if you have any additional questions.\n\n',
        imo: 'Thank you for allowing us the opportunity to compete for your business. ' +
            'I hope you find this Income Model is useful as ' +
            'you identify potential income solutions for your client. \n\n' +

            'As a reminder, this Income Model is designed to compare the potential income ' +
            'stream of two different income riders based on static inputs. This calculation only' +
            ' uses these inputs and does not include other factors that could impact the income ' +
            'stream like additional deposits or withdrawal, actual sub-account performance, ' +
            'or fees and charges (i.e. M&E, CDSC, rider fee, etc.).  \n\n' +

            'The Income Carryforward Income Model illustrates how the Income Carryforward feature' +
            ' might work if your client elects to withdrawal less than their full amount in a ' +
            'given year. Please note, any Income Carryforward amounts not withdrawn the ' +
            'following contract year are forfeited.  The assets remain invested in the contract ' +
            'so this does not impact the contract value.\n\n' +

            'Remember, it\'s important to consider all aspects of the annuities and ' +
            'riders when deciding the best option for your client. \n\n' +

            'Thank you again for your partnership. ' +
            'Please let me know if you have any additional questions.\n\n',
    },
    LEGAL_PAGE_CALCULATION_INFO: {
        lbc: 'Calculation used to get the annual rate of return on the income benefit ' +
            'base is: (Rider Income (Withdrawal Rate*Initial Premium)^(1/number of ' +
            'years until income starts)-1',
        mad: 'The calculation for the premium needed to generate the specified annual ' +
            'income is based on the roll-up percentage, interest type (simple or compounded), ' +
            'deferral period, and  payout percentage indicated.  The calculation assumes ' +
            'no withdrawals during the deferral period and does not  include any fees or ' +
            ' investment results.'
    },
};
