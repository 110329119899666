// XXX: This API stores locally sensitive information which may lead
// to a security hole. Consider moving the data to the backend with
// a proper authorization policy --georgi.ivanov@2017-05-11

import EntityStore from 'annuity_lab/services/data/base/entity_store.js';

const PERSISTENCE_KEY = 'contacts';

class ContactsAPI extends EntityStore {
    constructor() {
        super(PERSISTENCE_KEY);
    }
}

export default new ContactsAPI();
