import 'annuity_lab/tools/lbc/components/rider_cards_plus/rider_cards_plus.css.scss';

import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import scrollIntoView from 'scroll-into-view-if-needed';

import CardPlus from 'annuity_lab/tools/lbc/components/card_plus/card_plus.js.jsx';
import ProductContextPlusInput from
    'annuity_lab/tools/lbc/components/product_context_plus_input/product_context_plus_input.js.jsx';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import Disclaimer from 'annuity_lab/components/disclaimer/disclaimer.js.jsx';
import RiderCardsPlusControls from
    'annuity_lab/tools/lbc/containers/rider_cards_plus_controls/rider_cards_plus_controls.js.jsx';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import ScrollFix from 'annuity_lab/tools/lbc/services/scroll_fix';
import RiderCardsHint from
    'annuity_lab/tools/lbc/components/rider_cards_hint/rider_cards_hint.js.jsx';
import {requirePlusRider} from 'annuity_lab/tools/lbc/services/routes_navigation_util.js';

export default class RiderCardsPlus extends React.Component {
    componentDidMount() {
        const {riderType, updateRiderType} = this.props;

        requirePlusRider(riderType, updateRiderType);

        AnnuityLabAnalytics.trackView('Plus Cards');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentCards = this.props.riderCardsPlus;
        const nextCards = nextProps.riderCardsPlus;

        if (currentCards.length < nextCards.length) {
            ScrollFix.fix();
        }
    }

    scrollToNewCard() {
        const cards = document.querySelectorAll('.cards-plus-view .card-plus');
        const newCard = cards[cards.length - 1];

        scrollIntoView(newCard, false, {duration: 500});
    }

    componentDidUpdate(prevProps) {
        const previousCards = prevProps.riderCardsPlus;
        const currentCards = this.props.riderCardsPlus;

        if (previousCards.length > 0 && currentCards.length > previousCards.length) {
            this.scrollToNewCard();
        }
    }

    updateContext(money) {
        if (money !== this.props.productContextPlus.money) {
            AnnuityLabAnalytics.trackEditPremiumOnPlusCardsView(money);
        }

        this.props.updateProductContext(money);
    }

    renderCards(cards) {
        const context = this.props.productContextPlus;

        return cards.map((card, index) => {
            const isBaseProduct = index === 0;

            return (
                <CardPlus
                    key={card.id}
                    index={index}
                    card={card}
                    baseProduct={isBaseProduct ? null : cards[0]}
                    money={context.money}
                    removable

                    onChange={(updates) => this.props.updateCard(card, updates)}
                    onRemove={() => this.props.removeCard(card)} />
            );
        });
    }

    render() {
        const context = this.props.productContextPlus;
        const cardsCount = CardsPlusUtil.countCompletedCards(this.props.riderCardsPlus);

        return (
            <div className="cards-plus-view">
                <h3 className="product-count">Currently ({cardsCount}) Riders</h3>

                <ProductContextPlusInput
                    money={context.money}
                    moneyLabel={this.props.moneyLabel}
                    onChange={this.updateContext.bind(this)} />

                {cardsCount < 2 && <RiderCardsHint />}

                <FlipMove
                    duration={500} delay={0}
                    staggerDelayBy={20} staggerDurationBy={15}
                    enterAnimation={false} leaveAnimation={false}>

                    {this.renderCards(this.props.riderCardsPlus)}

                </FlipMove>

                <RiderCardsPlusControls/>
                <Disclaimer urboNumber='VAM-3427AO (2/21)' />
            </div>
        );
    }
}

RiderCardsPlus.propTypes = {
    riderCardsPlus: PropTypes.array,
    productContextPlus: PropTypes.object,
    updateCard: PropTypes.func,
    removeCard: PropTypes.func,
    updateProductContext: PropTypes.func
};