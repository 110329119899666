export default class View extends React.Component {
    componentDidMount() {
        this.viewEntered();
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (this.props.location.key !== props.location.key) {
            this.viewEntered();
        }
    }

    viewEntered() {
        // To be overriden in a subclass
    }
}
