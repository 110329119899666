import 'annuity_lab/tools/lbc/components/cards_comparison/cards_comparison.css.scss';

/* eslint-disable max-len */
import PropTypes from 'prop-types';

import View from 'common/lib/view';
import CardsUtil from 'common/services/cards_core_util.js';
import ComparisonUtil from 'common/services/comparison_util.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import ProductDifferential
    from 'annuity_lab/tools/lbc/containers/product_differential/product_differential.js.jsx';
import ProductContextInputs
    from 'annuity_lab/tools/common/components/product_context_inputs/product_context_inputs.js.jsx';
import ComparisonChart from 'common/components/comparison_chart/comparison_chart.js.jsx';
import DifferentialBalloon
    from 'annuity_lab/tools/lbc/components/differential_balloon/differential_balloon.js.jsx';
import HurdleRateBalloon
    from 'annuity_lab/tools/lbc/components/hurdle_rate_balloon/hurdle_rate_balloon.js.jsx';
import Disclaimer from 'annuity_lab/components/disclaimer/disclaimer.js.jsx';
import ComparisonControls
    from 'annuity_lab/tools/lbc/containers/comparison_controls/comparison_controls.js.jsx';
import scrollIntoView from 'scroll-into-view-if-needed';
import {requireCoreRider} from 'annuity_lab/tools/lbc/services/routes_navigation_util.js';
import {TOOLS} from 'common/domain/tools.js';
/* eslint-enable max-len */

export default class CardsComparison extends View {
    constructor(props) {
        super(props);

        this.state = {
            saveDialogOpen: false,
            comparison: null,
            selectedCardIndex: 1,
        };
    }

    UNSAFE_componentWillMount() {
        this.loadComparison(this.props);
    }

    componentDidMount() {
        const {riderType, updateRiderType} = this.props;

        requireCoreRider(riderType, updateRiderType);

        super.componentDidMount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentParams = this.props.match.params;
        const nextParams = nextProps.match.params;

        var shouldReload = this.props.location.state !== nextProps.location.state ||
                          currentParams.id !== nextParams.id;

        if (shouldReload) {
            this.loadComparison(nextProps);
        }

        super.UNSAFE_componentWillReceiveProps(nextProps);
    }

    viewEntered() {
        if (!this.state.comparison ||
            ComparisonUtil.hasInvalidCards(this.state.comparison, this.props.riderCards)) {
            this.props.history.replace('/lbc/comparisons');
        } else {
            const isCardUpdated = this.state.cards.some( card => {
                const cardInStore = this.props.riderCards[card.id];

                return cardInStore.updatedAt > card.updatedAt;
            });

            if (isCardUpdated) {
                this.state.cards = this.loadComparisonCards(this.state.comparison.cardIds);
            }
        }
    }

    trackScreenView() {
        const name = _.get(this.state, 'comparison.name', 'unsaved');

        AnnuityLabAnalytics.trackView(`Comparison > ${name}`);
    }

    scrollToBaseCard() {
        // base card is always the first so we don't need to use querySelectorAll
        var baseCard = document.querySelector('.products-comparison-view .small-product-card');

        scrollIntoView(baseCard, false, {time: 500});
    }

    loadComparisonCards(cardsIds) {
        return cardsIds.map( id => this.props.riderCards[id]);
    }

    loadComparison(props) {
        var routerState = props.location.state,
            routerParams = props.match.params,
            comparison;

        if (routerState && routerState.cardIds) {
            comparison = {cardIds: [], tool: TOOLS.LIVING_BENEFIT_RIDERS_CORE};
            routerState.cardIds.forEach(cardId => {
                comparison.cardIds.push(cardId);
            });
        } else {
            comparison = this.props.comparisons[routerParams.id];
        }

        if (!comparison || ComparisonUtil.hasInvalidCards(comparison, this.props.riderCards)) {
            this.props.history.replace('/lbc/comparisons');
        } else {
            this.setState({
                comparison,
                selectedCardIndex: comparison.cardIds.length - 1,
                cards: this.loadComparisonCards(comparison.cardIds)
            }, () => {
                this.trackScreenView();
            });
        }
    }

    render() {
        if (!this.state.comparison) {
            return null;
        }
        var context = this.props.productContext,
            location = this.props.location,
            match = this.props.match,
            history = this.props.history,
            comparison = this.state.comparison,

            cards = CardsUtil.sortByAnnualIncome(
                this.state.cards,
                context.money,
                context.deferralPeriod
            ),

            baseProduct = cards[0],
            selectedCardIndex = this.state.selectedCardIndex,
            selectedCard = cards[selectedCardIndex];

        if (!baseProduct || !selectedCard) {
            return null;
        }
        const selectedCardHurdleRate = CardsUtil.hurdleRate(
            selectedCard,
            baseProduct,
            context.money,
            context.deferralPeriod
        ).toNumber();
        const incomeDifference = CardsUtil.incomeDifference(
            baseProduct, selectedCard,
            context.money, context.deferralPeriod
        );

        return (
            <div className="products-comparison-view">
                <div className="comparison-header">
                    <div className="name">
                        {comparison.name || 'New Comparison'}
                    </div>
                    <ComparisonControls
                        view="desktop"
                        location={location}
                        match={match}
                        history={history}/>
                </div>

                <ProductContextInputs
                    minMoney={'10000'}
                    money={context.money}
                    moneyLabel={this.props.moneyLabel}
                    deferralPeriod={context.deferralPeriod}
                    deferralPeriodLabel={this.props.deferralPeriodLabel}
                    onChange={this.updateContextValues.bind(this)} />

                <p className="comparison-disclaimer">
                    This income comparison does not include any fees or investment experience
                </p>

                <ProductDifferential
                    cards={cards}
                    money={context.money}
                    deferralPeriod={context.deferralPeriod}
                    selectedCardIndex={selectedCardIndex}
                    onSelect={(index) => this.selectCard(index)}
                    card={selectedCard}
                    difference={incomeDifference}
                    baseProduct={baseProduct}
                    hurdleRate={selectedCardHurdleRate}/>

                <DifferentialBalloon difference={incomeDifference} />

                <HurdleRateBalloon
                    cardIndex={selectedCardIndex}
                    card={selectedCard}
                    baseProduct={baseProduct}
                    hurdleRate={selectedCardHurdleRate}
                    deferralPeriod={context.deferralPeriod} />

                <ComparisonChart
                    cards={cards}
                    money={context.money}
                    deferralPeriod={context.deferralPeriod}
                    selectedColumn={selectedCardIndex}

                    onClickColumn={(index) => this.selectCard(index)} />
                <Disclaimer urboNumber='VAM-3427AO (2/21)' />
            </div>
        );
    }

    updateContextValues(money, deferralPeriod) {
        if (money !== this.props.productContext.money) {
            AnnuityLabAnalytics.trackEditPremiumOnComparisonView(money);
        }

        if (deferralPeriod !== this.props.productContext.deferralPeriod) {
            AnnuityLabAnalytics.trackEditDeferralPeriodOnComparisonView(deferralPeriod);
        }

        this.props.updateProductContext(money, deferralPeriod);
    }

    selectCard(index) {
        if (index === 0) {
            return;
        }

        if (index !== this.state.selectedCardIndex) {
            AnnuityLabAnalytics.trackChooseRiderOnComparisonChart(index);
        }

        this.setState({selectedCardIndex: index});
        this.scrollToBaseCard();
    }
}

CardsComparison.propTypes = {
    router: PropTypes.object,
};
