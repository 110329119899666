import ACTION_TYPES from 'annuity_lab/tools/common/actions/action_types.js';

const initialState = [];

export default function reducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.CONTACTS_LOADED: {
        result = [].concat(action.payload.contacts);
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
