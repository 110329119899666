import {useMemo} from 'react';
import PropTypes from 'prop-types';

import AnnualIncomeChart from
    'annuity_lab/tools/imo/components/annual_income_chart/annual_income_chart.js.jsx';

const StandardViewChart = (props) => {
    const {valuesA, valuesB, showAllYears} = props;

    const valuesAFormatted = useMemo(() => {
        let valuesAMap = {};

        Object.keys(valuesA)
            .forEach(year => {
                valuesAMap[year] = valuesA[year].annualIncome;
            });

        return valuesAMap;
    }, [valuesA]);

    const valuesBFormatted = useMemo(() => {
        let valuesBMap = null;

        if (valuesB) {
            valuesBMap = {};

            Object.keys(valuesB)
                .forEach(year => {
                    valuesBMap[year] = valuesB[year].annualIncome;
                });
        }

        return valuesBMap;
    }, [valuesB]);

    return (
        <AnnualIncomeChart
            id="standard-view-chart"
            showAllYears={showAllYears}
            valuesA={valuesAFormatted}
            valuesB={valuesBFormatted}
            secondaryRiderColor="#672146"/>
    );
};

StandardViewChart.propTypes = {
    valuesA: PropTypes.object.isRequired,
    valuesB: PropTypes.object,
    showAllYears: PropTypes.bool
};

StandardViewChart.defaultProps = {
    showAllYears: false
};

export default StandardViewChart;