import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';

class ComparisonPlusUtil {
    hasInvalidCards(comparison, cards) {
        if (!comparison || !cards) {
            return false;
        }
        const completedCards = _(cards)
            .values()
            .filter(c => CardsPlusUtil.isComplete(c))
            .map(card => card.id)
            .value();

        return _.some(comparison.cardIds, (comparisonCardId) => {
            return !_.find(completedCards, completedCardId => comparisonCardId === completedCardId);
        });
    }
}

const comparisonPlusUtil = new ComparisonPlusUtil();

export default comparisonPlusUtil;
