import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CardsComparisonPlus
    from 'annuity_lab/tools/lbc/components/cards_comparison_plus/cards_comparison_plus.js.jsx';
import {updateRiderType} from 'annuity_lab/tools/lbc/actions/select_rider.js';

function mapStateToProps({lbc}) {
    const {riderCardsPlus, comparisonsPlus, selectRider, newComparisonPlus} = lbc;

    return {
        riderType: selectRider.riderType,
        riderCardsPlus,
        comparisonsPlus,
        newComparisonPlus
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateRiderType: (riderType) => {
            dispatch(updateRiderType(riderType));
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardsComparisonPlus));
