/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
export const X1 = {
    '4.5': [
        64, 65, 66, 67,
        67.8, 67.8, // duplicate year
        68.2, 68.2, // duplicate year
        69, 70, 71, 72,
        73, 73, // duplicate year
        74, 75, 76,
        76.6, 76.6, // duplicate year
        77.04, 77.04, // duplicate year
        78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ],
    '5': [
        64, 65, 66, 67, 68, 69,
        70, 70, // duplicate year
        70.4, 70.4, // duplicate year
        72, 73, 74, 75, 76,
        77.6, 77.6, // duplicate year
        78.04, 78.04, // duplicate year
        79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ]
};

export const X2 = {
    '4.5': [
        64, 65, 66, 67,
        67.8, 67.8, // duplicate year
        68.2, 68.2, // duplicate year
        69, 70, 71, 72,
        73, 73, // duplicate year
        74, 75, 76,
        76.6, 76.6, // duplicate year
        76.96, 76.96, // duplicate year
        78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ],
    '5': [
        64, 65, 66, 67, 68, 69,
        70, 70, // duplicate year
        70.4, 70.4, // duplicate year
        72, 73, 74, 75, 76,
        77.6, 77.6, // duplicate year
        77.96, 77.96, // duplicate year
        79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ]
};

export const EXTENDED_INCOME_DATA = {
    '4.5': [
        5625, 5625, 5625, 5625,
        5625, 15000, // data for duplicate year
        15000, 7500, // data for duplicate year
        7500, 7500, 7500, 7500,
        7500, 5625, // data for duplicate year
        5625, 5625, 5625,
        5625, 15000, // data for duplicate year
        15000, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ],
    '5': [
        6250, 6250, 6250, 6250, 6250, 6250,
        6250, 15000, // data for duplicate year
        15000, 6250, // data for duplicate year
        6250, 6250, 6250, 6250, 6250,
        6250, 15000, // data for duplicate year
        15000, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ]
};

export const CARRY_FORWARD_DATA = {
    '4.5': [
        7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 7500, // data for duplicate year
        7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ],
    '5': [
        7500, 7500, 7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 7500, // data for duplicate year
        7500, 7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ]
};

