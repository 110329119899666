import CardsUtil from 'common/services/cards_core_util.js';
import Select from 'annuity_lab/components/select/select.js.jsx';

export default class CardSelect extends React.Component {
    makeOptions() {
        return _.map(this.props.cards, (card) => {
            var name = `Rider ${card.displayName.toUpperCase()}`,
                stats = CardsUtil.getStats(card);

            return {
                value: card.id,
                label: `${name} (${stats})`
            };
        });
    }

    onChange(option) {
        this.props.onChange(_.find(this.props.cards, {id: option.value}));
    }

    render() {
        const options = this.makeOptions();

        const props = _.assign({}, this.props, {
            onChange: this.onChange.bind(this),
            options: this.makeOptions(),
            value: this.props.value && options.find(o => o.value === this.props.value.id)
        });

        return <Select {...props}/>;
    }
}
