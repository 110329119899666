import 'annuity_lab/tools/mad/components/cards_comparison/cards_comparison.css.scss';

import PropTypes from 'prop-types';
import moment from 'moment';

import ComparisonUtil from 'common/services/comparison_util.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import PremiumComparison
    from 'annuity_lab/tools/mad/containers/premium_comparison/premium_comparison.js.jsx';
import ProductContextInputs
    from 'annuity_lab/tools/common/components/product_context_inputs/product_context_inputs.js.jsx';
import Disclaimer from 'annuity_lab/components/disclaimer/disclaimer.js.jsx';
import ComparisonControls
    from 'annuity_lab/tools/mad/containers/comparison_controls/comparison_controls.js.jsx';
import scrollIntoView from 'scroll-into-view-if-needed';
import NumberFormat from 'common/services/number_format';
import {TOOLS} from 'common/domain/tools';

export default class CardsComparison extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saveDialogOpen: false,
            comparison: null,
        };
    }

    UNSAFE_componentWillMount() {
        if(ComparisonUtil.hasInvalidCards(this.state.comparison, this.props.riderCards)) {
            this.props.history.replace('/mad/comparisons');
        }

        this.loadComparison(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(ComparisonUtil.hasInvalidCards(this.state.comparison, nextProps.riderCards)) {
            this.props.history.replace('/mad/comparisons');
        }

        const currentParams = this.props.match.params;
        const nextParams = nextProps.match.params;

        var shouldReload = this.props.location.state !== nextProps.location.state ||
                            currentParams.id !== nextParams.id;

        if (shouldReload) {
            this.loadComparison(nextProps);
        }
    }

    trackScreenView() {
        const name = _.get(this.state, 'comparison.name', 'unsaved');

        AnnuityLabAnalytics.trackView(`Comparison > ${name}`);
    }

    scrollToBaseCard() {
        // base card is always the first so we don't need to use querySelectorAll
        var baseCard = document.querySelector('.products-comparison-view .small-product-card');

        scrollIntoView(baseCard, false, {time: 500});
    }

    loadComparisonCards(cardsIds) {
        return cardsIds.map( id => this.props.riderCards[id]);
    }

    loadComparison(props) {
        const routerState = props.location.state;
        const routerParams = props.match.params;

        let comparison = {};

        if (routerState && routerState.cardIds) {
            comparison = {
                tool: TOOLS.MAKE_A_DIFFERENCE,
                cardIds: [],
                baseCardId: routerState.baseCardId
            };
            routerState.cardIds.forEach(cardId => {
                comparison.cardIds.push(cardId);
            });
        } else {
            comparison = this.props.comparisons[routerParams.id];
        }

        if (!comparison || ComparisonUtil.hasInvalidCards(comparison, this.props.riderCards)) {
            this.props.history.replace('/mad/comparisons');
        }

        this.setState({
            comparison: comparison,
        }, () => {
            this.trackScreenView();
        });
    }

    render() {
        var context = this.props.productContext,
            comparison = this.state.comparison;

        if (!comparison) {
            return null;
        }

        const baseProduct = this.props.riderCards[comparison.baseCardId];
        const nonBaseIds = _.filter(comparison.cardIds, (cardId) => {
            return comparison.baseCardId !== cardId;
        });

        const selectedCard = this.state.selectedCard || this.props.riderCards[nonBaseIds[0]];

        if (!baseProduct || !selectedCard) {
            return null;
        }

        return (
            <div className="products-comparison-view">
                <div className="comparison-header">
                    <div className="details">
                        <span className="comparison-name">
                            {comparison.name || 'New Comparison'}:{' '}
                        </span>
                        Premium needed to generate {NumberFormat.currency(Number(context.money))}
                        {' '}per year starting in{' '}
                        {(moment().add(context.deferralPeriod, 'y')).year()}.
                    </div>
                    <ComparisonControls
                        history={this.props.history}
                        match={this.props.match}
                        location={this.props.location}
                        view="desktop"/>
                </div>

                <ProductContextInputs
                    minMoney={'1000'}
                    money={context.money}
                    moneyLabel={this.props.moneyLabel}
                    deferralPeriod={context.deferralPeriod}
                    deferralPeriodLabel={this.props.deferralPeriodLabel}
                    onChange={this.updateContextValues.bind(this)}/>

                <PremiumComparison
                    money={context.money}
                    deferralPeriod={context.deferralPeriod}
                    onSelect={(card) => this.selectCard(card)}
                    selectedCard={selectedCard}
                    baseProduct={baseProduct}
                    nonBase={this.loadComparisonCards(nonBaseIds)}/>

                <Disclaimer urboNumber='VAM-2988AO.1 (2/21)' />
            </div>
        );
    }

    updateContextValues(money, deferralPeriod) {
        if (money !== this.props.productContext.money) {
            AnnuityLabAnalytics.trackEditPremiumOnComparisonView(money);
        }

        if (deferralPeriod !== this.props.productContext.deferralPeriod) {
            AnnuityLabAnalytics.trackEditDeferralPeriodOnComparisonView(deferralPeriod);
        }

        this.props.updateProductContext(money, deferralPeriod);
    }

    selectCard(card, index) {
        if (card.id === this.state.comparison.baseCardId) {
            return;
        }

        if (card !== this.state.selectedCard) {
            AnnuityLabAnalytics.trackChooseRiderOnComparisonChart(index);
        }

        this.setState({selectedCard: card});
        this.scrollToBaseCard();
    }
}

CardsComparison.propTypes = {
    router: PropTypes.object,
};
