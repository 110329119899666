import {Route, Router, Switch} from 'react-router-dom';
import appHistory from 'annuity_lab/services/history.js';
import Login from 'annuity_lab/containers/login/login.js.jsx';
// import LoginAdvisors from 'annuity_lab/containers/login_advisors/login_advisors.js.jsx';
// import AdvisorsLoginError from
//     'annuity_lab/containers/advisors_login_error/advisors_login_error.js.jsx';

import LBCLayout from 'annuity_lab/tools/lbc/containers/layout/layout.js.jsx';
import MADLayout from 'annuity_lab/tools/mad/containers/layout/layout.js.jsx';
import ImoApp from 'annuity_lab/tools/imo/containers/imo_app/imo_app.js.jsx';
import AuthRoutes from 'annuity_lab/auth_routes.js.jsx';
import AdminRoutes from 'annuity_lab/tools/admin/admin_routes.js.jsx';
import AdminApp from 'annuity_lab/tools/admin/containers/admin_app/admin_app.js.jsx';

export default function Root() {
    return (
        <Router history={appHistory}>
            <Switch>
                <Route exact path="/login">
                    <Login />
                </Route>

                {/* <Route exact path="/advisors">
                    <LoginAdvisors />
                </Route>

                <Route exact path="/advisors-error">
                    <AdvisorsLoginError />
                </Route>
 */}

                <AuthRoutes history={appHistory}>
                    <Switch>
                        <Route path="/lbc">
                            <LBCLayout />
                        </Route>
                        <Route path="/mad">
                            <MADLayout />
                        </Route>
                        <Route path="/imo">
                            <ImoApp />
                        </Route>
                        <Route path="/admin">
                            <AdminRoutes history={appHistory}>
                                <AdminApp />
                            </AdminRoutes>
                        </Route>
                    </Switch>
                </AuthRoutes>
            </Switch>
        </Router>
    );
}
