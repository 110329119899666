import {LegacyStorage} from 'annuity_lab/services/data/base/local_storage.js';
import SyncAPI from 'annuity_lab/tools/lbc/services/nwide/sync.js';
import Promise from 'bluebird';
import _ from 'lodash';

const KEYS = {
    products: 'product-store',
    comparisons: 'comparison-store',
    users: 'users-store',
};

function getLegacyData(type, email) {
    const key = KEYS[type];

    return LegacyStorage.get(`${key}-${email}`);
}

class LegacyDataMigrator {
    migrate(currentUser) {
        const users = LegacyStorage.get(KEYS.users) || [];
        const user = _.find(users, (user) => {
            return user.email === currentUser.email;
        });

        let products;

        let comparisons;

        if (user) {
            products = getLegacyData('products', currentUser.email) || [];
            comparisons = getLegacyData('comparisons', currentUser.email) || [];
        }

        if (!products || !products.length) {
            // already migrated
            return Promise.resolve();
        }

        return SyncAPI
            .migrateLegacyData(currentUser.id, products, comparisons)
            .then(() => {
                LegacyStorage.unset(`${KEYS.products}-${currentUser.email}`);
                LegacyStorage.unset(`${KEYS.comparisons}-${currentUser.email}`);
            });
    }
}

export default new LegacyDataMigrator();