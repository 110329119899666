import 'annuity_lab/styles/modal_dialog';
import 'common/components/dialogs/info_dialog/info_dialog.css.scss';

import Modal from 'react-modal';

const body = document.querySelector('body');

const InfoDialog = (props) => {
    const {title, content, className, onRequestClose} = props;

    return (
        <Modal
            isOpen
            appElement={body}
            {...props}
            className="modal-dialog info-dialog panel"
            overlayClassName={`modal-dialog-overlay ${className}`}>

            <div className="header">
                <h4>{title}</h4>

                <button className="close-button" onClick={onRequestClose} />
            </div>

            <div className="body">
                {content}
            </div>
        </Modal>
    );
};

export default InfoDialog;