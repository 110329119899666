/* eslint-disable react/no-multi-comp */
/* eslint-disable no-inline-comments */
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import Comparisons from 'annuity_lab/tools/lbc/containers/comparisons/comparisons.js.jsx';
import ComparisonsPlus from
    'annuity_lab/tools/lbc/containers/comparisons_plus/comparisons_plus.js.jsx';
import CardsComparison from
    'annuity_lab/tools/lbc/containers/cards_comparison/cards_comparison.js.jsx';
import CardsComparisonPlus from
    'annuity_lab/tools/lbc/containers/cards_comparison_plus/cards_comparison_plus.js.jsx';

import DeepRouteRedirect from 'annuity_lab/tools/common/lib/deep_route_redirect';
import RiderCards from 'annuity_lab/tools/lbc/containers/rider_cards/rider_cards.js.jsx';
import RiderCardsPlus from
    'annuity_lab/tools/lbc/containers/rider_cards_plus/rider_cards_plus.js.jsx';
import SelectRider from 'annuity_lab/tools/lbc/containers/select_rider/select_rider.js.jsx';
import {useDeepRouteRedirect} from 'annuity_lab/hooks/useDeepRouteRedirect';

const comparisonsRedirect = new DeepRouteRedirect('/lbc/comparisons');
const comparisonsPlusRedirect = new DeepRouteRedirect('/lbc/comparisons/plus');

const LBCComparisonsRoutes = ({history, location}) => {
    const shouldRender = useDeepRouteRedirect(comparisonsRedirect, location, history);

    // We need to disable rendering of the tree until the
    // check for redirect has occurred. Otherwise we will visit the baseRoute
    // which will generate fake analytics data.
    if (!shouldRender) {
        return null;
    }

    return (
        <Switch>
            <Route exact path="/lbc/comparisons">
                <div className='comparisons-tab-content'>
                    <Comparisons />
                </div>
            </Route>
            <Route exact path="/lbc/comparisons/new">
                <div className='comparisons-tab-content'>
                    <CardsComparison />
                </div>
            </Route>
            <Route exact path="/lbc/comparisons/:id">
                <div className='comparisons-tab-content'>
                    <CardsComparison />
                </div>
            </Route>
        </Switch>
    );
};

const LBCPlusComparisonsRoutes = React.memo((props) => {
    const {history, location} = props;

    const shouldRender = useDeepRouteRedirect(comparisonsPlusRedirect, location, history);

    // We need to disable rendering of the tree until the
    // check for redirect has occurred. Otherwise we will visit the baseRoute
    // which will generate fake analytics data.
    if (!shouldRender) {
        return null;
    }

    return (
        <Switch>
            <Route exact path="/lbc/comparisons/plus">
                <div className='comparisons-plus-tab-content'>
                    <ComparisonsPlus />
                </div>
            </Route>
            <Route path="/lbc/comparisons/plus/new">
                <div className='comparisons-plus-tab-content'>
                    <CardsComparisonPlus key="new"/>
                </div>
            </Route>
            <Route path="/lbc/comparisons/plus/:id">
                <div className='comparisons-plus-tab-content'>
                    <CardsComparisonPlus key={location.pathname.split('/')[4]}/>
                </div>
            </Route>
        </Switch>
    );
});

const LBCRoutes = ({history, location}) => {
    return (
        <div className="view-content">
            <div className="view-content-container">
                <Switch>
                    <Route exact path="/lbc">
                        <div className='select-rider-tab-content'>
                            <SelectRider />
                        </div>
                    </Route>

                    <Route exact path="/lbc/cards">
                        <div className='cards-tab-content'>
                            <RiderCards />
                        </div>
                    </Route>
                    <Route exact path="/lbc/cards/plus">
                        <div className='cards-plus-tab-content'>
                            <RiderCardsPlus />
                        </div>
                    </Route>

                    <Route path="/lbc/comparisons/plus">
                        <LBCPlusComparisonsRoutes
                            history={history}
                            location={location}/>
                    </Route>

                    <Route path="/lbc/comparisons">
                        <LBCComparisonsRoutes
                            history={history}
                            location={location}/>
                    </Route>

                    <Redirect to="/lbc" />
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(LBCRoutes);