import Confirm from 'common/components/dialogs/confirm/confirm.js.jsx';

class ModalDialogs {
    constructor() {
        this.currentDialog = null;
        this.host = null;
    }

    confirm(title, content, onConfirm, onCancel = () => {}) {
        if (!this.host) {
            throw 'No dialog hosts registered!';
        }

        this.currentDialog = {
            klass: Confirm,
            props: {
                title: title,
                content: content,
                onRequestClose: (...args) => {
                    onCancel(...args);
                    this.close();
                },
                onConfirm: (...args) => {
                    onConfirm(...args);
                    this.close();
                },
            }
        };

        this.host.notify();
    }

    close() {
        this.currentDialog = null;
        this.host.notify();
    }

    registerHost(onUpdate) {
        if (this.host) {
            throw 'Tried to register two dialog hosts';
        }

        this.host = {notify: onUpdate};
    }

    unregisterHost() {
        this.host = null;
    }
}

export default new ModalDialogs();
