import 'annuity_lab/tools/imo/components/tab_view/tab_view.css.scss';

import {useEffect} from 'react';
import {Switch, Route, NavLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import StandardView from 'annuity_lab/tools/imo/components/standard_view/standard_view.js.jsx';
import CarryForwardView from
    'annuity_lab/tools/imo/components/carry_forward_view/carry_forward_view.js.jsx';
import WithTooltip from 'annuity_lab/components/with_tooltip/with_tooltip.js.jsx';

const tooltipInformation = {
    carryForward: {
        title: 'Income Carryforward',
        info: 'Income Carryforward can only be modeled for one rider at a time'
    }
};

const TabView = ({location, history, match, model, handleCarryForwardChange}) => {
    const {pathname: currentPath} = location;

    useEffect(() => {
        if (
            currentPath !== `${match.url}/standard` &&
            currentPath !== `${match.url}/carry-forward`) {
            history.replace(`${match.url}/standard`);
        }
    }, [history, currentPath]);

    const id = match.params.id ?? null;

    return (
        <div className="imo-tab-view">
            <div className="tab-view-navigation">
                <NavLink to={`/imo/models/${id ?? 'new'}/standard`} activeClassName="active">
                    Standard
                </NavLink>
                <NavLink to={`/imo/models/${id ?? 'new'}/carry-forward`} activeClassName="active">
                    <WithTooltip
                        title={tooltipInformation.carryForward.title}
                        content={tooltipInformation.carryForward.info}>
                        Income Carryforward
                    </WithTooltip>
                </NavLink>
            </div>
            <div className="tab-view-rotues">
                <Switch>
                    <Route exact path={`${match.path}/standard`}>
                        <StandardView model={model} />
                    </Route>
                    <Route exact path={`${match.path}/carry-forward`}>
                        <CarryForwardView
                            model={model}
                            handleCarryForwardChange={handleCarryForwardChange} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

TabView.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    model: PropTypes.object,
    handleCarryForwardChange: PropTypes.func
};

export default withRouter(TabView);