import {connect} from 'react-redux';

import {updateProductContextPlus} from 'annuity_lab/tools/lbc/actions/product_context_plus.js';
import {updateCard, removeCard} from 'annuity_lab/tools/lbc/actions/rider_cards_plus.js';
import {updateRiderType} from 'annuity_lab/tools/lbc/actions/select_rider.js';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import RiderCardsPlus from
    'annuity_lab/tools/lbc/components/rider_cards_plus/rider_cards_plus.js.jsx';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';
import {TOOLS} from 'common/domain/tools.js';

function mapStateToProps({lbc}) {
    const {riderCardsPlus, productContextPlus, selectRider} = lbc;

    const sortedCards = CardsPlusUtil.sortByName(_.values(riderCardsPlus));

    const moneyLabel = Constants.CONTEXT_MONEY_LABEL;

    return {
        productContextPlus,
        riderCardsPlus: sortedCards,
        moneyLabel,
        riderType: selectRider.riderType,
        tool: TOOLS.LIVING_BENEFIT_RIDERS_CORE,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateProductContext: (money) => {
            dispatch(updateProductContextPlus(money));
        },
        updateCard: (card, updateTemplate) => {
            dispatch(updateCard(card.id, updateTemplate));
        },
        removeCard: (card) => {
            dispatch(removeCard(card.id));
        },
        updateRiderType: (riderType) => {
            dispatch(updateRiderType(riderType));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RiderCardsPlus);
