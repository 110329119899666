import {createStore as reduxCreateStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import sync from 'annuity_lab/middlewares/sync.js';
import handleTransitions from 'redux-history-transitions';
import reducer from 'annuity_lab/reducers/';

const MIDDLEWARE = [thunk, sync];

export default function createStore(history) {
    const transitionEnhancer = handleTransitions(history);

    let enhancer = transitionEnhancer;

    if (IS_DEBUG && global.devToolsExtension) {
        enhancer = compose(transitionEnhancer, global.devToolsExtension());
    }

    return applyMiddleware(...MIDDLEWARE)(reduxCreateStore)(reducer, enhancer);
}
