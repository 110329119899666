import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Constants from 'common/services/constants.js';
import ShareComparisonDialog
    from 'annuity_lab/tools/common/components/dialogs/share_comparison/share_comparison.js.jsx';
import {loadContacts} from 'annuity_lab/tools/common/actions/contacts.js';
import {
    completeComparisonShare,
    cancelComparisonShare,
} from 'annuity_lab/tools/common/actions/share.js';
import {TOOLS} from 'common/domain/tools.js';

const {
    LIVING_BENEFIT_RIDERS_CORE,
    LIVING_BENEFIT_RIDERS_PLUS,
    INCOME_MODELER
} = TOOLS;

const WholesalerShareModal = (props) => {
    const {
        // From redux
        user,
        offline,
        contacts,
        cancelComparisonShare,
        completeComparisonShare,
        loadContacts,

        // Direct props
        tool,
        className,
        comparisonName,
        comparisonId,
        additionalShareData
    } = props;

    const subject = tool === INCOME_MODELER ? 'Model information' : 'Comparison information';
    const entity = tool === INCOME_MODELER ? 'Model' : 'Comparison';
    const emailBodyText = Constants.EMAIL_BODY_TEXT[
        tool === LIVING_BENEFIT_RIDERS_PLUS ? LIVING_BENEFIT_RIDERS_CORE : tool
    ];

    const onRequestClose = () => {
        cancelComparisonShare();
    };

    const onShare = ({subject, body, agentName, receiversEmails}) => {
        completeComparisonShare({
            subject,
            body,
            agentName,
            receiversEmails,
            userId: user.id,
            comparisonId,
            tool,
            ...additionalShareData
        });
    };

    return (
        <ShareComparisonDialog
            onRequestClose={onRequestClose}
            loadContacts={loadContacts}
            contacts={contacts}
            className={className}
            onShare={onShare}
            comparisonName={comparisonName}
            offline={offline}
            userEmail={user.email}
            entity={entity}
            subject={subject}
            emailBodyText={emailBodyText}/>
    );
};

const mapStateToProps = ({auth, apiStatus, common}) => {
    const {contacts} = common;

    return {
        user: auth.user,
        offline: !apiStatus.online,
        contacts,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadContacts: () => {
        dispatch(loadContacts());
    },
    cancelComparisonShare: () => {
        dispatch(cancelComparisonShare());
    },
    completeComparisonShare: (options) => {
        dispatch(completeComparisonShare(options));
    },
});

WholesalerShareModal.propTypes = {
    user: PropTypes.object.isRequired,
    offline: PropTypes.bool.isRequired,
    contacts: PropTypes.array.isRequired,
    loadContacts: PropTypes.func.isRequired,
    completeComparisonShare: PropTypes.func.isRequired,
    cancelComparisonShare: PropTypes.func.isRequired,
    tool: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    comparisonName: PropTypes.string.isRequired,
    comparisonId: PropTypes.string.isRequired,
    additionalShareData: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(WholesalerShareModal);