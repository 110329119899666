import 'annuity_lab/components/input_with_icon/input_with_icon.css.scss';
import PropTypes from 'prop-types';

const InputWithIcon = (props) => {
    const inputProps = _.omit(props, 'isValid', 'iconClass');

    const {iconClass, isValid} = props;

    let className = 'input-with-icon';

    if (isValid === true) {
        className += ' valid';
    }

    if (isValid === false) {
        className += ' invalid';
    }

    return (
        <div className={className}>
            <div className={`input-icon ${iconClass}`} />
            <input {...inputProps} />
        </div>
    );
};

InputWithIcon.propTypes = {
    iconClass: PropTypes.string.isRequired,
    isValid: PropTypes.bool
};

export default InputWithIcon;