import keyMirror from 'common/services/key_mirror.js';
import {TOOLS} from 'common/domain/tools.js';

export default keyMirror([
    'SELECT_RIDER',
    'SHOW_SELECT_RIDER_ERROR',

    'PRODUCT_CONTEXT_SET',
    'PRODUCT_CONTEXT_PLUS_SET',

    'COMPARISONS_LOADED',
    'COMPARISON_SAVED',
    'COMPARISON_REMOVED',

    'CARDS_PLUS_LOADED',
    'CARD_PLUS_CREATED',
    'CARD_PLUS_UPDATED',
    'CARD_PLUS_REMOVED',
    'CARDS_PLUS_RESET',

    'COMPARISONS_PLUS_LOADED',
    'COMPARISON_PLUS_SAVED',
    'COMPARISON_PLUS_REMOVED',

    'NEW_COMPARISON_PLUS_SET',

    'SYNC_STARTED',
    'SYNC_COMPLETED',
    'SHARE_COMPARISON_IN_PROGRESS',
    'SHARE_COMPARISON_REQUESTED',
    'SHARE_COMPARISON_COMPLETED',
    'SHARE_COMPARISON_CANCELED',

    'SHARE_COMPARISON_STATUS_SEEN',
], TOOLS.LIVING_BENEFIT_RIDERS_CORE.toUpperCase());
