import actionTypes from 'annuity_lab/tools/fia/actions/action_types.js';

const initialState = {};

export default function comparisonReducer(state = initialState, action) {
    let result;

    switch (action.type) {
    case actionTypes.COMPARISONS_LOADED: {
        result = _.assign({}, action.payload.data);
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
