// import 'annuity_lab/tools/admin/components/fia_uploader/fia_uploader.css.scss';

import config from 'config/config.js';
import Attachment from 'annuity_lab/tools/admin/components/attachment/attachment.js.jsx';
import Uploader from 'annuity_lab/tools/admin/services/uploader.js';
import ConfirmDialog from 'common/components/dialogs/confirm/confirm.js.jsx';
import LoadingIndicator
    from 'annuity_lab/components/loading_indicator/loading_indicator.js.jsx';
import MessageDialog
    from 'common/components/dialogs/message_dialog/message_dialog.js.jsx';
import {useRef, useState} from 'react';

const SUPPORTED_FILE_TYPES = [
    '.xlsx',
];

const LbcUploader = () => {
    const [validType, setValidType] = useState(true);
    const [file, setFile] = useState(null);
    const [parseError, setParseError] = useState(null);
    const [parseWarning, setParseWarning] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
    const inputRef = useRef(null);

    const isValidFileType = (file) => {
        if (!file) {
            return true;
        }

        const filename = file.name;
        const fileType = filename.substring(filename.lastIndexOf('.'));

        return SUPPORTED_FILE_TYPES.indexOf(fileType) !== -1;
    };

    const onChange = () => {
        const file = inputRef.current.files[0];
        const valid = isValidFileType(file);

        setValidType(valid);
        setFile(file);
        setParseError(null);
    };

    // eslint-disable-next-line react/no-multi-comp
    const renderWarnings = (warnings) => (
        <ul>
            {warnings.map((conflict, id) =>
                <li key={id}>{conflict}</li>
            )}
        </ul>
    );

    const uploadFile = (force = false) => {
        setUploading(true);

        Uploader.uploadLbcData(file, force)
            .then(response => {
                const {error, warning} = response;

                setUploading(false);
                setParseError(error);
                setParseWarning(warning);
                setIsUploadSuccessful(!error && !warning);
            });
    };

    return (
        <div className='fia-uploader'>
            <div className='title'>Upload LBC Data File</div>
            {
                uploading &&
                <LoadingIndicator loadingText='Uploading...' />
            }
            {/* <Attachment*/}
            {/*    header='View the requirements for uploading a file'*/}
            {/*    linkText='View Guide'*/}
            {/*    linkTarget={`${config.API_URL}/fia/upload/importGuide`}/>*/}
            <Attachment
                header='View the current version of data used by the application'
                linkText='Download Data File'
                linkTarget={`${config.API_URL}/lbc/upload/lastDocument`}/>
            <div className='file-input'>
                <input
                    id='index-file'
                    ref={inputRef}
                    type='file'
                    name='indexesData'
                    onChange={() => {
                        onChange();
                    }}
                    accept={SUPPORTED_FILE_TYPES}/>
                <label htmlFor='index-file'>Select New Data File to Upload</label>
                {
                    file &&
                    <div className='file-info'>
                        <div className='label'>Selected file</div>
                        <div className='file-name'>{file.name}</div>
                    </div>
                }
            </div>
            {
                !validType &&
                <div className='error'>
                    The selected file type is not supported.
                </div>
            }
            {
                file && validType &&
                <button
                    className='upload-button'
                    onClick={() => {
                        uploadFile();
                    }}>
                    Upload
                </button>
            }
            {
                !!parseError &&
                <div className='error parser-errors'>
                    { parseError }
                </div>
            }
            {
                isUploadSuccessful &&
                <MessageDialog
                    onRequestClose={() => {
                        setIsUploadSuccessful(null);
                    }}
                    title='Success'
                    content='Your file has been successfully uploaded.'/>
            }
            {
                parseWarning && parseWarning.length > 0 &&
                <ConfirmDialog
                    title='Discrepancies with previous document'
                    content={
                        <div>
                            <p>
                                It seems that the values in the new document
                                do not match the values from the current one.
                            </p>
                            {renderWarnings(parseWarning)}
                            <p>Are you sure you want to upload this file?</p>
                        </div>
                    }
                    onRequestClose={() => {
                        setParseWarning(null);
                    }}
                    onConfirm={() => {
                        uploadFile(true);
                        setParseWarning(null);
                    }}/>
            }
        </div>
    );
};

export default LbcUploader;