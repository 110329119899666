/* eslint-disable react/no-multi-comp */
/* eslint-disable max-len */
import 'annuity_lab/tools/lbc/components/cards_comparison_plus/cards_comparison_plus.css.scss';

import PropTypes from 'prop-types';
import {useState, useEffect, useMemo, useCallback} from 'react';

import ComparisonPlusUtil from 'annuity_lab/tools/lbc/services/comparison_plus_util.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import ComparisonPlusControls from
    'annuity_lab/tools/lbc/containers/comparison_plus_controls/comparison_plus_controls.js.jsx';
import Disclaimer from 'annuity_lab/components/disclaimer/disclaimer.js.jsx';
import NumberFormat from 'common/services/number_format';

import {requirePlusRider} from 'annuity_lab/tools/lbc/services/routes_navigation_util.js';
import TabView from 'annuity_lab/tools/lbc/components/tab_view/tab_view.js.jsx';

const CardsComparisonPlus = (props) => {
    const {
        location,
        comparisonsPlus,
        history,
        riderCardsPlus,
        riderType,
        updateRiderType,
        match,
        newComparisonPlus
    } = props;

    const onNewComparisonPage = useMemo(() =>
        location.pathname.startsWith('/lbc/comparisons/plus/new'), [location.pathname]);

    const getSavedComparison = useCallback(() => {
        return comparisonsPlus[match.params.id];
    }, [comparisonsPlus, match.params.id]);

    const getComparison = useCallback(() => {
        if (onNewComparisonPage) {
            return newComparisonPlus;
        }

        return getSavedComparison();
    }, [newComparisonPlus, getSavedComparison, onNewComparisonPage]);

    const [comparison, setComparison] = useState(getComparison);
    const cards = _.isEqual(comparison, {}) || !comparison  ? [] : comparison.cardIds.map(id => riderCardsPlus[id]);

    useEffect(() => {
        requirePlusRider(riderType, updateRiderType);
    }, []);

    useEffect(() => {
        const newComparison = getComparison();

        if (!_.isEqual(comparison, newComparison)) {
            setComparison(newComparison);
        }
    }, [comparison, getComparison]);

    useEffect(() => {
        const name = _.get(comparison, 'name', 'unsaved');

        AnnuityLabAnalytics.trackView(`Plus Comparison > ${name}`);
    }, [comparison]);

    const cannotRender = useMemo(() =>
        !comparison ||
        _.isEqual(comparison, {}) ||
        _.isEqual(cards, []) ||
        cards.some(card => card === undefined) ||
        ComparisonPlusUtil.hasInvalidCards(comparison, cards),
    [comparison, cards]);

    useEffect(() => {
        if (cannotRender) {
            history.replace('/lbc/comparisons/plus');
        }
    }, [cannotRender, history]);

    const renderComparisonHeader = () => {
        const {name, premium, currentAge, ageAtWithdrawal} = comparison;

        let renderedName = '';

        let comparisonSummary = '';

        const onCarryForwardTab = location.pathname.endsWith('carry-forward');
        // const onMonteCarloTab = location.pathname.endsWith('monte-carlo');

        if (onCarryForwardTab) {
            renderedName = 'Carry Forward Example';

            // comparisonSummary = `(${NumberFormat.currency(100000)} / ${59}-${64} / 6% & 4% Withdrawal)`;

            // if (onCarryForwardTab) {
            //     comparisonSummary = `(${NumberFormat.currency(100000)} / ${59}-${64} / 6% & 4% Withdrawal)`;
            // } else {
            //     comparisonSummary = `(${NumberFormat.currency(100000)} / ${55}-${65})`;
            // }
        } else {
            comparisonSummary = `(${NumberFormat.currency(Number(premium))} / ${currentAge}-${ageAtWithdrawal})`;
            if (name) {
                renderedName = name;
            } else {
                renderedName = 'New Comparison';
            }
        }

        return (
            <div className="comparison-header">
                <div className="plus-name">
                    {renderedName}
                    {comparisonSummary && (
                        <>
                            {': '}
                            <span>{comparisonSummary}</span>
                        </>
                    )}
                </div>
                <ComparisonPlusControls
                    view="desktop"
                    location={location}
                    match={match}
                    history={history} />
            </div>
        );
    };

    if (cannotRender) {
        return null;
    }

    return (
        <div className="products-comparison-plus-view">
            {renderComparisonHeader()}

            <TabView cards={cards} comparison={comparison}/>

            <Disclaimer urboNumber='VAM-3427AO (2/21)' />
        </div>
    );
};

CardsComparisonPlus.propTypes = {
    history: PropTypes.object,
    riderCardsPlus: PropTypes.object,
    comparisonPlus: PropTypes.object
};

export default CardsComparisonPlus;