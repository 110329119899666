import ACTION_TYPES from 'annuity_lab/tools/common/actions/action_types.js';
import ContactsAPI from 'annuity_lab/tools/lbc/services/data/api/contacts.js';

export function loadContacts() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const contacts = ContactsAPI
            .find({userId})
            .map(contact => contact.email);

        dispatch({
            type: ACTION_TYPES.CONTACTS_LOADED,
            payload: {contacts},
        });
    };
}
