import PropTypes from 'prop-types';

const PercentageInput = (props) => {
    const onBlur = () => {
        if (!props.value) {
            props.onChange('');

            return;
        }

        const min = props.min ?? 0;
        const max = props.max ?? 100;

        const clampedValue = Math.max(min, Math.min(max, Number(props.value)));
        const formatted = clampedValue.toFixed(2);

        props.onChange(formatted);
    };

    const onChange = (ev) => {
        const {value} = ev.target;
        const regex = /^-?[0-9]*(\.[0-9]{0,2})?$/;

        if (regex.test(value)) {
            props.onChange(value);
        }
    };

    return (
        <input
            {...props}
            onBlur={onBlur}
            onChange={onChange}
            type="text"/>
    );
};

PercentageInput.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.string
};

export default PercentageInput;
