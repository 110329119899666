import 'annuity_lab/components/with_tooltip/with_tooltip.css.scss';

import {useState} from 'react';
import InfoDialog from 'common/components/dialogs/info_dialog/info_dialog.js.jsx';

const WithTooltip = (props) => {
    const {title, content, children} = props;
    const [modalOpened, setModalOpened] = useState(false);

    const closeModal = () => setModalOpened(false);
    const openModal = () => setModalOpened(true);

    return (
        <div className="with-tooltip">
            <div className="content">
                {children}
            </div>
            <span className="info-icon" onClick={openModal} />

            { modalOpened &&
                <InfoDialog
                    title={title}
                    content={content}
                    onRequestClose={closeModal}/>
            }
        </div>
    );
};

export default WithTooltip;
