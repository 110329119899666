import EntityStore from 'annuity_lab/services/data/base/entity_store.js';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';

const PERSISTENCE_KEY = 'models';

class ModelsAPI extends EntityStore {
    constructor() {
        super(PERSISTENCE_KEY);
    }

    create(template) {
        const now = moment.utc().valueOf();

        const {riderA, riderB} = template;

        const riderAWithId = {
            ...riderA,
            id: riderA.id || uuidv4()
        };

        let riderBWithId = null;

        if (riderB) {
            riderBWithId = {
                ...riderB,
                id: riderB.id || uuidv4()
            };
        }

        const entity = _.defaults({
            riderA: riderAWithId,
            riderB: riderBWithId
        }, template, {
            id: uuidv4(),
            createdAt: now,
            updatedAt: now
        });

        const entities = this._loadEntities();

        entities.push(entity);
        this._store(entities);

        return entity;
    }
}

export default new ModelsAPI();
