/* eslint-disable max-len */
/* eslint-disable react/no-multi-comp */
import 'annuity_lab/tools/imo/components/carry_forward_view_table/carry_forward_view_table.css.scss';

import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import CarryForwardViewTableRows from 'annuity_lab/tools/imo/components/carry_forward_view_table_rows/carry_forward_view_table_rows.js.jsx';

const ColumnNamesTD = () => {
    return (
        <td className="large">
            <div className="two-column-container">
                <span className="standard-text table-rider-name">STANDARD</span>
                <span className="carry-forward-text table-rider-name">INC CF</span>
            </div>
        </td>
    );
};

const CarryForwardViewTable = ({ageAtWithdrawal, tableValues}) => {
    const [highlightedYearRow, setHighlightedYearRow] = useState(null);

    useEffect(() => {
        const table = document.querySelector('.imo-carry-forward-view-table');

        const handleClick = (ev) => {
            if (!table.contains(ev.target)) {
                setHighlightedYearRow(null);
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const handleTableRowClick = (yearTableRow) => () => {
        setHighlightedYearRow(yearTableRow);
    };

    return (
        <div className="imo-carry-forward-view-table">
            <table>
                <thead>
                    <tr>
                        <th>W/D YEAR</th>
                        <th>AGE</th>
                        <th>GUARANTEED ROLL-UP</th>
                        <th>CONTRACT VALUE</th>
                        <th>ANNUAL INCOME</th>
                        <th>TOTAL INCOME</th>
                        <th>INC CF AMOUNT</th>
                        <th>AVAIL INCOME</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={highlightedYearRow === null ? 'highlighted' : undefined}>
                        <td />
                        <td />
                        <td />
                        <ColumnNamesTD />
                        <ColumnNamesTD />
                        <ColumnNamesTD />
                        <td />
                        <td />
                    </tr>
                    <CarryForwardViewTableRows
                        ageAtWithdrawal={ageAtWithdrawal}
                        tableValues={tableValues}
                        highlightedYearRow={highlightedYearRow}
                        onTableRowClick={handleTableRowClick}/>

                </tbody>
            </table>
        </div>
    );
};

CarryForwardViewTable.propTypes = {
    ageAtWithdrawal: PropTypes.string,
    tableValues: PropTypes.object
};

export default CarryForwardViewTable;