import 'annuity_lab/containers/logout_button/logout_button.css.scss';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logout} from 'annuity_lab/actions/auth.js';

function LogoutButton({logout}) {
    return (
        <div
            className='logout-button'
            onClick={logout}>Log Out</div>
    );
}

LogoutButton.propTypes = {
    logout: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        logout: () => {
            dispatch(logout());
        },
    };
}

export default connect(null, mapDispatchToProps)(LogoutButton);
