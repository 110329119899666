import Select from 'annuity_lab/components/select/select.js.jsx';
import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import RiderSelect from 'annuity_lab/tools/imo/containers/rider_select/rider_select.js.jsx';
import CurrencyInput from 'annuity_lab/components/currency_input/currency_input.js.jsx';
import PercentageInput from 'annuity_lab/components/percentage_input/percentage_input.js.jsx';
import Constants from 'common/services/constants.js';

const fieldData = {
    premium: {
        label: 'Premium',
        information: 'Lorem ipsum all the way'
    },
    selectCard: {
        label: 'Select a Card (Optional)',
        information: 'Lorem ipsum all the way'
    },
    rollup: {
        label: 'Roll-Up %',
        information: 'Lorem ipsum all the way'
    },
    interestType: {
        label: 'Interest Type',
        information: 'Lorem ipsum all the way'
    },
    cashValueGreater: {
        label: 'Withdrawal rate when contract value > $0',
        information: 'Lorem ipsum all the way'
    },
    cashValueEqual: {
        label: 'Withdrawal rate when contract value = $0',
        information: 'Lorem ipsum all the way'
    },
    rateOfReturn: {
        label: 'Assumed Rate of Return',
        information: 'Lorem ipsum all the way'
    }
};

const RiderCardInputs = ({
    rider,
    handleChange,
    erroredFields = [],
    clientInformation,
    withoutDropdown
}) => {
    const onChange = (key, value) => {
        handleChange({
            [key]: value
        });
    };

    const getErrorClassFor = (fieldName) => erroredFields.includes(fieldName) ? 'error' : '';

    return (
        <React.Fragment>
            {withoutDropdown !== true && (
                <WithLabel label={fieldData.selectCard.label.toUpperCase()}>
                    <RiderSelect
                        selectedRiderId={rider.selectedRiderCardId}
                        clientInformation={clientInformation}
                        handleChange={handleChange} />
                </WithLabel>
            )}

            <WithLabel label={fieldData.premium.label.toUpperCase()}>
                <CurrencyInput
                    value={rider.premium}
                    onChange={(nv) => onChange('premium', nv)}
                    placeholder="Select a Premium Amount"
                    className={getErrorClassFor('premium')} />
            </WithLabel>

            <WithLabel label={fieldData.rollup.label.toUpperCase()}>
                <PercentageInput
                    value={rider.rollup}
                    onChange={(nv) => onChange('rollup', nv)}
                    placeholder="Enter Value"
                    className={getErrorClassFor('rollup')} />
            </WithLabel>

            <WithLabel label={fieldData.interestType.label.toUpperCase()}>
                <Select
                    value={Constants.INTEREST_TYPE_OPTIONS
                        .find(o => o.value === rider.interestType)
                    }
                    onChange={(option) => onChange('interestType', option.value)}
                    options={Constants.INTEREST_TYPE_OPTIONS}
                    placeholder="Select Interest Type"
                    className={getErrorClassFor('interestType')} />
            </WithLabel>

            <WithLabel label={fieldData.cashValueGreater.label.toUpperCase()}>
                <PercentageInput
                    value={rider.cashValueGreaterThanZeroRate}
                    onChange={(nv) => onChange('cashValueGreaterThanZeroRate', nv)}
                    placeholder="Enter Value"
                    className={getErrorClassFor('cashValueGreaterThanZeroRate')} />
            </WithLabel>

            <WithLabel label={fieldData.cashValueEqual.label.toUpperCase()}>
                <PercentageInput
                    value={rider.cashValueEqualZeroRate}
                    onChange={(nv) => onChange('cashValueEqualZeroRate', nv)}
                    placeholder="Enter Value"
                    className={getErrorClassFor('cashValueEqualZeroRate')} />
            </WithLabel>

            <WithLabel label={fieldData.rateOfReturn.label.toUpperCase()}>
                <PercentageInput
                    min={-4}
                    max={4}
                    value={rider.assumedReturnRate}
                    onChange={(nv) => onChange('assumedReturnRate', nv)}
                    placeholder="Enter Value"
                    className={getErrorClassFor('assumedReturnRate')} />
            </WithLabel>
        </React.Fragment>
    );
};

export default RiderCardInputs;