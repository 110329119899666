import ACTION_TYPES from 'annuity_lab/tools/imo/actions/action_types.js';
import NewModelAPI from 'annuity_lab/tools/lbc/services/data/api/imo_new_model.js';

export function updateNewModel(newModel) {
    const newModelSafe = _.pick(newModel, [
        'clientInformation',
        'riderA',
        'riderB',
        'carryForward'
    ]);

    return (dispatch, getState) => {
        const currentUser = getState().auth.user;

        NewModelAPI.update(
            {userId: currentUser.id},
            newModelSafe);
        dispatch({
            type: ACTION_TYPES.NEW_MODEL_SET,
            payload: newModelSafe,
        });
    };
}

export function resetNewModel() {
    return {
        type: ACTION_TYPES.NEW_MODEL_RESET
    };
}