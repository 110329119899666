import 'annuity_lab/tools/common/components/layout/layout.css.scss';

import PropTypes from 'prop-types';

import ToolLayout from 'annuity_lab/components/tool_layout/tool_layout.js.jsx';
import Sync from 'annuity_lab/tools/lbc/containers/sync/sync.jsx';
import Menu from 'annuity_lab/tools/lbc/components/menu/menu.js.jsx';
import DialogHost from 'annuity_lab/tools/lbc/components/dialog_host/dialog_host.js.jsx';
import Footer from 'annuity_lab/tools/lbc/containers/footer/footer.js.jsx';
import FooterJumpFix from 'annuity_lab/tools/lbc/services/footer_jump_fix';
import RecentToolAPI from 'annuity_lab/services/data/api/recent_tool.js';
import LBCRoutes from 'annuity_lab/tools/lbc/routes.js.jsx';
import RiderCardsControls from
    'annuity_lab/tools/common/containers/rider_cards_controls/rider_cards_controls.js.jsx';
import RiderCardsPlusControls from
    'annuity_lab/tools/lbc/containers/rider_cards_plus_controls/rider_cards_plus_controls.js.jsx';
import ComparisonControls
    from 'annuity_lab/tools/lbc/containers/comparison_controls/comparison_controls.js.jsx';
import ComparisonPlusControls from
    'annuity_lab/tools/lbc/containers/comparison_plus_controls/comparison_plus_controls.js.jsx';
import ShareDialogs from 'annuity_lab/tools/common/containers/share_dialogs/share_dialogs.js.jsx';

export default class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.updateRecentUserTool();
    }

    componentDidUpdate() {
        this.updateRecentUserTool();
    }

    updateRecentUserTool() {
        const {user, location} = this.props;

        if (this.props.user) {
            const recentToolApi = new RecentToolAPI(user.id);

            recentToolApi.set(location.pathname);
        }
    }

    onFocus(e) {
        FooterJumpFix.hide(e);
    }

    onBlur(e) {
        FooterJumpFix.show(e);
    }

    render() {
        const {
            user,
            location,
            history
        } = this.props;

        const onCardsPlusView = location.pathname === '/lbc/cards/plus';
        const onCardsView = location.pathname === '/lbc/cards';
        const onComparisonsPlusView = location.pathname.startsWith('/lbc/comparisons/plus');
        const onComparisonsView = location.pathname.startsWith('/lbc/comparisons')
            && !onComparisonsPlusView;

        let {headerTitle} = this.props;

        if (onCardsView || onComparisonsView) {
            headerTitle += ': Level Rate Riders';
        } else if (onCardsPlusView || onComparisonsPlusView) {
            headerTitle += ':  Enhanced Rate Riders';
        }

        let FooterChildren;

        let id;

        if (location.pathname === '/lbc/cards') {
            FooterChildren = RiderCardsControls;
        }

        if (location.pathname === '/lbc/cards/plus') {
            FooterChildren = RiderCardsPlusControls;
        }

        if (
            location.pathname.startsWith('/lbc/comparisons') &&
            !location.pathname.startsWith('/lbc/comparisons/plus') &&
            location.pathname !== '/lbc/comparisons'
        ) {
            if (location.pathname !== '/lbc/comparisons/new') {
                id = location.pathname.split('/')[3];
            }

            FooterChildren = ComparisonControls;
        }

        if (
            location.pathname.startsWith('/lbc/comparisons/plus') &&
            location.pathname !== '/lbc/comparisons/plus'
        ) {
            if (!location.pathname.startsWith('/lbc/comparisons/plus/new')) {
                id = location.pathname.split('/')[4];
            }

            FooterChildren = ComparisonPlusControls;
        }

        // Tool layout wrapper gets rendered on all /mad routes.
        // We need to create our own match object to pass to the footer children which
        // are rendered in the ToolLayout component
        const match = {
            params: {
                id
            }
        };

        return (
            <div className='tool-wrapper'>
                {
                    !this.state.loading &&
                        <ToolLayout
                            user={user}
                            headerTitle={headerTitle}
                            footer={(
                                <Footer>
                                    {FooterChildren &&
                                        <FooterChildren
                                            history={history}
                                            location={location}
                                            match={match}
                                            key={location.pathname}/>}
                                </Footer>
                            )}>
                            <div
                                className="layout"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}>

                                <div className='content'>
                                    <Menu
                                        root={this.props.menuRoot}
                                        history={history}
                                        location={location}
                                        comparisons={this.props.menuComparisons}
                                        comparisonsPlus={this.props.menuComparisonsPlus}
                                        riderType={this.props.riderType}
                                        updateShowSelectRiderError={
                                            this.props.updateShowSelectRiderError}/>
                                    <LBCRoutes />
                                </div>
                                <DialogHost />
                            </div>
                        </ToolLayout>
                }
                <ShareDialogs entity="comparison"/>
                <Sync
                    onSyncCompleted={() => this.setState({loading: false})}
                    loadComparisons={this.props.loadComparisons}/>
            </div>
        );
    }
}

Layout.propTypes = {
    riderType: PropTypes.string,
    user: PropTypes.object,
    headerTitle: PropTypes.string.isRequired,
    menuRoot: PropTypes.string.isRequired,
    menuComparisons: PropTypes.array.isRequired,
    menuComparisonsPlus: PropTypes.array.isRequired,
    loadComparisons: PropTypes.func.isRequired,
};
