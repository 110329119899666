import EntityStore from 'annuity_lab/services/data/base/entity_store.js';

// XXX If we want to fix the following typo in the persistence key:
// - the users will lose their offline comparisons data;
// - when changing something in the offline data api we need to write migrations
// that are executed upon application start.
// --victoria.atanasova@2017-08-28
const PERSISTENCE_KEY = 'comparisions';

class ComparisonsAPI extends EntityStore {
    constructor() {
        super(PERSISTENCE_KEY);
    }
}

export default new ComparisonsAPI();
