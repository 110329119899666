import 'annuity_lab/styles/app';

require('core-js/features/object/assign');

global.React = require('react');
global.ReactDOM = require('react-dom');
global._ = require('lodash');
global.Decimal = require('decimal.js');
global.appHistory = require('annuity_lab/services/history.js').default;

const AnnuityLabAnalytics = require('annuity_lab/services/analytics').default;

const createStore = require('annuity_lab/services/create_store.js').default;
const store = createStore(appHistory);

const Provider = require('react-redux').Provider;
const authenticate = require('annuity_lab/actions/auth.js').authenticate;
const updateStatus = require('annuity_lab/actions/api_status.js').updateStatus;
const Constants = require('common/services/constants.js').default;
const Promise = require('bluebird');
const moment = require('moment');

window.moment = moment;

window.mapIndexesToDates = data => {
    return data.map(d => {
        return moment.utc(d.expiryDate).startOf('day').format('MMMM Do YYYY');
    });
};

if (!IS_DEBUG) {
    Promise.config({warnings: false});
}

function initApiStatusAndAuthenticate() {
    return dispatch => {
        return dispatch(updateStatus())
            .then(() => {
                return dispatch(authenticate());
            });
    };
}

function monitorApiStatus(store) {
    window.setInterval(() => {
        store.dispatch(updateStatus());
    }, Constants.UPDATE_API_STATUS_INTERVAL);
}

function render(store) {
    const Root = require('annuity_lab/root.js.jsx').default;

    ReactDOM.render(
        (
            <Provider store={store}>
                <Root store={store} />
            </Provider>
        ),
        document.getElementById('application-root')
    );
}

function initializeApplication() {
    AnnuityLabAnalytics.initialize();

    monitorApiStatus(store);

    const indexesStore = require('annuity_lab/tools/fia/services/index_store.js').default;

    return indexesStore.connect()
        .then(() => {
            return store.dispatch(initApiStatusAndAuthenticate());
        })
        .then(render.bind(this, store));
}

document.addEventListener(
    IS_MOBILE ? 'deviceready' : 'DOMContentLoaded',
    initializeApplication,
    false
);
