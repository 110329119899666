/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
export const X = {
    '4.5': [
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
        78, 78, // duplicate year
        79, 80,
        81, 81, // duplicate year
        82,
        83, 83, // duplicate year
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ],
    '5': [
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
        78, 78, // duplicate year
        79, 80,
        81, 81, // duplicate year
        82,
        83, 83, // duplicate year
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ]
};

export const EXTENDED_INCOME_DATA = {
    '4.5': [
        5625, 5625, 5625, 5625, 5625, 5625, 5625, 5625, 5625, 5625, 5625, 5625, 5625, 5625,
        5625, 5625, // data for duplicate year
        5625, 5625,
        5625, 5625, // data for duplicate year
        5625,
        5625, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ],
    '5': [
        6250, 6250, 6250, 6250, 6250, 6250, 6250, 6250, 6250, 6250, 6250, 6250, 6250, 6250,
        6250, 6250, // data for duplicate year
        6250, 6250,
        6250, 5000, // data for duplicate year
        5000,
        5000, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ]
};

export const CARRY_FORWARD_DATA = {
    '4.5': [
        7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500,
        7500, 5000, // data for duplicate year
        5000, 5000,
        5000, 5000, // data for duplicate year
        5000,
        5000, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ],
    '5': [
        7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500, 7500,
        7500, 5000, // data for duplicate year
        5000, 5000,
        5000, 5000, // data for duplicate year
        5000,
        5000, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
    ]
};

