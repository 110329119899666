import 'annuity_lab/components/tool_layout/tool_layout.css.scss';

import PropTypes from 'prop-types';
import SideMenu from 'annuity_lab/components/side_menu/side_menu.js.jsx';

export default class ToolLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        window.setTimeout(this._fixTransition, 100);
    }

    _fixTransition() {
        // XXX: Quick fix. The navigation menu appears on refresh.
        // --georgi.ivanov@2017-03-28
        const transition = 'transform 0.2s ease-in';
        const sideMenu = document.querySelector('.tool-layout .side-menu');
        const tool = document.querySelector('.tool-layout .tool');

        [sideMenu, tool].forEach(element => {
            element.style.transition = transition;
        });
    }

    _toggleMenu() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen,
        });
    }

    render() {
        const movedClassName = this.state.isMenuOpen
            ? 'moved'
            : '';

        return (
            <div className='tool-layout'>
                <SideMenu
                    user={this.props.user}
                    className={movedClassName}
                    toggleMenu={this._toggleMenu.bind(this)}/>
                <div
                    className={`tool ${movedClassName}`}
                    onClick={() => {
                        if (this.state.isMenuOpen) {
                            this.setState({isMenuOpen: false});
                        }
                    }}>
                    <div className='tool-base'>
                        <div className='tool-header'>
                            <div className={`menu-toggle-button ${movedClassName}`}
                                onClick={() => {
                                    this._toggleMenu();
                                }}>
                            </div>
                            <div className='tool-name'>
                                {this.props.headerTitle}
                            </div>
                        </div>

                        <div className='content'>
                            {this.props.children}
                        </div>
                    </div>
                    {
                        this.props.footer &&
                            <div className='tool-footer'>
                                {this.props.footer}
                            </div>
                    }
                </div>
            </div>
        );
    }
}

ToolLayout.propTypes = {
    user: PropTypes.object,
    headerTitle: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.node,
};
