import 'rc-checkbox/assets/index.css';
import 'annuity_lab/components/checkbox/checkbox.css.scss';

import React from 'react';
import PropTypes from 'prop-types';
import CheckboxInput from 'rc-checkbox';

export default class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        this.checked = this.props.checked || false;
    }

    toggleCheckbox() {
        this.checked = !this.checked;
        this.props.onChange(this.checked);
    }

    render() {
        return (
            <div className={`${this.props.className} checkbox`}>

                <label>
                    <CheckboxInput
                        onChange={() => {
                            this.toggleCheckbox();
                        }}
                        checked={this.checked}/>
                    {this.props.label}
                </label>
            </div>
        );
    }
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};
