import superagent from 'superagent';
import Promise from 'bluebird';
import config from 'config/config.js';

class Connection {
    send(path, data, method, attachments = []) {
        const url = `${config.API_URL}${path}`;
        const request = superagent(method || 'GET', url)
            .withCredentials()
            .type('application/json')
            .accept('json');

        if (method === 'PUT' || method === 'POST') {
            request.send(data);
        } else {
            request.query(data);
        }

        attachments.forEach(attachment => {
            const {name, file} = attachment;

            request.field(name, file);
        });

        return Promise.promisify(request.end.bind(request))()
            .then(resp => {
                return resp.body;
            }, err => {
                const message = err.response.body
                    ? err.response.body.error
                    : err.message;

                const error = new Error(message);

                return Promise.reject(error);
            });
    }
}

export default new Connection();
