import {connect} from 'react-redux';

import {updateProductContext} from 'annuity_lab/tools/lbc/actions/product_context.js';
import {updateCard, removeCard} from 'annuity_lab/tools/common/actions/rider_cards.js';
import {updateRiderType} from 'annuity_lab/tools/lbc/actions/select_rider.js';
import CardsUtil from 'common/services/cards_core_util.js';
import RiderCards from 'annuity_lab/tools/lbc/components/rider_cards/rider_cards.js.jsx';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';
import {TOOLS} from 'common/domain/tools.js';

function mapStateToProps({lbc, common}) {
    const {productContext, selectRider} = lbc;
    const {riderCards} = common;
    const sortedCards = CardsUtil.sortByAnnualIncome(
        _.values(riderCards),
        productContext.money,
        productContext.deferralPeriod
    );
    const moneyLabel = Constants.CONTEXT_MONEY_LABEL;
    const deferralPeriodLabel = Constants.DEFERRAL_PERIOD_LABEL;

    return {
        riderCards: sortedCards,
        productContext,
        moneyLabel,
        deferralPeriodLabel,
        riderType: selectRider.riderType,
        tool: TOOLS.LIVING_BENEFIT_RIDERS_CORE,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateProductContext: (money, deferralPeriod) => {
            dispatch(updateProductContext(money, deferralPeriod));
        },
        updateCard: (card, updateTemplate) => {
            dispatch(updateCard(card.id, updateTemplate));
        },
        removeCard: (card) => {
            dispatch(removeCard(card.id));
        },
        updateRiderType: (riderType) => {
            dispatch(updateRiderType(riderType));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RiderCards);
