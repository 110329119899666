import 'annuity_lab/tools/lbc/components/differential_balloon/differential_balloon.css.scss';
import PropTypes from 'prop-types';
import InfoBalloon from 'annuity_lab/components/info_balloon/info_balloon.js.jsx';
import IncomeDifferenceDescription
    from 'common/components/income_difference_description/income_difference_description';

export default class DifferentialBalloon extends React.Component {
    static propTypes = {
        difference: PropTypes.number.isRequired
    }

    render() {
        var target = this.props.target || '.small-product-card:first-child';

        return (
            <InfoBalloon
                pointsTo={target}
                arrowPosition="top"
                className="differential-balloon">
                <IncomeDifferenceDescription difference={this.props.difference}/>
            </InfoBalloon>
        );
    }
}
