import 'annuity_lab/styles/panel';
import 'annuity_lab/tools/common/components/card/card.css.scss';

/* eslint-disable max-len */
import PropTypes from 'prop-types';

import ComparisonDependencyMessage from 'annuity_lab/tools/common/containers/comparison_dependency_message/comparison_dependency_message.js.jsx';
import CardOptions from 'annuity_lab/tools/common/components/card_options/card_options.js.jsx';

import NumberFormat from 'common/services/number_format';
import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';

import CardsUtil from 'common/services/cards_core_util.js';
/* eslint-enable max-len */

var CONFIRM_TITLE = 'Please confirm',
    CONFIRM_MESSAGE = 'Are you sure you want to remove this rider from all devices?';

export default class Card extends React.Component {
    static propTypes = {
        index: PropTypes.number,
        card: PropTypes.object,
        baseProduct: PropTypes.object,
        money: PropTypes.string,
        deferralPeriod: PropTypes.number,
        removable: PropTypes.bool,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
    };

    isComplete() {
        return CardsUtil.isComplete(this.props.card) &&
               !_.isNil(this.props.money) &&
               !_.isNil(this.props.deferralPeriod);
    }

    isBaseProduct() {
        return !this.props.baseProduct;
    }

    shouldShowComparison() {
        var baseProduct = this.props.baseProduct,
            money = this.props.money,
            deferralPeriod = this.props.deferralPeriod;

        return !this.isBaseProduct() && CardsUtil.isComplete(baseProduct) &&
                this.annualIncome().lessThan(CardsUtil.annualIncome(
                    baseProduct,
                    money,
                    deferralPeriod)
                );
    }

    annualIncome() {
        if (!this.isComplete()) {
            return 0;
        }

        return CardsUtil.annualIncome(
            this.props.card,
            this.props.money,
            this.props.deferralPeriod
        );
    }

    renderDetails() {
        if (!this.isComplete()) {
            return;
        }

        var baseBenefit = CardsUtil.baseBenefit(
            this.props.card,
            this.props.money,
            this.props.deferralPeriod
        );

        return (
            <div className="details">
                <h2 className="base-benefit">
                    Base Benefit Value: {NumberFormat.currency(baseBenefit)}
                </h2>

                {this.renderComparison()}
            </div>
        );
    }

    renderComparison() {
        if (!this.shouldShowComparison()) {
            return;
        }

        var hurdleRate = CardsUtil.hurdleRate(
            this.props.card,
            this.props.baseProduct,
            this.props.money,
            this.props.deferralPeriod
        );

        return (
            <div className="hurdle-rate">
                <div className="up-arrow-icon"></div>
                <span className="text">
                    The annuity associated with this rider needs an annual rate of return of
                    {' '}
                    {NumberFormat.percentage(hurdleRate)} for {this.props.deferralPeriod}
                    {' '}
                    years to outperform Rider
                    {' '}
                    {this.props.baseProduct.displayName.toUpperCase()}
                    {' '}
                    (Rate of return does not factor in fees).
                </span>
            </div>
        );
    }

    onRemove() {
        if (!CardsUtil.isComplete(this.props.card)) {
            this.props.onRemove();

            return;
        }

        var dependencyMessage = <ComparisonDependencyMessage card={this.props.card} />,
            dialogContent = <div>{CONFIRM_MESSAGE} {dependencyMessage}</div>;

        ModalDialogs.confirm(CONFIRM_TITLE, dialogContent, this.props.onRemove);
    }

    render() {
        var card = this.props.card;

        return (
            <div className={'card panel ' + (this.isComplete() ? 'card-evaluated' : '')}>
                <div className="header">
                    <div className="number">{this.props.index + 1}</div>
                    <h4 className="name">Rider {card.displayName}</h4>

                    {this.props.removable &&
                        <button className="close-button" onClick={() => this.onRemove()}></button>
                    }
                </div>

                <div className="body">
                    <CardOptions
                        card={card}
                        onChange={this.props.onChange} />

                    {this.renderDetails()}
                </div>

                <div className="footer">
                    <h2 className="annual-income">
                        <span className="money-value">
                            {NumberFormat.currency(this.annualIncome())}
                        </span> (Annual Income)
                    </h2>
                </div>
            </div>
        );
    }
}
