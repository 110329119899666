import Connection from 'common/services/connection.js';

const API_PATH = '/lbc/plus-comparisons';

const FIELDS = [
    'id',
    'createdAt',
    'updatedAt',
    'name',
    'userId',
    'cardIds',
    'jointRiders',
    'premium',
    'currentAge',
    'ageAtWithdrawal'
];

function mapComparison(rawComparison) {
    const result = _.pick(rawComparison, FIELDS);

    result.cardIds = rawComparison.plusCards.map(card => card.id);

    return result;
}

class ComaprisonsApi {
    loadComparison(comparisonId) {
        const path = `${API_PATH}/${comparisonId}`;

        return Connection.send(path);
    }

    loadComparisons(userId) {
        return Connection.send(API_PATH, {userId})
            .then(rawComparisons => {
                return _.map(rawComparisons, mapComparison);
            });
    }

    createComparison(userId, template) {
        const data = _.merge({}, template, {userId});

        return Connection
            .send(API_PATH, data, 'PUT')
            .then(mapComparison);
    }

    removeComparison(userId, comparisonId) {
        const path = `${API_PATH}/${comparisonId}`;

        return Connection.send(path, {userId}, 'DELETE');
    }
}

export default new ComaprisonsApi();
