import EntityStore from 'annuity_lab/services/data/base/entity_store.js';

const PERSISTENCE_KEY = 'migrations';

class MigrationsAPI extends EntityStore {
    constructor() {
        super(PERSISTENCE_KEY);
    }
}

export default new MigrationsAPI();
