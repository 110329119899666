import 'annuity_lab/styles/modal_dialog.css.scss';
// eslint-disable-next-line max-len
import 'annuity_lab/tools/lbc/components/dialogs/lifetime-withdrawal-values/lifetime-withdrawal-values.css.scss';

import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import PropTypes from 'prop-types';

import Modal from 'react-modal';

const body = document.querySelector('body');

export default class LifetimeWithdrawalValues extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        className: PropTypes.string,
        withdrawalValues: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                rangeStart: PropTypes.number,
                rangeEnd: PropTypes.number,
                singlePositive: PropTypes.string,
                singleNegative: PropTypes.string,
                jointPositive: PropTypes.string,
                jointNegative: PropTypes.string,
                order: PropTypes.number.isRequired,
            })
        ),
        note: PropTypes.string
    }

    constructor(props) {
        super(props);

        const withdrawalState = this.props.withdrawalValues;

        withdrawalState.sort((l, r) => l.order - r.order);

        this.state = {
            withdrawalValues: this.transformDataForInputs(withdrawalState),
            note: this.props.note ? this.props.note : '',
            formError: '',
        };
    }

    isWithdrawalPropsEmpty() {
        return CardsPlusUtil.isWithdrawalValuesEmpty(this.props.withdrawalValues);
    }

    transformDataForInputs(withdrawalValues) {
        return withdrawalValues.map(wv => {
            const {
                id,
                order,
                singlePositive,
                singleNegative,
                jointPositive,
                jointNegative,
                rangeStart,
                rangeEnd,
            } = wv;

            return {
                ...wv,
                id,
                rangeStart: rangeStart ? rangeStart.toString() : '',
                rangeEnd: rangeEnd ? rangeEnd.toString() : '',
                singlePositive: singlePositive ? singlePositive : '',
                singleNegative: singleNegative ? singleNegative : '',
                jointPositive: jointPositive ? jointPositive : '',
                jointNegative: jointNegative ? jointNegative : '',
                order
            };
        });
    }

    transformDataFromInputs(withdrawalValues) {
        return withdrawalValues.map(wv => {
            const {
                id,
                rangeStart,
                rangeEnd,
                singlePositive,
                singleNegative,
                jointPositive,
                jointNegative,
                order
            } = wv;

            return {
                id,
                rangeStart: rangeStart ? Number(rangeStart) : null,
                rangeEnd: rangeEnd ? Number(rangeEnd) : null,
                singlePositive: singlePositive ? Number(singlePositive).toFixed(2) : null,
                singleNegative: singleNegative ? Number(singleNegative).toFixed(2) : null,
                jointPositive: jointPositive ? Number(jointPositive).toFixed(2) : null,
                jointNegative: jointNegative ? Number(jointNegative).toFixed(2) : null,
                order
            };
        });
    }

    atLeastOneAgeCategory() {
        return this.state.withdrawalValues.some((v) => !!v.rangeStart && !isNaN(v.rangeStart));
    }

    ageCategoriesAreValid() {
        let lastEnd = Number.MIN_SAFE_INTEGER;

        for (const current of this.state.withdrawalValues) {
            if (!current.rangeStart && current.rangeEnd) {
                return false;
            }

            if (!current.rangeStart) {
                continue;
            }

            const floatStart = Number(current.rangeStart);

            // Again check if there is a string or number?
            if (floatStart < lastEnd) {
                return false;
            } else if (floatStart === lastEnd && Number.isInteger(floatStart)) {
                return false;
            }

            if (current.rangeEnd) {
                const floatEnd = Number(current.rangeEnd);

                if (floatStart > floatEnd) {
                    return false;
                }
                lastEnd = floatEnd;
            } else {
                lastEnd = Number.MAX_SAFE_INTEGER;
            }
        }

        return true;
    }

    neededPercentagesEntered() {
        const percentagesEmpty = (row) => {
            return !row.singlePositive &&
                !row.singleNegative &&
                !row.jointPositive &&
                !row.jointNegative;
        };

        const haveOnlyOneSingleValue = (row) => {
            return (row.singlePositive && !row.singleNegative) ||
              (!row.singlePositive && row.singleNegative);
        };

        const haveOnlyOneJointValue = (row) => {
            return (row.jointPositive && !row.jointNegative) ||
              (!row.jointPositive && row.jointNegative);
        };

        const percentagesAreValidNumbers = (row) => {
            const notDefinedOrValidPercent = (value) => {
                if (!value) {
                    return true;
                } else if (isNaN(value)) {
                    return false;
                }

                const num = Number(value);

                return num > 0 && num < 100;
            };

            return notDefinedOrValidPercent(row.singlePositive) &&
              notDefinedOrValidPercent(row.singleNegative) &&
              notDefinedOrValidPercent(row.jointPositive) &&
              notDefinedOrValidPercent(row.jointNegative);
        };

        for (const current of this.state.withdrawalValues) {
            if (!current.rangeStart) {
                continue;
            }

            if (percentagesEmpty(current)) {
                return false;
            }

            if (haveOnlyOneSingleValue(current) || haveOnlyOneJointValue(current)) {
                return false;
            }

            if (!percentagesAreValidNumbers(current)) {
                return false;
            }
        }

        return true;
    }

    formValid() {
        if (!this.atLeastOneAgeCategory()) {
            this.setState({formError: 'There should be at least one age interval'});

            return false;
        }
        if (!this.ageCategoriesAreValid()) {
            this.setState({formError: 'Age categories should be valid and not overlapping'});

            return false;
        }
        if (!this.neededPercentagesEntered()) {
            this.setState({formError: 'For a valid calculation you need to have both valid ' +
                  'single or joint values with each age category entered'});

            return false;
        }

        this.setState({formError: ''});

        return true;
    }

    save(event) {
        event.preventDefault();

        if (this.formValid()) {
            this.props.onSave(
                this.transformDataFromInputs(this.state.withdrawalValues),
                this.state.note
            );
        }
    }

    renderWithdrawalHeader() {
        return (
            <div className="title-row">
                <span className="age-ranges">Age Ranges</span>
                <span>Single life cash value &gt;$0</span>
                <span>Single life cash value =$0</span>
                <span>Joint life cash value &gt;$0</span>
                <span>Joint life cash value =$0</span>
            </div>
        );
    }

    handleChange(index, fieldName) {
        return (event) => {
            const current = _.clone(this.state.withdrawalValues);

            current[index][fieldName] = event.target.value;
            this.setState({
                withdrawalValues: current
            });
        };
    }

    handlePercentageInputBlur(index, fieldName) {
        return () => {
            if (!this.state.withdrawalValues[index][fieldName]) {
                return;
            }

            const current = _.clone(this.state.withdrawalValues);

            current[index][fieldName] = Number(current[index][fieldName]).toFixed(2);

            this.setState({
                withdrawalValues: current
            });
        };
    }

    renderWithdrawalValue(value, index) {
        return (
            <div key={value.order} className="value-inputs__row">
                <div className="range-container">
                    <input
                        type="text"
                        value={value.rangeStart}
                        onChange={this.handleChange(index,'rangeStart')} />
                    <span>-</span>
                    <input
                        type="text"
                        value={value.rangeEnd}
                        onChange={this.handleChange(index,'rangeEnd')} />
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        value={value.singlePositive}
                        onChange={this.handleChange(index,'singlePositive')}
                        onBlur={this.handlePercentageInputBlur(index, 'singlePositive')} />
                    <span>%</span>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        value={value.singleNegative}
                        onChange={this.handleChange(index,'singleNegative')}
                        onBlur={this.handlePercentageInputBlur(index, 'singleNegative')} />
                    <span>%</span>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        value={value.jointPositive}
                        onChange={this.handleChange(index,'jointPositive')}
                        onBlur={this.handlePercentageInputBlur(index, 'jointPositive')} />
                    <span>%</span>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        value={value.jointNegative}
                        onChange={this.handleChange(index,'jointNegative')}
                        onBlur={this.handlePercentageInputBlur(index, 'jointNegative')} />
                    <span>%</span>
                </div>
            </div>
        );
    }

    render() {
        const {className, onClose} = this.props;
        const {withdrawalValues, note, formError} = this.state;

        return (
            <Modal
                isOpen
                {...this.props}
                className="modal-dialog save-withdrawal-values-dialog panel"
                overlayClassName={`modal-dialog-overlay ${className}`}
                shouldFocusAfterRender={false}
                appElement={body}>

                <div className="header">
                    {this.isWithdrawalPropsEmpty() && <h4>Add Lifetime Withdrawal Values</h4>}
                    {!this.isWithdrawalPropsEmpty() && <h4>Edit Lifetime Withdrawal Values</h4>}

                    <button className="close-button" onClick={onClose} />
                </div>

                <form
                    onSubmit={(event) => this.save(event)}>
                    <div className="body">
                        {this.renderWithdrawalHeader()}
                        {withdrawalValues.map((v, i) =>
                            this.renderWithdrawalValue(v, i))}
                    </div>

                    <div className="footer">
                        <div className="card-note">
                            <h5>Notes</h5>
                            <textarea
                                rows="4"
                                value={note}
                                onChange={(event) => this.setState({note: event.target.value})}
                                placeholder="Notes for this enhanced rate rider card" />
                        </div>

                        {formError &&
                            <div className="errors">
                                {formError}
                            </div>
                        }

                        <button
                            type="submit"
                            className="btn save-button">
                            Save
                        </button>
                    </div>
                </form>

            </Modal>
        );
    }
}
