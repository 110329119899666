import 'c3/c3.css';
import 'annuity_lab/tools/lbc/components/more_time_chart/more_time_chart.css.scss';

import C3Chart from 'react-c3js';
import {useCallback, useEffect, useState} from 'react';

import {
    getGrid,
    getLegend,
    getPoint,
    getSize,
    getTooltip,
    positionInfoBalloon,
    positionRectangle,
    drawGridLines,
    adjustOverlapping
} from '../../../common/utils/chart';

import {EXTENDED_INCOME_DATA, CARRY_FORWARD_DATA, X} from
    'annuity_lab/tools/lbc/components/more_time_chart/data.js';

const EXTENDED_INCOME = 'EXTENDED-INCOME';
const CARRY_FORWARD = 'CARRY-FORWARD';

const PLOT_HEIGHT = 260;

const CHART_TITLE = 'Payout in Years';

const Y_AXIS_TOP_PADDING_RATIO = 0.1;

const ID = 'more-time-chart';
const CLASS_NAME = 'more-time-chart';

const AXIS = {
    x: {
        tick: {
            format: (x) => {
                if (x === 95) {
                    return `${x}+`;
                }

                if (x > 95) {
                    return '';
                }

                return x.toString();
            },
            values: [64, 70, 75, 80, 85, 90, 95, 96],
            culling: false,
        },
        min: 64,
        max: 96,
        padding: {
            left: 0.005,
            right: -0.5
        }
    },
    y: {
        tick: {
            format: y => y >= 1000 ? `$${ y.toFixed(0) / 1000}K` : `$${ y.toFixed(0)}`,
        },
        min: 0,
        padding: {
            top: Y_AXIS_TOP_PADDING_RATIO,
            bottom: 0,
            unit: 'ratio'
        }
    }
};

const MoreTimeChart = ({withdrawal}) => {
    const [key, setKey] = useState(1);

    const handleResize = useCallback(() => {
        setKey(key => key + 1);
    }, []);

    useEffect(() => {
        const handleResizeDebounced = _.debounce(handleResize, 300);

        window.addEventListener('resize', handleResizeDebounced);

        return () => {
            window.removeEventListener('resize', handleResizeDebounced);
        };
    }, [handleResize]);

    const positionRectangles = () => {
        const container = document.querySelector(`#${ID}`);

        const rectBelow = document.querySelector('.rect-below');
        const rectAbove = document.querySelector('.rect-above');

        const circlesCarryForward = Array.from(document
            .querySelectorAll(`.${CLASS_NAME} .c3-circles-${CARRY_FORWARD} circle`));
        const circlesExtendedIncome = document
            .querySelectorAll(`.${CLASS_NAME} .c3-circles-${EXTENDED_INCOME} circle`);

        if (withdrawal === 5) {
            // RECT BELOW
            const rectBelowTopLeft = circlesCarryForward[0];
            const rectBelowBottomRight = circlesExtendedIncome[15];

            positionRectangle(container, rectBelow, rectBelowTopLeft, rectBelowBottomRight);

            // RECT ABOVE
            const rectAboveTopLeft = circlesExtendedIncome[15];
            const rectAboveBottomRight = circlesCarryForward[18];

            positionRectangle(
                container,
                rectAbove,
                rectAboveTopLeft,
                rectAboveBottomRight,
                {
                    left: 2,
                    top: 0,
                }
            );
        } else {
            // withdrawal = 4.5%

            // RECT BELOW
            const rectBelowTopLeft = circlesCarryForward[0];
            const rectBelowBottomRight = circlesExtendedIncome[15];

            positionRectangle(container, rectBelow, rectBelowTopLeft, rectBelowBottomRight);

            // RECT ABOVE
            const rectAboveTopLeft = circlesExtendedIncome[15];
            const rectAboveBottomRight = circlesCarryForward[21];

            positionRectangle(
                container,
                rectAbove,
                rectAboveTopLeft,
                rectAboveBottomRight,
                {
                    left: 2,
                    top: 0
                }
            );
        }
    };

    const positionElements = () => {
        drawGridLines(ID);
        positionInfoBalloon(ID, CLASS_NAME);
        positionRectangles();
    };

    const data = adjustOverlapping(
        EXTENDED_INCOME_DATA[withdrawal],
        CARRY_FORWARD_DATA[withdrawal]
    );

    const DATA = {
        x: 'x',
        columns: [
            ['x',
                ...X[withdrawal]
            ],
            [EXTENDED_INCOME,
                ...data.riderAData
            ],
            [CARRY_FORWARD,
                ...data.riderBData
            ]
        ],
        colors: {
            [EXTENDED_INCOME]: '#672146',
            [CARRY_FORWARD]: '#9e9e9e',
        }
    };

    return (
        <div className={CLASS_NAME} id={ID}>
            <div className="title-container">
                <h3 className="chart-title">{CHART_TITLE}</h3>
                <div className="chart-line-info">
                    <p>Carry Forward</p>
                    <p>Extended Income</p>
                </div>
            </div>
            <div className="chart-start-balloon">
                <span className="start-text">START</span>
            </div>
            <C3Chart
                // To trigger a refresh of the component, when the state changes.
                key={key}
                data={DATA}
                legend={getLegend()}
                axis={AXIS}
                point={getPoint()}
                grid={getGrid()}
                tooltip={getTooltip()}
                size={getSize(PLOT_HEIGHT)}
                onrendered={positionElements}
                onresize={positionElements}/>

            {/* Rects Below & Above relative to carry forward line*/}
            <div className="rect-below" />
            <div className="rect-above" />
        </div>
    );
};

export default MoreTimeChart;