import {TOOLS} from 'common/domain/tools.js';

const {
    LIVING_BENEFIT_RIDERS_CORE,
    MAKE_A_DIFFERENCE,
    INCOME_MODELER,
} = TOOLS;

const ToolsButtons = ({handleClick}) => {
    return (
        <div className="controls">
            <div className="btn-group">
                <button
                    className="btn"
                    onClick={handleClick(LIVING_BENEFIT_RIDERS_CORE)}>
                    Living Benefit Income Calculator
                </button>
                <button
                    className="btn"
                    onClick={handleClick(MAKE_A_DIFFERENCE)}>
                    Living Benefit Investment Calculator
                </button>
                <button
                    className="btn"
                    onClick={handleClick(INCOME_MODELER)}>
                    Income Modeler
                </button>
            </div>
        </div>
    );
};

export default ToolsButtons;