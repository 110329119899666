/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';

import {formatCurrency} from 'annuity_lab/tools/imo/utils/format.js';

const CarryForwardViewTableRows = (props) => {
    const {
        ageAtWithdrawal,
        tableValues,
        highlightedYearRow,
        onTableRowClick
    } = props;

    const renderRowForYear = (year) => {
        const className = highlightedYearRow === null || highlightedYearRow === year
            ? 'highlighted'
            : undefined;

        return (
            <tr key={year} onClick={onTableRowClick(year)} className={className}>
                <td className="small">
                    <span className="data-container">{year - ageAtWithdrawal + 1}</span>
                </td>
                <td className="small">
                    <span className="data-container">{year}</span>
                </td>
                <td className="medium">
                    <span className="data-container money-container">
                        {formatCurrency(tableValues[year].baseBenefit)}
                    </span>
                </td>
                <td className="large">
                    <div className="two-column-container">
                        <span className="standard-text rider-value money-container">
                            {formatCurrency(tableValues[year].standard.cashValue)}
                        </span>
                        <span className="carry-forward-text rider-value money-container">
                            {formatCurrency(tableValues[year].carryForward.cashValue)}
                        </span>
                    </div>
                </td>
                <td className="large">
                    <div className="two-column-container">
                        <span className="standard-text rider-value money-container">
                            {formatCurrency(tableValues[year].standard.annualIncome)}
                        </span>
                        <span className="carry-forward-text rider-value money-container">
                            {formatCurrency(tableValues[year].carryForward.annualIncome)}
                        </span>
                    </div>
                </td>
                <td className="large">
                    <div className="two-column-container">
                        <span className="standard-text rider-value money-container">
                            {formatCurrency(tableValues[year].standard.totalIncome)}
                        </span>
                        <span className="carry-forward-text rider-value money-container">
                            {formatCurrency(tableValues[year].carryForward.totalIncome)}
                        </span>
                    </div>
                </td>
                <td className="medium">
                    <span className="data-container money-container">
                        {
                            year === Number(ageAtWithdrawal)
                                ? 'N/A'
                                : formatCurrency(tableValues[year].balance)}
                    </span>
                </td>
                <td className="medium">
                    <span className="data-container money-container">
                        {formatCurrency(tableValues[year].availableIncome)}
                    </span>
                </td>
            </tr>
        );
    };

    return (
        <React.Fragment>
            {_.range(Number(ageAtWithdrawal), Number(ageAtWithdrawal) + 30).map(renderRowForYear)}
        </React.Fragment>
    );
};

CarryForwardViewTableRows.propTypes = {
    ageAtWithdrawal: PropTypes.string,
    tableValues: PropTypes.object,
    highlightedYearRow: PropTypes.number,
    onTableRowClick: PropTypes.func
};

export default CarryForwardViewTableRows;