
import 'annuity_lab/tools/imo/components/model/model.css.scss';

import {useEffect, useMemo, useState} from 'react';
import {isEqual} from 'lodash';
import PropTypes from 'prop-types';

import ClientInformation from
    'annuity_lab/tools/imo/components/client_information/client_information.js.jsx';
import RidersModeled from 'annuity_lab/tools/imo/components/riders_modeled/riders_modeled.js.jsx';
import TabView from 'annuity_lab/tools/imo/components/tab_view/tab_view.js.jsx';
import ModelControls from 'annuity_lab/tools/imo/containers/model_controls/model_controls.js.jsx';
import ModelSelect from 'annuity_lab/tools/imo/containers/model_select/model_select.js.jsx';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';

const Model = (props) => {
    const {
        newModel,
        updateNewModel,
        resetNewModel,
        location,
        match,
        models,
        history,
    } = props;

    const onNewModelPage = useMemo(() =>
        location.pathname.startsWith('/imo/models/new'), [location]);

    const [savedModelWasUpdated, setSavedModelWasUpdated] = useState(false);

    const [model, setModel] = useState(() => {
        if (onNewModelPage || savedModelWasUpdated) {
            return newModel;
        } else {
            return models[match.params.id] ?? {};
        }
    });

    useEffect(() => {
        if (onNewModelPage || savedModelWasUpdated) {
            if (!isEqual(model, newModel)) {
                setModel(newModel);
            }
        } else {
            setModel(models[match.params.id] ?? {});
        }
    });

    const {clientInformation, riderA, riderB, carryForward} = model;

    const clientInformationDefined = useMemo(() => clientInformation &&
        Object.values(clientInformation)
            .reduce((acc, curr) => acc && curr, true), [clientInformation]);

    useEffect(() => {
        AnnuityLabAnalytics.trackView(`Model > ${model.name ?? 'unsaved'}`);
    }, [model.id, model.name]);

    useEffect(() => {
        if (
            !riderA || !clientInformationDefined
        ) {
            history.replace('/imo/new');
        }
    }, [history, clientInformationDefined, riderA]);

    const stripSavedModelData = (modelData) => {
        const riderAStripped = _.omit(modelData.riderA, ['id', 'createdAt', 'updatedAt']);

        let riderBStripped = null;

        if (modelData.riderB) {
            riderBStripped = _.omit(modelData.riderB, ['id', 'createdAt', 'updatedAt']);
        }

        return {
            clientInformation: modelData.clientInformation,
            riderA: riderAStripped,
            riderB: riderBStripped,
            carryForward: modelData.carryForward
        };
    };

    const updateRiderA = (changes) => {
        const updates = {
            ...model,
            riderA: {
                ...riderA,
                ...changes
            }
        };

        updateNewModel(stripSavedModelData(updates));

        if (!onNewModelPage) {
            setSavedModelWasUpdated(true);
        }
    };

    const updateRiderB = (changes) => {
        const updates = {
            ...model,
            riderB: {
                ...riderB,
                ...changes
            }
        };

        updateNewModel(stripSavedModelData(updates));

        if (!onNewModelPage) {
            setSavedModelWasUpdated(true);
        }
    };

    const updateCarryForward = (changes) => {
        const updates = {
            ...model,
            carryForward: {
                ...carryForward,
                ...changes
            }
        };

        updateNewModel(stripSavedModelData(updates));

        if (!onNewModelPage) {
            setSavedModelWasUpdated(true);
        }
    };

    const onModelChange = (o) => {
        resetNewModel();

        if (o.value === match.params.id) {
            setSavedModelWasUpdated(false);
        } else {
            history.push(`/imo/models/${o.value}/standard`);
        }
    };

    if (!model || !clientInformationDefined || !riderA) {
        return null;
    }

    return (
        <div className="imo-model">
            <div className="title-and-controls">
                <div className="title-container">
                    <ModelSelect
                        label={model.name ?? 'New Model (Unsaved)'}
                        onModelChange={onModelChange}/>
                </div>

                <div className="desktop-controls">
                    <ModelControls
                        view="desktop"
                        match={match}
                        history={history}
                        location={location}/>
                </div>
            </div>

            <ClientInformation
                clientInformation={clientInformation}
                updateClientInformation={_.noop}
                disabled />

            <RidersModeled
                riderA={riderA}
                handleRiderAChange={updateRiderA}
                riderB={riderB}
                handleRiderBChange={updateRiderB}/>

            <TabView model={model} handleCarryForwardChange={updateCarryForward}/>
        </div>
    );
};

Model.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    newModel: PropTypes.object,
    models: PropTypes.object,
    updateNewModel: PropTypes.func,
    resetNewModel: PropTypes.func
};

export default Model;