import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {updateProductContext}
    from 'annuity_lab/tools/lbc/actions/product_context.js';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';
import CardsComparison
    from 'annuity_lab/tools/lbc/components/cards_comparison/cards_comparison.js.jsx';
import {updateRiderType} from 'annuity_lab/tools/lbc/actions/select_rider.js';

function mapStateToProps({lbc, common}) {
    const {productContext, comparisons, selectRider} = lbc;
    const {riderCards} = common;
    const moneyLabel = Constants.CONTEXT_MONEY_LABEL;
    const deferralPeriodLabel = Constants.DEFERRAL_PERIOD_LABEL;

    return {
        productContext,
        riderCards,
        riderType: selectRider.riderType,
        comparisons,
        moneyLabel,
        deferralPeriodLabel
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateProductContext: (money, deferralPeriod) => {
            dispatch(updateProductContext(money, deferralPeriod));
        },
        updateRiderType: (riderType) => {
            dispatch(updateRiderType(riderType));
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardsComparison));
