/* eslint-disable react/no-multi-comp */

import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import DeepRouteRedirect from 'annuity_lab/tools/common/lib/deep_route_redirect';
import Comparisons from 'annuity_lab/tools/mad/containers/comparisons/comparisons.js.jsx';
import CardsComparison from
    'annuity_lab/tools/mad/containers/cards_comparison/cards_comparison.js.jsx';
import RiderCards from 'annuity_lab/tools/mad/containers/rider_cards/rider_cards.js.jsx';
import {useDeepRouteRedirect} from 'annuity_lab/hooks/useDeepRouteRedirect';

const comparisonsRedirect = new DeepRouteRedirect('/mad/comparisons');

const MADComparisonsRoutes = ({history, location}) => {
    const shouldRender = useDeepRouteRedirect(comparisonsRedirect, location, history);

    // We need to disable rendering of the tree until the
    // check for redirect has occurred. Otherwise we will visit the baseRoute
    // which will generate fake analytics data.
    if (!shouldRender) {
        return null;
    }

    return (
        <Switch>
            <Route exact path="/mad/comparisons">
                <div className='comparisons-tab-content'>
                    <Comparisons />
                </div>
            </Route>

            <Route exact path="/mad/comparisons/new">
                <div className='comparisons-tab-content'>
                    <CardsComparison />
                </div>
            </Route>

            <Route exact path="/mad/comparisons/:id">
                <div className='comparisons-tab-content'>
                    <CardsComparison />
                </div>
            </Route>
        </Switch>
    );
};

const MADRoutes = ({history, location}) => {
    return (
        <div className="view-content">
            <div className="view-content-container">
                <Switch>
                    <Route exact path="/mad">
                        <div className='cards-tab-content'>
                            <RiderCards />
                        </div>
                    </Route>

                    <Route path="/mad/comparisons">
                        <MADComparisonsRoutes
                            history={history}
                            location={location}/>
                    </Route>

                    <Redirect to="/mad" />
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(MADRoutes);