import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import Select from 'annuity_lab/components/select/select.js.jsx';
import Constants from 'common/services/constants.js';
import PropTypes from 'prop-types';

export default class CardOptions extends React.Component {
    static propTypes = {
        card: PropTypes.object,
        onChange: PropTypes.func,
    };

    render() {
        const {card} = this.props;

        return (
            <div className="options">
                <WithLabel label="Roll-up %">
                    <Select
                        placeholder="Select %"
                        value={Constants.ROLLUP_PERCENTAGE_OPTIONS.find(o =>
                            o.value === card.rollup
                        )}
                        options={Constants.ROLLUP_PERCENTAGE_OPTIONS}
                        onChange={(option) =>
                            this.props.onChange({rollup: option.value})
                        }/>
                </WithLabel>

                <WithLabel label="Interest type">
                    <Select
                        placeholder="Select Type"
                        value={Constants.INTEREST_TYPE_OPTIONS.find(o =>
                            o.value === card.interestType
                        )}
                        options={Constants.INTEREST_TYPE_OPTIONS}
                        onChange={(option) =>
                            this.props.onChange({interestType: option.value})
                        } />
                </WithLabel>

                <WithLabel label="Payout %">
                    <Select
                        placeholder="Select %"
                        value={Constants.PAYOUT_PERCENTAGE_OPTIONS.find(o =>
                            o.value === card.payout
                        )}
                        options={Constants.PAYOUT_PERCENTAGE_OPTIONS}
                        onChange={(option) =>
                            this.props.onChange({payout: option.value})
                        } />
                </WithLabel>
            </div>
        );
    }
}
