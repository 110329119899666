import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import NewComparisonHOC from
    'annuity_lab/tools/common/components/new_comparison/new_comparison.js.jsx';

import CardSelect from 'annuity_lab/tools/lbc/components/card_select/card_select.js.jsx';

class LBCComparison extends React.Component {
    render() {
        const canCompare = this.props.selected[0] && this.props.selected[1];
        const hasThreeCards = this.props.riderCards.length >= 3;

        return (
            <div className="new-comparison-view">
                <h1>Start comparing by selecting two of the rider cards you created.</h1>

                <div className="multiple-selects-container">
                    <div className="card-select">
                        <CardSelect
                            placeholder="Select Rider #1"
                            cards={this.props.cardOptionsFor(0)}
                            matchPos="any"
                            onChange={(prod) => this.props.setCard(0, prod)}
                            value={this.props.selected[0]}/>
                    </div>
                    <div className="card-select">
                        <CardSelect
                            placeholder="Select Rider #2"
                            cards={this.props.cardOptionsFor(1)}
                            matchPos="any"
                            onChange={(prod) => this.props.setCard(1, prod)}
                            value={this.props.selected[1]}/>
                    </div>
                </div>

                <h1>Select another rider card to compare three Living Benefit riders.</h1>

                <div className="single-select-container">
                    <CardSelect
                        placeholder={
                            hasThreeCards ?
                                'Select a Third Rider' :
                                'No more riders available'
                        }
                        cards={this.props.cardOptionsFor(2)}
                        matchPos="any"
                        onChange={(prod) => this.props.setCard(2, prod)}
                        value={this.props.selected[2]}
                        disabled={!canCompare || !hasThreeCards} />
                </div>

                <button
                    className={'btn btn-block btn-compare ' + (canCompare ? '' : 'disabled')}
                    onClick={() =>
                        canCompare && this.props.compareRiderCards(this.props.selected)}>
                    Compare Riders
                </button>
            </div>
        );
    }
}

LBCComparison.propTypes = {
    selected: PropTypes.array,
    cardOptionsFor: PropTypes.func,
    setCard: PropTypes.func,
    riderCards: PropTypes.array,
    history: PropTypes.object,
};

export default withRouter(NewComparisonHOC(LBCComparison));
