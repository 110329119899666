import 'annuity_lab/tools/common/components/layout/layout.css.scss';
import RiderCardsControls from
    'annuity_lab/tools/common/containers/rider_cards_controls/rider_cards_controls.js.jsx';
import ComparisonControls
    from 'annuity_lab/tools/mad/containers/comparison_controls/comparison_controls.js.jsx';
import PropTypes from 'prop-types';
import ToolLayout from 'annuity_lab/components/tool_layout/tool_layout.js.jsx';
import Sync from 'annuity_lab/tools/lbc/containers/sync/sync.jsx';
import Menu from 'annuity_lab/tools/mad/components/menu/menu.js.jsx';
import DialogHost from 'annuity_lab/tools/lbc/components/dialog_host/dialog_host.js.jsx';
import Footer from 'annuity_lab/tools/lbc/containers/footer/footer.js.jsx';
import FooterJumpFix from 'annuity_lab/tools/lbc/services/footer_jump_fix';
import RecentToolAPI from 'annuity_lab/services/data/api/recent_tool.js';
import MADRoutes from 'annuity_lab/tools/mad/routes.js.jsx';
import ShareDialogs from 'annuity_lab/tools/common/containers/share_dialogs/share_dialogs.js.jsx';
export default class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.updateRecentUserTool();
    }

    componentDidUpdate() {
        this.updateRecentUserTool();
    }

    updateRecentUserTool() {
        const {user, location} = this.props;

        if (this.props.user) {
            const recentToolApi = new RecentToolAPI(user.id);

            recentToolApi.set(location.pathname);
        }
    }

    onFocus(e) {
        FooterJumpFix.hide(e);
    }

    onBlur(e) {
        FooterJumpFix.show(e);
    }

    render() {
        const {
            location,
            history,
            menuRoot,
            menuComparisons,
            headerTitle,
            user,
        } = this.props;

        let FooterChildren;

        let id;

        if (location.pathname === '/mad') {
            FooterChildren = RiderCardsControls;
        } else if (
            location.pathname.startsWith('/mad/comparisons') &&
            location.pathname !== '/mad/comparisons'
        ) {
            if (location.pathname !== '/mad/comparisons/new') {
                id = location.pathname.split('/')[3];
            }
            FooterChildren = ComparisonControls;
        }

        // Tool layout wrapper gets rendered on all /mad routes.
        // We need to create our own match object to pass to the footer children which
        // are rendered in the ToolLayout component
        const match = {
            params: {
                id
            }
        };

        return (
            <div className='tool-wrapper'>
                {
                    !this.state.loading && (
                        <ToolLayout
                            user={user}
                            headerTitle={headerTitle}
                            footer={(
                                <Footer>
                                    {FooterChildren &&
                                        <FooterChildren
                                            history={history}
                                            location={location}
                                            match={match}
                                            key={location.pathname}/>}
                                </Footer>
                            )}>
                            <div
                                className="layout"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}>

                                <div className='content'>
                                    <Menu
                                        location={location}
                                        history={history}
                                        root={menuRoot}
                                        comparisons={menuComparisons}/>
                                    <MADRoutes />
                                </div>
                                <DialogHost />
                            </div>
                        </ToolLayout>
                    )
                }
                <ShareDialogs entity="comparison" />
                <Sync
                    onSyncCompleted={() => this.setState({loading: false})}
                    loadComparisons={this.props.loadComparisons}/>
            </div>
        );
    }
}

Layout.propTypes = {
    location: PropTypes.object,
    user: PropTypes.object,
    headerTitle: PropTypes.string.isRequired,
    menuRoot: PropTypes.string.isRequired,
    menuComparisons: PropTypes.array.isRequired,
    loadComparisons: PropTypes.func.isRequired,
};
