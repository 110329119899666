import Constants from 'annuity_lab/tools/lbc/services/constants.js';

export function requireCoreRider(riderType, updateRiderType) {
    if (riderType !== Constants.RIDER_TYPE_CORE) {
        updateRiderType(Constants.RIDER_TYPE_CORE);
    }
}

export function requirePlusRider(riderType, updateRiderType) {
    if (riderType !== Constants.RIDER_TYPE_PLUS) {
        updateRiderType(Constants.RIDER_TYPE_PLUS);
    }
}