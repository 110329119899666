import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';
import BASE_ACTION_TYPES from 'annuity_lab/actions/action_types.js';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';

const initialState = {
    money: Constants.DEFAULT_PREMIUM,
    deferralPeriod: Constants.DEFAULT_DEFERRAL_PERIOD,
};

export default function reducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.PRODUCT_CONTEXT_SET: {
        result = _.assign({}, state, action.payload);
        break;
    }

    case BASE_ACTION_TYPES.LOGOUT: {
        result = initialState;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
