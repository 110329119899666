import _ from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {loadComparisons} from 'annuity_lab/tools/mad/actions/comparisons.js';
import Layout from 'annuity_lab/tools/mad/components/layout/layout.js.jsx';

function mapStateToProps({auth, mad}) {
    return {
        user: auth.user,
        headerTitle: 'Living Benefit Investment Calculator',
        menuRoot: '/mad',
        menuComparisons: _.values(mad.comparisons),
        loadComparisons: loadComparisons,
    };
}

export default withRouter(connect(mapStateToProps)(Layout));
