import 'annuity_lab/tools/admin/components/attachment/attachment.css.scss';

export default function Attachment({header, linkText, linkTarget}) {
    return (
        <div className='attachment'>
            <h1 className='header'>{header}</h1>
            <div className='link'>
                <div className='icon-link'></div>
                <a href={linkTarget} target='blank'>{linkText}</a>
            </div>
        </div>
    );
}
