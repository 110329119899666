import {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import RecentToolAPI from 'annuity_lab/services/data/api/recent_tool.js';
// import AdvisorInformationAPI from 'annuity_lab/services/data/api/advisor_information.js';
import Constants from 'common/services/constants.js';
import {logout} from 'annuity_lab/actions/auth.js';
// import {ROLES} from 'common/domain/users';

const AuthRoutes = ({
    children,
    user,
    history
    /* , logout */
}) => {
    useEffect(() => {
        if (user) {
            const recentToolApi = new RecentToolAPI(user.id);
            const recentTool = recentToolApi.get() || Constants.DEFAULT_TOOL;

            history.push(recentTool);
        }
    }, [user]);

    // This effects logs out the currently active user on tab close
    // useEffect(() => {
    //     const handleUnload = () => {
    //         if (user && user.role === ROLES.ADVISOR) {
    //             // FIXME: Not sure where to plug it in the logout logic
    //             // Auth user is being reset when the authenticate request
    //             // returns empty object but this is separate advisor logic
    //             logout();
    //         }
    //     };

    //     window.addEventListener('beforeunload', handleUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleUnload);
    //     };
    // }, [user, logout]);

    if (!user) {
        // const isLastLoginAdvisor = AdvisorInformationAPI.get() !== null;
        // const redirect = isLastLoginAdvisor ? '/advisors-error' : '/login';

        const redirect = '/login';

        return (
            <Redirect to={redirect} />
        );
    }

    // TODO We need some way to detect if the user was logged in as advisor
    // and redirect him to /advisors in that case.
    // Maybe that keep in local storage?

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoutes);