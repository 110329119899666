import {combineReducers} from 'redux';
import productContext from 'annuity_lab/tools/lbc/reducers/product_context.js';
import comparisons from 'annuity_lab/tools/lbc/reducers/comparisons.js';
import sync from 'annuity_lab/tools/lbc/reducers/sync.js';
import selectRider from 'annuity_lab/tools/lbc/reducers/select_rider.js';
import riderCardsPlus from 'annuity_lab/tools/lbc/reducers/rider_cards_plus.js';
import productContextPlus from 'annuity_lab/tools/lbc/reducers/product_context_plus.js';
import comparisonsPlus from 'annuity_lab/tools/lbc/reducers/comparisons_plus.js';
import newComparisonPlus from 'annuity_lab/tools/lbc/reducers/new_comparison_plus.js';

const reducer = combineReducers({
    productContext,
    productContextPlus,
    comparisons,
    sync,
    selectRider,
    riderCardsPlus,
    comparisonsPlus,
    newComparisonPlus
});

export default reducer;
