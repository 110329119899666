import {useReducer} from 'react';

const reducer = (prevState, nextState) => ({
    ...prevState,
    ...nextState
});

/**
 * This hook doesn't overwrite the state when setState is called.
 * It only overwrites the targeted key/value pairs.
 * Works similarly to state and setState in React class components.
 *
 * @example
 * const [someObj, setSomeObj] = useClassState({
 *   a: 1,
 *   b: 2
 * });
 * setSomeObj({a: 3})
 * // someObj = {a: 3, b: 2}
 */
export const useClassState = (initialState) => {
    const [state, setState] = useReducer(reducer, initialState);

    return [state, setState];
};