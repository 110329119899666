import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import Select from 'annuity_lab/components/select/select.js.jsx';
import Constants from 'common/services/constants.js';
import {isPhone} from 'common/services/screen_util.js';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import LifetimeWithdrawalValuesDialog from
// eslint-disable-next-line max-len
    'annuity_lab/tools/lbc/components/dialogs/lifetime-withdrawal-values/lifetime-withdrawal-values.js.jsx';
import PropTypes from 'prop-types';
export default class CardPlusOptions extends React.Component {
    static propTypes = {
        card: PropTypes.object,
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            withdrawalValuesDialogOpen: false,
        };
    }

    openWithdrawalValuesDialog() {
        this.setState({withdrawalValuesDialogOpen: true});
    }

    closeWithdrawalValuesDialog() {
        this.setState({withdrawalValuesDialogOpen: false});
    }

    saveCard(withdrawalValues, note) {
        this.props.onChange({
            withdrawalValues,
            note
        });

        this.closeWithdrawalValuesDialog();
    }

    render() {
        const card = this.props.card;
        const selectYearsText = isPhone() ? 'Select Years' : 'Select No. of Years';

        return (
            <div className="options">
                <div className="selects-container">
                    <WithLabel label="Roll-up %">
                        <Select
                            placeholder="Select %"
                            value={Constants.ROLLUP_PERCENTAGE_PLUS_OPTIONS.find(o =>
                                o.value === card.rollup)}
                            options={Constants.ROLLUP_PERCENTAGE_PLUS_OPTIONS}
                            onChange={(option) =>
                                this.props.onChange({rollup: option.value})
                            } />
                    </WithLabel>

                    <WithLabel label="Rollup years">
                        <Select
                            placeholder={selectYearsText}
                            value={Constants.DEFERRAL_PERIOD_OPTIONS.find(o =>
                                o.value === card.rollupYears)}
                            options={Constants.DEFERRAL_PERIOD_OPTIONS}
                            onChange={(option) =>
                                this.props.onChange({rollupYears: option.value})
                            } />
                    </WithLabel>

                    <WithLabel label="Interest type">
                        <Select
                            placeholder="Select Type"
                            value={Constants.INTEREST_TYPE_OPTIONS.find(o =>
                                o.value === card.interestType)}
                            options={Constants.INTEREST_TYPE_OPTIONS}
                            onChange={(option) =>
                                this.props.onChange({interestType: option.value})
                            } />
                    </WithLabel>
                </div>

                <div className="withdrawl-values-container">
                    {this.props.card.note && this.props.card.note.length > 0 &&
                        <WithLabel label="Notes">
                            {this.props.card.note}
                        </WithLabel>
                    }

                    <button
                        type="button"
                        className="btn withdrawal-values"
                        onClick={() => this.openWithdrawalValuesDialog()}>
                        <span className="plus-icon" />
                        {CardsPlusUtil.isWithdrawalValuesEmpty(card.withdrawalValues)
                            ? 'Add Lifetime Withdrawal Values'
                            : 'Edit Lifetime Withdrawal Values'}
                    </button>

                    {this.state.withdrawalValuesDialogOpen &&
                        <LifetimeWithdrawalValuesDialog
                            withdrawalValues={this.props.card.withdrawalValues}
                            note={this.props.card.note}
                            onClose={() => {
                                this.closeWithdrawalValuesDialog();
                            }}
                            onSave={(values, note) => this.saveCard(values, note)} />
                    }
                </div>
            </div>
        );
    }
}
