/* eslint-disable max-len */
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import NewComparisonPlusHOC from
    'annuity_lab/tools/lbc/components/new_comparison_plus/new_comparison_plus_hoc.js.jsx';
import CardPlusSelect from
    'annuity_lab/tools/lbc/components/card_plus_select/card_plus_select.js.jsx';
import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import NumberInput from 'annuity_lab/components/number_input/number_input.js.jsx';
import Select from 'annuity_lab/components/select/select.js.jsx';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';
import CurrencyInput from 'annuity_lab/components/currency_input/currency_input.js.jsx';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';

class NewComparisonPlus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        };
    }

    render() {
        const {
            selected,
            comparisonsState,
            setCard,
            setComparisonsState,
            cardOptionsFor,
            compareRiderCards,
            moneyLabel
        } = this.props;

        const {
            premium,
            currentAge,
            ageAtWithdrawal,
            jointRider
        } = comparisonsState;

        const areAllFieldsFilled = selected[0] && selected[1]
            && premium && currentAge && ageAtWithdrawal && jointRider;

        const onFormSubmit = (ev) => {
            ev.preventDefault();

            if (Number(currentAge) > Number(ageAtWithdrawal)) {
                this.setState({
                    error: 'Age at first withdrawal should be more than age at issue.'
                });

                return;
            }

            const canCompare = CardsPlusUtil.canCompare(
                selected[0].withdrawalValues,
                selected[1].withdrawalValues,
                ageAtWithdrawal,
                jointRider === 'yes'
            );

            if (!canCompare) {
                let errorMessage = '';

                if (jointRider === 'yes') {
                    errorMessage = 'Please select a rider that has a Joint option.';
                }

                if (jointRider === 'no') {
                    errorMessage = 'Please select a rider that has a Single option.';
                }

                this.setState({
                    error: errorMessage
                });
            }

            if (areAllFieldsFilled && canCompare) {
                compareRiderCards(selected);
            }
        };

        return (
            <div className="new-comparison-plus-view">
                <h1 className="title">
                    Start comparing by selecting two of the rider cards you created.
                </h1>

                <form onSubmit={onFormSubmit} noValidate>
                    <div className="form-container">
                        <div className="row-container">
                            <div className="card-plus-select">
                                <CardPlusSelect
                                    placeholder="Select Rider #1"
                                    cards={cardOptionsFor(0)}
                                    matchPos="any"
                                    onChange={(prod) => setCard(0, prod)}
                                    value={selected[0]}/>

                            </div>
                            <div className="card-plus-select">
                                <CardPlusSelect
                                    placeholder="Select Rider #2"
                                    cards={cardOptionsFor(1)}
                                    matchPos="any"
                                    onChange={(prod) => setCard(1, prod)}
                                    value={selected[1]}/>
                            </div>
                        </div>
                        <div className="row-container">
                            <WithLabel label={moneyLabel}>
                                <CurrencyInput
                                    value={premium}
                                    onChange={v => setComparisonsState('premium', v)}/>
                            </WithLabel>

                            <WithLabel label="Age at issue">
                                <NumberInput
                                    min="0"
                                    max="1000"
                                    value={currentAge}
                                    onChange={v => setComparisonsState('currentAge', v)}/>
                            </WithLabel>
                        </div>
                        <div className="row-container">
                            <WithLabel label="Age at first withdrawal">
                                <NumberInput
                                    min="0"
                                    max="1000"
                                    value={ageAtWithdrawal}
                                    onChange={v => setComparisonsState('ageAtWithdrawal', v)}/>
                            </WithLabel>

                            <WithLabel label="Joint life option elected?">
                                <Select
                                    value={Constants.JOINT_RIDER_OPTIONS.find(o =>
                                        o.value === jointRider)}
                                    options={Constants.JOINT_RIDER_OPTIONS}
                                    onChange={o => setComparisonsState('jointRider', o.value)}/>
                            </WithLabel>
                        </div>
                    </div>

                    <div className="error">
                        {this.state.error}
                    </div>

                    <button
                        className={'btn btn-block btn-compare ' + (areAllFieldsFilled ? '' : 'disabled')}
                        type="submit"
                        disabled={!areAllFieldsFilled}>
                        Compare Riders
                    </button>
                </form>
            </div>
        );
    }
}

NewComparisonPlus.propTypes = {
    selected: PropTypes.array,
    cardOptionsFor: PropTypes.func,
    setCard: PropTypes.func,
    riderCardsPlus: PropTypes.array,
    history: PropTypes.object,
};

export default withRouter(NewComparisonPlusHOC(NewComparisonPlus));