/* eslint-disable max-len */
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import CardsUtil from 'common/services/cards_core_util.js';
import NoCardsView from 'annuity_lab/tools/common/components/no_cards/no_cards.js.jsx';
import NewMADComparison from 'annuity_lab/tools/mad/components/new_comparison/new_comparison.js.jsx';
/* eslint-enable max-len */

class Comparisons extends React.Component {
    render() {
        return this.props.riderCards.length >= 2 ?
            <NewMADComparison {...this.props}/> :
            <NoCardsView {...this.props} onPlusRidersView={false}/>;
    }
}

Comparisons.propTypes = {
    riderCards: PropTypes.array,
};

function mapStateToProps({common}) {
    const riderCards = common.riderCards;
    const completedCards = _(riderCards).values().filter(CardsUtil.isComplete).value();

    return {
        riderCards: completedCards,
        compareButtonText: 'Compare Selected Riders',
    };
}

export default connect(mapStateToProps)(Comparisons);
