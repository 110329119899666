import 'annuity_lab/styles/panel';
import 'annuity_lab/tools/common/components/card/card.css.scss';
import 'annuity_lab/tools/mad/components/card/card.css.scss';

// TODO: The following Component is for lbc comparisons
// We will need to make similar container for mad comparisons

/* eslint-disable max-len */
import ComparisonDependencyMessage from 'annuity_lab/tools/common/containers/comparison_dependency_message/comparison_dependency_message.js.jsx';
import CardOptions from 'annuity_lab/tools/common/components/card_options/card_options.js.jsx';
import PropTypes from 'prop-types';

import NumberFormat from 'common/services/number_format';
import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';

import CardsUtil from 'common/services/cards_core_util.js';
/* eslint-enable max-len */

var CONFIRM_TITLE = 'Please confirm',
    CONFIRM_MESSAGE = 'Are you sure you want to remove this rider from all devices?';

export default class Card extends React.Component {
    static propTypes = {
        index: PropTypes.number,
        card: PropTypes.object,
        baseProduct: PropTypes.object,
        money: PropTypes.string,
        deferralPeriod: PropTypes.number,
        removable: PropTypes.bool,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
    };

    isComplete() {
        return CardsUtil.isComplete(this.props.card) &&
               !_.isNil(this.props.money) &&
               !_.isNil(this.props.deferralPeriod);
    }

    isBaseProduct() {
        return !this.props.baseProduct;
    }

    shouldShowComparison() {
        const baseProduct = this.props.baseProduct;
        const money = this.props.money;
        const deferralPeriod = this.props.deferralPeriod;

        return !this.isBaseProduct() && CardsUtil.isComplete(baseProduct) &&
                (CardsUtil.initialPremium(
                    baseProduct,
                    money,
                    deferralPeriod)
                ).lessThan(this.initialPremium());
    }

    initialPremium() {
        if (!this.isComplete()) {
            return 0;
        }

        return CardsUtil.initialPremium(
            this.props.card,
            this.props.money,
            this.props.deferralPeriod
        );
    }

    onRemove() {
        if (!CardsUtil.isComplete(this.props.card)) {
            this.props.onRemove();

            return;
        }

        const dependencyMessage = <ComparisonDependencyMessage card={this.props.card} />,
            dialogContent = <div>{CONFIRM_MESSAGE} {dependencyMessage}</div>;

        ModalDialogs.confirm(CONFIRM_TITLE, dialogContent, this.props.onRemove);
    }

    getDetails() {
        if (!this.isComplete() || !this.shouldShowComparison()) {
            return;
        }

        const difference = CardsUtil.initialPremiumDifference(
            this.props.card,
            this.props.baseProduct,
            this.props.money,
            this.props.deferralPeriod
        );

        return (
            <span className="required-premium money-value">
                {` (${NumberFormat.currency(difference)} More Premium Required)`}
            </span>
        );
    }

    render() {
        const card = this.props.card;

        return (
            <div className={'card panel ' + (this.isComplete() ? 'card-evaluated' : '')}>
                <div className="header">
                    <div className="number">{this.props.index + 1}</div>
                    <h4 className="name">Rider {card.displayName}</h4>

                    {
                        this.props.removable &&
                            <button className="close-button" onClick={() => this.onRemove()} />
                    }
                </div>

                <div className="body">
                    <CardOptions
                        card={card}
                        onChange={this.props.onChange} />

                    <div className="with-label additional-premium-label">
                        <label>Premium</label>
                    </div>
                </div>

                <div className="footer">
                    <h2 className="initial-premium">
                        <span className="money-value">
                            {NumberFormat.currency(this.initialPremium())}
                        </span>
                        {this.getDetails()}
                    </h2>

                </div>
            </div>
        );
    }
}
