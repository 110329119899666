import _ from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {loadComparisons} from 'annuity_lab/tools/lbc/actions/comparisons.js';
import {updateShowSelectRiderError} from 'annuity_lab/tools/lbc/actions/select_rider.js';
import Layout from 'annuity_lab/tools/lbc/components/layout/layout.js.jsx';

function mapStateToProps({auth, lbc}) {
    return {
        user: auth.user,
        headerTitle: 'Living Benefit Income Calculator',
        menuRoot: '/lbc',
        menuComparisons: _.values(lbc.comparisons),
        menuComparisonsPlus: _.values(lbc.comparisonsPlus),
        loadComparisons: loadComparisons,
        riderType: lbc.selectRider.riderType
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateShowSelectRiderError: (showSelectRiderError) => {
            dispatch(updateShowSelectRiderError(showSelectRiderError));
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
