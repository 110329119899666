import Constants from 'common/services/constants.js';
import actionTypes from 'annuity_lab/tools/fia/actions/action_types.js';

const initialState = {
    all: {},
    selected: [{
        productName: '',
        capRate: Constants.CAP_RATE.DEFAULT,
        maxCapRate: Constants.CAP_RATE.MAX,
    }, {
        productName: '',
        capRate: Constants.CAP_RATE.DEFAULT,
        maxCapRate: Constants.CAP_RATE.MAX,
    }]
};

export default function productsReducer(state = initialState, action) {
    let result;

    switch (action.type) {
    case actionTypes.PRODUCTS_LOADED: {
        const products = action.payload.products;
        const productsMap = _.keyBy(products, 'name');

        result = _.assign({}, state, {all: productsMap});
        break;
    }

    case actionTypes.UPDATE_SELECTED_PRODUCTS: {
        result = _.assign({}, state);
        result.selected = action.payload.products;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
