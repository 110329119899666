/* eslint-disable react/no-multi-comp */
import {useState, useEffect} from 'react';
import _ from 'lodash';

import Constants from 'common/services/constants.js';
import MessageDialog
    from 'common/components/dialogs/message_dialog/message_dialog.js.jsx';
import LoadingIndicator
    from 'annuity_lab/components/loading_indicator/loading_indicator.js.jsx';

const ShareDialogs = (props) => {
    const {share, clearShare, entity} = props;

    const SHARE_OUTCOME_CONFIRMATION_TEXTS = {
        success: {
            title: 'Successful Share',
            content: `Your ${_.capitalize(entity)} has been successfully shared.`
        },
        fail: {
            title: 'Unsuccessful Share',
            content: 'Something went wrong, please try again later.'
        }
    };

    const [isShareOutcomeMessageOpen, setIsShareOutcomeMessageOpen] = useState(false);

    const openShareOutcomeMessage = () => {
        setIsShareOutcomeMessageOpen(true);
    };
    const closeShareOutcomeMessage = () => {
        setIsShareOutcomeMessageOpen(false);
        clearShare();
    };

    const {status} = share;

    useEffect(() => {
        if(
            status === Constants.COMPARISON_SHARE_STATUS.SUCCESS ||
            status === Constants.COMPARISON_SHARE_STATUS.FAIL) {
            openShareOutcomeMessage();
        }
    }, [status]);

    const renderLoadingIndicator = () => {
        const inProgress = share.status === Constants.COMPARISON_SHARE_STATUS.IN_PROGRESS;

        return inProgress && (
            <LoadingIndicator
                loadingText="Generating PDF..."
                opacity={0.7}/>
        );
    };

    const renderShareOutcomeMessage = () => {
        if (isShareOutcomeMessageOpen) {
            const isSuccessful = status === Constants.COMPARISON_SHARE_STATUS.SUCCESS;
            const texts = isSuccessful
                ? SHARE_OUTCOME_CONFIRMATION_TEXTS.success
                : SHARE_OUTCOME_CONFIRMATION_TEXTS.fail;

            return (
                <MessageDialog
                    title={texts.title}
                    content={texts.content}
                    onRequestClose={closeShareOutcomeMessage}/>
            );
        }
    };

    return (
        <>
            {renderLoadingIndicator()}
            {renderShareOutcomeMessage()}
        </>
    );
};

export default ShareDialogs;