import 'annuity_lab/containers/login/login.css.scss';

import PropTypes from 'prop-types';
import View from 'common/lib/view';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import InputWithIcon from 'annuity_lab/components/input_with_icon/input_with_icon.js.jsx';
import Checkbox from 'annuity_lab/components/checkbox/checkbox.js.jsx';

import Constants from 'common/services/constants.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import {login, resetAuthError} from 'annuity_lab/actions/auth.js';
import RecentToolAPI from 'annuity_lab/services/data/api/recent_tool.js';
import validateEmail from 'common/services/email_validator.js';
import MessageDialog
    from 'common/components/dialogs/message_dialog/message_dialog.js.jsx';
import ToolsButtons from 'annuity_lab/components/tools_buttons/tools_buttons.js.jsx';

const unsuccessfulLoginDialogTitle = 'Unsuccessful Login';
const unsuccessfulLoginDialogText =
    'Please check your internet connection or try again later.';

class Login extends View {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            rememberMe: true,
            validEmail: true,
            unsuccessfulLoginDialogOpen: false,
        };
    }

    UNSAFE_componentWillMount() {
        this._navigate(this.props.currentUser);
    }

    viewEntered() {
        AnnuityLabAnalytics.trackView('Login');
    }

    _navigate(currentUser) {
        if (currentUser) {
            const {history, location} = this.props;
            const recentToolApi = new RecentToolAPI(currentUser.id);
            const defaultPathname = recentToolApi.get() || Constants.DEFAULT_TOOL;
            const nextPathname = (location.state && location.state.nextPathname) || defaultPathname;

            history.push({
                pathname: nextPathname,
            });
        }
    }

    openUnsuccessfulLoginDialog() {
        this.setState({unsuccessfulLoginDialogOpen: true});
    }

    closeUnsuccessfulLoginDialog() {
        this.setState({unsuccessfulLoginDialogOpen: false});
    }

    onLoginClicked(nextPath) {
        if (this.props.online) {
            const {email, password, rememberMe} = this.state;

            if (!validateEmail(email)) {
                this.setState({validEmail: false});

                return;
            }

            this.props.onLogin(email, password, rememberMe, nextPath);
        } else {
            this.openUnsuccessfulLoginDialog();
        }
    }

    render() {
        return (
            <div className="login-view">
                <div className="icon-logo">
                </div>

                <div onSubmit={(event) => this.onLoginClicked(event)}>
                    <h1 className="body">
                        <div>Simply sign-up</div>
                        <InputWithIcon
                            type="text"
                            iconClass="icon-email"
                            placeholder="Enter Email Address"
                            isValid={this.state.validEmail}
                            onChange={(event) =>
                                this.setState({
                                    email: event.target.value.toLowerCase(),
                                    validEmail: true,
                                })} />
                        <InputWithIcon
                            type="password"
                            iconClass="icon-password"
                            placeholder="Enter Password"
                            isValid={!this.props.invalidPassword}
                            onChange={(event) => {
                                this.props.invalidPassword && this.props.resetAuthError();
                                this.setState({
                                    password: event.target.value,
                                });
                            }}/>
                        <Checkbox
                            className="remember-me"
                            label="Remember Me"
                            checked={this.state.rememberMe}
                            onChange={(checked) => {
                                this.setState({
                                    rememberMe: checked
                                });
                            }} />
                        <div className="tip">
                            and begin comparing with one of the
                        </div>
                        <div className="tools-label">
                            <b>Annuity Lab Tools</b>
                        </div>
                    </h1>

                    <div className="invitation">Start comparing</div>

                    <ToolsButtons
                        handleClick={(tool) => () => {
                            this.onLoginClicked(tool);
                        }}/>

                    <div className="errors">
                        <ul>
                            { this.state.validEmail ? '' : <li>Invalid Email</li> }
                            { this.props.invalidPassword ? <li>Invalid Password</li> : '' }
                        </ul>
                    </div>
                </div>
                { this.state.unsuccessfulLoginDialogOpen &&
                    <MessageDialog
                        onRequestClose={() => this.closeUnsuccessfulLoginDialog()}
                        title={unsuccessfulLoginDialogTitle}
                        content={unsuccessfulLoginDialogText}/>
                }
            </div>
        );
    }
}

Login.propTypes = {
    currentUser: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
};

function mapStateToProps({auth, apiStatus}) {
    return {
        currentUser: auth.user,
        // XXX: extract app errors --georgi.ivanov@2016-12-14
        invalidPassword: auth.error === 'wrongCredentials',
        online: apiStatus.online,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onLogin: (email, password, rememberMe, nextPath) => {
            dispatch(login(email, password, rememberMe, nextPath));
        },
        resetAuthError: () => {
            dispatch(resetAuthError());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
