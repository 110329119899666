import 'annuity_lab/tools/mad/components/comparison_card_summary/comparison_card_summary.css.scss';
import PropTypes from 'prop-types';
import NumberFormat from 'common/services/number_format.js';

export default class ComparisonCardSummary extends React.Component {
    static propTypes = {
        money: PropTypes.string.isRequired,
        card: PropTypes.object.isRequired,
        baseProduct: PropTypes.object.isRequired,
        premiumDifference: PropTypes.number.isRequired,
        annualDifference: PropTypes.object.isRequired,
        inline: PropTypes.bool,
        additionalClasses: PropTypes.string,
        showAdditionalText: PropTypes.bool.isRequired,
    }

    render() {
        const {
            money,
            card,
            baseProduct,
            premiumDifference,
            annualDifference,
            inline,
            showAdditionalText,
            additionalClasses,
        } = this.props;

        const worseThanBase = premiumDifference < 0;

        return (
            <div className={
                'comparison-card-summary ' +
                    (inline ? 'inline ' : '') +
                    (additionalClasses ? additionalClasses : '')
            }>
                <div className={'summary product'}>
                    <div className="difference-description">
                        Rider {card.displayName.toUpperCase()} Requires
                    </div>
                    <div className="difference-amount">
                        {NumberFormat.currency(Math.abs(premiumDifference))}
                    </div>
                    <div className="difference-description">
                        {worseThanBase ? 'More' : 'Less'} Premium than
                        Rider {baseProduct.displayName.toUpperCase()}
                    </div>
                </div>
                {showAdditionalText ? (
                    <div className={'additional-text ' + (worseThanBase ? '' : 'hidden')}>
                        By electing Rider {baseProduct.displayName.toUpperCase()} the client{' '}
                        would achieve their income goal using <span>
                            {NumberFormat.currency(Math.abs(premiumDifference))}
                        </span> less premium than Rider {card.displayName.toUpperCase()}. If the
                        Rider {baseProduct.displayName.toUpperCase() + ' '} premium was increased
                        {' '}by the <span>{NumberFormat.currency(Math.abs(premiumDifference))}
                        </span> then the annual income would increase from
                        <span> {NumberFormat.currency(Number(money))} </span>to
                        <span> {NumberFormat.currency(Number(money) + Math.abs(annualDifference))}
                        </span> each year.
                    </div> ) : ''
                }
            </div>
        );
    }
}
