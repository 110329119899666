import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';

const initialState = {
    riderType: null,
    errorVisible: false
};

export default function reducer(state = initialState, action) {
    let result = _.clone(state);

    if (action.type === ACTION_TYPES.SELECT_RIDER) {
        result.riderType = action.payload.riderType;
        result.errorVisible = false;
    } else if (action.type === ACTION_TYPES.SHOW_SELECT_RIDER_ERROR) {
        result.errorVisible = action.payload.errorVisible;
    } else {
        result = state;
    }

    return result;
}