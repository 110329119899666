import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {updateNewModel, resetNewModel} from 'annuity_lab/tools/imo/actions/new_model.js';
import Model from 'annuity_lab/tools/imo/components/model/model.js.jsx';

const mapStateToProps = ({imo}) => ({
    newModel: imo.newModel,
    models: imo.models,
});

const mapDispatchToProps = (dispatch) => ({
    updateNewModel: (newModel) => {
        dispatch(updateNewModel(newModel));
    },
    resetNewModel: () => {
        dispatch(resetNewModel());
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Model));