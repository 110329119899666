import ACTION_TYPES from 'annuity_lab/tools/imo/actions/action_types.js';
import BASE_ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const initialState = {};

export default function modelsReducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.MODELS_LOADED: {
        result = _.keyBy(action.payload.models, 'id');
        break;
    }

    case ACTION_TYPES.MODEL_SAVED: {
        result = _.assign({}, state);
        const model = action.payload.model;

        result[model.id] = model;
        break;
    }

    case ACTION_TYPES.MODEL_REMOVED: {
        result = _.assign({}, state);
        delete result[action.payload.modelId];
        break;
    }

    case BASE_ACTION_TYPES.LOGOUT: {
        result = initialState;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}