import ACTION_TYPES from 'annuity_lab/tools/common/actions/action_types.js';
import BASE_ACTION_TYPES from 'annuity_lab/actions/action_types.js';
import _ from 'lodash';
import CardsUtil from 'common/services/cards_core_util.js';

// key-value map cardId -> card
const initialState = {};

export default function riderCardsReducer(state = initialState, action) {
    let result;

    switch (action.type) {
    case ACTION_TYPES.CARDS_LOADED: {
        const cards = CardsUtil.generateCardsDisplayNames(action.payload.cards);

        result = _.keyBy(cards, 'id');
        break;
    }

    case ACTION_TYPES.CARD_CREATED: {
        const card = action.payload.card;
        const displayName = CardsUtil.generateDisplayName(card);
        const newCard = _.assign({}, card, {displayName});

        result = _.assign({}, state);
        result[newCard.id] = newCard;
        break;
    }

    case ACTION_TYPES.CARD_UPDATED: {
        const card = action.payload.card;

        result = _.assign({}, state);
        card.displayName = result[action.payload.cardId].displayName;
        result[action.payload.cardId] = card;
        break;
    }

    case ACTION_TYPES.CARD_REMOVED: {
        result = _.assign({}, state);
        delete result[action.payload.card.id];
        break;
    }

    case ACTION_TYPES.CARDS_RESET: {
        const cards = CardsUtil.generateCardsDisplayNames(action.payload.cards);
        const cardsMap = _.keyBy(cards, 'id');

        result = _.assign({}, cardsMap);
        break;
    }

    case BASE_ACTION_TYPES.LOGOUT: {
        result = initialState;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
