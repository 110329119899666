import PropTypes from 'prop-types';

import Select from 'annuity_lab/components/select/select.js.jsx';
import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import WithTooltip from 'annuity_lab/components/with_tooltip/with_tooltip.js.jsx';
import CurrencyInput from 'annuity_lab/components/currency_input/currency_input.js.jsx';
import Calculator from 'annuity_lab/tools/imo/services/calculator.js';

const MAX_AMOUNT_OF_YEAR_OPTIONS = 30;

const inputsInformation = {
    annualIncome: {
        label: 'Reduced annual income',
        content: `Amount withdrawn from the contract.
        Must be less than the allowed annual income to generate carryforward amount.`
    },
    startYear: {
        label: 'Start year',
        content: 'The first withdrawal year the client takes the Reduced Annual Income amount.'
    },
    endYear: {
        label: 'End year',
        content: 'The last withdrawal year the client takes the Reduced Annual Income amount.'
    }
};

const CarryForwardInputs = (props) => {
    const {
        model,
        handleCarryForwardChange
    } = props;

    const {carryForward, riderA, clientInformation} = model;

    const {
        annualIncome,
        startYear,
        endYear
    } = carryForward;

    const onChange = (key, value) => {
        handleCarryForwardChange({
            [key]: value
        });
    };

    const generateOptions = (startYear, endYear) => {
        return _.range(Number(startYear), Number(endYear) + 1).map(year => ({
            value: year,
            label: year
        }));
    };

    const getStartYearOptions = () => {
        if (endYear) {
            return generateOptions(1, Number(endYear));
        } else {
            return generateOptions(
                1,
                MAX_AMOUNT_OF_YEAR_OPTIONS);
        }
    };

    const getEndYearOptions = () => {
        if (startYear) {
            return generateOptions(
                Number(startYear),
                MAX_AMOUNT_OF_YEAR_OPTIONS);
        } else {
            return generateOptions(
                1,
                MAX_AMOUNT_OF_YEAR_OPTIONS + 1);
        }
    };

    const startYearOptions = getStartYearOptions();
    const endYearOptions = getEndYearOptions();

    const maxAnnualIncome = Math.floor(
        Number(Calculator.calculateAbove0YearlyPayout(riderA, clientInformation))
    );

    return (
        <React.Fragment>
            <WithLabel label={inputsInformation.annualIncome.label.toUpperCase()}>
                <WithTooltip
                    title={inputsInformation.annualIncome.label}
                    content={inputsInformation.annualIncome.content}>
                    <CurrencyInput
                        value={annualIncome.toString()}
                        onChange={(nv) => onChange('annualIncome', nv)}
                        placeholder="Select a Premium Amount"
                        max={maxAnnualIncome.toString()}/>
                </WithTooltip>
            </WithLabel>
            <WithLabel label={inputsInformation.startYear.label}>
                <WithTooltip
                    title={inputsInformation.startYear.label}
                    content={inputsInformation.startYear.content}>
                    <Select
                        value={startYearOptions.find(o => o.value === startYear)}
                        options={startYearOptions}
                        onChange={o => onChange('startYear', o.value)}
                        placeholder="Select Year"/>
                </WithTooltip>
            </WithLabel>
            <WithLabel label={inputsInformation.endYear.label}>
                <WithTooltip
                    title={inputsInformation.endYear.label}
                    content={inputsInformation.endYear.content}>
                    <Select
                        value={endYearOptions.find(o => o.value === endYear)}
                        options={endYearOptions}
                        onChange={o => onChange('endYear', o.value)}
                        placeholder="Select Year"/>
                </WithTooltip>
            </WithLabel>
        </React.Fragment>
    );
};

CarryForwardInputs.propTypes = {
    model: PropTypes.object,
    handleCarryForwardChange: PropTypes.func
};

export default CarryForwardInputs;