import 'annuity_lab/tools/lbc/components/card_plus/card_plus.css.scss';
import 'annuity_lab/styles/panel';

/* eslint-disable max-len */

import PropTypes from 'prop-types';

import ComparisonPlusDependencyMessage from 'annuity_lab/tools/lbc/containers/comparison_plus_dependency_message/comparison_plus_dependency_message.js.jsx';
import CardPlusOptions from 'annuity_lab/tools/lbc/components/card_plus_options/card_plus_options.js.jsx';

import NumberFormat from 'common/services/number_format';
import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';

import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
/* eslint-enable max-len */

const CONFIRM_TITLE = 'Please confirm';
const CONFIRM_MESSAGE = 'Are you sure you want to remove this rider from all devices?';

export default class CardPlus extends React.Component {
    static propTypes = {
        index: PropTypes.number,
        card: PropTypes.object,
        baseProduct: PropTypes.object,
        money: PropTypes.string,
        removable: PropTypes.bool,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
    };

    isComplete() {
        return CardsPlusUtil.isComplete(this.props.card) &&
               !_.isNil(this.props.money);
    }

    isBaseProduct() {
        return !this.props.baseProduct;
    }

    shouldShowComparison() {
        const baseProduct = this.props.baseProduct,
            money = this.props.money;

        return !this.isBaseProduct() && CardsPlusUtil.isComplete(baseProduct) &&
                this.annualIncome().lessThan(CardsPlusUtil.annualIncome(
                    baseProduct,
                    money,
                )
                );
    }

    annualIncome() {
        if (!this.isComplete()) {
            return 0;
        }

        return CardsPlusUtil.annualIncome(
            this.props.card,
            this.props.money,
        );
    }

    renderFooterAmount() {
        if (!this.isComplete()) {
            return NumberFormat.currency(0);
        }

        const baseBenefit = CardsPlusUtil.baseBenefit(this.props.card, this.props.money);

        if (!this.isBaseProduct()) {
            const neededPremiumIncrease = CardsPlusUtil.calculateNeededPremiumIncrease(
                this.props.baseProduct,
                this.props.card,
                this.props.money
            );

            return (
                <div>
                    <span className="money-value">{NumberFormat.currency(baseBenefit)}</span>
                    <span className="amount-needed">({NumberFormat.currency(neededPremiumIncrease)}
                        {' '}More Premium Required)
                    </span>
                </div>
            );
        }

        return <span className="money-value">{NumberFormat.currency(baseBenefit)}</span>;
    }

    onRemove() {
        if (!CardsPlusUtil.isComplete(this.props.card)) {
            this.props.onRemove();

            return;
        }

        const dependencyMessage = <ComparisonPlusDependencyMessage card={this.props.card} />;
        const dialogContent = <div>{CONFIRM_MESSAGE} {dependencyMessage}</div>;

        ModalDialogs.confirm(CONFIRM_TITLE, dialogContent, this.props.onRemove);
    }

    render() {
        const card = this.props.card;

        return (
            <div className={'card-plus panel ' + (this.isComplete() ? 'card-evaluated' : '')}>
                <div className="header">
                    <div className="number">{this.props.index + 1}</div>
                    <h4 className="name">Rider {card.displayName}</h4>

                    {this.props.removable &&
                        <button className="close-button" onClick={() => this.onRemove()}></button>
                    }
                </div>

                <div className="body">
                    <CardPlusOptions
                        card={card}
                        onChange={this.props.onChange} />
                </div>

                <div className="footer">
                    {/* {this.renderFooterAmount()} */}
                </div>
            </div>
        );
    }
}
