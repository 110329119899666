import ACTION_TYPES from 'annuity_lab/tools/common/actions/action_types.js';
import _ from 'lodash';
import Constants from 'common/services/constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.SHARE_COMPARISON_IN_PROGRESS: {
        result = _.assign({}, state, {
            status: Constants.COMPARISON_SHARE_STATUS.IN_PROGRESS
        });

        break;
    }

    case ACTION_TYPES.SHARE_COMPARISON_REQUESTED: {
        result = _.assign({}, state, {
            status: Constants.COMPARISON_SHARE_STATUS.PENDING
        });

        break;
    }

    case ACTION_TYPES.SHARE_COMPARISON_COMPLETED: {
        result = _.assign({}, state, {
            status: action.payload.status,
        });

        break;
    }

    case ACTION_TYPES.SHARE_COMPARISON_CANCELED: {
        result = _.assign({}, state, {
            status: Constants.COMPARISON_SHARE_STATUS.CANCELED
        });

        break;
    }

    case ACTION_TYPES.SHARE_CLEAR: {
        result = initialState;

        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
