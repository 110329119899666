import 'annuity_lab/styles/modal_dialog.css.scss';
import 'annuity_lab/tools/common/components/dialogs/share_comparison/share_comparison.css.scss';
import EmailChips from 'annuity_lab/tools/lbc/components/email_chips/email_chips.js.jsx';
import OfflineErrorMessage from 'annuity_lab/tools/lbc/services/offline_share_error_messages.js';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const SUBJECT_PLACEHOLDER = ' sent by agent using Nationwide Annuity Lab';
const body = document.querySelector('body');

export default class ShareComparison extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func.isRequired,
        onShare: PropTypes.func.isRequired,
        comparisonName: PropTypes.string,
        userEmail: PropTypes.string,
        className: PropTypes.string,
        contacts: PropTypes.array,
        loadContacts: PropTypes.func,
        offline: PropTypes.bool,
        emailBodyText: PropTypes.string,
        entity: PropTypes.string,
        subject: PropTypes.string
    }

    static defaultProps = {
        entity: 'comparison',
        subject: 'Income Calculator'
    }

    constructor(props) {
        super(props);

        const subject = props.subject + SUBJECT_PLACEHOLDER;

        this.state = {
            subject,
            wholesalerName: '',
            wholesalerPhoneNumber: '',
            hasEmailError: false,
            hasNameError: false,
            hasPhoneError: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.loadContacts();
    }

    handleOnAfterOpen() {
        this.toInput.focus();
    }

    storeFocusInput(autosuggest) {
        if (autosuggest) {
            this.toInput = autosuggest.input;
        }
    }

    isFormValid() {
        const {wholesalerName, wholesalerPhoneNumber} = this.state;
        const emails = (this.chips && this.chips.getEmails()) || [];

        return wholesalerName.trim() && wholesalerPhoneNumber.trim() && emails.length;
    }

    validateEmails() {
        this.setState({hasEmailError: !this.chips || this.chips.getEmails().length === 0});
    }

    share(event) {
        event.preventDefault();

        const emails = (this.chips && this.chips.getEmails()) || [];

        let {subject, wholesalerName, wholesalerPhoneNumber} = this.state;

        wholesalerName = wholesalerName.trim();
        wholesalerPhoneNumber = wholesalerPhoneNumber.trim();

        if (wholesalerName && wholesalerPhoneNumber && emails.length) {
            const shareOptions = _.merge({}, this.state, {
                subject: subject,
                body: this.getCopyText(),
                agentName: wholesalerName,
                receiversEmails: this.chips.getEmails(),
            });

            this.props.onShare(shareOptions);
        } else {
            this.setState({
                hasEmailError: !emails.length,
                hasNameError: !wholesalerName,
                hasPhoneError: !wholesalerPhoneNumber,
            });
        }
    }

    getCopyText() {
        return `${this.props.emailBodyText}` +
            `${this.state.wholesalerName}\n` +
            `${this.state.wholesalerPhoneNumber}\n`;
    }

    render() {
        const isFormValid = this.isFormValid();
        const disableShareButton = !isFormValid || this.props.offline;

        return (
            <Modal
                isOpen
                {...this.props}
                className="modal-dialog share-comparison-dialog panel"
                overlayClassName={`modal-dialog-overlay ${this.props.className}`}
                onAfterOpen={this.handleOnAfterOpen.bind(this)}
                appElement={body}>

                <div className="header">
                    <h4>Share This {_.capitalize(this.props.entity)}</h4>

                    <button className="close-button" onClick={this.props.onRequestClose} />
                </div>

                <form onSubmit={(ev) => this.share(ev)} className="share-form">
                    <div className="body">
                        <div>
                            <label className="active">To</label>
                            <EmailChips
                                contacts={this.props.contacts}
                                onEmailsChanged={() => {
                                    this.validateEmails();
                                }}
                                className={this.state.hasEmailError ? 'invalid' : ''}
                                ref={(chips) => {
                                    this.chips = chips;
                                }}
                                storeFocusInput={this.storeFocusInput.bind(this)}/>
                            {
                                this.state.hasEmailError &&
                                    <div className='invalid-input-text'>
                                        Email is required
                                    </div>
                            }
                        </div>
                        <div className="sender">
                            <label>Bcc</label>
                            <input
                                type="email"
                                value={this.props.userEmail}
                                className="sender-input"
                                disabled/>
                        </div>
                        <div className="subject">
                            <label>Subject</label>
                            <input
                                type="text"
                                className="subject-input"
                                value={this.state.subject}
                                disabled />
                        </div>
                        <div className={
                            `wholesaler-name ${this.state.hasNameError ? 'invalid' : ''}`
                        }>
                            <label className="optional active">Name</label>
                            <input
                                className="wholesaler-name-input"
                                type="text"
                                value={this.state.wholesalerName}
                                placeholder="Please enter your name"
                                onChange={(e) => {
                                    const wholesalerName = e.target.value;

                                    let subject = this.props.subject + SUBJECT_PLACEHOLDER;

                                    if (wholesalerName) {
                                        subject = this.props.subject + ' sent by '
                                            + wholesalerName
                                            + ' using the Nationwide Annuity Lab';
                                    }

                                    this.setState({
                                        subject,
                                        wholesalerName,
                                        hasNameError: !wholesalerName,
                                    });
                                }} />
                            {
                                this.state.hasNameError &&
                                    <div className='invalid-input-text'>
                                        Contact name is required
                                    </div>
                            }
                        </div>
                        <div className={
                            `wholesaler-phone-number ${this.state.hasPhoneError ? 'invalid' : ''}`
                        }>
                            <label className="optional active">Phone number</label>
                            <input
                                className="wholesaler-phone-number-input"
                                type="text"
                                value={this.state.wholesalerPhoneNumber}
                                placeholder="Please enter your phone number"
                                onChange={(e) => {
                                    const wholesalerPhoneNumber = e.target.value;

                                    this.setState({
                                        wholesalerPhoneNumber,
                                        hasPhoneError: !wholesalerPhoneNumber
                                    });
                                }}/>
                            {
                                this.state.hasPhoneError &&
                                    <div className='invalid-input-text'>
                                        Phone number is required
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="body-area">
                        <label>Body</label>
                        <textarea
                            className="body-input"
                            value={this.getCopyText()}
                            readOnly={true} />
                    </div>
                    <div className="comparison-name">
                        <label>{_.capitalize(this.props.entity)} Name</label>
                        <div className="comparison-name-input">{this.props.comparisonName}</div>
                    </div>

                    <div className="footer">
                        {
                            this.props.offline &&
                                <div className='offline-message'>{OfflineErrorMessage.CONTENT}</div>
                        }
                        <button
                            type="submit"
                            className={'btn share-button ' + (disableShareButton ? 'disabled' : '')}
                            disabled={disableShareButton}>
                            Share This {_.capitalize(this.props.entity)}
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}
