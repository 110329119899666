import Connection from 'common/services/connection.js';

class HealthCheckService {
    check() {
        return Connection.send('/healthcheck')
            .then(() => true, () => false);
    }
}

export default new HealthCheckService();
