import superagent from 'superagent';
import Promise from 'bluebird';
import config from 'config/config.js';

import {TOOLS} from 'common/domain/tools.js';

class Uploader {
    uploadFiaData(file, force) {
        return this.uploadFile(file, TOOLS.INDEX_COMPARISON_CALCULATOR, force);
    }

    uploadLbcData(file, force) {
        return this.uploadFile(file, TOOLS.LIVING_BENEFIT_RIDERS_CORE, force);
    }

    uploadFile(file, tool, force) {
        const data = new FormData();

        data.append('indexesData', file);
        data.append('forceUpdate', force);

        const request = superagent
            .post(`${config.API_URL}/${tool}/upload`)
            .send(data)
            .withCredentials()
            .accept('json');

        return Promise.promisify(request.end.bind(request))()
            .then(resp => {
                return resp.body;
            }, err => {
                const message = err.response.body
                    ? err.response.body.error
                    : err.message;

                const error = new Error(message);

                return Promise.reject(error);
            });
    }
}

export default new Uploader();