import 'annuity_lab/tools/lbc/components/chart/column/column.css.scss';
import PropTypes from 'prop-types';
export default class Column extends React.Component {
    static propTypes = {
        activeHeight: PropTypes.number.isRequired,
        inactiveHeight: PropTypes.number.isRequired,
        label: PropTypes.string,
        subtext: PropTypes.string,
        columnText: PropTypes.string,

        onClick: PropTypes.func
    }

    render() {
        return (
            <div className="chart-column" onClick={this.props.onClick}>
                <div className="block-container">
                    <div className="active" style={{height: `${this.props.activeHeight}%`}} />
                    <div className="inactive" style={{height: `${this.props.inactiveHeight}%`}}>
                        {this.props.columnText}
                    </div>
                </div>

                <div className="labels">
                    <div className="label">{this.props.label}</div>
                    <div className="subtext">{this.props.subtext}</div>
                </div>
            </div>
        );
    }
}
