import 'annuity_lab/styles/modal_dialog.css.scss';
import 'annuity_lab/tools/common/components/dialogs/save_modal/save_modal.css.scss';

import PropTypes from 'prop-types';

import Modal from 'react-modal';

const COMPARISON_NAME_REF = 'nameInput';
const body = document.querySelector('body');

export default class SaveModal extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        entity: PropTypes.string.isRequired,
        className: PropTypes.string,
    }

    constructor(props) {
        super(props);

        this.state = {
            name: ''
        };
    }

    formValid() {
        return _.trim(this.state.name) !== '';
    }

    handleOnAfterOpen() {
        this.refs[COMPARISON_NAME_REF].focus();
    }

    save(event) {
        event.preventDefault();

        if (this.formValid()) {
            this.props.onSave(this.state.name);
        }
    }

    render() {
        const {entity} = this.props;
        const entityCapitalized = entity.charAt(0).toUpperCase() + entity.slice(1);

        return (
            <Modal isOpen
                onAfterOpen={this.handleOnAfterOpen.bind(this)}
                {...this.props}
                className="modal-dialog save-comparison-dialog panel"
                overlayClassName={`modal-dialog-overlay ${this.props.className}`}
                appElement={body}>

                <div className="header">
                    <h4>Save This {entityCapitalized}</h4>

                    <button className="close-button" onClick={this.props.onRequestClose} />
                </div>

                <form onSubmit={(event) => this.save(event)}>
                    <div className="body">
                        <input
                            ref={COMPARISON_NAME_REF}
                            type="text"
                            placeholder={`Name This ${entityCapitalized}`}
                            value={this.state.name}
                            onChange={(e) => this.setState({name: e.target.value})} />
                    </div>

                    <div className="footer">
                        <button
                            type="submit"
                            className={
                                'btn save-button ' + (this.formValid() ? '' : 'disabled')
                            }>
                            Save
                        </button>
                    </div>
                </form>

            </Modal>
        );
    }
}
