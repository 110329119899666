import {connect} from 'react-redux';

import RiderSelect from 'annuity_lab/tools/imo/components/rider_select/rider_select.js.jsx';
import CardsUtil from 'common/services/cards_core_util.js';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';

const mapStateToProps = ({common, lbc}) => ({
    riderCards: Object.values(common.riderCards).filter(c => CardsUtil.isComplete(c)),
    riderCardsPlus: Object.values(lbc.riderCardsPlus).filter(c => CardsPlusUtil.isComplete(c))
});

export default connect(mapStateToProps)(RiderSelect);