import ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const initialState = {
    online: false,
};

export default function reducer(state = initialState, action) {
    let result;

    switch (action.type) {
    case ACTION_TYPES.API_STATUS_UPDATED: {
        const online = action.payload.online;

        result = {online};
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
