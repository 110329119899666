import {useEffect} from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';

import NewModelForm from 'annuity_lab/tools/imo/containers/new_model_form/new_model_form.js.jsx';
import Model from 'annuity_lab/tools/imo/containers/model/model.js.jsx';

const IMORoutes = ({history, location}) => {
    useEffect(() => {
        if (location.pathname === '/imo') {
            history.replace('/imo/new');
        }
    }, [history, location]);

    return (
        <div className="view-content">
            <div className="view-content-container">
                <Switch>
                    <Route exact path="/imo/new">
                        <NewModelForm />
                    </Route>
                    <Route path="/imo/models/new">
                        {/* key is needed to force remount on path change */}
                        <Model key="new"/>
                    </Route>
                    <Route path="/imo/models/:id">
                        {/* key is the model id */}
                        <Model key={location.pathname.split('/')[3]}/>
                    </Route>
                    <Redirect to="/imo/new" />
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(IMORoutes);