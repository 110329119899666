export default class Analytics {
    constructor() {
        var Adapter;

        if (IS_MOBILE) {
            Adapter = require('./cordova').default;
        } else {
            Adapter = require('./web').default;
        }

        this.adapter = new Adapter();
    }

    initialize() {
        this.adapter.initialize();
    }

    trackView(name) {
        this.adapter.trackView(name);
    }
}
