import {Redirect, Route, Switch} from 'react-router-dom';

import FiaUploader from 'annuity_lab/tools/admin/components/fia_uploader/fia_uploader.js.jsx';
import LbcUploader from 'annuity_lab/tools/admin/components/lbc_uploader/lbc_uploader.js.jsx';

const Routes = () => {
    return (
        <Route path="/admin">
            <Switch>
                <Route exact path="/admin/fia">
                    <FiaUploader />
                </Route>
                <Route exact path="/admin/lbc">
                    <LbcUploader />
                </Route>
                <Redirect to="/admin/fia" />
            </Switch>
        </Route>
    );
};

export default Routes;

