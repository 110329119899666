import 'annuity_lab/tools/imo/components/client_information/client_information.css.scss';

import PropTypes from 'prop-types';

import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import NumberInput from 'annuity_lab/components/number_input/number_input.js.jsx';
import Select from 'annuity_lab/components/select/select.js.jsx';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';

const ClientInformation = (props) => {
    const {clientInformation, updateClientInformation, disabled} = props;
    const {currentAge, ageAtWithdrawal, jointRider} = clientInformation;
    const erroredFields = props.erroredFields || [];

    const handleChange = (key, value) => {
        updateClientInformation({
            [key]: value
        });
    };

    const getErrorClassFor = (fieldName) => erroredFields.includes(fieldName) ? 'error' : '';

    return (
        <div className="imo-client-information">
            <h3 className="title">Client Information</h3>

            <div className="inputs-container">

                <WithLabel label="Age at Issue">
                    <NumberInput
                        className={getErrorClassFor('currentAge')}
                        placeholder="Enter Age at Issue"
                        min="0"
                        max="1000"
                        value={currentAge}
                        disabled={disabled}
                        onChange={disabled ? _.noop : (nv) => handleChange('currentAge', nv)}/>
                </WithLabel>

                <WithLabel label="Age at First Withdrawal">
                    <NumberInput
                        className={getErrorClassFor('ageAtWithdrawal')}
                        placeholder="Enter Age at First Withdrawal"
                        min="0"
                        max="1000"
                        value={ageAtWithdrawal}
                        disabled={disabled}
                        onChange={disabled ? _.noop : (nv) => handleChange('ageAtWithdrawal', nv)}/>
                </WithLabel>
                <WithLabel label="Joint Life Option elected?">
                    {disabled ? (
                        <input
                            type="text"
                            disabled
                            value={Constants.JOINT_RIDER_OPTIONS.find(o =>
                                o.value === jointRider).label}
                            onChange={_.noop}/>
                    ) : (
                        <Select
                            className={getErrorClassFor('jointRider')}
                            placeholder="Joint Life Option elected?"
                            value={Constants.JOINT_RIDER_OPTIONS.find(o =>
                                o.value === jointRider)}
                            options={Constants.JOINT_RIDER_OPTIONS}
                            onChange={(o) => handleChange('jointRider', o.value)}/>
                    )}

                </WithLabel>
            </div>
        </div>
    );
};

ClientInformation.propTypes = {
    clientInformation: PropTypes.object,
    updateClientInformation: PropTypes.func,
    disabled: PropTypes.bool
};

export default ClientInformation;