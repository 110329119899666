import keyMirror from 'common/services/key_mirror.js';

export default keyMirror([
    'AUTH_COMPLETED',
    'AUTH_ERROR_RESET',

    'LOGOUT',

    'API_STATUS_UPDATED',
], 'NWIDE');
