import {connect} from 'react-redux';

import ModelControls from 'annuity_lab/tools/imo/components/model_controls/model_controls.js.jsx';
import {saveModel, removeModel} from 'annuity_lab/tools/imo/actions/models.js';
import {
    requestComparisonShare,
    cancelComparisonShare,
} from 'annuity_lab/tools/common/actions/share.js';

const mapStateToProps = ({imo, apiStatus, common}) => {
    const {newModel, models} = imo;
    const {share} = common;

    return {
        newModel,
        models,
        offline: !apiStatus.online,
        share
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveModel: (template) => {
        dispatch(saveModel(template));
    },
    removeModel: (modelId) => {
        dispatch(removeModel(modelId));
    },
    requestComparisonShare: () => {
        dispatch(requestComparisonShare());
    },
    cancelComparisonShare: () => {
        dispatch(cancelComparisonShare());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelControls);