import 'annuity_lab/tools/common/containers/rider_cards_controls/rider_cards_controls.css.scss';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {createNewCard, resetCards} from 'annuity_lab/tools/common/actions/rider_cards.js';

import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';
import ScrollFix from 'annuity_lab/tools/lbc/services/scroll_fix';

import CardsUtil from 'common/services/cards_core_util.js';

var CONFIRM_RESET_TITLE = 'Please confirm reset',
    CONFIRM_RESET_MESSAGE = 'This will remove all rider cards and saved comparisons.';

class RiderCardsControls extends React.Component {
    resetCards() {
        ModalDialogs.confirm(CONFIRM_RESET_TITLE, CONFIRM_RESET_MESSAGE, () => {
            ScrollFix.fix();
            this.props.resetCards();
        });
    }

    render() {
        var cardsCount = CardsUtil.countCompletedCards(this.props.riderCards);

        return (
            <div className="rider-cards-controls">
                <div className="controls">
                    <button
                        className="btn btn-block add-button"
                        onClick={() => this.props.createNewCard()}>
                        <span className="plus-icon"></span> {
                            cardsCount > 0
                                ? 'Add Another Rider'
                                : 'Add a Rider'
                        }
                    </button>

                    {cardsCount > 0 &&
                        <button
                            className="btn btn-block reset-button"
                            onClick={() => this.resetCards()}>

                            Reset Rider Cards
                        </button>
                    }
                </div>
            </div>
        );
    }
}

RiderCardsControls.propTypes = {
    riderCards: PropTypes.array,
    createNewCard: PropTypes.func,
    resetCards: PropTypes.func
};

function mapStateToProps({common}) {
    const {riderCards} = common;

    return {
        riderCards: _.values(riderCards),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createNewCard: () => {
            dispatch(createNewCard());
        },
        resetCards: () => {
            dispatch(resetCards());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RiderCardsControls);
