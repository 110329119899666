import PropTypes from 'prop-types';
import 'annuity_lab/tools/lbc/components/small_card_plus/small_card_plus.css.scss';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import NumberFormat from 'common/services/number_format';

export default class SmallCardPlus extends React.Component {
    // TODO Expand
    static propTypes = {
        card: PropTypes.object.isRequired,
        comparison: PropTypes.object.isRequired
    }

    render() {
        const {card, comparison} = this.props;
        const {premium, currentAge, ageAtWithdrawal, jointRiders} = comparison;

        const withdrawalInfo = CardsPlusUtil.calculateWithdrawalInfo(
            card, premium, currentAge, ageAtWithdrawal, jointRiders
        );
        const baseBenefit = CardsPlusUtil.baseBenefit(card, premium);

        const {moneyPerMonthNegative, moneyPerMonthPositive, ageMoneyPositive} = withdrawalInfo;

        const cardName = card.displayName || card.riderName;

        const renderFooter = () => {
            const currencyPerMonthPositive = NumberFormat.currency(moneyPerMonthPositive);
            const currencyPerMonthNegative = NumberFormat.currency(moneyPerMonthNegative);

            if (currencyPerMonthPositive === currencyPerMonthNegative) {
                return (
                    <div className="footer">
                        <p>
                            {NumberFormat.currency(moneyPerMonthNegative)}{' '}
                            mo. from Age{' '}
                            {ageAtWithdrawal.toString()}+
                        </p>
                    </div>
                );
            }

            return (
                <div className="footer">
                    <p>
                        {`
                        ${currencyPerMonthPositive} mo. until Age
                        ${ageMoneyPositive.toString()}
                        (${CardsPlusUtil.getPayoutAbove0(card, ageAtWithdrawal, jointRiders)}%)
                        `}
                    </p>
                    <p>
                        {`
                        *${currencyPerMonthNegative} mo. from Age
                        ${ageMoneyPositive.toString()}+
                        (${CardsPlusUtil.getPayoutEqual0(card, ageAtWithdrawal, jointRiders)}%)
                        `}
                    </p>
                </div>
            );
        };

        return (
            <div className='small-product-card-plus panel'>
                <div className='card'>
                    <div className="header">
                        <span className="product-name">
                            Rider {cardName.toUpperCase()}
                        </span>
                        <span className="product-stats">
                            ({CardsPlusUtil.getRollup(card)})
                        </span>
                    </div>
                    <div className="content">
                        <div className="main">
                            <div className="body">
                                <div className="remark">Guaranteed Income Benefit Base</div>
                                {NumberFormat.currency(baseBenefit)}
                            </div>
                        </div>
                        {renderFooter()}
                    </div>
                </div>
            </div>
        );
    }
}
