import 'annuity_lab/components/disclaimer/disclaimer.css.scss';

const Disclaimer = ({contents, urboNumber: urboProp}) =>  {
    const urbo = urboProp ? urboProp : 'VAM-2988AO (1/18)';

    return (
        <div className="disclaimer">
            {contents ? contents : (
                <>
                    <p>For Financial Professional Use Only - Not For Use With Clients</p>
                    <p>Investing Involves Market Risk, Including the Possible Loss of Principal
                        &nbsp;&nbsp;&nbsp;
                        {urbo}</p>
                </>)}
        </div>
    );
};

export default Disclaimer;