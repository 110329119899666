import 'annuity_lab/styles/modal_dialog';
import 'common/components/dialogs/confirm/confirm.css.scss';

import PropTypes from 'prop-types';

import Modal from 'react-modal';
import {Component} from 'react';

const body = document.querySelector('body');

export default class ConfirmDialog extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object]
        ).isRequired
    }

    render() {
        return (
            <Modal
                isOpen
                {...this.props}
                className="modal-dialog confirm-dialog panel"
                overlayClassName="modal-dialog-overlay"
                appElement={body}>

                <div className="header">
                    <h4>{this.props.title}</h4>

                    <button className="close-button" onClick={this.props.onRequestClose} />
                </div>

                <div className="body">
                    {this.props.content}
                </div>

                <div className="footer">
                    <button className="btn cancel-button" onClick={this.props.onRequestClose}>
                        Cancel
                    </button>

                    <button className="btn confirm-button" onClick={this.props.onConfirm}>
                        Confirm
                    </button>
                </div>

            </Modal>
        );
    }
}
