import COMMON_ACTION_TYPES from 'annuity_lab/tools/common/actions/action_types.js';
import PrintShare from 'annuity_lab/tools/common/services/print_share.js';
import ContactsAPI from 'annuity_lab/tools/lbc/services/data/api/contacts.js';
import Constants from 'common/services/constants';

export function requestComparisonShare() {
    return {
        type: COMMON_ACTION_TYPES.SHARE_COMPARISON_REQUESTED,
        payload: {},
    };
}

export function cancelComparisonShare() {
    return {
        type: COMMON_ACTION_TYPES.SHARE_COMPARISON_CANCELED,
        payload: {},
    };
}

export function clearShare() {
    return {
        type: COMMON_ACTION_TYPES.SHARE_CLEAR,
        payload: {},
    };
}

export function completeComparisonShare({subject, body, agentName, receiversEmails, userId,
    comparisonId, money, period, tool}) {
    return (dispatch) => {
        dispatch({
            type: COMMON_ACTION_TYPES.SHARE_COMPARISON_IN_PROGRESS,
            payload: {},
        });

        // update contacts with newly added email addresses
        const receivers = _.uniq(receiversEmails);
        const contacts = ContactsAPI.find({userId});
        const newContactsMails = _.difference(
            receivers,
            contacts.map(contact => contact.email)
        );

        newContactsMails.forEach(email => {
            const template = {userId, email};

            ContactsAPI.create(template);
        });

        // initiate share process
        return PrintShare.shareComparison({
            subject,
            body,
            agentName,
            receivers,
            userId,
            comparisonId,
            money,
            period,
            tool,
        }).then((data) => {
            const shareFailed = data && data.error;

            return dispatch({
                type: COMMON_ACTION_TYPES.SHARE_COMPARISON_COMPLETED,
                payload: {
                    status: shareFailed
                        ? Constants.COMPARISON_SHARE_STATUS.FAIL
                        : Constants.COMPARISON_SHARE_STATUS.SUCCESS
                },
            });
        });
    };
}

export function completeAdvisorShare({
    userId,
    comparisonId,
    advisorEmail,
    wholesalerEmail,
    firstName,
    lastName,
    phoneNumber,
    zipCode,
    firmName,
    modelCreatedFor,
    tool,
    money,
    period
}) {
    return (dispatch) => {
        dispatch({
            type: COMMON_ACTION_TYPES.SHARE_COMPARISON_IN_PROGRESS,
            payload: {},
        });

        // initiate share process
        return PrintShare.advisorShare({
            userId,
            comparisonId,
            advisorEmail,
            wholesalerEmail,
            firstName,
            lastName,
            phoneNumber,
            zipCode,
            firmName,
            modelCreatedFor,
            tool,
            money,
            period
        }).then((data) => {
            const shareFailed = data && data.error;

            return dispatch({
                type: COMMON_ACTION_TYPES.SHARE_COMPARISON_COMPLETED,
                payload: {
                    status: shareFailed
                        ? Constants.COMPARISON_SHARE_STATUS.FAIL
                        : Constants.COMPARISON_SHARE_STATUS.SUCCESS
                },
            });
        });
    };
}
