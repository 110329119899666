import 'annuity_lab/tools/common/components/rider_cards/rider_cards.css.scss';

import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import scrollIntoView from 'scroll-into-view-if-needed';

import Card from 'annuity_lab/tools/mad/components/card/card.js.jsx';
import ProductContextInputs
    from 'annuity_lab/tools/common/components/product_context_inputs/product_context_inputs.js.jsx';
import Disclaimer from 'annuity_lab/components/disclaimer/disclaimer.js.jsx';
import RiderCardsControls
    from 'annuity_lab/tools/common/containers/rider_cards_controls/rider_cards_controls.js.jsx';

import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import CardsUtil from 'common/services/cards_core_util';
import ScrollFix from 'annuity_lab/tools/lbc/services/scroll_fix';

export default class RiderCards extends React.Component {
    componentDidMount() {
        AnnuityLabAnalytics.trackView('Cards');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentCards = this.props.riderCards;
        const nextCards = nextProps.riderCards;

        if (currentCards.length < nextCards.length) {
            ScrollFix.fix();
        }
    }

    scrollToNewCard() {
        var cards = document.querySelectorAll('.cards-view .card');
        var newCard = cards[cards.length - 1];

        scrollIntoView(newCard, false, {duration: 500});
    }

    componentDidUpdate(prevProps) {
        const previousCards = prevProps.riderCards;
        const currentCards = this.props.riderCards;

        if (previousCards.length > 0 && currentCards.length > previousCards.length) {
            this.scrollToNewCard();
        }
    }

    updateContext(money, deferralPeriod) {
        if (money !== this.props.productContext.money) {
            AnnuityLabAnalytics.trackEditPremiumOnCardsView(money);
        }

        if (deferralPeriod !== this.props.productContext.deferralPeriod) {
            AnnuityLabAnalytics.trackEditDeferralPeriodOnCardsView(deferralPeriod);
        }

        this.props.updateProductContext(money, deferralPeriod);
    }

    renderCards(cards) {
        var context = this.props.productContext;

        return cards.map((card, index) => {
            var isBaseProduct = index === 0;

            return (
                <Card
                    key={card.id}
                    index={index}
                    card={card}
                    baseProduct={isBaseProduct ? null : cards[0]}
                    money={context.money}
                    deferralPeriod={context.deferralPeriod}
                    removable={true}

                    onChange={(updates) => this.props.updateCard(card, updates)}
                    onRemove={() => this.props.removeCard(card)} />
            );
        });
    }

    render() {
        const context = this.props.productContext;
        const cardsCount = CardsUtil.countCompletedCards(this.props.riderCards);

        return (
            <div className="cards-view">
                <h3 className="product-count">Currently ({cardsCount}) Riders</h3>

                <ProductContextInputs
                    minMoney={'1000'}
                    money={context.money}
                    moneyLabel={this.props.moneyLabel}
                    deferralPeriod={context.deferralPeriod}
                    deferralPeriodLabel={this.props.deferralPeriodLabel}
                    onChange={this.updateContext.bind(this)} />

                {cardsCount < 2 &&
                    <div className="hint">
                        <span className="arrow-down-icon"></span>
                        <div className="hint-text">
                            Start comparing riders by
                            creating at least two rider cards.
                        </div>
                    </div>
                }

                <FlipMove
                    duration={500} delay={0}
                    staggerDelayBy={20} staggerDurationBy={15}
                    enterAnimation={false} leaveAnimation={false}>

                    {this.renderCards(this.props.riderCards)}

                </FlipMove>

                <RiderCardsControls/>
                <Disclaimer urboNumber='VAM-2988AO.1 (2/21)' />
            </div>
        );
    }
}

RiderCards.propTypes = {
    riderCards: PropTypes.array,
    productContext: PropTypes.object,
    updateCard: PropTypes.func,
    removeCard: PropTypes.func,
    updateProductContext: PropTypes.func,
};
