import NumberFormat from 'common/services/number_format.js';
import PropTypes from 'prop-types';
export default class IncomeDifferenceDescription extends React.Component {
    static propTypes = {
        difference: PropTypes.number.isRequired
    }

    render() {
        return (
            <div className="income-difference-description">
                <div className="highlighted">
                    {NumberFormat.currency(this.props.difference)} Per Year
                </div> Income Differential
                {this.props.children}
            </div>
        );
    }
}
