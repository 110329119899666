// eslint-disable-next-line max-len
import 'annuity_lab/tools/lbc/components/product_context_plus_input/product_context_plus_input.css.scss';
import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import CurrencyInput from 'annuity_lab/components/currency_input/currency_input.js.jsx';
import PropTypes from 'prop-types';
export default class ProductContextPlusInput extends React.Component {
    static propTypes = {
        money: PropTypes.string.isRequired,
        moneyLabel: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    }

    onChange({money = this.props.money}) {
        this.props.onChange(money);
    }

    render() {
        return (
            <div className="product-context-inputs">
                <WithLabel label={this.props.moneyLabel}>
                    <CurrencyInput
                        value={this.props.money}
                        onChange={(value) => this.onChange({money: value})} />
                </WithLabel>
            </div>
        );
    }
}
