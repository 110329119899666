import 'common/components/comparison_chart/comparison_chart.css.scss';

import Grid from 'annuity_lab/tools/lbc/components/chart/grid/grid.js.jsx';
import Column from 'annuity_lab/tools/lbc/components/chart/column/column.js.jsx';
import CardsUtil from 'common/services/cards_core_util.js';
import NumberFormat from 'common/services/number_format';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';

const INACTIVE_COLUMN_TEXT = 'View Annual Rate of Return';

export default class ComparisonChart extends React.Component {
    static propTypes = {
        cards: PropTypes.array.isRequired,
        money: PropTypes.string.isRequired,
        deferralPeriod: PropTypes.number.isRequired,
        selectedColumn: PropTypes.number.isRequired,
        selectableCards: PropTypes.bool,
        onClickColumn: PropTypes.func
    }

    static defaultProps = {
        selectableCards: true
    }

    render() {
        const cards = this.props.cards;
        const money = this.props.money;
        const deferralPeriod = this.props.deferralPeriod;

        const incomes = _(cards).map((card) =>
            [card.id, CardsUtil.annualIncome(card, money, deferralPeriod)]
        ).fromPairs().value();

        const maxIncome = _(incomes).values().maxBy((income) => income.toNumber()).toString();
        const maxValue = this.maxValue(maxIncome);

        return (
            <div className="comparison-chart">
                <Grid
                    min={0} max={maxValue.toNumber()} stepCount={10}
                    format={(value) => NumberFormat.currency(value)} />

                <div className="columns">
                    {cards.map((card, i) =>
                        this.renderColumn(i, incomes[card.id], maxValue, maxIncome))}
                </div>
            </div>
        );
    }

    renderColumn(index, income, maxValue, maxIncome) {
        const isBest = index === 0;
        const activeValue = isBest ? income : Decimal.sub(maxIncome, income);
        const inactiveValue = isBest ? 0 : income;
        const isSelected = index === this.props.selectedColumn;
        const {selectableCards} = this.props;

        let columnText = '';

        if (selectableCards) {
            columnText = isSelected || index === 0 ? '' : INACTIVE_COLUMN_TEXT;
        }

        return (
            <Column
                key={index}
                activeHeight={this.valueToPercentage(activeValue, maxValue)}
                inactiveHeight={this.valueToPercentage(inactiveValue, maxValue)}
                label={NumberFormat.currency(income)}
                columnText={columnText}
                onClick={() => this.props.onClickColumn(index)} />
        );
    }

    valueToPercentage(value, max) {
        return Decimal.mul(Decimal.div(value, max), 100).toNumber();
    }

    maxValue(maxIncome) {
        const step = 1000;

        return Decimal.mul(Decimal.ceil(Decimal.div(maxIncome, step)), step);
    }
}
