/* eslint-disable no-console */

import appHistory from 'annuity_lab/services/history.js';

export default class WebAnalyticsAdapter {
    constructor() {
        this.currentLocation = undefined;
    }

    initialize() {
        this.currentLocation = appHistory.location.pathname;

        appHistory.listen((state) => {
            this.currentLocation = state.location.pathname;
        });
    }

    setDimension(dimension, value) {
        console.log(`Setting dimension ${dimension} to ${value}`);

        window.ga('set', `dimension${dimension}`, value);
    }

    trackView(name) {
        if (IS_DEBUG) {
            console.log(`Screen view: ${name}, url='${(this.currentLocation)}'`);
        }

        window.ga('send', {
            hitType: 'pageView',
            title: name,
            page: this.currentLocation
        });
    }

    trackEvent(category, action, label) {
        if (IS_DEBUG) {
            console.log(`Event: category='${category}', action='${action}', label='${label}'`);
        }

        window.ga('send', {
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
            eventLabel: label
        });
    }
}