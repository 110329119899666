import keyMirror from 'common/services/key_mirror.js';
import {TOOLS} from 'common/domain/tools';

export default keyMirror([
    'NEW_MODEL_SET',
    'NEW_MODEL_RESET',

    'SYNC_STARTED',
    'SYNC_COMPLETED',

    'MODELS_LOADED',
    'MODEL_SAVED',
    'MODEL_REMOVED',
], TOOLS.INCOME_MODELER.toUpperCase());
