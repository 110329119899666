import {connect} from 'react-redux';

import ComparisonPlusControls
    // eslint-disable-next-line max-len
    from 'annuity_lab/tools/lbc/components/comparison_plus_controls/comparison_plus_controls.js.jsx';
import {
    saveComparison,
    removeComparison,
} from 'annuity_lab/tools/lbc/actions/comparisons_plus.js';
import {
    requestComparisonShare,
    cancelComparisonShare,
} from 'annuity_lab/tools/common/actions/share.js';
import Constants from 'common/services/constants';

function mapStateToProps({lbc, apiStatus, common}) {
    const {
        riderCardsPlus,
        comparisonsPlus,
        newComparisonPlus
    } = lbc;
    const {share} = common;

    return {
        riderCardsPlus,
        comparisonsPlus,
        newComparisonPlus,
        offline: !apiStatus.online,
        hasPendingShare: share.status === Constants.COMPARISON_SHARE_STATUS.PENDING,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveComparison: (template) => {
            dispatch(saveComparison(template));
        },
        removeComparison: (comparison) => {
            dispatch(removeComparison(comparison.id));
        },
        requestComparisonShare: () => {
            dispatch(requestComparisonShare());
        },
        cancelComparisonShare: () => {
            dispatch(cancelComparisonShare());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonPlusControls);
