import Connection from 'common/services/connection.js';
const API_PATH = '/lbc/plus-cards';

class RiderCardsPlusApi {
    loadCards(userId) {
        return Connection.send(API_PATH, {userId});
    }

    createCard(userId, template) {
        const data = _.merge({}, template, {userId});

        return Connection.send(API_PATH, data, 'PUT');
    }

    updateCard(userId, cardId, template) {
        const data = _.merge({}, {template}, {userId});
        const path = `${API_PATH}/${cardId}`;

        return Connection.send(path, data, 'POST');
    }

    removeCard(userId, cardId) {
        const path = `${API_PATH}/${cardId}`;

        return Connection.send(path, {
            userId,
        }, 'DELETE');
    }

    removeAllCards(userId) {
        const path = `${API_PATH}/delete/all`;

        return Connection.send(path, {userId}, 'POST');
    }
}

export default new RiderCardsPlusApi();
