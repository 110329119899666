export default class DeepRouteRedirect {
    constructor(baseLocation) {
        this.lastLocation = null;
        this.baseLocation = baseLocation;

        this.onEnter = this.onEnter.bind(this);
        this.onLeave = this.onLeave.bind(this);
    }

    onEnter(location, replaceRoute) {
        const isBaseLocation = location.pathname === this.baseLocation;

        if (!this.lastLocation || !isBaseLocation) {
            return;
        }

        replaceRoute(
            this.lastLocation.pathname,
            this.lastLocation.state
        );
    }

    onLeave(location) {
        this.lastLocation = location;
    }
}
