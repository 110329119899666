import {connect} from 'react-redux';

import {sync} from 'annuity_lab/tools/common/actions/sync.js';
import {loadRiderCards} from 'annuity_lab/tools/common/actions/rider_cards.js';
import {loadRiderCardsPlus} from 'annuity_lab/tools/lbc/actions/rider_cards_plus.js';
import {loadModels} from 'annuity_lab/tools/imo/actions/models.js';
import Sync from 'annuity_lab/tools/common/components/sync/sync.jsx';

function mapStateToProps({apiStatus, lbc}) {
    return {
        online: apiStatus.online,
        isSyncing: lbc.sync.syncing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sync: () => {
            return dispatch(sync()).then(() => {
                dispatch(loadRiderCards());
                dispatch(loadRiderCardsPlus());

                dispatch(loadModels());
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sync);