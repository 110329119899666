/* eslint-disable react/no-multi-comp */
import 'annuity_lab/tools/common/components/comparison_controls/comparison_controls.css.scss';

import PropTypes from 'prop-types';
import {useState} from 'react';

import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';
import SaveDialog
    from 'annuity_lab/tools/common/components/dialogs/save_modal/save_modal.js.jsx';
import ControlButton
    from 'annuity_lab/tools/common/components/control_button/control_button.js.jsx';
import MessageDialog
    from 'common/components/dialogs/message_dialog/message_dialog.js.jsx';
import OfflineErrorMessage from 'annuity_lab/tools/lbc/services/offline_share_error_messages.js';
import Constants from 'common/services/constants.js';
import ShareComparisonModal from
    'annuity_lab/tools/common/containers/share_comparison_modal/share_comparison_modal.js.jsx';

const BUTTON_LABELS = Constants.MODELS_BUTTON_LABELS;

const ModelControls = (props) => {
    const {
        history,
        location,
        models,
        newModel,
        match,
        saveModel,
        removeModel,
        view,
        requestComparisonShare,
        cancelComparisonShare,
        offline,
        share
    } = props;

    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [hasOfflineShareError, setHasOfflineShareError] = useState(false);

    const openSaveDialog = () => setIsSaveDialogOpen(true);
    const closeSaveDialog = () => setIsSaveDialogOpen(false);

    const hasPendingShare = share.status === Constants.COMPARISON_SHARE_STATUS.PENDING;

    const goToNewModelPage = () => {
        history.push('/imo/new');
    };

    const className = view;

    const isModelEmpty = (model) => Object.values(model.clientInformation).every(v => !v) &&
        model.riderA === null;

    const isSaved = () =>
        !location.pathname.startsWith('/imo/models/new') && isModelEmpty(newModel);

    const remove = () => {
        ModalDialogs.confirm(
            Constants.CONFIRM_TITLE,
            Constants.MODELS_CONFIRM_REMOVE_TEXT,
            () => {
                const {id} = match.params;

                if (id && models[id]) {
                    removeModel(id);
                }
            }
        );
    };

    const save = (name) => {
        saveModel({
            ...newModel,
            name
        });

        closeSaveDialog();
    };

    const onShareClick = () => {
        if (offline) {
            setHasOfflineShareError(true);
        } else {
            requestComparisonShare();
        }
    };

    const removeButton = (
        <ControlButton
            labels={BUTTON_LABELS.REMOVE}
            onClick={remove}
            classNames="remove-button"/>
    );

    const saveButton = (
        <ControlButton
            labels={BUTTON_LABELS.SAVE}
            onClick={openSaveDialog}/>
    );

    const shareButton = (
        <ControlButton
            labels={BUTTON_LABELS.SHARE}
            onClick={onShareClick}/>
    );

    const newButton = (
        <ControlButton
            labels={BUTTON_LABELS.NEW}
            onClick={goToNewModelPage}
            classNames="new-button"/>
    );

    return (
        <div className={`comparison-controls ${className}`}>
            <div className={`controls ${className}`}>
                {
                    isSaved() ? removeButton : saveButton
                }
                {
                    shareButton
                }
                {
                    newButton
                }
            </div>
            {(isSaveDialogOpen || (hasPendingShare  && !isSaved())) &&
                <SaveDialog
                    className={className}
                    onRequestClose={() => {
                        closeSaveDialog();
                        cancelComparisonShare();
                    }}
                    onSave={save}
                    entity="model" />
            }
            {(isSaved() && hasPendingShare) && (
                <ShareComparisonModal
                    tool="imo"
                    className={className}
                    comparisonName={models[match.params.id].name}
                    comparisonId={match.params.id}/>
            )}
            {hasOfflineShareError &&
                <MessageDialog
                    onRequestClose={() => {
                        setHasOfflineShareError(false);
                    }}
                    className={className}
                    title={OfflineErrorMessage.TITLE}
                    content={OfflineErrorMessage.CONTENT}/>
            }

        </div>
    );
};

ModelControls.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    view: PropTypes.string,
    newModel: PropTypes.object,
    models: PropTypes.object,
    saveModel: PropTypes.func,
    removeModel: PropTypes.func,
    offline: PropTypes.bool,
    share: PropTypes.object,
    requestComparisonShare: PropTypes.func,
    cancelComparisonShare: PropTypes.func
};

ModelControls.defaultProps = {
    view: 'mobile',
};

export default ModelControls;