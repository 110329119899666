/* eslint-disable react/no-multi-comp */
import 'annuity_lab/tools/common/components/comparison_controls/comparison_controls.css.scss';

import PropTypes from 'prop-types';
import {useEffect, useState, useCallback} from 'react';

import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';
import SaveComparisonDialog
    from 'annuity_lab/tools/common/components/dialogs/save_modal/save_modal.js.jsx';
import MessageDialog
    from 'common/components/dialogs/message_dialog/message_dialog.js.jsx';
import ControlButton
    from 'annuity_lab/tools/common/components/control_button/control_button.js.jsx';
import OfflineErrorMessage from 'annuity_lab/tools/lbc/services/offline_share_error_messages.js';
import Constants from 'common/services/constants.js';
import ShareComparisonModal from
    'annuity_lab/tools/common/containers/share_comparison_modal/share_comparison_modal.js.jsx';
import {TOOLS} from 'common/domain/tools';

const BUTTON_LABELS = Constants.COMPARISONS_BUTTON_LABELS;

const isComparisonDefined = (comp) => !!comp && !_.isEqual(comp, {});

const ComparisonPlusControls = (props) => {
    const {
        offline,
        view,
        history,
        location,
        match,
        newComparisonPlus,
        comparisonsPlus,
        riderCardsPlus,
        hasPendingShare,
        saveComparison,
        cancelComparisonShare,
        requestComparisonShare,
        removeComparison,
    } = props;

    const [hasOfflineShareError, setHasOfflineShareError] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [comparison, setComparison] = useState(null);

    const isSaved = () => !!comparison.id;
    const openSaveDialog = () => setSaveDialogOpen(true);
    const closeSaveDialog = () => setSaveDialogOpen(false);
    const onOfflineShareMessageClosed = () => setHasOfflineShareError(false);

    const loadComparison = useCallback(() => {
        const routerParams = match.params;

        let comparison;

        if (routerParams.id) {
            comparison = comparisonsPlus[routerParams.id];
        } else {
            comparison = newComparisonPlus;
        }

        if (isComparisonDefined(comparison)) {
            setComparison({
                ...comparison,
                cards: comparison.cardIds.map(id => riderCardsPlus[id]),
            });
        }
    }, [match.params.id, newComparisonPlus, comparisonsPlus, riderCardsPlus]);

    const segments = location.pathname.split('/');
    const showSaveShare = segments[segments.length - 1] === 'standard';

    useEffect(() => {
        loadComparison();
    }, [loadComparison]);

    const save = (name) => {
        const savedComparison = _.clone(comparison);

        savedComparison.name = name;
        closeSaveDialog();

        saveComparison(savedComparison);
    };

    const remove = () => {
        ModalDialogs.confirm(
            Constants.COMPARISONS_CONFIRM_TITLE,
            Constants.COMPARISONS_CONFIRM_REMOVE_TEXT, () => {
                removeComparison(comparison);
            }
        );
    };

    const newComparison = () => {
        AnnuityLabAnalytics.trackStartNewPlusComparison();
        history.push('/lbc/comparisons/plus');
    };

    const onShareClick = () => {
        if (offline) {
            setHasOfflineShareError(true);

            return;
        }

        requestComparisonShare();
    };

    if (!isComparisonDefined(comparison)) {
        return null;
    }

    const className = view;

    const removeButton = (
        <ControlButton
            labels={BUTTON_LABELS.REMOVE}
            onClick={remove}
            classNames={'remove-button'} />);

    const saveButton = (
        <ControlButton
            labels={BUTTON_LABELS.SAVE}
            onClick={openSaveDialog}/>
    );

    const shareButton = (
        <ControlButton
            labels={BUTTON_LABELS.SHARE}
            onClick={onShareClick} />
    );

    return (
        <div className={`comparison-controls ${className}`}>
            <div className={`controls ${className}`}>
                { showSaveShare && (isSaved() ? removeButton : saveButton) }
                { showSaveShare && shareButton }
                <ControlButton
                    labels={BUTTON_LABELS.NEW}
                    onClick={newComparison}
                    classNames="new-button" />
            </div>
            {(saveDialogOpen || (hasPendingShare && !isSaved())) &&
                <SaveComparisonDialog
                    className={className}
                    onRequestClose={() => {
                        closeSaveDialog();
                        cancelComparisonShare();
                    }}
                    onSave={(name) => save(name)}
                    entity="comparison" />
            }
            {(isSaved() && hasPendingShare) && (
                <ShareComparisonModal
                    tool={TOOLS.LIVING_BENEFIT_RIDERS_PLUS}
                    className={className}
                    comparisonName={comparison.name}
                    comparisonId={comparison.id}/>
            )}
            { hasOfflineShareError &&
                <MessageDialog
                    onRequestClose={() => {
                        onOfflineShareMessageClosed();
                    }}
                    className={className}
                    title={OfflineErrorMessage.TITLE}
                    content={OfflineErrorMessage.CONTENT}/>
            }
        </div>
    );
};

ComparisonPlusControls.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    riderCardsPlus: PropTypes.object,
    comparisonsPlus: PropTypes.object,
    view: PropTypes.string,
    offline: PropTypes.bool
};

ComparisonPlusControls.defaultProps = {
    view: 'mobile',
};

export default ComparisonPlusControls;
