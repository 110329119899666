import 'annuity_lab/tools/common/components/no_cards/no_cards.css.scss';
import PropTypes from 'prop-types';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';

export default class NoCards extends React.Component {
    componentDidMount() {
        const {onPlusRidersView} = this.props;

        if (onPlusRidersView) {
            AnnuityLabAnalytics.trackView('New plus comparison > No riders');
        } else {
            AnnuityLabAnalytics.trackView('New comparison > No riders');
        }
    }

    render() {
        return (
            <div className="no-products-view">
                <div className="icon-container">
                    <div className="icon-up" />
                    <div />
                </div>
                <div className="content">
                    <h1>
                        You currently have no riders to compare.
                        {' '}
                        Return to the <em>Create Rider Cards</em> tab and populate a
                        {' '}
                        minimum of two cards with the rider details of your choice.
                    </h1>
                </div>
                <button
                    className="btn btn-block btn-compare disabled">
                    {this.props.compareButtonText}
                </button>
            </div>
        );
    }
}

NoCards.propTypes = {
    compareButtonText: PropTypes.string,
    onPlusRidersView: PropTypes.bool.isRequired
};
