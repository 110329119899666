import LocalComparisonsPlusApi from 'annuity_lab/tools/lbc/services/data/api/comparisons_plus.js';
import NwideComparisonsPlusApi from 'common/services/lbc/nwide/comparisons_plus.js';
import DataChangeRegistry from 'annuity_lab/services/data_change_registry.js';
import MigrationConsts from 'annuity_lab/tools/lbc/services/migration_consts.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';

function loadComparisons() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        let comparisonsPlus = LocalComparisonsPlusApi.find({userId});

        dispatch({
            type: ACTION_TYPES.COMPARISONS_PLUS_LOADED,
            payload: {comparisonsPlus},
        });
    };
}

function saveComparison(template) {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        template.userId = userId;
        const comparisonPlus = LocalComparisonsPlusApi.create(template);

        AnnuityLabAnalytics.trackSavePlusComparison(comparisonPlus);

        dispatch({
            type: ACTION_TYPES.COMPARISON_PLUS_SAVED,
            payload: {comparisonPlus},
            meta: {
                sync: {
                    online: () => {
                        return NwideComparisonsPlusApi.createComparison(userId, comparisonPlus);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.COMPARISONS_PLUS,
                            entity: comparisonPlus,
                            operation: MigrationConsts.operations.CREATE,
                            userId,
                        });
                    },
                },
                transition: (prevState, nextState, action) => {
                    return {
                        pathname:
                            `/lbc/comparisons/plus/${action.payload.comparisonPlus.id}/standard`,
                    };
                },
            },
        });
    };
}

function removeComparison(comparisonPlusId) {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const removed = LocalComparisonsPlusApi.remove({id: comparisonPlusId})[0];

        AnnuityLabAnalytics.trackRemovePlusComparison(removed);

        dispatch({
            type: ACTION_TYPES.COMPARISON_PLUS_REMOVED,
            payload: {comparisonPlusId},
            meta: {
                sync: {
                    online: () => {
                        return NwideComparisonsPlusApi.removeComparison(userId, comparisonPlusId);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.COMPARISONS_PLUS,
                            entity: removed,
                            operation: MigrationConsts.operations.DELETE,
                            userId,
                        });
                    },
                },
                transition: () => ({pathname: '/lbc/comparisons/plus'}),
            }
        });
    };
}

export {
    loadComparisons,
    saveComparison,
    removeComparison
};