export default class FooterJumpFix {
    static _getFooter() {
        return document.querySelector('.phone-footer');
    }

    static hide(e) {
        if (e.target.tagName === 'INPUT') {
            this._getFooter().classList.add('hidden');
        }
    }

    static show(e) {
        if (e.target.tagName === 'INPUT') {
            this._getFooter().classList.remove('hidden');
        }
    }
}
