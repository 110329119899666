import LocalStorage from 'annuity_lab/services/data/base/local_storage.js';
/**
 * Base class providing persistence operations for accessing
 * and modifying a single object in the underying local storage.
 */
export default class SingletonStore {
    constructor(dataType) {
        this._dataType = dataType;
    }

    get() {
        return LocalStorage.get(this._dataType);
    }

    set(newObject) {
        LocalStorage.set(this._dataType, newObject || null);
    }
}
