import {combineReducers} from 'redux';
import products from 'annuity_lab/tools/fia/reducers/products.js';
import period from 'annuity_lab/tools/fia/reducers/period.js';
import comparisons from 'annuity_lab/tools/fia/reducers/comparisons.js';

const reducer = combineReducers({
    products,
    period,
    comparisons,
});

export default reducer;
