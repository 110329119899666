import SingletonStore from 'annuity_lab/services/data/base/singleton_store.js';

const PERSISTENCE_KEY = 'current_user';

class CurrentUserAPI extends SingletonStore {
    constructor() {
        super(PERSISTENCE_KEY);
    }
}

export default new CurrentUserAPI();
