import 'annuity_lab/tools/lbc/components/carry_forward_charts/carry_forward_charts.css.scss';

import PropTypes from 'prop-types';

// eslint-disable-next-line max-len
import OptionButton from 'annuity_lab/tools/lbc/components/option_button/option_button.js.jsx';
import MoreTimeChart from 'annuity_lab/tools/lbc/components/more_time_chart/more_time_chart.js.jsx';
import MoreIncomeChart from
    'annuity_lab/tools/lbc/components/more_income_chart/more_income_chart.js.jsx';
import MoreFlexibilityChart from
    'annuity_lab/tools/lbc/components/more_flexibility_chart/more_flexibility_chart.js.jsx';

import NumberFormat from 'common/services/number_format.js';

const CarryForwardCharts = (props) => {
    const {withdrawal, options, selectedOption, onCarryForwardChange} = props;

    return (
        <div className="carry-forward-charts">
            <div className="buttons-and-charts">
                {options.map(o => (
                    <OptionButton
                        key={o.id}
                        disabled={o.id === 0}
                        className={o.className}
                        title={o.title}
                        topText={o.topText}
                        mainText={NumberFormat.currency(o.money)}
                        bottomText={o.bottomText}
                        description={o.description}
                        color={o.color}
                        onClick={() => onCarryForwardChange(o)}
                        selected={o === selectedOption}/>
                ))}

                {selectedOption.id === 1 && (
                    <MoreTimeChart
                        key={withdrawal}
                        withdrawal={withdrawal}/>
                )}
                {selectedOption.id === 2 && (
                    <MoreIncomeChart
                        key={withdrawal}
                        withdrawal={withdrawal}/>
                )}
                {selectedOption.id === 3 && (
                    <MoreFlexibilityChart
                        key={withdrawal}
                        withdrawal={withdrawal}/>
                )}
            </div>
        </div>
    );
};

CarryForwardCharts.propTypes = {
    withdrawal: PropTypes.number.isRequired,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.object,
    onCarryForwardChange: PropTypes.func
};

export default CarryForwardCharts;
