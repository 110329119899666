import MigrationsAPI from 'annuity_lab/services/data/api/migrations.js';

const DSCRIPTOR_FIELDS = [
    'entityType',
    'entity',
    'operation',
    'userId',
];

class DataChangeRegistry {
    /**
    * Adds new change descriptor in the registry.
    *
    * @param {String} descriptor.entityType
    *
    * @param {Object} desciptor.entity
    *
    * @param {String} desciptor.operation
    *
    * @param {Number} desciptor.userId
    *
    */
    add(descriptor) {
        const template = _.pick(descriptor, DSCRIPTOR_FIELDS);
        const migration = MigrationsAPI.create(template);

        return migration;
    }

    find(query) {
        return MigrationsAPI.find(query);
    }
}

export default new DataChangeRegistry();
