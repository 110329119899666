import Connection from 'common/services/connection.js';

const API_PATH = '/print';

class PrintShare {
    shareComparison({subject, body, agentName, receivers, comparisonId, userId,
        money, period, tool}) {
        const data = {
            tool,
            subject,
            body,
            agentName,
            receivers,
            comparisonId,
            userId,
            money,
            period,
        };

        return Connection.send(API_PATH, data, 'POST');
    }

    advisorShare(data) {
        return Connection.send(`${API_PATH}/advisor`, data, 'POST');
    }
}

export default new PrintShare();
