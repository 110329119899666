4;/* eslint-disable max-len */
/* eslint-disable no-inline-comments */
export const X1 = {
    '4.5': [
        64, 65, 66, 67,
        68, 68, // duplicate year
        69, 70, 71, 72,
        73, 73, // duplicate year
        74, 75, 76, 77,
        78.04, 78.04, // duplicate year
        79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ],
    '5': [
        64, 65, 66, 67, 68, 69,
        70, 70, // duplicate year
        71, 72, 73, 74,
        75, 75, // duplicate year
        76, 77,
        78.04, 78.04, // duplicate year
        79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ]
};

export const X2 = {
    '4.5': [
        64, 65, 66, 67,
        68, 68, // duplicate year
        69, 70, 71, 72,
        73, 73, // duplicate year
        74, 75, 76, 77,
        77.96, 77.96, // duplicate year
        79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ],
    '5': [
        64, 65, 66, 67, 68, 69,
        70, 70, // duplicate year
        71, 72, 73, 74,
        75, 75, // duplicate year
        76, 77,
        77.96, 77.96, // duplicate year
        79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96
    ]
};

export const EXTENDED_INCOME_DATA = {
    '4.5': [
        5625, 5625, 5625, 5625,
        5625, 9000, // data for duplicate year
        9000, 9000, 9000, 9000,
        9000, 7500, // data for duplicate year
        7500, 7500, 7500, 7500,
        7500, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000
    ],
    '5': [
        6250, 6250, 6250, 6250, 6250, 6250,
        6250, 9000, // data for duplicate year
        9000, 9000, 9000, 9000,
        9000, 7500, // data for duplicate year
        7500, 7500,
        7500, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000
    ]
};

export const CARRY_FORWARD_DATA = {
    '4.5': [
        7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 7500, 7500, 7500,
        7500, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000
    ],
    '5': [
        7500, 7500, 7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 7500, 7500, 7500,
        7500, 7500, // data for duplicate year
        7500, 7500,
        7500, 5000, // data for duplicate year
        5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000
    ]
};

