import ACTIONS from 'annuity_lab/actions/action_types.js';
import HealthCheckService from 'annuity_lab/services/nwide_api/healthcheck.js';

export function updateStatus() {
    return dispatch => {
        return HealthCheckService
            .check()
            .then(online => {
                dispatch({
                    type: ACTIONS.API_STATUS_UPDATED,
                    payload: {online},
                });
            });
    };
}
