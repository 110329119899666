/* eslint-disable max-len */
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import WholesalerShareModal from
    'annuity_lab/tools/common/containers/wholesaler_share_comparison_modal/wholesaler_share_comparison_modal.js.jsx';
// import AdvisorShareModal from
//     'annuity_lab/tools/common/containers/advisor_share_comparison_modal/advisor_share_comparison_modal.js.jsx';
// import {ROLES} from 'common/domain/users';

const ShareComparisonModal = (props) => {
    const {
        // From redux
        // user,

        // Direct props
        tool,
        className,
        comparisonName,
        comparisonId,
        additionalShareData = {}
    } = props;

    // if (user.role === ROLES.ADVISOR) {
    //     return (
    //         <AdvisorShareModal
    //             tool={tool}
    //             comparisonId={comparisonId}
    //             additionalShareData={additionalShareData}/>
    //     );
    // }

    return (
        <WholesalerShareModal
            tool={tool}
            className={className}
            comparisonName={comparisonName}
            comparisonId={comparisonId}
            additionalShareData={additionalShareData}/>
    );
};

ShareComparisonModal.propTypes = {
    user: PropTypes.object.isRequired,
    tool: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    comparisonName: PropTypes.string.isRequired,
    comparisonId: PropTypes.string.isRequired,
    additionalShareData: PropTypes.object
};

const mapStateToProps = ({auth}) => {
    return {
        user: auth.user,
    };
};

export default connect(mapStateToProps)(ShareComparisonModal);