import 'annuity_lab/tools/lbc/components/plus_carry_forward_view/plus_carry_forward_view.css.scss';

import PropTypes from 'prop-types';

import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import Select from 'annuity_lab/components/select/select.js.jsx';
import CarryForwardCharts from
    'annuity_lab/tools/lbc/components/carry_forward_charts/carry_forward_charts.js.jsx';

const CarryForwardView = (props) => {
    const {
        withdrawalOptions,
        carryForwardOptions,
        withdrawal,
        carryForwardOption,
        onWithdrawalChange,
        onCarryForwardChange
    } = props;

    return (
        <div className="plus-carry-forward-view">
            <div className="input-message-container">
                <WithLabel label="SELECT WITHDRAWAL %">
                    <Select
                        value={withdrawal}
                        options={withdrawalOptions}
                        onChange={onWithdrawalChange}/>
                </WithLabel>
                <p className="message">
                    The example shows a client that purchased a $100,000 policy at age 59
                    with a simple roll of up 5% during the 5 year period to receiving income.
                    They began taking income from their guaranteed living withdrawal benefit (GLWB)
                    rider at 64. The rider that they purchased had a 6% distribution rate when the
                    cash value was greater than 0 and a 4% distribution rate when the cash value
                    was equal to 0. Without a Carry Forward feature and assuming a 0% rate of
                    return, the client would receive $7,500 a year for 13 years until their
                    contract value reduced to $0 and then income would reduce to $5,000 a year
                    for the rest of their life.
                </p>
            </div>

            <div className="charts-container">
                <CarryForwardCharts
                    options={carryForwardOptions}
                    selectedOption={carryForwardOption}
                    withdrawal={withdrawal.value}
                    onCarryForwardChange={onCarryForwardChange}/>
            </div>
        </div>
    );
};

CarryForwardView.propTypes = {
    withdrawalOptions: PropTypes.array.isRequired,
    carryForwardOptions: PropTypes.array.isRequired,
    withdrawal: PropTypes.object.isRequired,
    carryForwardOption: PropTypes.object.isRequired,
    onWithdrawalChange: PropTypes.func,
    onCarryForwardChange: PropTypes.func
};

export default CarryForwardView;