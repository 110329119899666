/* eslint-disable no-inline-comments */

export const getLegend = () => {
    return {
        show: false
    };
};

export const getPoint = () => {
    return {
        show: false
    };
};

export const getTooltip = () => {
    return {
        show: false
    };
};

export const getGrid = () => {
    return {
        y: {
            show: false
        }
    };
};

export const getSize = (plotHeight) => {
    return {
        height: plotHeight
    };
};

export const positionInfoBalloon = (id, chartContainerClassName) => {
    const chartContainer = document.querySelector(`#${id}.${chartContainerClassName}`);
    const yDomainLine = document.querySelector(
        `#${id}.${chartContainerClassName} .c3-axis-y .domain`
    );
    const balloon = document.querySelector(
        `#${id}.${chartContainerClassName} .chart-start-balloon`
    );

    if (!chartContainer || !yDomainLine || !balloon) {
        return;
    }

    const axisBoundingRect = yDomainLine.getBoundingClientRect();
    const chartContainerBoundingRect = chartContainer.getBoundingClientRect();
    const balloonBoundingRect = balloon.getBoundingClientRect();

    const differenceTop = axisBoundingRect.top - chartContainerBoundingRect.top;
    const differenceLeft = axisBoundingRect.right - chartContainerBoundingRect.left;

    balloon.style.top = `${differenceTop - balloonBoundingRect.height - 10}px`;
    balloon.style.left = `${differenceLeft - balloonBoundingRect.width / 2}px`;
};

export const drawGridLines = (id) => {
    const container = document.querySelector(`#${id} .c3 > svg > g`);
    const chart = document.querySelector(`#${id} .c3-chart`);

    if (!container || !chart) {
        return;
    }
    const boundingRect = chart.getBoundingClientRect();

    const axisContainer = document.querySelector(`#${id} .c3-axis.c3-axis-y`);

    // Needed because in the svg the ticks are drawn after the chart.
    // This is a problem because than the ticks overlap the chart.
    // The line below duplicates the chart after the ticks are drawn.
    container.appendChild(chart);

    // Needed because otherwise c3 clips the ticks.
    axisContainer.removeAttribute('clip-path');

    const ticks = document.querySelectorAll(`#${id} .c3-axis.c3-axis-y .tick line`);
    const ticksArray = Array.from(ticks);

    // Make the ticks longer. They are effectively used both as ticks and y grid lines
    // This is needed because phantomJS didn't want to print the grid lines for
    // some reason.
    ticksArray.forEach(tick => {
        tick.setAttribute('x1', boundingRect.width);
    });
};

export const positionRectangle = (containerEl, rectEl, topLeftEl, bottomRightEl, offsets = {}) => {
    const offset = _.defaults(offsets, {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    });

    if (!containerEl || !rectEl || !topLeftEl || !bottomRightEl) {
        return;
    }

    const containerSizes = containerEl.getBoundingClientRect();
    const rectTopLeftSizes = topLeftEl.getBoundingClientRect();
    const rectBottomRightSizes = bottomRightEl.getBoundingClientRect();

    const rectWidth = rectBottomRightSizes.left - rectTopLeftSizes.left - 1 + offset.width;
    const rectHeight = rectBottomRightSizes.top - rectTopLeftSizes.top - 3 + offset.height;
    const rectTop = rectTopLeftSizes.top - containerSizes.top + 4 + offset.top;
    const rectLeft = rectTopLeftSizes.left - containerSizes.left + 2 + offset.left;

    rectEl.setAttribute('style', `
        width: ${rectWidth}px;
        height: ${rectHeight}px;
        top: ${rectTop}px;
        left: ${rectLeft}px;
    `);
};

export const adjustOverlapping = (riderAValues, riderBValues, options = {}) => {
    const Y_AXIS_TOP_PADDING_RATIO = options.Y_AXIS_TOP_PADDING_RATIO ?? 0.1;
    const PLOT_DRAW_HEIGHT = options.PLOT_DRAW_HEIGHT ?? 226;
    const LINE_WIDTH_IN_PX = options.LINE_WIDTH_IN_PX ?? 4;

    if (riderBValues.length === 0) {
        return {
            riderAData: riderAValues,
            riderBData: riderBValues
        };
    }

    const maxLineValue = Math.max(...riderAValues, ...riderBValues);
    const plotHeightValue = Math.round(maxLineValue + maxLineValue * Y_AXIS_TOP_PADDING_RATIO);
    const onePixelToRangeValue = plotHeightValue / PLOT_DRAW_HEIGHT;
    const lineWidthInRange = LINE_WIDTH_IN_PX * onePixelToRangeValue;

    const riderAData = riderAValues.map((value) => {
        const difference = Number(riderBValues.reduce((acc, curr) => {
            const currentDiff = Number(value) - Number(curr);
            const currentAbsoluteDiff = Math.abs(currentDiff);

            if (currentAbsoluteDiff < Math.abs(acc)) {
                return currentDiff;
            }

            return acc;
        }, Infinity).toFixed(0));

        if (difference >= 0 && difference < lineWidthInRange) {
            return Number(value) + (lineWidthInRange - difference);
        }
        if (difference < 0 && -difference < lineWidthInRange) {
            return Number(value) - (lineWidthInRange + difference);
        }

        return Number(value);
    });

    return {
        riderAData,
        riderBData: riderBValues
    };
};