/* eslint-disable quotes */
import 'annuity_lab/tools/lbc/components/menu/menu.css.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';
import {isPhone} from 'common/services/screen_util.js';
import MenuSelect from 'annuity_lab/tools/common/components/menu_select/menu_select.js.jsx';

export default class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.rootUrl = props.root;
        this.cardsUrl = props.root + '/cards';
        this.cardsPlusUrl = this.cardsUrl + '/plus';
        this.comparisonsUrl = props.root + '/comparisons';
        this.comparisonsPlusUrl = this.comparisonsUrl + '/plus';

        this.getNonRootActiveClassName = this.getNonRootActiveClassName.bind(this);
        this.getRelevantCardsUrl = this.getRelevantCardsUrl.bind(this);
        this.getRelevantComparisonsUrl = this.getRelevantComparisonsUrl.bind(this);
        this.onRidersTabClick = this.onRidersTabClick.bind(this);
        this.openComparison = this.openComparison.bind(this);
    }

    getRelevantComparisonsUrl() {
        const {riderType} = this.props;

        let relevantComparisonsUrl = this.comparisonsUrl;

        if (riderType === Constants.RIDER_TYPE_PLUS) {
            relevantComparisonsUrl = this.comparisonsPlusUrl;
        }

        if (riderType === null) {
            relevantComparisonsUrl = this.rootUrl;
        }

        return relevantComparisonsUrl;
    }

    getRelevantCardsUrl() {
        const {riderType} = this.props;

        let relevantCardsUrl = this.cardsUrl;

        if (riderType === Constants.RIDER_TYPE_PLUS) {
            relevantCardsUrl = this.cardsPlusUrl;
        }

        if (riderType === null) {
            relevantCardsUrl = this.rootUrl;
        }

        return relevantCardsUrl;
    }

    getNonRootActiveClassName() {
        const {riderType} = this.props;

        if (riderType === null) {
            return null;
        }

        return 'active';
    }

    getCompareRidersTabLabel() {
        return isPhone() ? 'Compare' : 'Compare Rider Cards';
    }

    onRidersTabClick() {
        const {riderType, updateShowSelectRiderError} = this.props;

        if (riderType === null) {
            updateShowSelectRiderError(true);
        }
    }

    openComparison(id) {
        let comparisonsUrl = this.getRelevantComparisonsUrl();
        const {history, riderType} = this.props;

        if (id === null) {
            history.push(comparisonsUrl);
        } else if (riderType === Constants.RIDER_TYPE_PLUS) {
            history.push(`${comparisonsUrl}/${id}/standard`);
        } else {
            history.push(`${comparisonsUrl}/${id}`);
        }
    }

    renderComparisonButton() {
        const {comparisons, comparisonsPlus, location} = this.props;

        const showComparisonDropdown = location.pathname.startsWith(this.comparisonsUrl)
            && comparisons.length > 0 && !location.pathname.startsWith(this.comparisonsPlusUrl);
        const showComparisonPlusDropdown = location.pathname.startsWith(this.comparisonsPlusUrl)
            && comparisonsPlus.length > 0;

        if (showComparisonDropdown) {
            return this.renderComparisonSelect(comparisons);
        }

        if (showComparisonPlusDropdown) {
            return this.renderComparisonSelect(comparisonsPlus);
        }

        return this.renderNewComparisonButton();
    }

    renderNewComparisonButton() {
        const nonRootActiveClassName = this.getNonRootActiveClassName();
        const comparisonsUrl = this.getRelevantComparisonsUrl();

        return (
            <NavLink
                to={comparisonsUrl}
                onClick={this.onRidersTabClick}
                activeClassName={nonRootActiveClassName}>
                {this.getCompareRidersTabLabel()}
            </NavLink>
        );
    }

    renderComparisonSelect(comparisons) {
        const options = _.map(comparisons, (comparison) => {
            return {value: comparison.id, label: comparison.name};
        });

        options.push({value: null, label: 'Start a New Comparison'});

        const openComparison = (option) => this.openComparison(option.value);

        return (
            <MenuSelect
                options={options}
                compareRidersTabLabel={this.getCompareRidersTabLabel()}
                openComparison={openComparison}/>
        );
    }

    render() {
        const cardsUrl = this.getRelevantCardsUrl();
        const nonRootActiveClassName = this.getNonRootActiveClassName();

        return (
            <div className="menu lbc-menu">
                <NavLink exact to={this.rootUrl} activeClassName="active">
                    {isPhone() ? 'Select' : 'Select a Rider'}
                </NavLink>
                <NavLink
                    exact
                    to={cardsUrl}
                    onClick={this.onRidersTabClick}
                    activeClassName={nonRootActiveClassName}>
                    {isPhone() ? 'Create' : 'Create Rider Cards'}
                </NavLink>

                {this.renderComparisonButton()}
            </div>
        );
    }
}

Menu.propTypes = {
    riderType: PropTypes.string,
    comparisons: PropTypes.array.isRequired,
    comparisonsPlus: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    root: PropTypes.string.isRequired,
    updateShowSelectRiderError: PropTypes.func.isRequired
};
