import 'annuity_lab/components/with_label/with_label.css.scss';

export default class WithLabel extends React.Component {
    render() {
        return (
            <div className="with-label">
                <label>{this.props.label}</label>
                {this.props.children}
            </div>
        );
    }
}
