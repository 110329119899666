import 'annuity_lab/tools/imo/components/standard_view/standard_view.css.scss';

import PropTypes from 'prop-types';

import StandardViewRiderCard from
    'annuity_lab/tools/imo/components/standard_view_rider_card/standard_view_rider_card.js.jsx';
import StandardViewTable from
    'annuity_lab/tools/imo/components/standard_view_table/standard_view_table.js.jsx';
import StandardViewChart from
    'annuity_lab/tools/imo/components/standard_view_chart/standard_view_chart.js.jsx';
import Calculator from 'annuity_lab/tools/imo/services/calculator.js';
import {useMemo} from 'react';

const StandardView = ({model}) => {
    const {riderA, riderB, clientInformation} = model;
    const {ageAtWithdrawal} = clientInformation;

    const tableValuesA = useMemo(() => {
        return Calculator.calculateStandardTableValues(riderA, clientInformation);
    }, [model]);

    let tableValuesB = useMemo(() => {
        if (riderB) {
            return Calculator.calculateStandardTableValues(riderB, clientInformation);
        }

        return null;
    }, [model]);

    return (
        <div className="imo-standard-view">
            <div className="cards-summary">
                <StandardViewRiderCard
                    rider={riderA}
                    clientInformation={clientInformation}
                    tableValues={tableValuesA}
                    isPrimary />
                {riderB && (
                    <StandardViewRiderCard
                        rider={riderB}
                        clientInformation={clientInformation}
                        tableValues={riderB ? tableValuesB : null} />
                )}
            </div>

            <StandardViewTable
                ageAtWithdrawal={ageAtWithdrawal}
                tableValuesA={tableValuesA}
                tableValuesB={riderB ? tableValuesB : null} />

            <p className='info-text'>Annual Income amount is calculated using the higher value
                between Guaranteed Roll-up and Contract Value.</p>

            <StandardViewChart valuesA={tableValuesA} valuesB={tableValuesB} />
        </div>
    );
};

StandardView.propTypes = {
    model: PropTypes.object
};

export default StandardView;