import Cleave from 'babel-loader!cleave.js';
import PropTypes from 'prop-types';

export default class NumberInput extends React.Component {
    constructor(props) {
        super(props);

        this.currentValue = this.clampValue(props.value);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.currentValue !== nextProps.value) {
            this.updateValue(nextProps.value);
        }
    }

    componentDidMount() {
        var cleaveOptions = _.defaults({
                numeralDecimalScale: this.props.precision,
                prefix: this.props.prefix
            }, {
                numeral: true,
                numeralDecimalScale: 2
            }),
            input = ReactDOM.findDOMNode(this.refs.input);

        this.cleave = new Cleave(input, cleaveOptions);

        input.addEventListener('input', () => {
            this.onChange();
        }, false);
    }

    componentWillUnmount() {
        this.cleave.destroy();
    }

    render() {
        var props = _.omit(this.props, 'value', 'onChange', 'precision', 'prefix');

        return (
            <input
                type="text"
                ref="input"
                defaultValue={this.currentValue}
                {...props}
                pattern="^[0-9]+(\.[0-9]{0,2})?$"
                inputMode="verbatim"
                noValidate={true}
                onBlur={() => this.onBlur()} />
        );
    }

    updateValue(value) {
        this.currentValue = value;

        if (!value) {
            this.cleave.setRawValue('');

            return;
        }

        this.cleave.setRawValue(value);
    }

    clampValue(value) {
        if (!value) {
            return;
        }

        return Decimal.min(Decimal.max(value, this.props.min), this.props.max).toString();
    }

    getRawValue() {
        var value = this.cleave.getRawValue();

        if (value) {
            value = value.replace(this.props.prefix, '');
        }

        if (_.isNil(value)) {
            return;
        }

        if (value === '') {
            return '0';
        }

        return new Decimal(value).toString();
    }

    onChange() {
        var value = this.getRawValue();

        this.currentValue = value;
        this.props.onChange(value);
    }

    onBlur() {
        var value = this.getRawValue(),
            clampedValue = this.clampValue(value);

        if (value !== clampedValue) {
            this.updateValue(clampedValue);
            this.props.onChange(clampedValue);
        }
    }
}

NumberInput.propTypes = {
    min: PropTypes.string.isRequired,
    max: PropTypes.string.isRequired,
    value: PropTypes.string
};
