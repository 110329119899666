import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';
import LocalCardsPlusApi from 'annuity_lab/tools/lbc/services/data/api/rider_cards_plus.js';
import NwideCardsPlusApi from 'annuity_lab/tools/lbc/services/nwide/rider_cards_plus.js';
import ComparisonsPlusApi from 'annuity_lab/tools/lbc/services/data/api/comparisons_plus.js';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import DataChangeRegistry from 'annuity_lab/services/data_change_registry.js';
import MigrationConsts from 'annuity_lab/tools/lbc/services/migration_consts.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import moment from 'moment';
import Promise from 'bluebird';

function createInitialCards(userId) {
    const cards = [];
    const timestamp = moment.utc().valueOf();

    cards.push(LocalCardsPlusApi.create({
        userId,
        riderName: CardsPlusUtil.generateRiderName(),
        createdAt: timestamp,
        withdrawalValues: CardsPlusUtil.generateEmptyWithdrawalValues()
    }));

    cards.push(LocalCardsPlusApi.create({
        userId,
        riderName: CardsPlusUtil.generateRiderName(cards),
        createdAt: timestamp + 1,
        withdrawalValues: CardsPlusUtil.generateEmptyWithdrawalValues()
    }));

    return cards;
}

export function loadRiderCardsPlus() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        let shouldCreateCards = false;

        let cards = LocalCardsPlusApi.find({userId});

        if (!cards || !cards.length) {
            shouldCreateCards = true;
            cards = createInitialCards(userId);
        }

        dispatch({
            type: ACTION_TYPES.CARDS_PLUS_LOADED,
            payload: {cards},
            meta: {
                sync: {
                    online: () => {
                        let creating = Promise.resolve();

                        if (shouldCreateCards) {
                            creating = Promise.map(cards, card => {
                                NwideCardsPlusApi.createCard(userId, card);
                            });
                        }

                        return creating;
                    },
                    offline: () => {
                        if (shouldCreateCards) {
                            cards.forEach(card => {
                                DataChangeRegistry.add({
                                    entityType: MigrationConsts.entityTypes.CARDS_PLUS,
                                    entity: card,
                                    operation: MigrationConsts.operations.CREATE,
                                    userId,
                                });
                            });
                        }
                    },
                }
            },
        });
    };
}

export function createNewCard() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const cards = _.values(getState().lbc.riderCardsPlus);
        const card = LocalCardsPlusApi.create({
            userId,
            riderName: CardsPlusUtil.generateRiderName(cards),
            createdAt: moment.utc().valueOf(),
            withdrawalValues: CardsPlusUtil.generateEmptyWithdrawalValues()
        });

        AnnuityLabAnalytics.trackCreatePlusCard(card);

        dispatch({
            type: ACTION_TYPES.CARD_PLUS_CREATED,
            payload: {card},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsPlusApi.createCard(userId, card);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.CARDS_PLUS,
                            entity: card,
                            operation: MigrationConsts.operations.CREATE,
                            userId,
                        });
                    },
                },
            },
        });
    };
}

export function updateCard(cardId, template) {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const card = LocalCardsPlusApi.findOne({id: cardId});
        const wasComplete = CardsPlusUtil.isComplete(card);

        const updated = LocalCardsPlusApi.updateOne(
            {id: cardId},
            _.assign(template, {updatedAt: moment.utc().valueOf()})
        );

        AnnuityLabAnalytics.trackEditPlusCard(updated, _.keys(template)[0]);

        if (!wasComplete && CardsPlusUtil.isComplete(updated)) {
            AnnuityLabAnalytics.trackFillPlusCard(updated);
        }

        dispatch({
            type: ACTION_TYPES.CARD_PLUS_UPDATED,
            payload: {cardId, card: updated},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsPlusApi.updateCard(userId, cardId, updated);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.CARDS_PLUS,
                            entity: updated,
                            operation: MigrationConsts.operations.UPDATE,
                            userId,
                        });
                    },
                },
            },
        });
    };
}

export function removeCard(cardId) {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const deleted = LocalCardsPlusApi.remove({id: cardId});
        const card = deleted[0];

        const comparisons = ComparisonsPlusApi.find();
        const dependentComparisons = _.filter(comparisons, comparison => {
            return _.includes(comparison.cardIds, card.id);
        });

        dependentComparisons.forEach(comparison => {
            ComparisonsPlusApi.remove({id: comparison.id});
        });

        AnnuityLabAnalytics.trackRemovePlusCard(card);

        dispatch({
            type: ACTION_TYPES.CARD_PLUS_REMOVED,
            payload: {card},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsPlusApi.removeCard(userId, cardId);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.CARDS_PLUS,
                            entity: card,
                            operation: MigrationConsts.operations.DELETE,
                            userId,
                        });

                        dependentComparisons.forEach(comparison => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.COMPARISONS_PLUS,
                                entity: comparison,
                                operation: MigrationConsts.operations.DELETE,
                                userId,
                            });
                        });
                    },
                }
            },
        });
    };
}

export function resetCards() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        const previous = {
            cards: _.values(getState().lbc.riderCardsPlus),
            comparisons: ComparisonsPlusApi.find({userId}),
        };

        ComparisonsPlusApi.remove({userId});
        LocalCardsPlusApi.remove({userId});
        const cards = createInitialCards(userId);

        AnnuityLabAnalytics.trackResetPlusCards();

        dispatch({
            type: ACTION_TYPES.CARDS_PLUS_RESET,
            payload: {cards},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsPlusApi
                            .removeAllCards(userId)
                            .then(() => {
                                return Promise.map(cards, card => {
                                    NwideCardsPlusApi.createCard(userId, card);
                                });
                            });
                    },
                    offline: () => {
                        previous.cards.forEach(card => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.CARDS_PLUS,
                                entity: card,
                                operation: MigrationConsts.operations.DELETE,
                                userId,
                            });
                        });

                        previous.comparisons.forEach(comparison => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.COMPARISONS_PLUS,
                                entity: comparison,
                                operation: MigrationConsts.operations.DELETE,
                                userId,
                            });
                        });

                        cards.forEach(card => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.CARDS_PLUS,
                                entity: card,
                                operation: MigrationConsts.operations.CREATE,
                                userId,
                            });
                        });
                    },
                },
            },
        });
    };
}
