/* eslint-disable max-len */
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import CardsUtil from 'common/services/cards_core_util.js';
import NoCardsView from 'annuity_lab/tools/common/components/no_cards/no_cards.js.jsx';
import NewLBCComparison from 'annuity_lab/tools/lbc/components/new_comparison/new_comparison.js.jsx';
import {updateRiderType} from 'annuity_lab/tools/lbc/actions/select_rider.js';
import {requireCoreRider} from 'annuity_lab/tools/lbc/services/routes_navigation_util.js';
/* eslint-enable max-len */

class Comparisons extends React.Component {
    componentDidMount() {
        const {riderType, updateRiderType} = this.props;

        requireCoreRider(riderType, updateRiderType);
    }

    render() {
        return this.props.riderCards.length >= 2 ?
            <NewLBCComparison {...this.props}/> :
            <NoCardsView {...this.props} onPlusRidersView={false}/>;
    }
}

Comparisons.propTypes = {
    riderCards: PropTypes.array,
};

function mapStateToProps({common, lbc}) {
    const {selectRider} = lbc;
    const riderCards = common.riderCards;
    const completedCards = _(riderCards).values().filter(CardsUtil.isComplete).value();

    return {
        riderType: selectRider.riderType,
        riderCards: completedCards,
        compareButtonText: 'Compare Riders',
    };
}

const mapDispatchToProps = (dispatch) => ({
    updateRiderType: (riderType) => {
        dispatch(updateRiderType(riderType));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Comparisons);
