import {useMemo} from 'react';
import PropTypes from 'prop-types';

import AnnualIncomeChart from
    'annuity_lab/tools/imo/components/annual_income_chart/annual_income_chart.js.jsx';

const CarryForwardViewChart = (props) => {
    const {tableValues, showAllYears} = props;

    const values = useMemo(() => {
        let valuesAFormatted = {};

        let valuesBFormatted = {};

        Object.keys(tableValues)
            .forEach(year => {
                valuesAFormatted[year] = tableValues[year].standard.annualIncome;
                valuesBFormatted[year] = tableValues[year].carryForward.annualIncome;
            });

        return {valuesAFormatted, valuesBFormatted};
    }, [tableValues]);

    const {valuesAFormatted, valuesBFormatted} = values;

    return (
        <AnnualIncomeChart
            id="carry-forward-chart"
            showAllYears={showAllYears}
            valuesA={valuesAFormatted}
            valuesB={valuesBFormatted}
            secondaryRiderColor="#403a60"/>
    );
};

CarryForwardViewChart.propTypes = {
    tableValues: PropTypes.object.isRequired,
    showAllYears: PropTypes.bool
};

CarryForwardViewChart.defaultProps = {
    showAllYears: false
};

export default CarryForwardViewChart;