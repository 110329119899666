import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';

const initialState = {
    syncing: false,
    error: false,
};

export default function reducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.SYNC_STARTED: {
        result = {syncing: true, error: false};
        break;
    }

    case ACTION_TYPES.SYNC_COMPLETED: {
        const error = !!action.payload.error;

        result = {syncing: false, error};
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
