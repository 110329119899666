import 'annuity_lab/tools/admin/containers/admin_app/admin_app.css.scss';

import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import ToolLayout from 'annuity_lab/components/tool_layout/tool_layout.js.jsx';
import AdminRoutes from 'annuity_lab/tools/admin/routes.js.jsx';
import RecentToolAPI from 'annuity_lab/services/data/api/recent_tool.js';

class AdminApp extends React.Component {
    static propTypes = {
        user: PropTypes.object,
    }

    componentDidMount() {
        this.updateRecentUserTool();
    }

    componentDidUpdate() {
        this.updateRecentUserTool();
    }

    updateRecentUserTool() {
        const {user, location} = this.props;

        if (this.props.user) {
            const recentToolApi = new RecentToolAPI(user.id);

            recentToolApi.set(location.pathname);
        }
    }

    render() {
        return (
            <ToolLayout
                user={this.props.user}
                headerTitle="Admin Panel">
                <div className="admin-app">
                    <AdminRoutes />
                </div>

            </ToolLayout>
        );
    }
}

function mapStateToProps({auth}) {
    return {
        user: auth.user,
    };
}

export default withRouter(connect(mapStateToProps)(AdminApp));
