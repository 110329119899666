import {combineReducers} from 'redux';
import authReducer from 'annuity_lab/reducers/auth.js';
import apiStatusReducer from 'annuity_lab/reducers/api_status.js';
import lbcReducer from 'annuity_lab/tools/lbc/reducers/';
import fiaReducer from 'annuity_lab/tools/fia/reducers/';
import madReducer from 'annuity_lab/tools/mad/reducers/';
import imoReducer from 'annuity_lab/tools/imo/reducers/';
import commonReducer from 'annuity_lab/tools/common/reducers/';
import ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const appReducer = combineReducers({
    auth: authReducer,
    apiStatus: apiStatusReducer,
    lbc: lbcReducer,
    fia: fiaReducer,
    mad: madReducer,
    imo: imoReducer,
    common: commonReducer,
});

const rootReducer = (state, action) => {
    let result;

    switch (action.type) {
    case ACTION_TYPES.LOGOUT: {
        result = _.assign({}, state, {
            lbc: {},
            fia: {},
            mad: {},
            imo: {},
            common: {},
        });
        break;
    }
    default: {
        result = state;
    }
    }

    return appReducer(result, action);
};

export default rootReducer;
