/* eslint-disable react/no-multi-comp */
import 'annuity_lab/tools/common/components/comparison_controls/comparison_controls.css.scss';

import {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import SaveComparisonDialog
    from 'annuity_lab/tools/common/components/dialogs/save_modal/save_modal.js.jsx';
import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';
import MessageDialog
    from 'common/components/dialogs/message_dialog/message_dialog.js.jsx';
import ControlButton
    from 'annuity_lab/tools/common/components/control_button/control_button.js.jsx';
import OfflineErrorMessage from 'annuity_lab/tools/lbc/services/offline_share_error_messages.js';
import Constants from 'common/services/constants.js';
import ShareComparisonModal from
    'annuity_lab/tools/common/containers/share_comparison_modal/share_comparison_modal.js.jsx';

const BUTTON_LABELS = Constants.COMPARISONS_BUTTON_LABELS;

const ComparisonControls = (props) => {
    const {
        offline,
        view,
        history,
        location,
        match,
        comparisons,
        riderCards,
        productContext,
        hasPendingShare,
        tool,
        saveComparison,
        removeComparison,
        requestComparisonShare,
        cancelComparisonShare,
    } = props;

    const [hasOfflineShareError, setHasOfflineShareError] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [comparison, setComparison] = useState(null);

    const isSaved = () => !!comparison.id;
    const openSaveDialog = () => setSaveDialogOpen(true);
    const closeSaveDialog = () => setSaveDialogOpen(false);
    const onOfflineShareMessageClosed = () => setHasOfflineShareError(false);

    const loadComparison = useCallback(() => {
        // TODO: Check if state is needed
        const routerState = location.state;
        const routerParams = match.params;

        let comparison;

        if (routerState && routerState.cardIds) {
            comparison = {
                tool,
                cardIds: [],
                baseCardId: routerState.baseCardId
            };
            routerState.cardIds.forEach(cardId => {
                comparison.cardIds.push(cardId);
            });
        } else {
            comparison = comparisons[routerParams.id];
        }

        if (comparison) {
            setComparison({
                ...comparison,
                cards: comparison.cardIds.map(id => riderCards[id]),
            });
        } else {
            history.replace(`/${tool}/comparisons`);
        }
    }, [match.params.id, location.state, comparisons, riderCards]);

    useEffect(() => {
        loadComparison();
    }, [loadComparison]);

    // component will receive props
    // useEffect(() => {
    //     const currentParams = this.props.match.params;
    //     const nextParams = nextProps.match.params;
    //
    //     const shouldReload = this.props.location.state !== nextProps.location.state ||
    //         currentParams.id !== nextParams.id;
    //
    //     if (shouldReload) {
    //         this.loadComparison(nextProps);
    //     }
    // }, []);

    const save = (name) => {
        const newComparison = _.clone(comparison);

        newComparison.name = name;
        closeSaveDialog();
        saveComparison(newComparison);
    };

    const remove = () => {
        ModalDialogs.confirm(
            Constants.COMPARISONS_CONFIRM_TITLE,
            Constants.COMPARISONS_CONFIRM_REMOVE_TEXT, () => {
                removeComparison(comparison);
            }
        );
    };

    const newComparison = () => {
        AnnuityLabAnalytics.trackStartNewComparison();
        history.push(`/${tool}/comparisons`);
    };

    const onShareClick = () => {
        if (offline) {
            setHasOfflineShareError(true);

            return;
        }

        requestComparisonShare();
    };

    if (!comparison) {
        return null;
    }

    const className = view;

    const removeButton = (
        <ControlButton
            labels={BUTTON_LABELS.REMOVE}
            onClick={remove}
            classNames='remove-button' />);

    const saveButton = (
        <ControlButton
            labels={BUTTON_LABELS.SAVE}
            onClick={openSaveDialog}/>
    );

    return (
        <div className={`comparison-controls ${className}`}>
            <div className={`controls ${className}`}>
                {isSaved() ? removeButton : saveButton}
                <ControlButton
                    labels={BUTTON_LABELS.SHARE}
                    onClick={() => onShareClick()} />
                <ControlButton
                    labels={BUTTON_LABELS.NEW}
                    onClick={() => newComparison()}
                    classNames="new-button" />
            </div>
            {(saveDialogOpen || (hasPendingShare && !isSaved())) &&
                <SaveComparisonDialog
                    className={className}
                    onRequestClose={() => {
                        closeSaveDialog();
                        cancelComparisonShare();
                    }}
                    onSave={(name) => save(name)}
                    entity="comparison"/>
            }
            {(isSaved() && hasPendingShare) && (
                <ShareComparisonModal
                    tool={tool}
                    className={className}
                    comparisonName={comparison.name}
                    comparisonId={comparison.id}
                    additionalShareData={{
                        money: productContext.money,
                        period: productContext.deferralPeriod
                    }}/>
            )}
            { hasOfflineShareError &&
                <MessageDialog
                    onRequestClose={() => {
                        onOfflineShareMessageClosed();
                    }}
                    className={className}
                    title={OfflineErrorMessage.TITLE}
                    content={OfflineErrorMessage.CONTENT}/>
            }
        </div>
    );
};

ComparisonControls.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    riderCards: PropTypes.object,
    comparisons: PropTypes.object,
    hasPendingShare: PropTypes.bool,
    view: PropTypes.string,
    tool: PropTypes.string.isRequired,
};

ComparisonControls.defaultProps = {
    view: 'mobile',
};

export default ComparisonControls;