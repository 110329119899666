/* eslint-disable react/no-multi-comp */
import 'annuity_lab/tools/imo/components/standard_view_table/standard_view_table.css.scss';

import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import StandardViewTableRows from
    'annuity_lab/tools/imo/components/standard_view_table_rows/standard_view_table_rows.js.jsx';

const RiderNamesTD = ({riderBDefined}) => {
    return (
        <td className={riderBDefined ? 'large' : 'medium'}>
            <div className="ab-container">
                <span className="rider-a-text table-rider-name">RIDER A</span>
                {riderBDefined && <span className="rider-b-text table-rider-name">RIDER B</span>}
            </div>
        </td>
    );
};

const StandardViewTable = ({tableValuesA, tableValuesB, ageAtWithdrawal}) => {
    const riderBDefined = tableValuesB !== null;

    const [highlightedYearRow, setHighlightedYearRow] = useState(null);

    useEffect(() => {
        const table = document.querySelector('.imo-standard-view-table');

        const handleClick = (ev) => {
            if (!table.contains(ev.target)) {
                setHighlightedYearRow(null);
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const handleTableRowClick = (yearTableRow) => () => {
        setHighlightedYearRow(yearTableRow);
    };

    const renderRidersNames = () => <RiderNamesTD riderBDefined={riderBDefined}/>;

    return (
        <div className={`imo-standard-view-table ${riderBDefined ? '' : 'one-rider'}`}>
            <table>
                <thead>
                    <tr>
                        <th>W/D YEAR</th>
                        <th>AGE</th>
                        <th>GUARANTEED ROLL-UP</th>
                        <th>CONTRACT VALUE</th>
                        <th>ANNUAL INCOME</th>
                        <th>TOTAL INCOME</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={highlightedYearRow === null ? 'highlighted' : undefined}>
                        <td className="small"/>
                        <td className="small"/>
                        {renderRidersNames()}
                        {renderRidersNames()}
                        {renderRidersNames()}
                        {renderRidersNames()}
                    </tr>
                    <StandardViewTableRows
                        ageAtWithdrawal={ageAtWithdrawal}
                        tableValuesA={tableValuesA}
                        tableValuesB={tableValuesB}
                        highlightedYearRow={highlightedYearRow}
                        onTableRowClick={handleTableRowClick}/>
                </tbody>
            </table>
        </div>
    );
};

StandardViewTable.propTypes = {
    ageAtWithdrawal: PropTypes.string,
    tableValuesA: PropTypes.object,
    tableValuesB: PropTypes.object
};

export default StandardViewTable;