/* eslint-disable max-len */
import 'annuity_lab/tools/mad/containers/premium_comparison/premium_comparison.css.scss';
import CardsUtil from 'common/services/cards_core_util.js';
import SmallCard from 'common/components/small_card/small_card.js.jsx';
import ComparisonCardSummary from 'annuity_lab/tools/mad/components/comparison_card_summary/comparison_card_summary.js.jsx';
import {isPhone} from 'common/services/screen_util.js';

import PropTypes from 'prop-types';

/* eslint-enable max-len */

export default class PremiumComparison extends React.Component {
    static propTypes = {
        money: PropTypes.string.isRequired,
        deferralPeriod: PropTypes.number.isRequired,
        onSelect: PropTypes.func.isRequired,
        selectedCard: PropTypes.object.isRequired,
        baseProduct: PropTypes.object.isRequired,
        nonBase: PropTypes.array.isRequired,
    }

    getCardContent(card, showAdditionalText) {
        if (!isPhone() || this.isSelected(card)) {
            const {baseProduct, money, deferralPeriod} = this.props;

            const premiumDifference = CardsUtil.initialPremiumDifference(
                baseProduct,
                card,
                money,
                deferralPeriod,
            );
            const annualDifference = CardsUtil.additionalAnnualIncome(
                baseProduct,
                premiumDifference,
                deferralPeriod
            );

            return (
                <ComparisonCardSummary
                    money={money}
                    card={card}
                    baseProduct={baseProduct}
                    premiumDifference={premiumDifference}
                    annualDifference={annualDifference}
                    additionalClasses='large-fonts'
                    showAdditionalText={showAdditionalText}/>
            );
        }

        return (
            <div className="hint">
                Tap to compare
            </div>
        );
    }

    shouldShowText(money, deferralPeriod, baseProduct, nonBase) {
        let count = 0;

        nonBase.forEach(card => {
            const premiumDifference = CardsUtil.initialPremiumDifference(
                baseProduct,
                card,
                money,
                deferralPeriod,
            );

            if (premiumDifference >= 0) {
                count++;
            }
        });

        return count !== nonBase.length;
    }

    isSelected(card) {
        return card === this.props.selectedCard;
    }

    render() {
        const {money, deferralPeriod, baseProduct, nonBase} = this.props;
        const showAdditionalText = this.shouldShowText(money, deferralPeriod, baseProduct, nonBase);

        return (
            <div className="premium-comparison">
                <div className="products">
                    <div className="product product-order-0 in-comparison">
                        <SmallCard
                            card={baseProduct}
                            amountLabel='Premium'
                            monetaryValue={
                                CardsUtil.initialPremium(baseProduct, money, deferralPeriod)
                            }
                            additionalClasses='large-fonts' />
                    </div>
                    <div className="comparable">
                        {
                            _.map(nonBase, (card, index) =>
                                (<div
                                    className={
                                        `product product-order-${index + 1} ` +
                                  (this.isSelected(card)
                                      ? 'in-comparison'
                                      : 'not-in-comparison')
                                    }
                                    key={card.id}>
                                    <SmallCard
                                        isSelected={this.isSelected(card)}
                                        card={card}
                                        amountLabel='Premium'
                                        monetaryValue={
                                            CardsUtil.initialPremium(card, money, deferralPeriod)
                                        }
                                        additionalClasses='large-fonts'
                                        onClick={isPhone()
                                            ? () => this.props.onSelect(card, index + 1)
                                            : null}>
                                        {this.getCardContent(card, showAdditionalText)}
                                    </SmallCard>
                                </div>))
                        }
                    </div>
                </div>
            </div>
        );
    }
}
