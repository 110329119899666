/* eslint-disable max-len */
import 'c3/c3.css';
import 'annuity_lab/tools/lbc/components/comparison_plus_chart/comparison_plus_chart.css.scss';

import C3Chart from 'react-c3js';

import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import {isPhone} from 'common/services/screen_util.js';
import {
    getGrid,
    getLegend,
    getPoint,
    getSize,
    getTooltip,
    positionInfoBalloon,
    drawGridLines,
    adjustOverlapping
} from '../../../common/utils/chart';

const BASE_RIDER_NAME = 'base';
const SECONDARY_RIDER_NAME = 'secondary';
const CHART_CONTAINER_CLASS_NAME = 'plus-riders-comparisons-chart';
const CHART_CONTAINER_ID = 'plus-riders-comparisons-chart';

const OFFSET_YEARS_AFTER_X_AXIS_END = 6;
const MIN_X_AXIS_LENGTH = 14;
// last one is not shown so there will be effectively maximum of MAX_X_AXIS_TICKS - 1 on screen.
const MAX_X_AXIS_TICKS = 30;
const MAX_X_AXIS_TICKS_SMALL_SCREEN = 16;

const Y_AXIS_TOP_PADDING_RATIO = 0.1;

const PLOT_HEIGHT = 260;

class ComparisonPlusChart extends React.Component {
    constructor(props) {
        super(props);

        this.positionElements = this.positionElements.bind(this);
        this.handleResizeDebounced = _.debounce(this.handleResize.bind(this), 300);

        this.state = {
            isSmallScreen: isPhone()
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResizeDebounced);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizeDebounced);
    }

    handleResize = () => {
        this.setState({
            isSmallScreen: isPhone()
        });
    }

    positionCircle(chartCircleClass, starContainerClass) {
        const chartContainer = document.querySelector(`.${CHART_CONTAINER_CLASS_NAME}`);
        const circles = document.querySelectorAll(chartCircleClass);
        const starContainer = document.querySelector(starContainerClass);

        if (!chartContainer || !circles || !starContainer) {
            return;
        }

        const circlesArr = Array.from(circles);

        const circlesGroupedByX = _.groupBy(circlesArr, c => c.__data__.x);
        const duplicateCirclesByX = Object.values(circlesGroupedByX).filter(v => v.length > 1);

        // We are sure that there are more than 2 points per circles array
        // We are also sure that there is maximum one drop in y per chart path
        // We are also sure that the last of the points in circles array is the
        // one we care about because querySelectorAll returns the elements in the
        // same order as they appear in the document
        const relevantCircleArr = duplicateCirclesByX.find(circlesArr =>
            circlesArr[0].__data__.value - circlesArr[circlesArr.length - 1].__data__.value !== 0
        );

        // A flat line represents the rider
        if (!relevantCircleArr) {
            // hide the star for the rider
            starContainer.style.display = 'none';

            return;
        }

        // Last circle is always one after =0 amount is reached
        const relevantCircle = relevantCircleArr[relevantCircleArr.length - 1];
        const chartContainerBoundingRect = chartContainer.getBoundingClientRect();
        const circleBoundingRect = relevantCircle.getBoundingClientRect();

        const differenceTop = circleBoundingRect.top - chartContainerBoundingRect.top;
        const differenceLeft = circleBoundingRect.left - chartContainerBoundingRect.left;

        starContainer.style.top = `${Math.round(differenceTop - 8)}px`;
        starContainer.style.left = `${Math.round(differenceLeft - 8)}px`;
    }

    positionCircles() {
        this.positionCircle(
            `.${CHART_CONTAINER_CLASS_NAME} .c3-circles-${BASE_RIDER_NAME} circle`,
            `.${CHART_CONTAINER_CLASS_NAME} .circle-container-path-${BASE_RIDER_NAME}`
        );

        this.positionCircle(
            `.${CHART_CONTAINER_CLASS_NAME} .c3-circles-${SECONDARY_RIDER_NAME} circle`,
            `.${CHART_CONTAINER_CLASS_NAME} .circle-container-path-${SECONDARY_RIDER_NAME}`
        );
    }

    positionElements() {
        this.positionCircles();
        positionInfoBalloon(CHART_CONTAINER_ID, CHART_CONTAINER_CLASS_NAME);
        drawGridLines(CHART_CONTAINER_ID);
    }

    getWithdrawalInfoForCard(card) {
        const {comparison} = this.props;
        const {premium, currentAge, ageAtWithdrawal, jointRiders} = comparison;

        const withdrawalInfo = CardsPlusUtil.calculateWithdrawalInfo(
            card, premium, currentAge, ageAtWithdrawal, jointRiders
        );

        return withdrawalInfo;
    }

    getXAxisDesiredLength() {
        const {cards, comparison} = this.props;
        const {ageAtWithdrawal} = comparison;

        const {
            moneyPerMonthNegative: baseMoneyPerMonthNegative,
            moneyPerMonthPositive: baseMoneyPerMonthPositive,
            ageMoneyPositive: baseAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[0]);
        const {
            moneyPerMonthNegative: secondaryMoneyPerMonthNegative,
            moneyPerMonthPositive: secondaryMoneyPerMonthPositive,
            ageMoneyPositive: secondaryAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[1]);

        let desiredXAxisAgeLength;
        const baseNoMoneyDrop = Number(baseMoneyPerMonthPositive).toFixed(0) === Number(baseMoneyPerMonthNegative).toFixed(0);
        const secondaryNoMoneyDrop = Number(secondaryMoneyPerMonthPositive).toFixed(0) === Number(secondaryMoneyPerMonthNegative).toFixed(0);

        if (baseNoMoneyDrop) {
            if (secondaryNoMoneyDrop) {
                desiredXAxisAgeLength = MIN_X_AXIS_LENGTH;
            } else {
                desiredXAxisAgeLength = Math.max(
                    secondaryAgeMoneyPositive - ageAtWithdrawal + OFFSET_YEARS_AFTER_X_AXIS_END,
                    MIN_X_AXIS_LENGTH
                );
            }
        } else if (secondaryNoMoneyDrop) {
            desiredXAxisAgeLength = Math.max(
                baseAgeMoneyPositive - ageAtWithdrawal + OFFSET_YEARS_AFTER_X_AXIS_END,
                MIN_X_AXIS_LENGTH
            );
        } else {
            const maxAgeMoneyPositive = Math.max(baseAgeMoneyPositive, secondaryAgeMoneyPositive);

            desiredXAxisAgeLength = Math.max(
                maxAgeMoneyPositive - ageAtWithdrawal + OFFSET_YEARS_AFTER_X_AXIS_END,
                MIN_X_AXIS_LENGTH
            );
        }

        // To handle duplicate values on the x axis.
        const desiredXAxisLength = desiredXAxisAgeLength + 2;

        return desiredXAxisLength;
    }

    getXAxis() {
        const {cards, comparison} = this.props;
        const {ageAtWithdrawal} = comparison;

        const desiredXAxisLength = this.getXAxisDesiredLength();

        const {
            ageMoneyPositive: baseAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[0]);
        const {
            ageMoneyPositive: secondaryAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[1]);

        const minAgeMoneyPositive = Math.min(baseAgeMoneyPositive, secondaryAgeMoneyPositive);
        const maxAgeMoneyPositive = Math.max(baseAgeMoneyPositive, secondaryAgeMoneyPositive);

        const xAxis = ['x',
            ..._.range(ageAtWithdrawal, minAgeMoneyPositive + 1, 1),
            ..._.range(minAgeMoneyPositive, maxAgeMoneyPositive + 1, 1),
            ..._.range(maxAgeMoneyPositive, maxAgeMoneyPositive + 100, 1)
        ];

        return xAxis.slice(0, desiredXAxisLength + 1);
    }

    getBaseRiderData = () => {
        const {cards, comparison} = this.props;
        const {ageAtWithdrawal} = comparison;

        const xAxisDesiredLength = this.getXAxisDesiredLength();

        const {
            moneyPerMonthNegative: baseMoneyPerMonthNegative,
            moneyPerMonthPositive: baseMoneyPerMonthPositive,
            ageMoneyPositive: baseAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[0]);

        const {
            ageMoneyPositive: secondaryAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[1]);

        const baseRiderAgeDropLowerConst = Number(secondaryAgeMoneyPositive) <= Number(baseAgeMoneyPositive) ? 1 : 0;

        return [
            ..._.times(
                Number(baseAgeMoneyPositive) + 1 - ageAtWithdrawal + baseRiderAgeDropLowerConst ,
                _.constant(Number(baseMoneyPerMonthPositive))
            ),
            ..._.times(
                200,
                _.constant(Number(baseMoneyPerMonthNegative))
            ),
        ].slice(0, xAxisDesiredLength + 1);
    }

    getSecondaryRiderData = () => {
        const {cards, comparison} = this.props;
        const {ageAtWithdrawal} = comparison;

        const xAxisDesiredLength = this.getXAxisDesiredLength();

        const {
            ageMoneyPositive: baseAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[0]);

        const {
            moneyPerMonthNegative: secondaryMoneyPerMonthNegative,
            moneyPerMonthPositive: secondaryMoneyPerMonthPositive,
            ageMoneyPositive: secondaryAgeMoneyPositive
        } = this.getWithdrawalInfoForCard(cards[1]);

        const secondaryRiderAgeDropLowerConst = Number(baseAgeMoneyPositive) < Number(secondaryAgeMoneyPositive) ? 1 : 0;

        return [
            ..._.times(
                Number(secondaryAgeMoneyPositive) + 1 - ageAtWithdrawal + secondaryRiderAgeDropLowerConst,
                _.constant(Number(secondaryMoneyPerMonthPositive))
            ),
            ..._.times(
                200,
                _.constant(Number(secondaryMoneyPerMonthNegative))
            )
        ].slice(0, xAxisDesiredLength + 1);
    }

    getData() {
        const xAxis = this.getXAxis();

        const baseRiderData = this.getBaseRiderData();
        const secondaryRiderData = this.getSecondaryRiderData();

        const {
            riderAData,
            riderBData
        } = adjustOverlapping(baseRiderData, secondaryRiderData);

        const baseRiderCardData = [BASE_RIDER_NAME,
            ...riderAData
        ];

        const secondaryRiderCardData = [SECONDARY_RIDER_NAME,
            ...riderBData
        ];

        return  {
            x: 'x',
            columns: [
                xAxis,
                secondaryRiderCardData,
                baseRiderCardData
            ],
            colors: {
                [BASE_RIDER_NAME]: '#279989',
                [SECONDARY_RIDER_NAME]: '#672146'
            }
        };
    }

    getAxis() {
        const xAxis = this.getXAxis();
        const desiredXAxisLength = this.getXAxisDesiredLength();

        const xAxisSliced = xAxis.slice(0, desiredXAxisLength + 1);

        // -1 for the string 'x' and -2 for the duplicate values.
        const xAxisLengthInTicks = xAxisSliced.length - 3;
        const cullyingApplied = this.state.isSmallScreen && !this.props.showAllYears
            ? xAxisLengthInTicks >= MAX_X_AXIS_TICKS_SMALL_SCREEN
            : xAxisLengthInTicks >= MAX_X_AXIS_TICKS;

        let yearWithPlusAppended;

        // The last xAxisSliced value is hidden so that the line continues to
        // the right after the year+ xAxis value
        let lastVisibleYearWithoutCullying = xAxisSliced[xAxisSliced.length - 2];

        if (cullyingApplied) {
            // The 0th element is the string 'x'
            const isFirstElementEven = xAxisSliced[1] % 2 === 0;
            const isLastElementEven = lastVisibleYearWithoutCullying % 2 === 0;

            if (isFirstElementEven === isLastElementEven) {
                yearWithPlusAppended = lastVisibleYearWithoutCullying;
            } else {
                yearWithPlusAppended = xAxisSliced[xAxisSliced.length - 3];
            }
        } else {
            yearWithPlusAppended = lastVisibleYearWithoutCullying;
        }

        return {
            x: {
                tick: {
                    format: (x) => {
                        if (x === yearWithPlusAppended) {
                            return `${x}+`;
                        }

                        if (x > yearWithPlusAppended) {
                            return '';
                        }

                        return x;
                    },
                    culling: {
                        max: (this.state.isSmallScreen && !this.props.showAllYears) ? MAX_X_AXIS_TICKS_SMALL_SCREEN : MAX_X_AXIS_TICKS
                    },
                },
                padding: {
                    left: 0,
                    // hide the last year, so that the line continues to the right after (year)+ tick
                    right: -0.6
                },
                label: {
                    text: 'Client Age',
                    position: 'outer-center'
                }
            },
            y: {
                tick: {
                    format: y => y >= 1000 ? `$${ y.toFixed(0) / 1000}K` : `$${ y.toFixed(0)}`,
                },
                min: 0,
                padding: {
                    top: Y_AXIS_TOP_PADDING_RATIO,
                    bottom: 0,
                    unit: 'ratio'
                },
                label: {
                    text: 'Annual Income Amount',
                    position: 'outer-middle'
                }
            }
        };
    }

    render() {
        const data = this.getData();
        const axis = this.getAxis();

        const legend = getLegend();
        const point = getPoint();
        const tooltip = getTooltip();
        const grid = getGrid();
        const size = getSize(PLOT_HEIGHT);

        return (
            <div id={CHART_CONTAINER_ID} className={CHART_CONTAINER_CLASS_NAME}>
                <h3 className="chart-title">Payout in Years</h3>
                <div className="chart-start-balloon">
                    <span className="start-text">START</span>
                </div>
                <C3Chart
                    // To trigger a refresh of the component, when the state changes.
                    key={this.state.isSmallScreen}
                    data={data}
                    legend={legend}
                    axis={axis}
                    point={point}
                    grid={grid}
                    tooltip={tooltip}
                    size={size}
                    onrendered={this.positionElements}
                    onresize={this.positionElements}/>
                <div className={`circle-container-path-${BASE_RIDER_NAME}`} />
                <div className={`circle-container-path-${SECONDARY_RIDER_NAME}`} />
            </div>
        );
    }
}

ComparisonPlusChart.defaultProps = {
    showAllYears: false
};

export default ComparisonPlusChart;