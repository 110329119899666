import ProductContextAPI from 'annuity_lab/tools/lbc/services/data/api/product_context.js';

export function _updateProductContext(money, deferralPeriod, actionTypes) {
    return (dispatch, getState) => {
        const currentUser = getState().auth.user;

        ProductContextAPI.update({userId: currentUser.id}, {money, deferralPeriod});
        dispatch({
            type: actionTypes.PRODUCT_CONTEXT_SET,
            payload: {money, deferralPeriod},
        });
    };
}
