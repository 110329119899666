import 'annuity_lab/tools/lbc/components/tab_view/tab_view.css.scss';

import {useState, useEffect} from 'react';
import {Switch, Route, NavLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import StandardView from
    'annuity_lab/tools/lbc/components/plus_standard_view/plus_standard_view.js.jsx';
import CarryForwardView from
    'annuity_lab/tools/lbc/components/plus_carry_forward_view/plus_carry_forward_view.js.jsx';
// import MonteCarloView from
//     'annuity_lab/tools/lbc/components/plus_monte_carlo_view/plus_monte_carlo_view.js.jsx';

// import {MONTE_CARLO_OPTIONS, PAYOUT_OPTIONS} from
//     'annuity_lab/tools/lbc/components/tab_view/monte_carlo.js';

import {WITHDRAWAL_OPTIONS, CARRY_FORWARD_OPTIONS} from
    'annuity_lab/tools/lbc/components/tab_view/carry_forward.js';

const TabView = (props) => {
    const {location, history, match, comparison, cards} = props;
    const {pathname: currentPath} = location;

    useEffect(() => {
        if (
            currentPath !== `${match.url}/standard` &&
            currentPath !== `${match.url}/carry-forward`
            // eslint-disable-next-line no-inline-comments
            /* && currentPath !== `${match.url}/monte-carlo`*/) {
            history.replace(`${match.url}/standard`);
        }
    }, [history, currentPath]);

    // const [payout, setPayout] = useState(PAYOUT_OPTIONS[0]);
    const [withdrawal, setWithdrawal] = useState(WITHDRAWAL_OPTIONS[0]);
    const [carryForwardOption, setCarryForwardOption] = useState(
        CARRY_FORWARD_OPTIONS[withdrawal.value][1]
    );
    // const [monteCarloOption, setMonteCarloOption] =
    // useState(MONTE_CARLO_OPTIONS[payout.value][0]);

    const onWithdrawalChange = (o) => {
        setWithdrawal(o);
        setCarryForwardOption(CARRY_FORWARD_OPTIONS[o.value][carryForwardOption.id]);
    };

    // const onPayoutChange = (o) => {
    //     setPayout(o);
    //     setMonteCarloOption(MONTE_CARLO_OPTIONS[o.value][monteCarloOption.id]);
    // };

    const onCarryForwardChange = (o) => {
        if (o.id > 0) {
            setCarryForwardOption(o);
        }
    };

    // const onMonteCarloChange = (o) => {
    //     setMonteCarloOption(o);
    // };

    const id = match.params.id ?? null;

    return (
        <div className="plus-tab-view">
            <div className="tab-view-navigation">
                <NavLink
                    to={`/lbc/comparisons/plus/${id ?? 'new'}/standard`}
                    activeClassName="active">
                    Standard Comparison
                </NavLink>
                <div>
                    <NavLink
                        to={`/lbc/comparisons/plus/${id ?? 'new'}/carry-forward`}
                        activeClassName="active">
                        Carry Forward Example
                    </NavLink>
                    {/* <NavLink
                        to={`/lbc/comparisons/plus/${id ?? 'new'}/monte-carlo`}
                        activeClassName="active">
                        Monte Carlo Example
                    </NavLink> */}
                </div>
            </div>
            <div className="tab-view-rotues">
                <Switch>
                    <Route exact path={`${match.path}/standard`}>
                        <StandardView comparison={comparison} cards={cards}/>
                    </Route>
                    <Route exact path={`${match.path}/carry-forward`}>
                        <CarryForwardView
                            withdrawalOptions={WITHDRAWAL_OPTIONS}
                            carryForwardOptions={CARRY_FORWARD_OPTIONS[withdrawal.value]}
                            withdrawal={withdrawal}
                            carryForwardOption={carryForwardOption}
                            onWithdrawalChange={onWithdrawalChange}
                            onCarryForwardChange={onCarryForwardChange}/>
                    </Route>
                    {/* <Route exact path={`${match.path}/monte-carlo`}>
                        <MonteCarloView
                            payoutOptions={PAYOUT_OPTIONS}
                            monteCarloOptions={MONTE_CARLO_OPTIONS[payout.value]}
                            payout={payout}
                            monteCarloOption={monteCarloOption}
                            onPayoutChange={onPayoutChange}
                            onMonteCarloChange={onMonteCarloChange}/>
                    </Route> */}
                </Switch>
            </div>
        </div>
    );
};

TabView.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    cards: PropTypes.array,
    comparison: PropTypes.object,
};

export default withRouter(TabView);