import ACTION_TYPES from 'annuity_lab/tools/imo/actions/action_types.js';
import BASE_ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const initialClientInformation = {
    currentAge: '',
    ageAtWithdrawal: '',
    jointRider: null
};

const initialCarryForward = {
    startYear: '',
    endYear: '',
    annualIncome: ''
};

const initialState = {
    clientInformation: initialClientInformation,
    riderA: null,
    riderB: null,
    carryForward: initialCarryForward
};

export default function reducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.NEW_MODEL_SET: {
        result = _.assign({}, state, action.payload);
        break;
    }

    case ACTION_TYPES.NEW_MODEL_RESET: {
        result = initialState;
        break;
    }

    case ACTION_TYPES.MODEL_SAVED: {
        result = initialState;
        break;
    }

    case BASE_ACTION_TYPES.LOGOUT: {
        result = initialState;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}