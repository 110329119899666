import keyMirror from 'common/services/key_mirror.js';

const ACTION_TYPES = [
    'PRODUCT_CONTEXT_SET',

    'COMPARISONS_LOADED',
    'COMPARISON_SAVED',
    'COMPARISON_REMOVED',

    'CARDS_LOADED',
    'CARD_CREATED',
    'CARD_UPDATED',
    'CARD_REMOVED',
    'CARDS_RESET',

    'SYNC_STARTED',
    'SYNC_COMPLETED',

    'SHARE_COMPARISON_IN_PROGRESS',
    'SHARE_COMPARISON_REQUESTED',
    'SHARE_COMPARISON_COMPLETED',
    'SHARE_COMPARISON_CANCELED',
    'SHARE_CLEAR',

    'CONTACTS_LOADED',

    'SHARE_COMPARISON_STATUS_SEEN',
];

export default keyMirror(ACTION_TYPES, 'COMMON');
