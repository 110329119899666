/* eslint-disable max-len */
import 'annuity_lab/tools/lbc/containers/product_differential/product_differential.css.scss';
import CardsUtil from 'common/services/cards_core_util.js';
import SmallCard from 'common/components/small_card/small_card.js.jsx';
import DifferentialBalloon
    from 'annuity_lab/tools/lbc/components/differential_balloon/differential_balloon.js.jsx';
import HurdleRateBalloon from 'annuity_lab/tools/lbc/components/hurdle_rate_balloon/hurdle_rate_balloon.js.jsx';

import PropTypes from 'prop-types';

export default class ProductDifferential extends React.Component {
    static propTypes = {
        cards: PropTypes.array.isRequired,
        money: PropTypes.string.isRequired,
        deferralPeriod: PropTypes.number.isRequired,
        selectedCardIndex: PropTypes.number.isRequired,

        onSelect: PropTypes.func.isRequired
    }

    getProductSelector(productIndex) {
        return `.product:nth-child(${productIndex}) .small-product-card`;
    }

    getCardContentOfNonBaseProduct(currIndex) {
        // get the card content of the product different from the base one
        const {
            cards, selectedCardIndex, baseProduct,
            hurdleRate, deferralPeriod
        } = this.props;

        if (selectedCardIndex === currIndex) {
            return (
                <HurdleRateBalloon
                    arrowPosition="top"
                    cardIndex={selectedCardIndex}
                    card={cards[selectedCardIndex]}
                    baseProduct={baseProduct}
                    hurdleRate={hurdleRate}
                    deferralPeriod={deferralPeriod}
                    target={this.getProductSelector(currIndex)}/>
            );
        }

        return (
            <div className="hint">
                Tap to compare
            </div>
        );
    }

    isSelected(index) {
        return index === 0 || index === this.props.selectedCardIndex;
    }

    render() {
        var {
            money, deferralPeriod, cards,
            baseProduct, difference
        } = this.props;

        return (
            <div className="product-differential">
                <div className="products">
                    {_.map(cards, (card, index) =>
                        (<div
                            className={'product ' + (this.isSelected(index)
                                ? 'in-comparison'
                                : 'not-in-comparison')}
                            key={card.id}>

                            <SmallCard
                                isSelected={this.isSelected(index)}
                                card={card}
                                amountLabel='Annual Income'
                                monetaryValue={
                                    CardsUtil.annualIncome(card, money, deferralPeriod)
                                }
                                onClick={() => this.props.onSelect(index)}>

                                {
                                    card === baseProduct
                                        ? (<DifferentialBalloon
                                            difference={difference}
                                            target=".product:first-child .small-product-card"/>)
                                        : this.getCardContentOfNonBaseProduct(index)
                                }

                            </SmallCard>

                        </div>)

                    )}
                </div>
            </div>
        );
    }
}
