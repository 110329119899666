import 'annuity_lab/tools/imo/components/rider_details/rider_details.css.scss';

import NumberFormat from 'common/services/number_format.js';

const RiderDetails = (props) => {
    const {rider, isPrimary} = props;

    const {
        premium,
        cashValueGreaterThanZeroRate,
        cashValueEqualZeroRate
    } = rider;

    return (
        <div className="imo-card-information">
            <span className="name">{isPrimary ? 'A' : 'B'}:</span> {' '}
            <span className="details">
                (
                {NumberFormat.currency(Number(premium))}{' '}/{' '}
                {cashValueGreaterThanZeroRate}%{' '}/{' '}
                {cashValueEqualZeroRate}%)
            </span>
        </div>
    );
};

export default RiderDetails;