import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {updateProductContext} from 'annuity_lab/tools/mad/actions/product_context.js';
import Constants from 'annuity_lab/tools/mad/services/constants.js';
import CardsComparison
    from 'annuity_lab/tools/mad/components/cards_comparison/cards_comparison.js.jsx';

function mapStateToProps({mad, common}) {
    const {productContext, comparisons} = mad;
    const {riderCards} = common;
    const moneyLabel = Constants.CONTEXT_MONEY_LABEL;
    const deferralPeriodLabel = Constants.DEFERRAL_PERIOD_LABEL;

    return {
        productContext,
        riderCards,
        comparisons,
        moneyLabel,
        deferralPeriodLabel,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateProductContext: (money, deferralPeriod) => {
            dispatch(updateProductContext(money, deferralPeriod));
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardsComparison));
