import ComparisonControls from
    'annuity_lab/tools/common/components/comparison_controls/comparison_controls.js.jsx';
import {connect} from 'react-redux';
import {
    saveComparison,
    removeComparison,
} from 'annuity_lab/tools/lbc/actions/comparisons.js';
import {
    requestComparisonShare,
    cancelComparisonShare
} from 'annuity_lab/tools/common/actions/share.js';
import Constants from 'common/services/constants';
import {TOOLS} from 'common/domain/tools.js';

function mapStateToProps({lbc, apiStatus, common}) {
    const {
        comparisons,
        productContext,
    } = lbc;

    const {riderCards, share} = common;
    const tool = TOOLS.LIVING_BENEFIT_RIDERS_CORE;

    return {
        riderCards,
        comparisons,
        productContext,
        offline: !apiStatus.online,
        tool,
        hasPendingShare: share.status === Constants.COMPARISON_SHARE_STATUS.PENDING,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveComparison: (template) => {
            dispatch(saveComparison(template));
        },
        removeComparison: (comparison) => {
            dispatch(removeComparison(comparison.id));
        },
        requestComparisonShare: () => {
            dispatch(requestComparisonShare());
        },
        cancelComparisonShare: () => {
            dispatch(cancelComparisonShare());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonControls);
