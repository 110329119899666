const transformFERiderToBERider = (feRider) => {
    if (!feRider) {
        return feRider;
    }

    const {
        id,
        premium,
        interestType,
        rollup,
        cashValueGreaterThanZeroRate,
        cashValueEqualZeroRate,
        assumedReturnRate,
    } = feRider;

    return {
        id,
        premium: Number(premium),
        interestType,
        rollup,
        payoutRatePositive: cashValueGreaterThanZeroRate,
        payoutRateNegative: cashValueEqualZeroRate,
        rateOfReturn: assumedReturnRate
    };
};

export const transformFEModelToBEModel = (feModel) => {
    const {
        id,
        createdAt,
        updatedAt,
        name,
        clientInformation,
        carryForward,
        riderA,
        riderB,
        ...rest
    } = feModel;
    const {currentAge, ageAtWithdrawal, jointRider} = clientInformation;
    const {startYear, endYear, annualIncome} = carryForward;

    return {
        id,
        createdAt,
        updatedAt,
        name,
        currentAge: Number(currentAge),
        ageAtWithdrawal: Number(ageAtWithdrawal),
        jointRider: jointRider === 'yes',
        carryForwardAnnualIncome: annualIncome ? annualIncome : null,
        carryForwardStartYear: startYear ? startYear : null,
        carryForwardEndYear: endYear ? endYear : null,
        riderA: transformFERiderToBERider(riderA),
        riderB: transformFERiderToBERider(riderB),
        ...rest
    };
};

const transformBERiderToFERider = (beRider) => {
    if (!beRider) {
        return beRider;
    }

    const {
        id,
        premium,
        interestType,
        rollup,
        payoutRatePositive,
        payoutRateNegative,
        rateOfReturn,
        createdAt,
        updatedAt
    } = beRider;

    return {
        id,
        createdAt,
        updatedAt,
        interestType,
        rollup,
        premium: premium.toString(),
        cashValueGreaterThanZeroRate: payoutRatePositive,
        cashValueEqualZeroRate: payoutRateNegative,
        assumedReturnRate: rateOfReturn
    };
};

export const transformBEModelToFEModel = (beModel) => {
    const {
        riderA,
        riderB,
        id,
        name,
        currentAge,
        ageAtWithdrawal,
        jointRider,
        carryForwardAnnualIncome,
        carryForwardStartYear,
        carryForwardEndYear,
        createdAt,
        updatedAt,
        userId,
    } = beModel;

    return {
        riderA: transformBERiderToFERider(riderA),
        riderB: transformBERiderToFERider(riderB),
        id,
        name,
        createdAt,
        updatedAt,
        userId,
        clientInformation: {
            currentAge: currentAge.toString(),
            ageAtWithdrawal: ageAtWithdrawal.toString(),
            jointRider: jointRider ? 'yes' : 'no'
        },
        carryForward: {
            startYear: carryForwardStartYear ? carryForwardStartYear : '',
            endYear: carryForwardEndYear ? carryForwardEndYear : '',
            annualIncome: carryForwardAnnualIncome ? carryForwardAnnualIncome : ''
        }
    };
};