import 'c3/c3.css';
import 'annuity_lab/tools/imo/components/annual_income_chart/annual_income_chart.css.scss';

import C3Chart from 'react-c3js';
import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {isPhone} from 'common/services/screen_util.js';
import {
    getGrid,
    getLegend,
    getPoint,
    getSize,
    getTooltip,
    positionInfoBalloon,
    adjustOverlapping,
    drawGridLines
} from '../../../common/utils/chart';

const BASE_RIDER_NAME = 'base';
const SECONDARY_RIDER_NAME = 'secondary';
const CHART_CONTAINER_CLASS_NAME = 'standard-model-view-chart';

// last one is not shown so there will be effectively maximum of MAX_X_AXIS_TICKS - 1 on screen.
const MAX_X_AXIS_TICKS = 36;
const MAX_X_AXIS_TICKS_SMALL_SCREEN = 18;

const Y_AXIS_TOP_PADDING_RATIO = 0.1;

const PLOT_HEIGHT = 260;

const CHART_TITLE = 'Annual Withdrawal Amount by Year';

const AnnualIncomeChart = (props) => {
    const {
        valuesA,
        valuesB,
        secondaryRiderColor,
        showAllYears,
        id
    } = props;

    const [key, setKey] = useState(1);
    const [isSmallScreen, setIsSmallScreen] = useState(isPhone());

    // Trigger re-render on values changes
    useEffect(() => {
        setKey(key + 1);
    }, [valuesA, valuesB, isSmallScreen]);

    const handleResize = useCallback(() => {
        setIsSmallScreen(isPhone());
        setKey(key => key + 1);
    }, []);

    useEffect(() => {
        const handleResizeDebounced = _.debounce(handleResize, 300);

        window.addEventListener('resize', handleResizeDebounced);

        return () => {
            window.removeEventListener('resize', handleResizeDebounced);
        };
    }, [handleResize]);

    const positionElements = () => {
        drawGridLines(id);
        positionInfoBalloon(id, CHART_CONTAINER_CLASS_NAME);
    };

    const getDifferenceYearIndices = (values) => {
        if (!values) {
            return [];
        }

        const valuesArray = Object.values(values).map(value => value.toString());

        const differencesIndices = [];

        for (let i = 1; i < valuesArray.length; ++i) {
            if (valuesArray[i] !== valuesArray[i - 1]) {
                differencesIndices.push(i - 1);
            }
        }

        return differencesIndices;
    };

    const getXAxis = () => {
        const years = Object.keys(valuesA);

        const startYear = Number(years[0]);

        const differenceYearAIndices = getDifferenceYearIndices(valuesA);
        const differenceYearsA = differenceYearAIndices.map(i => startYear + i + 1);

        const differenceYearBIndices = getDifferenceYearIndices(valuesB);
        const differenceYearsB = differenceYearBIndices.map(i => i + startYear + 1);

        const differenceYearsSorted = [...differenceYearsA, ...differenceYearsB]
            .sort((a, b) => a - b);

        const yearsArrayWithoutLastInterval = differenceYearsSorted
            .reduce((acc, curr) => {
                if (acc.length === 0) {
                    return _.range(startYear, curr, 1);
                } else {
                    return acc.concat(_.range(acc[acc.length - 1], curr, 1));
                }
            }, []);

        const yearsArray = yearsArrayWithoutLastInterval.length > 0
            ? yearsArrayWithoutLastInterval.concat(
                _.range(
                    differenceYearsSorted[differenceYearsSorted.length - 1] - 1,
                    startYear + 31,
                    1
                )
            )
            : yearsArrayWithoutLastInterval.concat(
                _.range(
                    startYear,
                    startYear + 31,
                    1
                )
            );

        return yearsArray;
    };

    const getAxis = () => {
        const xAxis = getXAxis();

        const showReducedAmountOfYears = isSmallScreen && !showAllYears;

        return {
            x: {
                tick: {
                    format: (x) => {
                        const lastVisibleTick = xAxis[
                            showReducedAmountOfYears
                                ? xAxis.length - 3
                                : xAxis.length - 2
                        ];

                        if (x === lastVisibleTick) {
                            return `${x}+`;
                        }

                        if (x > lastVisibleTick) {
                            return '';
                        }

                        return x;
                    },
                    culling: {
                        max: showReducedAmountOfYears
                            ? MAX_X_AXIS_TICKS_SMALL_SCREEN
                            : MAX_X_AXIS_TICKS
                    },
                },
                padding: {
                    left: 0.005,
                    right: -0.6
                },
                label: {
                    text: 'Client Age',
                    position: 'outer-center'
                }
            },
            y: {
                tick: {
                    format: y => y >= 1000 ? `$${ y.toFixed(0) / 1000}K` : `$${ y.toFixed(0)}`,
                },
                min: 0,
                padding: {
                    top: Y_AXIS_TOP_PADDING_RATIO,
                    bottom: 0,
                    unit: 'ratio'
                },
                label: {
                    text: 'Annual Income Amount',
                    position: 'outer-middle'
                }
            }
        };
    };

    const getPlotValues = (originalValues, ownDifferenceIndices, otherDifferenceIndices) => {
        if (!originalValues) {
            return [];
        }

        let plotValues = [];

        Object.keys(originalValues).forEach((year, index) => {
            plotValues.push(originalValues[year]);

            if (otherDifferenceIndices.includes(index)) {
                plotValues.push(originalValues[year]);
            }

            if (ownDifferenceIndices.includes(index)) {
                plotValues.push(originalValues[Number(year) + 1]);
            }
        });

        // Duplicate the last value. This is needed so that we can make the
        // line continue to the right after the "year+" year.
        plotValues.push(plotValues[plotValues.length - 1]);

        return plotValues;
    };

    const getData = () => {
        const xAxis = getXAxis();

        const differenceYearAIndices = getDifferenceYearIndices(valuesA);
        const differenceYearBIndices = getDifferenceYearIndices(valuesB);

        const standardValues =
            getPlotValues(valuesA, differenceYearAIndices, differenceYearBIndices);
        const carryForwardValues =
            getPlotValues(valuesB, differenceYearBIndices, differenceYearAIndices);

        const {riderAData, riderBData} = adjustOverlapping(carryForwardValues, standardValues);

        return  {
            x: 'x',
            columns: [
                ['x', ...xAxis],
                [BASE_RIDER_NAME, ...riderBData],
                [SECONDARY_RIDER_NAME, ...riderAData]
            ],
            colors: {
                [BASE_RIDER_NAME]: '#279989',
                [SECONDARY_RIDER_NAME]: secondaryRiderColor,
            }
        };
    };

    return (
        <div className={CHART_CONTAINER_CLASS_NAME} id={id}>
            <div className="title-container">
                <h3 className="chart-title">{CHART_TITLE}</h3>
            </div>
            <div className="chart-start-balloon">
                <span className="start-text">START</span>
            </div>
            <C3Chart
                // To trigger a refresh of the component, when the state changes.
                key={key}
                data={getData()}
                legend={getLegend()}
                axis={getAxis()}
                point={getPoint()}
                grid={getGrid()}
                tooltip={getTooltip()}
                size={getSize(PLOT_HEIGHT)}
                onrendered={positionElements}
                onresize={positionElements}/>
        </div>
    );
};

AnnualIncomeChart.propTypes = {
    valuesA: PropTypes.object,
    valuesB: PropTypes.object,
    secondaryRiderColor: PropTypes.string,
    showAllYears: PropTypes.bool
};

export default AnnualIncomeChart;