import NumberInput from 'annuity_lab/components/number_input/number_input.js.jsx';

export default class CurrencyInput extends React.Component {
    render() {
        const min = this.props.min ?? '0';
        const max = this.props.max ?? '1000000000000';

        return (
            <NumberInput
                min={min}
                max={max}
                {...this.props}
                prefix="$"
                precision={0} />
        );
    }
}
