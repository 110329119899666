import 'annuity_lab/tools/admin/components/fia_uploader/fia_uploader.css.scss';

import config from 'config/config.js';
import Attachment from 'annuity_lab/tools/admin/components/attachment/attachment.js.jsx';
import Uploader from 'annuity_lab/tools/admin/services/uploader.js';
import ConfirmDialog from 'common/components/dialogs/confirm/confirm.js.jsx';
import LoadingIndicator
    from 'annuity_lab/components/loading_indicator/loading_indicator.js.jsx';
import MessageDialog
    from 'common/components/dialogs/message_dialog/message_dialog.js.jsx';

const SUPPORTED_FILE_TYPES = [
    '.xlsx',
    '.ods',
];

class FiaUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validType: true,
            file: null,
            parseError: null,
            parseWarning: null,
            uploading: false,
            isUploadSuccessful: null,
        };
    }

    isValidFileType(file) {
        if (!file) {
            return true;
        }

        const filename = file.name;
        const fileType = filename.substring(filename.lastIndexOf('.'));

        return SUPPORTED_FILE_TYPES.indexOf(fileType) !== -1;
    }

    onChange() {
        const file = this.input.files[0];
        const valid = this.isValidFileType(file);

        this.setState({
            validType: valid,
            file,
            parseError: null,
        });
    }

    renderWarnings(warnings) {
        return (
            <ul>
                {warnings.map((conflict, id) =>
                    <li key={id}>{conflict}</li>
                )}
            </ul>
        );
    }

    uploadFile(force = false) {
        this.setState({uploading: true});

        Uploader.uploadFiaData(this.state.file, force)
            .then(response => {
                const {error, warning} = response;

                this.setState({
                    uploading: false,
                    parseError: error,
                    parseWarning: warning,
                    isUploadSuccessful: !error && !warning
                });
            });
    }

    render() {
        const {file, validType, isUploadSuccessful,
            parseError, uploading, parseWarning} = this.state;

        return (
            <div className='fia-uploader'>
                <div className='title'>Upload FIA Data File</div>
                {
                    uploading &&
                        <LoadingIndicator loadingText='Uploading...' />
                }
                <Attachment
                    header='View the requirements for uploading a file'
                    linkText='View Guide'
                    linkTarget={`${config.API_URL}/fia/upload/importGuide`}/>
                <Attachment
                    header='View the current version of data used by the application'
                    linkText='Download Data File'
                    linkTarget={`${config.API_URL}/fia/upload/lastDocument`}/>
                <div className='file-input'>
                    <input
                        id='index-file'
                        ref={(input) => {
                            this.input = input;
                        }}
                        type='file'
                        name='indexesData'
                        onChange={() => {
                            this.onChange();
                        }}
                        accept={SUPPORTED_FILE_TYPES}/>
                    <label htmlFor='index-file'>Select New Data File to Upload</label>
                    {
                        file &&
                            <div className='file-info'>
                                <div className='label'>Selected file</div>
                                <div className='file-name'>{file.name}</div>
                            </div>
                    }
                </div>
                {
                    !validType &&
                        <div className='error'>
                            The selected file type is not supported.
                        </div>
                }
                {
                    file && validType &&
                        <button
                            className='upload-button'
                            onClick={() => {
                                this.uploadFile();
                            }}>
                            Upload
                        </button>
                }
                {
                    !!parseError &&
                        <div className='error parser-errors'>
                            { parseError }
                        </div>
                }
                {
                    isUploadSuccessful &&
                        <MessageDialog
                            onRequestClose={() => {
                                this.setState({
                                    isUploadSuccessful: null
                                });
                            }}
                            title='Success'
                            content='Your file has been successfully uploaded.'/>
                }
                {
                    parseWarning && parseWarning.length > 0 &&
                        <ConfirmDialog
                            title='Discrepancies with previous document'
                            content={
                                <div>
                                    <p>
                                        It seems that the values in the new document
                                        do not match the values from the current one.
                                    </p>
                                    {this.renderWarnings(parseWarning)}
                                    <p>Are you sure you want to upload this file?</p>
                                </div>
                            }
                            onRequestClose={() => {
                                this.setState({
                                    parseWarning: null
                                });
                            }}
                            onConfirm={() => {
                                this.uploadFile(true);
                                this.setState({
                                    parseWarning: null
                                });
                            }}/>
                }
            </div>
        );
    }
}

export default FiaUploader;