import 'annuity_lab/tools/lbc/components/chart/grid/grid.css.scss';
import PropTypes from 'prop-types';
export default class Grid extends React.Component {
    static propTypes = {
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        stepCount: PropTypes.number.isRequired,
        format: PropTypes.func.isRequired
    }

    render() {
        var percentStep = 1 / this.props.stepCount,
            valueRange = this.props.max - this.props.min,
            steps = _.range(0, 1, percentStep);

        steps.push(1);

        return (
            <div className="chart-grid">
                {_.map(steps, (step) => this.renderStep(step, valueRange))}
            </div>
        );
    }

    renderStep(stepValue, valueRange) {
        return (
            <div key={stepValue} className="grid-step" style={{bottom: `${stepValue * 100}%`}}>
                <div className="label">{this.props.format(stepValue * valueRange)}</div>
                <div className="line" />
            </div>
        );
    }
}
