/* eslint-disable max-len */
export const WITHDRAWAL_OPTIONS = [
    {
        label: '5%',
        value: 5
    },
    {
        label: '4.5%',
        value: 4.5
    },
];

export const CARRY_FORWARD_OPTIONS = {
    '5': [
        {
            id: 0,
            className: 'carry-forward',
            title: '1% CARRY-FORWARD',
            topText: 'SAVE',
            money: 1250,
            bottomText: 'PER YEAR',
            color: 'dark-grey',
        }, {
            id: 1,
            className: 'more-time',
            title: 'MORE TIME',
            topText: '',
            money: 6250,
            bottomText: 'A YEAR FOR 16 YEARS',
            color: 'dark-red',
            description: 'The client would receive $6,250 a year for 16 years instead of $7,500 a year for 13 years. After the 16 years the cash value would equal 0 and the client would receive $5,000 a year. The 1% less income withdrawal will allow for 3 more years of income above the minimum amount.'
        }, {
            id: 2,
            className: 'more-income',
            title: 'MORE INCOME',
            topText: '',
            money: 9000,
            bottomText: 'A YEAR FOR A 5 YEAR PERIOD',
            color: 'purple',
            description: 'The client would receive $6,250 a year for the first 6 years to save the maximum allowable amount. The additional funds would be spread evenly across the next 5 years to provide $9,000 a year of income. After that period the client would receive $7,500 a year until the cash value equals 0, when they would receive $5,000 a year.'
        }, {
            id: 3,
            className: 'more-flexibility',
            title: 'MORE FLEXIBILITY',
            topText: 'SAVE',
            money: 1250,
            bottomText: 'PER YEAR AS OFTEN AS NEEDED',
            color: 'green',
            description: 'The client would receive $6,250 a year for the first 6 years to build up their carryforward bucket to $7,500. At that point they would receive the original $7,500 a year while also taking an additional withdrawal of $7,500. They would then begin taking $6,250 a year for another 6 years to again build up their carryforward bucket to $7,500 and take a second withdrawal before the cash value equaled 0.'
        }
    ],
    '4.5': [
        {
            id: 0,
            className: 'carry-forward',
            title: '1.5% CARRY-FORWARD',
            topText: 'SAVE',
            money: 1875,
            bottomText: 'PER YEAR',
            color: 'dark-grey',
        }, {
            id: 1,
            className: 'more-time',
            title: 'MORE TIME',
            topText: '',
            money: 5625,
            bottomText: 'A YEAR FOR 18 YEARS',
            color: 'dark-red',
            description: 'The client would receive $5,625 a year for 18 years instead of $7,500 a year for 13 years. After the 18 years the cash value would equal 0 and the client would receive $5,000 a year. The 1.5% less income withdrawal will allow for 5 more years of income above the minimum amount.'
        }, {
            id: 2,
            className: 'more-income',
            title: 'MORE INCOME',
            topText: '',
            money: 9000,
            bottomText: 'A YEAR FOR A 5 YEAR PERIOD',
            color: 'purple',
            description: 'The client would receive $5,625 a year for the first 4 years to save the maximum allowable amount. The additional funds would be spread evenly across the next 5 years to provide $9,000 a year of income. After that period the client would receive $7,500 a year until the cash value equals 0, when they would receive $5,000 a year.'
        }, {
            id: 3,
            className: 'more-flexibility',
            title: 'MORE FLEXIBILITY',
            topText: 'SAVE',
            money: 1875,
            bottomText: 'PER YEAR AS OFTEN AS NEEDED',
            color: 'green',
            description: 'The client would receive $5,625 a year for the first 4 years to build up their carryforward bucket to $7,500. At that point they would receive the original $7,500 a year while also taking an additional withdrawal of $7,500. They would then begin taking $5,625 a year for another 4 years to again build up their carryforward bucket to $7,500 and take a second withdrawal before the cash value equaled 0.'
        }
    ]
};