import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class ComparisonPlusDependencyMessage extends React.Component {
    render() {
        const dependentComparisons = _.filter(this.props.comparisons, comparison => {
            return !!_.includes(comparison.cardIds, this.props.card.id);
        });

        if (dependentComparisons.length === 0) {
            return null;
        }

        return (
            <div>
                The following saved comparisons use this rider
                and will be deleted if you proceed:
                <ul>
                    {_.map(dependentComparisons, (d) => <li key={d.id}>{d.name}</li>)}
                </ul>
            </div>
        );
    }
}

ComparisonPlusDependencyMessage.propTypes = {
    card: PropTypes.object.isRequired,
    comparisons: PropTypes.array.isRequired,
};

function mapStateToProps({lbc}) {
    const lbcComparisonsPlus = _.values(lbc.comparisonsPlus);

    return {
        comparisons: lbcComparisonsPlus
    };
}

export default connect(mapStateToProps)(ComparisonPlusDependencyMessage);
