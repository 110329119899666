import Promise from 'bluebird';

export default function syncMiddleware({getState}) {
    return next => action => {
        if (action.meta && action.meta.sync) {
            const {online, offline} = action.meta.sync;
            const state = getState();
            const callback = state.apiStatus.online
                ? online
                : offline;

            // TODO: error handling?
            // --georgi.ivanov@2016-12-30
            Promise.resolve().then(callback);
        }

        return next(action);
    };
}
