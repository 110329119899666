import 'annuity_lab/components/select/select.css.scss';

import ReactSelect from 'react-select';

const Select = (props) => {
    // Sets custom defaults more appropriate for our application
    const propsWithDefaults = _.defaults({}, props, {
        isClearable: false,
        isSearchable: true,
        openOnFocus: true,
        backspaceRemoves: false,
        matchPos: 'start',
        matchProp: 'label',
        noOptionsMessage: () => 'No matching options',
        autoBlur: true
    });

    const customStyles = {
        option: () => ({}),
        control: () => ({}),
        menuList: () => ({}),
        menu: () => ({}),
        valueContainer: () => ({}),
        indicatorsContainer: () => ({})
    };

    propsWithDefaults.className = [
        'Select',
        props.className || '',
        props.searchable ? 'searchable' : '',
    ].join(' ');

    propsWithDefaults.classNamePrefix = 'Select';

    return <ReactSelect styles={customStyles} {...propsWithDefaults} />;
};

export default Select;