// eslint-disable-next-line max-len
import 'annuity_lab/tools/lbc/components/option_button/option_button.css.scss';

import PropTypes from 'prop-types';

const OptionButton = (props) => {
    const {
        className,
        title,
        topText = '',
        mainText,
        bottomText,
        color = 'dark-grey',
        onClick,
        selected: selectedProp,
        description,
        disabled = false
    } = props;

    const selected = !disabled && selectedProp;

    return (
        <div
            className={`${className} option-button ${selected ? 'selected' : ''} ${color}`}
            onClick={onClick}
            role="button">
            <div className="title">{title}</div>
            <div className="content">
                {topText && (<span className="top-text">{topText}</span>)}
                <span className="main-text">{mainText}</span>
                <span className="bottom-text">{bottomText}</span>
            </div>
            {selected && description && (
                <>
                    <div className="arrow-up"></div>
                    <div className="description">{description}</div>
                </>
            )}
        </div>
    );
};

OptionButton.propTypes = {
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    topText: PropTypes.string,
    mainText: PropTypes.string.isRequired,
    bottomText: PropTypes.string.isRequired,
    description: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
};

export default OptionButton;
