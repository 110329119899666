import 'annuity_lab/tools/imo/components/riders_modeled/riders_modeled.css.scss';

import PropTypes from 'prop-types';

import SmallRiderCard from
    'annuity_lab/tools/imo/components/small_rider_card/small_rider_card.js.jsx';

const RidersModeled = (props) => {
    const {riderA, handleRiderAChange, riderB, handleRiderBChange} = props;

    return (
        <div className="imo-riders-modeled">
            <h2 className="title">Riders Modeled</h2>

            <div className="riders-container">
                <SmallRiderCard rider={riderA} handleChange={handleRiderAChange} isPrimary />
                {riderB && (
                    <SmallRiderCard rider={riderB} handleChange={handleRiderBChange} />
                )}
            </div>
        </div>
    );
};

RidersModeled.propTypes = {
    riderA: PropTypes.object,
    riderB: PropTypes.object,
    handleRiderAChange: PropTypes.func,
    handleRiderBChange: PropTypes.func
};

export default RidersModeled;