import NumberFormat from 'common/services/number_format.js';

import PropTypes from 'prop-types';
export default class HurdleRateDescription extends React.Component {
    static propTypes = {
        card: PropTypes.object.isRequired,
        baseProduct: PropTypes.object.isRequired,
        hurdleRate: PropTypes.number.isRequired,
        deferralPeriod: PropTypes.number.isRequired
    }

    static defaultProps = {
        hurdleRate: 0
    }

    render() {
        const {deferralPeriod, hurdleRate} = this.props;
        const cardName = this.props.card.displayName ||
            this.props.card.riderName;
        const baseProductName = this.props.baseProduct.displayName ||
            this.props.baseProduct.riderName;

        return (
            <div className="hurdle-rate-description">
                <span className="highlighted">
                    The annuity associated with
                    {' '} <strong>Rider {cardName.toUpperCase()}</strong>
                    {' '} needs an annual rate of return of {NumberFormat.percentage(hurdleRate)}
                </span>
                {' '} on the income benefit base
                {' '} for {deferralPeriod + ' year' + (deferralPeriod > 1 ? 's' : '')}
                {' '} to outperform Rider {baseProductName.toUpperCase()}
                {' '} (Rate of return does not factor in fees).
            </div>
        );
    }
}
