import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';
import BASE_ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const initialState = {};

export default function comparisonPlusReducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.COMPARISONS_PLUS_LOADED: {
        result = _.keyBy(action.payload.comparisonsPlus, 'id');
        break;
    }

    case ACTION_TYPES.COMPARISON_PLUS_SAVED: {
        result = _.assign({}, state);
        const comparisonPlus = action.payload.comparisonPlus;

        result[comparisonPlus.id] = comparisonPlus;
        break;
    }

    case ACTION_TYPES.COMPARISON_PLUS_REMOVED: {
        result = _.assign({}, state);
        delete result[action.payload.comparisonPlusId];
        break;
    }

    case ACTION_TYPES.CARD_PLUS_REMOVED: {
        result = _.assign({}, state);
        Object.keys(result).forEach(comparisonPlusId => {
            const comparisonPlus = result[comparisonPlusId];

            if (_.includes(comparisonPlus.cardIds, action.payload.card.id)) {
                delete result[comparisonPlusId];
            }
        });
        break;
    }

    case ACTION_TYPES.CARDS_PLUS_RESET: {
        result = initialState;
        break;
    }

    case BASE_ACTION_TYPES.LOGOUT: {
        result = initialState;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}