import actionTypes from 'annuity_lab/tools/fia/actions/action_types.js';

const initialState = {
    month: null,
    startYear: null,
    endYear: null,
    minYear: null,
    maxYear: null,
    timeFrame: 'All time',
};

export default function periodReducer(state = initialState, action) {
    let result;

    switch (action.type) {
    case actionTypes.PERIOD_UPDATED: {
        result = _.merge({}, state, action.payload.period);
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
