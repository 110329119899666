import Decimal from 'decimal.js';
import NumberFormat from './number_format.js';
import _ from 'lodash';
import CardsUtil from './cards_util';
import CalculatorCommon from './calculator';

class CardsCoreUtil extends CardsUtil {
    isComplete({rollup, interestType, payout}) {
        return !!rollup && !!interestType && !!payout;
    }

    baseBenefit(card, premium, deferralPeriod) {
        if (_.isNil(card.rollup) || !_.inRange(card.rollup, 0, 1)) {
            throw 'Rollup percentage fraction must be between 0 and 1 inclusive';
        }

        if (_.isNil(card.payout) || !_.inRange(card.payout, 0, 1)) {
            throw 'Payout percentage fraction must be between 0 and 1 inclusive';
        }

        return CalculatorCommon
            .calculateBaseBenefit(premium, card.rollup, deferralPeriod, card.interestType);
    }

    annualIncome(card, premium, deferralPeriod) {
        return Decimal.mul(this.baseBenefit(card, premium, deferralPeriod), card.payout);
    }

    baseMadBenefit(card, deferralPeriod) {
        if (_.isNil(card.rollup) || !_.inRange(card.rollup, 0, 1)) {
            throw new Error('Rollup percentage fraction must be between 0 and 1 inclusive');
        }

        if (_.isNil(card.payout) || !_.inRange(card.payout, 0, 1)) {
            throw new Error('Payout percentage fraction must be between 0 and 1 inclusive');
        }

        switch (card.interestType) {
        case 'simple':
            return Decimal.add(1,
                Decimal.mul(
                    deferralPeriod,
                    card.rollup
                )
            );
        case 'compound':
            return Decimal.pow(
                Decimal.add(
                    1,
                    card.rollup
                ),
                deferralPeriod
            );
        default:
            throw new Error('Interest type must be either `simple` or `compound`');
        }
    }

    initialPremium(card, desiredAnnualIncome, deferralPeriod) {
        return Decimal.div(
            desiredAnnualIncome,
            Decimal.mul(
                card.payout,
                this.baseMadBenefit(card, deferralPeriod)
            )
        );
    }

    hurdleRate(card, baseProduct, premium, deferralPeriod) {
        return Decimal.sub(
            Decimal.pow(
                Decimal.div(
                    this.annualIncome(baseProduct, premium, deferralPeriod),
                    Decimal.mul(card.payout, premium)
                ),
                Decimal.div(1, deferralPeriod)
            ),
            1
        );
    }

    calculateHurdleRates(cards, baseProduct, premium, deferralPeriod) {
        return _.reduce(cards, (rates, card, index) => {
            if (!_.isEqual(card, baseProduct)) {
                rates[index] = this.hurdleRate(
                    card,
                    baseProduct,
                    premium,
                    deferralPeriod
                ).toNumber();
            }

            return rates;
        }, {});
    }

    sortByAnnualIncome(cards, money, deferralPeriod) {
        if (!money || !deferralPeriod) {
            return cards;
        }

        return _.clone(cards).sort((a, b) => {
            return this.compareByAnnualIncome(a, b, money, deferralPeriod);
        });
    }

    sortByInitialPremium(cards, money, deferralPeriod) {
        if (!money || !deferralPeriod) {
            return cards;
        }

        return _.clone(cards).sort((a, b) => {
            return this.compareByInitialPremium(a, b, money, deferralPeriod);
        });
    }

    compareByAnnualIncome(a, b, premium, deferralPeriod) {
        var nameComparison = this._compareRiderNames(a.riderName, b.riderName);

        if (!this.isComplete(a) && !this.isComplete(b)) {
            return nameComparison;
        } else if (!this.isComplete(a)) {
            return 1;
        } else if (!this.isComplete(b)) {
            return -1;
        }

        const incomeForA = this.annualIncome(a, premium, deferralPeriod);
        const incomeForB = this.annualIncome(b, premium, deferralPeriod);
        const incomeComparison = incomeForB - incomeForA;

        if (incomeComparison === 0) {
            return nameComparison;
        }

        return incomeComparison;
    }

    compareByInitialPremium(a, b, desiredAnnualIncome, deferralPeriod) {
        var nameComparison = this._compareRiderNames(a.riderName, b.riderName);

        if (!this.isComplete(a) && !this.isComplete(b)) {
            return nameComparison;
        } else if (!this.isComplete(a)) {
            return 1;
        } else if (!this.isComplete(b)) {
            return -1;
        }

        const initialPremiumForA =
            this.initialPremium(a, desiredAnnualIncome, deferralPeriod);
        const initialPremiumForB =
            this.initialPremium(b, desiredAnnualIncome, deferralPeriod);
        const initialPremiumComparison = initialPremiumForA - initialPremiumForB;

        if (initialPremiumComparison === 0) {
            return nameComparison;
        }

        return initialPremiumComparison;
    }

    countCompletedCards(cards) {
        const completedCards = _.filter(cards, (card) => {
            return this.isComplete(card);
        });

        return completedCards.length;
    }

    getStats(card) {
        const rollup = NumberFormat.percentage(card.rollup);
        const interest = _.capitalize(card.interestType);
        const payout = NumberFormat.percentage(card.payout);

        return `${rollup}/${interest}/${payout}`;
    }

    incomeDifference(firstCard, secondCard, premium, deferralPeriod) {
        const firstCardIncome = this.annualIncome(
            firstCard,
            premium,
            deferralPeriod
        );
        const secondCardIncome = this.annualIncome(
            secondCard,
            premium,
            deferralPeriod
        );

        return Decimal.round(firstCardIncome) - Decimal.round(secondCardIncome);
    }

    initialPremiumDifference(firstCard, secondCard, desiredAnnualIncome, deferralPeriod) {
        const firstInitialPremium = this.initialPremium(
            firstCard,
            desiredAnnualIncome,
            deferralPeriod
        );
        const secondInitialPremium = this.initialPremium(
            secondCard,
            desiredAnnualIncome,
            deferralPeriod
        );

        return Decimal.round(firstInitialPremium) - Decimal.round(secondInitialPremium);
    }

    additionalAnnualIncome(card, difference, deferralPeriod) {
        return Decimal.mul(
            Decimal.mul(
                card.payout,
                difference
            ),
            this.baseMadBenefit(card, deferralPeriod)
        );
    }
}

const cardsCoreUtil = new CardsCoreUtil();

export default cardsCoreUtil;
