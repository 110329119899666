import 'annuity_lab/tools/common/components/menu_select/menu_select.css.scss';

import Select from 'annuity_lab/components/select/select.js.jsx';

const MenuSelect = ({options, compareRidersTabLabel, openComparison}) =>  (
    <div className="menu-select tab-select active">
        <Select
            isSearchable={false}
            value={{label: compareRidersTabLabel}}
            placeholder={compareRidersTabLabel}
            options={options}
            onChange={openComparison} />
    </div>
);

export default MenuSelect;