class LocalStorage {
    constructor(prefix, version) {
        this.prefix = prefix;
        this.version = version;
        this.storage = this._getStorage();
        this.set('_version', {version: version});
    }

    _getKey(key) {
        return `${this.prefix}-${this.version}-${key}`;
    }

    _getStorage() {
        try {
            window.localStorage.setItem('storage-test', '1');
            window.localStorage.removeItem('storage-test');
        } catch (e) {
            if (e instanceof DOMException && e.code === 22) {
                // In Private browsing mode, Safari disables LocalStorage. All calls to `setItem`
                // throw a QuotaExceededError.
                return null;
            }
        }

        return window.localStorage;
    }
    get(key) {
        if (!this.storage) {
            return;
        }

        return JSON.parse(this.storage.getItem(this._getKey(key)));
    }

    set(key, object) {
        if (!this.storage) {
            return;
        }
        try {
            this.storage.setItem(this._getKey(key), JSON.stringify(object));
        } catch (e) {
            window.alert('Could not write data to the local storage.');
        }
    }

    unset(key) {
        if (!this.storage) {
            return;
        }
        try {
            this.storage.removeItem(this._getKey(key));
        } catch (e) {
            window.alert('Could not remove item from local storage.');
        }
    }
}

export const LegacyStorage = new LocalStorage('linc', 5);

export default new LocalStorage('linc', 6);
