/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';

import {formatCurrency} from 'annuity_lab/tools/imo/utils/format.js';

const StandardViewTableRows = (props) => {
    const {
        ageAtWithdrawal,
        tableValuesA,
        tableValuesB,
        highlightedYearRow,
        onTableRowClick
    } = props;

    const getClassNameForRow = () =>
        tableValuesB ? 'large' : 'medium';

    const renderRowForYear = (year) => {
        const className = highlightedYearRow === null || highlightedYearRow === year
            ? 'highlighted'
            : undefined;

        return (
            <tr key={year} onClick={onTableRowClick(year)} className={className}>
                <td className="small">
                    <span className="contract-container">{year - ageAtWithdrawal + 1}</span>
                </td>
                <td className="small">
                    <span className="age-container">{year}</span>
                </td>
                <td className={getClassNameForRow()}>
                    <div className="ab-container">
                        <span className="rider-a-text rider-value money-container">
                            {formatCurrency(tableValuesA[year].baseBenefit)}
                        </span>
                        {tableValuesB && (
                            <span className="rider-b-text rider-value money-container">
                                {formatCurrency(tableValuesB[year].baseBenefit)}
                            </span>
                        )}
                    </div>
                </td>
                <td className={getClassNameForRow()}>
                    <div className="ab-container">
                        <span className="rider-a-text rider-value money-container">
                            {formatCurrency(tableValuesA[year].cashValue)}
                        </span>
                        {tableValuesB && (
                            <span className="rider-b-text rider-value money-container">
                                {formatCurrency(tableValuesB[year].cashValue)}
                            </span>
                        )}
                    </div>
                </td>
                <td className={getClassNameForRow()}>
                    <div className="ab-container">
                        <span className="rider-a-text rider-value money-container">
                            {formatCurrency(tableValuesA[year].annualIncome)}
                        </span>
                        {tableValuesB && (
                            <span className="rider-b-text rider-value money-container">
                                {formatCurrency(tableValuesB[year].annualIncome)}
                            </span>
                        )}
                    </div>
                </td>
                <td className={getClassNameForRow()}>
                    <div className="ab-container">
                        <span className="rider-a-text rider-value money-container">
                            {formatCurrency(tableValuesA[year].totalIncome)}
                        </span>
                        {tableValuesB && (
                            <span className="rider-b-text rider-value money-container">
                                {formatCurrency(tableValuesB[year].totalIncome)}
                            </span>
                        )}
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <React.Fragment>
            {_.range(Number(ageAtWithdrawal), Number(ageAtWithdrawal) + 30).map(renderRowForYear)}
        </React.Fragment>
    );
};

StandardViewTableRows.propTypes = {
    ageAtWithdrawal: PropTypes.string,
    tableValuesA: PropTypes.object,
    tableValuesB: PropTypes.object,
    highlightedYearRow: PropTypes.number,
    onTableRowClick: PropTypes.func
};

export default StandardViewTableRows;