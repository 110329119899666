import 'annuity_lab/tools/lbc/components/select_rider/select_rider.css.scss';

import PropTypes from 'prop-types';
import View from 'common/lib/view';
import Disclaimer from 'annuity_lab/components/disclaimer/disclaimer.js.jsx';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';

export default class SelectRider extends View {
    constructor(props) {
        super(props);

        this.handleCoreRidersClick = this.handleCoreRidersClick.bind(this);
        this.handlePlusRidersClick = this.handlePlusRidersClick.bind(this);
    }

    viewEntered() {
        AnnuityLabAnalytics.trackView('Select Rider');
    }

    handleCoreRidersClick() {
        const {updateRiderType, history} = this.props;

        updateRiderType(Constants.RIDER_TYPE_CORE);
        history.push('/lbc/cards');
    }

    handlePlusRidersClick() {
        const {updateRiderType, history} = this.props;

        updateRiderType(Constants.RIDER_TYPE_PLUS);
        history.push('/lbc/cards/plus');
    }

    render() {
        const {errorVisible} = this.props;

        return (
            <div className="select-rider-view">
                <div className="select-rider-content">
                    <h4 className="heading">
                        Please select the type of rider you would like to compare.
                    </h4>
                    <div className="button-container">
                        <button
                            className="btn btn-block"
                            onClick={this.handleCoreRidersClick}>
                            Level Rate Rider
                        </button>

                        <button
                            className="btn btn-block"
                            onClick={this.handlePlusRidersClick}>
                            Enhanced Rate Rider
                        </button>
                    </div>
                    <div>
                        {errorVisible &&
                            <p className="select-rider-error">Select rider cards to compare.</p>}
                    </div>
                </div>
                <Disclaimer urboNumber='VAM-3427AO (2/21)' />
            </div>
        );
    }
}

SelectRider.propTypes = {
    errorVisible: PropTypes.bool,
    updateRiderType: PropTypes.func,
    history: PropTypes.object.isRequired,
};