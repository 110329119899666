import Analytics from 'common/services/analytics/analytics.js';

class AnnuityLabAnalytics extends Analytics {
    setDimension(dimension, value) {
        this.adapter.setDimension(dimension, value);
    }

    /* Login view */

    trackLogin(email) {
        this.adapter.trackEvent('login', 'login', email);
    }

    /* Cards view */

    trackCreateCard(product) {
        this.adapter.trackEvent('cards', 'create-card', product.id);
    }

    trackFillCard(product) {
        this.adapter.trackEvent('cards', 'fill-card', product.id);
    }

    trackEditCard(product, field) {
        this.adapter.trackEvent('cards', 'edit-card', field);
    }

    trackRemoveCard(product) {
        this.adapter.trackEvent('cards', 'remove-card', product.id);
    }

    trackResetCards() {
        this.adapter.trackEvent('cards', 'reset-cards');
    }

    trackEditPremiumOnCardsView(premium) {
        this.adapter.trackEvent('cards', 'edit-premium', premium);
    }

    trackEditDeferralPeriodOnCardsView(period) {
        this.adapter.trackEvent('cards', 'edit-deferral-period', period);
    }

    /* Plus cards view */
    trackCreatePlusCard(product) {
        this.adapter.trackEvent('plus-cards', 'create-plus-card', product.id);
    }

    trackFillPlusCard(product) {
        this.adapter.trackEvent('plus-cards', 'fill-plus-card', product.id);
    }

    trackEditPlusCard(product, field) {
        this.adapter.trackEvent('plus-cards', 'edit-plus-card', field);
    }

    trackRemovePlusCard(product) {
        this.adapter.trackEvent('plus-cards', 'remove-plus-card', product.id);
    }

    trackResetPlusCards() {
        this.adapter.trackEvent('plus-cards', 'reset-plus-cards');
    }

    trackEditPremiumOnPlusCardsView(premium) {
        this.adapter.trackEvent('plus-cards', 'edit-premium', premium);
    }

    /* Comparisons view */

    trackSaveComparison(comparison) {
        this.adapter.trackEvent('comparisons', 'save', comparison.name);
    }

    trackRemoveComparison(comparison) {
        this.adapter.trackEvent('comparisons', 'remove', comparison.name);
    }

    trackStartNewComparison() {
        this.adapter.trackEvent('comparisons', 'start-new');
    }

    trackEditPremiumOnComparisonView(premium) {
        this.adapter.trackEvent('comparisons', 'edit-premium', premium);
    }

    trackEditDeferralPeriodOnComparisonView(period) {
        this.adapter.trackEvent('comparisons', 'edit-deferral-period', period);
    }

    trackChooseRiderOnComparisonChart(index) {
        this.adapter.trackEvent('comparisons', 'choose-rider-on-chart', index);
    }

    /* Plus comparisons view */

    trackSavePlusComparison(comparison) {
        this.adapter.trackEvent('plus-comparisons', 'save', comparison.name);
    }

    trackRemovePlusComparison(comparison) {
        this.adapter.trackEvent('plus-comparisons', 'remove', comparison.name);
    }

    trackStartNewPlusComparison() {
        this.adapter.trackEvent('plus-comparisons', 'start-new');
    }

    /* External Links */

    trackSpousalProtectionClicks(spousalProtectionLink) {
        this.adapter.trackEvent(
            'spousal-protection-page',
            'spousal-protection-quick-quote-button-clicked',
            spousalProtectionLink
        );
    }

    trackIncomePromiseClicks(incomePromiseLink) {
        this.adapter.trackEvent(
            'income-promise-page',
            'income-promise-select-quick-estimator-clicked',
            incomePromiseLink
        );
    }

    trackAnnuityLargeCaseProcess(annuityLargeCaseProcessLink) {
        this.adapter.trackEvent(
            'annuity-large-case-process-page',
            'annuity-large-case-process-button-clicked',
            annuityLargeCaseProcessLink
        );
    }

    /* Competitive Intelligence view */

    trackReportViews(reportId) {
        this.adapter.trackEvent('reports', 'report-viewed', reportId);
    }
}

export default new AnnuityLabAnalytics();
