/* eslint-disable max-len */
import PropTypes from 'prop-types';

import {useEffect} from 'react';
import {connect} from 'react-redux';

import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import NoCardsView from 'annuity_lab/tools/common/components/no_cards/no_cards.js.jsx';
import NewLBCComparisonPlus from 'annuity_lab/tools/lbc/components/new_comparison_plus/new_comparison_plus.js.jsx';
import Constants from 'annuity_lab/tools/lbc/services/constants.js';
import {updateRiderType} from 'annuity_lab/tools/lbc/actions/select_rider.js';
import {requirePlusRider} from 'annuity_lab/tools/lbc/services/routes_navigation_util.js';
/* eslint-enable max-len */

const ComparisonsPlus = (props) => {
    const {riderCardsPlus, riderType, updateRiderType} = props;

    useEffect(() => {
        requirePlusRider(riderType, updateRiderType);
    }, [riderType, updateRiderType]);

    return riderCardsPlus.length >= 2 ?
        <NewLBCComparisonPlus {...props}/> :
        <NoCardsView {...props} onPlusRidersView />;
};

ComparisonsPlus.propTypes = {
    riderCardsPlus: PropTypes.array,
};

function mapStateToProps({lbc}) {
    const {riderCardsPlus, selectRider} = lbc;
    const completedCards = _(riderCardsPlus).values().filter(c =>
        CardsPlusUtil.isComplete(c)).value();

    return {
        riderType: selectRider.riderType,
        riderCardsPlus: completedCards,
        compareButtonText: 'Compare Riders',
        moneyLabel: Constants.CONTEXT_MONEY_LABEL,
    };
}

const mapDispatchToProps = (dispatch) => ({
    updateRiderType: (riderType) => {
        dispatch(updateRiderType(riderType));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonsPlus);
