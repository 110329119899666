import {connect} from 'react-redux';
import {sync} from 'annuity_lab/tools/common/actions/sync.js';

import {loadRiderCards} from 'annuity_lab/tools/common/actions/rider_cards.js';
import {loadRiderCardsPlus} from 'annuity_lab/tools/lbc/actions/rider_cards_plus.js';
import {loadComparisons as loadComparisonsPlus} from
    'annuity_lab/tools/lbc/actions/comparisons_plus.js';
import Sync from 'annuity_lab/tools/common/components/sync/sync.jsx';

function mapStateToProps({apiStatus, lbc}) {
    return {
        online: apiStatus.online,
        isSyncing: lbc.sync.syncing
    };
}

function mapDispatchToProps(dispatch, props) {
    return {
        sync: () => {
            return dispatch(sync()).then(() => {
                dispatch(loadRiderCards());
                dispatch(props.loadComparisons());
                dispatch(loadRiderCardsPlus());
                dispatch(loadComparisonsPlus());
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sync);
