import 'annuity_lab/components/info_balloon/info_balloon.css.scss';
import PropTypes from 'prop-types';

export default class InfoBalloon extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        pointsTo: PropTypes.string.isRequired,
        color: PropTypes.string,
        arrowPosition: PropTypes.oneOf(['top', 'bottom']).isRequired,
        targetIsSVG: PropTypes.bool,
    }

    constructor() {
        super();

        this.state = {
            mounted: false
        };
    }

    updateArrowPosition() {
        const selector = this.props.pointsTo;
        const element = document.querySelector(selector);
        const balloon = ReactDOM.findDOMNode(this.balloon);
        const arrow = ReactDOM.findDOMNode(this.arrow);

        if (!element) {
            return;
        }

        const elementOffset = element.getBoundingClientRect();
        const balloonOffset = balloon.getBoundingClientRect();
        const elementWidth = this.props.targetIsSVG ? elementOffset.width : element.offsetWidth;
        const arrowWidth = arrow.offsetWidth;
        const arrowLeft = elementOffset.left - balloonOffset.left -
                        arrowWidth / 2 + elementWidth / 2;

        this.oldOffset = elementOffset;
        this.oldWidth = elementWidth;

        arrow.style.left = `${arrowLeft.toFixed()}px`;
    }

    shouldUpdateArrowPosition() {
        const selector = this.props.pointsTo;
        const element = document.querySelector(selector);

        if (!element) {
            return false;
        }

        const elementOffset = element.getBoundingClientRect();
        const elementWidth = element.offsetWidth;

        if (this.oldOffset) {
            return elementOffset.left !== this.oldOffset.left ||
                   elementOffset.top !== this.oldOffset.top ||
                   elementWidth !== this.oldWidth;
        }
    }

    componentDidMount() {
        (() => {
            this.setState({mounted: true});
        })();

        this.updateInterval = window.setInterval(() => {
            if (this.shouldUpdateArrowPosition()) {
                this.forceUpdate();
            }
        }, 500);
    }

    componentDidUpdate() {
        if (this.state.mounted) {
            this.updateArrowPosition();
        }
    }

    componentWillUnmount() {
        this.setState({mounted: false});

        if (this.updateInterval) {
            window.clearInterval(this.updateInterval);
            this.updateInterval = 0;
        }
    }

    render() {
        const balloonStyles = {};
        const arrowStyles = {};

        if (this.props.color) {
            balloonStyles.backgroundColor = this.props.color;
            if (this.props.arrowPosition === 'top') {
                arrowStyles.borderBottomColor = this.props.color;
            } else {
                arrowStyles.borderTopColor = this.props.color;
            }
        }

        return (
            <div ref={(infoBalloon) => {
                this.balloon = infoBalloon;
            }}
            className={`info-balloon ${this.props.className}`}
            style={balloonStyles}>
                {this.props.children}
                <div ref={(infoBalloon) => {
                    this.arrow = infoBalloon;
                }}
                className={`arrow ${this.props.arrowPosition}`}
                style={arrowStyles} />
            </div>
        );
    }
}
