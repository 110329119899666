import 'annuity_lab/tools/imo/components/carry_forward_view/carry_forward_view.css.scss';

import {useMemo} from 'react';
import PropTypes from 'prop-types';

import StandardViewRiderCard from
    'annuity_lab/tools/imo/components/standard_view_rider_card/standard_view_rider_card.js.jsx';
import CarryForwardInputs from
    'annuity_lab/tools/imo/components/carry_forward_inputs/carry_forward_inputs.js.jsx';
import CarryForwardViewTable from
    'annuity_lab/tools/imo/components/carry_forward_view_table/carry_forward_view_table.js.jsx';
import CarryForwardViewChart from
    'annuity_lab/tools/imo/components/carry_forward_view_chart/carry_forward_view_chart.js.jsx';
import Calculator from 'annuity_lab/tools/imo/services/calculator.js';

const CarryForwardView = ({model, handleCarryForwardChange}) => {
    const {riderA, clientInformation, carryForward} = model;
    const {ageAtWithdrawal} = clientInformation;

    const isCarryForwardFilled = useMemo(() => Object.values(carryForward)
        .every(e => e !== ''),
    [model]);

    const standardTableValues = useMemo(() => Calculator
        .calculateStandardTableValues(riderA, clientInformation),
    [model]);

    const tableValues = useMemo(() => {
        if (isCarryForwardFilled) {
            return Calculator.calculateCarryForwardTableValues(
                riderA,
                clientInformation,
                carryForward
            );
        }

        return null;
    }, [model, isCarryForwardFilled]);

    return (
        <div className="imo-carry-forward-view">
            <div className="inputs-container">
                <CarryForwardInputs
                    model={model}
                    handleCarryForwardChange={handleCarryForwardChange}/>
            </div>
            <div className="cards-summary">
                <StandardViewRiderCard
                    rider={riderA}
                    clientInformation={clientInformation}
                    tableValues={standardTableValues}
                    withTitle
                    isPrimary />
            </div>
            {isCarryForwardFilled && (
                <>
                    <CarryForwardViewTable
                        ageAtWithdrawal={ageAtWithdrawal}
                        tableValues={tableValues} />
                    <CarryForwardViewChart
                        tableValues={tableValues}/>
                </>
            )}
        </div>
    );
};

CarryForwardView.propTypes = {
    model: PropTypes.object,
    handleCarryForwardChange: PropTypes.func
};

export default CarryForwardView;