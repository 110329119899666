import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';
import BASE_ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const initialState = {};

export default function reducer(state = initialState, action) {
    let result;

    switch(action.type) {
    case ACTION_TYPES.NEW_COMPARISON_PLUS_SET: {
        result = _.assign({}, state, action.payload);
        break;
    }

    case ACTION_TYPES.COMPARISON_PLUS_SAVED: {
        result = initialState;
        break;
    }

    case BASE_ACTION_TYPES.LOGOUT: {
        result = initialState;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}