import 'annuity_lab/tools/imo/components/imo_app/imo_app.css.scss';

import {useEffect, useState} from 'react';

import RecentToolAPI from 'annuity_lab/services/data/api/recent_tool.js';
import ToolLayout from 'annuity_lab/components/tool_layout/tool_layout.js.jsx';
import Disclaimer from 'annuity_lab/components/disclaimer/disclaimer.js.jsx';
import IMORoutes from 'annuity_lab/tools/imo/routes.js.jsx';
import Sync from 'annuity_lab/tools/imo/containers/sync/sync.js.jsx';
import Footer from 'annuity_lab/tools/lbc/containers/footer/footer.js.jsx';
import ModelControls from 'annuity_lab/tools/imo/containers/model_controls/model_controls.js.jsx';
import DialogHost from 'annuity_lab/tools/lbc/components/dialog_host/dialog_host.js.jsx';
import ShareDialogs from 'annuity_lab/tools/common/containers/share_dialogs/share_dialogs.js.jsx';

const disclaimerContents = (<>
    <p>For Financial Professional Use Only - Not For Use With Clients</p>
    <p>Investing Involves Market Risk, Including the Possible Loss of Principal
        &nbsp;&nbsp;&nbsp;VAM-3378AO (10/20)</p>
</>);
const ImoApp = ({user, location, history}) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user) {
            const recentToolApi = new RecentToolAPI(user.id);

            recentToolApi.set(location.pathname);
        }
    }, [user, location]);

    let FooterChildren, id;

    if (location.pathname.startsWith('/imo/models')) {
        FooterChildren = ModelControls;

        if (!location.pathname.startsWith('/imo/models/new')) {
            id = location.pathname.split('/')[3];
        }
    }

    // Tool layout wrapper gets rendered on all /imo routes.
    // We need to create our own match object to pass to the footer children which
    // are rendered in the ToolLayout component
    const match = {
        params: {
            id
        }
    };

    return (
        <React.Fragment>
            {!isLoading && (
                <ToolLayout
                    user={user}
                    headerTitle='Income Modeler'
                    footer={(
                        <Footer>
                            {FooterChildren && (
                                <FooterChildren
                                    history={history}
                                    location={location}
                                    match={match}/>
                            )}
                        </Footer>
                    )}>
                    <React.Fragment>
                        <div className='imo-app'>
                            <IMORoutes />
                            <Disclaimer contents={disclaimerContents} />
                        </div>
                        <DialogHost />
                    </React.Fragment>
                </ToolLayout>
            )}
            <Sync
                onSyncCompleted={() => setIsLoading(false)}/>
            <ShareDialogs entity="model"/>
        </React.Fragment>
    );
};

export default ImoApp;