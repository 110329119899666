class NumberFormat {
    static percentage(value, decimalPoints = 2) {
        return (value * 100).toFixed(decimalPoints) + '%';
    }

    static currency(value, decimalPoints = 0) {
        var [wholePartString, decimalPartString] = value.toFixed(decimalPoints).split(/[.,]/);

        wholePartString = wholePartString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        decimalPartString = decimalPartString ? '.' + decimalPartString : '';

        return '$' + wholePartString + decimalPartString;
    }

    static alphabetical(value) {
        var symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            symbolCount = symbols.length;

        if (value < symbolCount) {
            return symbols[_.toInteger(value % symbolCount)];
        }

        return this.alphabetical(_.toInteger(value / symbolCount) - 1) +
               symbols[_.toInteger(value % symbolCount)];
    }

    static stringifyRationalNumber(number, precision) {
        const [whole, rational] = number.toString().split('.');

        return `${whole}.${rational
            ? rational.slice(0, precision)
            : '00'}`;
    }
}

module.exports = NumberFormat;
