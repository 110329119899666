import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';
import ProductContextPlusAPI from 'annuity_lab/tools/lbc/services/data/api/product_context_plus.js';

export function updateProductContextPlus(money) {
    return (dispatch, getState) => {
        const currentUser = getState().auth.user;

        ProductContextPlusAPI.update({userId: currentUser.id}, {money});

        dispatch({
            type: ACTION_TYPES.PRODUCT_CONTEXT_PLUS_SET,
            payload: {money},
        });
    };
}
