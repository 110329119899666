import SyncAPI from 'annuity_lab/tools/lbc/services/nwide/sync.js';
import MigrationsAPI from 'annuity_lab/services/data/api/migrations.js';
import LocalCardsApi from 'annuity_lab/tools/lbc/services/data/api/rider_cards.js';
import LocalCardsPlusApi from 'annuity_lab/tools/lbc/services/data/api/rider_cards_plus.js';
import NwideCardsApi from 'annuity_lab/tools/lbc/services/nwide/rider_cards.js';
import NwideCardsPlusApi from 'annuity_lab/tools/lbc/services/nwide/rider_cards_plus.js';
import LocalComparisonsApi from 'annuity_lab/tools/lbc/services/data/api/comparisons.js';
import LocalComparisonsPlusApi from 'annuity_lab/tools/lbc/services/data/api/comparisons_plus.js';
import LocalModelsApi from 'annuity_lab/tools/imo/services/data/api/models.js';

import NwideComparisonsApi from 'common/services/lbc/nwide/comparisons.js';
import NwideComparisonsPlusApi from 'common/services/lbc/nwide/comparisons_plus.js';
import NwideModelsApi from 'common/services/imo/nwide/models.js';
import Promise from 'bluebird';

import ACTIONS from 'annuity_lab/tools/lbc/actions/action_types.js';

export function sync() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const migrations = MigrationsAPI.find({userId});
        const online = getState().apiStatus.online;

        if (!online) {
            return Promise.resolve().then(() => {
                return dispatch({
                    type: ACTIONS.SYNC_COMPLETED,
                    payload: {},
                });
            });
        }

        dispatch({
            type: ACTIONS.SYNC_STARTED,
            payload: {},
        });

        return SyncAPI.sync(userId, migrations)
            .then(() => {
                MigrationsAPI.remove({userId});

                return NwideCardsApi.loadCards(userId);
            })
            .then(cards => {
                LocalCardsApi.remove({userId});
                LocalComparisonsApi.remove({userId});

                // TODO: bulk insert? --georgi.ivanov@2017-01-02
                cards.forEach(card => {
                    LocalCardsApi.create(card);
                });

                return NwideComparisonsApi.loadComparisons(userId);
            })
            .then(comparisons => {
                // TODO: bulk insert? --georgi.ivanov@2017-01-02
                comparisons.forEach(comparison => {
                    LocalComparisonsApi.create(comparison);
                });
            })
            .then(() => {
                return NwideCardsPlusApi.loadCards(userId);
            })
            .then((plusCards) => {
                LocalCardsPlusApi.remove({userId});
                LocalComparisonsPlusApi.remove({userId});

                plusCards.forEach(card => {
                    LocalCardsPlusApi.create(card);
                });

                return NwideComparisonsPlusApi.loadComparisons(userId);
            })
            .then(comparisonsPlus => {
                comparisonsPlus.forEach(comparison => {
                    LocalComparisonsPlusApi.create(comparison);
                });
            })
            .then(() => NwideModelsApi.loadModels(userId))
            .then((models) => {
                LocalModelsApi.remove({userId});

                models.forEach(model => {
                    LocalModelsApi.create(model);
                });
            })
            .then(() => {
                return dispatch({
                    type: ACTIONS.SYNC_COMPLETED,
                    payload: {},
                });
            })
            .catch(() => {
                return dispatch({
                    type: ACTIONS.SYNC_COMPLETED,
                    payload: {
                        error: true,
                    }
                });
            });
    };
}
