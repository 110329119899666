import 'annuity_lab/tools/imo/components/small_rider_card/small_rider_card.css.scss';

import {useState} from 'react';

import RiderDetails from 'annuity_lab/tools/imo/components/rider_details/rider_details.js.jsx';
import EditRiderModal from
    'annuity_lab/tools/imo/components/edit_rider_modal/edit_rider_modal.js.jsx';

const SmallRiderCard = (props) => {
    const {rider, handleChange, isPrimary} = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={`imo-small-rider-card ${isPrimary ? 'primary' : ''}`}>

            <RiderDetails rider={rider} isPrimary={isPrimary} />

            <button className="edit-card-button" type="button" onClick={openModal}>
                Edit
            </button>

            {isModalOpen && (
                <EditRiderModal
                    handleClose={closeModal}
                    isPrimary={isPrimary}
                    rider={rider}
                    handleChange={handleChange}/>
            )}
        </div>
    );
};

export default SmallRiderCard;