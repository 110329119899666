import {combineReducers} from 'redux';
import sync from 'annuity_lab/tools/lbc/reducers/sync.js';

import newModel from 'annuity_lab/tools/imo/reducers/new_model.js';
import models from 'annuity_lab/tools/imo/reducers/models.js';

const reducer = combineReducers({
    newModel,
    models,
    sync
});

export default reducer;