export default {
    entityTypes: {
        CARDS: 'cards',
        CARDS_PLUS: 'cards_plus',
        COMPARISONS: 'comparisons',
        COMPARISONS_PLUS: 'comparisons_plus',
        MODELS: 'models'
    },

    operations: {
        CREATE: 'create',
        UPDATE: 'update',
        DELETE: 'delete',
    },
};
