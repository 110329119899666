import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {updateNewModel} from 'annuity_lab/tools/imo/actions/new_model.js';
import NewModelForm from 'annuity_lab/tools/imo/components/new_model_form/new_model_form.js.jsx';

const mapStateToProps = ({imo}) => ({
    clientInformation: imo.newModel.clientInformation
});

const mapDispatchToProps = (dispatch) => ({
    updateNewModel: (newModel) => {
        dispatch(updateNewModel(newModel));
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewModelForm));