import keyMirror from 'common/services/key_mirror.js';
import {TOOLS} from 'common/domain/tools';

export default keyMirror([
    'PRODUCT_CONTEXT_SET',
    'COMPARISONS_LOADED',
    'COMPARISON_SAVED',
    'COMPARISON_REMOVED',
    'SYNC_STARTED',
    'SYNC_COMPLETED',
    'SHARE_COMPARISON_IN_PROGRESS',
    'SHARE_COMPARISON_REQUESTED',
    'SHARE_COMPARISON_COMPLETED',
    'SHARE_COMPARISON_CANCELED',
    'SHARE_COMPARISON_STATUS_SEEN',
], TOOLS.MAKE_A_DIFFERENCE.toUpperCase());
