import 'annuity_lab/styles/modal_dialog.css.scss';
import 'annuity_lab/tools/imo/components/edit_rider_modal/edit_rider_modal.css.scss';

import Modal from 'react-modal';
import {useState} from 'react';

import RiderDetails from 'annuity_lab/tools/imo/components/rider_details/rider_details.js.jsx';
import RiderCardInputs from
    'annuity_lab/tools/imo/components/rider_card_inputs/rider_card_inputs.js.jsx';
import {useClassState} from 'annuity_lab/hooks/useClassState.js';

const body = document.querySelector('body');

const EditRiderModal = ({rider, handleChange, isPrimary, handleClose}) => {
    const [riderState, setRiderState] = useClassState(rider);
    const [error, setError] = useState('');
    const [emptyFields, setEmptyFields] = useState([]);

    const handleSubmit = () => {
        const emptyFields = Object.keys(riderState).filter((key) =>
            !riderState[key] ||
            (key !== 'assumedReturnRate' && Number(riderState[key]) === 0));

        if (emptyFields.length > 0) {
            setError('All rider fields must be filled');
            setEmptyFields(emptyFields);

            return;
        }

        setError('');
        setEmptyFields([]);
        handleChange(riderState);
        handleClose();
    };

    return (
        <Modal
            isOpen
            appElement={body}
            className="modal-dialog edit-rider-modal panel"
            overlayClassName="modal-dialog-overlay">

            <div className={`header ${isPrimary ? 'primary' : ''}`}>

                <RiderDetails rider={riderState} isPrimary={isPrimary} />

                <button
                    type="button"
                    className="close-button"
                    onClick={handleClose} />
            </div>

            <div className="body">
                <RiderCardInputs
                    rider={riderState}
                    handleChange={setRiderState}
                    erroredFields={emptyFields}
                    withoutDropdown/>
            </div>

            <div className="footer">
                {error && (<p className="error">{error}</p>)}
                <button className="update-rider-button" type="button" onClick={handleSubmit}>
                    Update Rider
                </button>
            </div>

        </Modal>
    );
};

export default EditRiderModal;
