import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';

export default class DialogHost extends React.Component {
    componentDidMount() {
        ModalDialogs.registerHost(() => this.forceUpdate());
    }

    componentWillUnmount() {
        ModalDialogs.unregisterHost();
    }

    render() {
        var dialog = ModalDialogs.currentDialog;

        if (!dialog) {
            return null;
        }

        return <dialog.klass {...dialog.props} />;
    }
}
