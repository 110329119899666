import _ from 'lodash';

function nextStr(str) {
    const length = str.length;

    let c = str.charAt(length - 1);

    if(c === 'z') {
        return length > 1 ? nextStr(str.substring(0, length - 1)) + 'a' : 'aa';
    }

    return str.substring(0, length - 1) + String.fromCharCode(c.charCodeAt(0) + 1);
}

class CardsUtil {
    generateRiderName(existing) {
        if (!existing || !existing.length) {
            return 'a';
        }

        const latest = _.maxBy(existing, 'createdAt');
        const lastRiderName = latest.riderName;

        return nextStr(lastRiderName);
    }

    _compareRiderNames(a, b) {
        if (a.length < b.length) {
            return -1;
        }

        if (a.length > b.length) {
            return 1;
        }

        if (a < b) {
            return -1;
        }

        if (a > b) {
            return 1;
        }

        return 0;
    }

    generateDisplayName(card, withIndex) {
        if (!withIndex || !card.nameSequenceNumber) {
            return card.riderName;
        }

        return `${card.riderName} (${card.nameSequenceNumber})`;
    }

    generateCardsDisplayNames(riderCards) {
        return _
            .chain(riderCards)
            .groupBy('riderName')
            .mapValues(cards => {
                const withIndex = cards.length > 1;

                return _.map(cards, (card) => {
                    const displayName = this.generateDisplayName(card, withIndex);

                    return _.assign({}, card, {displayName});
                });
            })
            .values()
            .flatten()
            .value();
    }
}

export default CardsUtil;
