import LocalModelsApi from 'annuity_lab/tools/imo/services/data/api/models.js';
import NwideModelsApi from 'common/services/imo/nwide/models.js';
import DataChangeRegistry from 'annuity_lab/services/data_change_registry.js';
import MigrationConsts from 'annuity_lab/tools/lbc/services/migration_consts.js';
import ACTION_TYPES from 'annuity_lab/tools/imo/actions/action_types.js';
import {transformFEModelToBEModel} from 'annuity_lab/tools/imo/utils/transform.js';

const loadModels = () => {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        const models = LocalModelsApi.find({userId});

        dispatch({
            type: ACTION_TYPES.MODELS_LOADED,
            payload: {models}
        });
    };
};

const saveModel = (template) => {
    return (dispatch, getState) => {
        const templateClone = _.clone(template);

        const userId = getState().auth.user.id;

        templateClone.userId = userId;
        const model = LocalModelsApi.create(templateClone);

        // TODO Maybe track analytics

        dispatch({
            type: ACTION_TYPES.MODEL_SAVED,
            payload: {model},
            meta: {
                sync: {
                    online: () => {
                        return NwideModelsApi.createModel(userId, model);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.MODELS,
                            entity: transformFEModelToBEModel(model),
                            operation: MigrationConsts.operations.CREATE,
                            userId,
                        });
                    }
                },

                transition: (prevState, nextState, action) => {
                    const standardOrCarryforward = window.location.hash.split('/')[4];

                    return {
                        pathname: `/imo/models/${action.payload.model.id}/${standardOrCarryforward}`
                    };
                }
            }
        });
    };
};

const removeModel = (modelId) => {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        const removed = LocalModelsApi.remove({id: modelId})[0];

        // TODO Maybe track analytics

        dispatch({
            type: ACTION_TYPES.MODEL_REMOVED,
            payload: {modelId},
            meta: {
                sync: {
                    online: () => {
                        return NwideModelsApi.removeModel(userId, modelId);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.MODELS,
                            entity: transformFEModelToBEModel(removed),
                            operation: MigrationConsts.operations.DELETE,
                            userId
                        });
                    }
                },
                transition: () => ({pathname: '/imo/new'})
            }
        });
    };
};

export {
    loadModels,
    saveModel,
    removeModel
};