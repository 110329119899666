import _ from 'lodash';
import Decimal from 'decimal.js';

import NumberFormat from '../../../../common/services/number_format.js';
import CardsUtil from '../../../../common/services/cards_util.js';
import CalculatorCommon from '../../../../common/services/calculator.js';

class CardsPlusUtil extends CardsUtil {
    generateEmptyWithdrawalValues() {
        const EMPTY_WITHDRAW_VALUES_ROW = {
            rangeStart: null,
            rangeEnd: null,
            singlePositive: null,
            singleNegative: null,
            jointPositive: null,
            jointNegative: null,
        };

        const result = [];

        for (let i = 1; i <= 6; ++i) {
            const current = {};

            Object.assign(current, EMPTY_WITHDRAW_VALUES_ROW);
            current.order = i;

            result.push(current);
        }

        return result;
    }

    isWithdrawalValueLineEmpty(withdrawalValueLine) {
        const relevantFields = Object.values(_.pick(withdrawalValueLine, [
            'singlePositive',
            'singleNegative',
            'jointPositive',
            'jointNegative',
            'rangeStart',
            'rangeEnd'
        ]));

        return relevantFields.every(f => _.isNil(f));
    }

    isWithdrawalValuesEmpty(withdrawalValues) {
        return withdrawalValues.every(wv => this.isWithdrawalValueLineEmpty(wv));
    }

    isComplete({rollup, rollupYears, interestType, withdrawalValues}) {
        return !_.isNil(rollup) &&
            !_.isNil(rollupYears) &&
            !_.isNil(interestType) &&
            !this.isWithdrawalValuesEmpty(withdrawalValues);
    }

    sortByBaseBenefit(cards, premium) {
        return _.clone(cards).sort((a, b) => {
            return this.compareByBaseBenefit(a, b, premium);
        });
    }

    sortByName(cards) {
        return _.clone(cards)
            .sort((a, b) => this._compareRiderNames(a.displayName, b.displayName));
    }

    getPayoutAbove0(card, ageAtWithdrawal, jointRiders) {
        const relevantWithdrawalLine = this.getPayoutPercentageLine(
            card.withdrawalValues, ageAtWithdrawal
        );

        let payoutAbove0 = '';

        if (jointRiders === false) {
            payoutAbove0 = relevantWithdrawalLine.singlePositive;
        } else if (jointRiders === true) {
            payoutAbove0 = relevantWithdrawalLine.jointPositive;
        }

        return payoutAbove0;
    }

    getPayoutEqual0(card, ageAtWithdrawal, jointRiders) {
        const relevantWithdrawalLine = this.getPayoutPercentageLine(
            card.withdrawalValues, ageAtWithdrawal
        );

        let payoutEqual0 = '';

        if (jointRiders === false) {
            payoutEqual0 = relevantWithdrawalLine.singleNegative;
        } else if (jointRiders === true) {
            payoutEqual0 = relevantWithdrawalLine.jointNegative;
        }

        return payoutEqual0;
    }

    getRollup(card) {
        const rollup = NumberFormat.percentage(card.rollup);

        return rollup;
    }

    compareByBaseBenefit(a, b, premium) {
        const nameComparison = this._compareRiderNames(a.riderName, b.riderName);

        if (!this.isComplete(a) && !this.isComplete(b)) {
            return nameComparison;
        } else if (!this.isComplete(a)) {
            return 1;
        } else if (!this.isComplete(b)) {
            return -1;
        }

        const baseBenefitA = this.baseBenefit(a, premium);
        const baseBenefitB = this.baseBenefit(b, premium);

        const baseBenefitDifference = baseBenefitB - baseBenefitA;

        if (baseBenefitDifference === 0) {
            return nameComparison;
        }

        return baseBenefitDifference;
    }

    baseBenefit(card, premium) {
        if (_.isNil(card.rollup) || !_.inRange(card.rollup, 0, 1)) {
            throw 'Rollup percentage fraction must be between 0 and 1 inclusive';
        }

        return CalculatorCommon
            .calculateBaseBenefit(premium, card.rollup, card.rollupYears, card.interestType);
    }

    getPayoutPercentageLine(withdrawalValues, ageAtWithdrawal) {
        const nonEmptyWithdrawalLines = withdrawalValues.filter(wv => wv.rangeStart !== null);
        const sortedWithdrawalLines = nonEmptyWithdrawalLines
            .sort((a, b) => a.rangeStart - b.rangeStart);

        const differenceBetweenWithdrawalLinesAndAgeAtWithdrawal = sortedWithdrawalLines
            .map(withdrawalValue => {
                const {rangeStart, rangeEnd} = withdrawalValue;

                const differenceFromStart = Math.abs(rangeStart - ageAtWithdrawal);
                // range end could be missing
                const differenceFromEnd = Math.abs(
                    rangeEnd ? rangeEnd - ageAtWithdrawal : Number.MAX_SAFE_INTEGER);

                const smallestDifference = Math.min(differenceFromStart, differenceFromEnd);

                return smallestDifference;
            });

        const minDifference = Math.min(...differenceBetweenWithdrawalLinesAndAgeAtWithdrawal);
        const minDifferenceIndex = differenceBetweenWithdrawalLinesAndAgeAtWithdrawal
            .indexOf(minDifference);

        const relevantWithdrawalLine = sortedWithdrawalLines[minDifferenceIndex];

        return relevantWithdrawalLine;
    }

    canCompare(
        baseWithdrawalValues,
        secondaryWithdrawalValues,
        ageAtWithdrawal,
        jointRidersSelected
    ) {
        const basePayoutPercentageLine = this.getPayoutPercentageLine(
            baseWithdrawalValues,
            ageAtWithdrawal
        );
        const secondaryPayoutPercentageLine = this.getPayoutPercentageLine(
            secondaryWithdrawalValues,
            ageAtWithdrawal
        );

        const {
            jointPositive: baseJointPositive,
            singlePositive: baseSinglePositive
        } = basePayoutPercentageLine;

        const {
            jointPositive: secondaryJointPositive,
            singlePositive: secondarySinglePositive
        } = secondaryPayoutPercentageLine;

        const baseCanCompareJoint = baseJointPositive !== null;
        const baseCanCompareSingle = baseSinglePositive !== null;
        const secondaryCanCompareJoint = secondaryJointPositive !== null;
        const secondaryCanCompareSingle = secondarySinglePositive !== null;

        const canCompareJoint = baseCanCompareJoint && secondaryCanCompareJoint;
        const canCompareSingle = baseCanCompareSingle && secondaryCanCompareSingle;

        return jointRidersSelected ? canCompareJoint : canCompareSingle;
    }

    // For LBC tool we assume that the base benefit is more than the cash value
    // after the deferral period, because the assumedReturnRate is 0 there
    calculateWithdrawalInfo(card, premium, currentAge, ageAtWithdrawal, jointRiders = false) {
        const rollupYears = Decimal.sub(ageAtWithdrawal, currentAge);
        const modifiedCard = _.clone(card);

        modifiedCard.rollupYears = Number(rollupYears);
        const baseBenefit = this.baseBenefit(modifiedCard, premium);

        const payoutPercentageLine =
            this.getPayoutPercentageLine(modifiedCard.withdrawalValues, ageAtWithdrawal);

        let payoutPercentagePositive, payoutPercentageNegative;

        if (jointRiders) {
            payoutPercentagePositive = payoutPercentageLine.jointPositive;
            payoutPercentageNegative = payoutPercentageLine.jointNegative;
        } else {
            payoutPercentagePositive = payoutPercentageLine.singlePositive;
            payoutPercentageNegative = payoutPercentageLine.singleNegative;
        }

        const moneyPerYearPositive = Decimal.mul(
            Decimal.div(payoutPercentagePositive, 100),
            baseBenefit
        );
        const moneyPerYearNegative = Decimal.mul(
            Decimal.div(payoutPercentageNegative, 100),
            baseBenefit
        );

        const moneyPerMonthPositive = Decimal.div(moneyPerYearPositive, 12);
        const moneyPerMonthNegative = Decimal.div(moneyPerYearNegative, 12);

        const ageMoneyPositive = Decimal.add(
            Decimal.div(premium, moneyPerYearPositive),
            ageAtWithdrawal
        ).ceil();

        return {
            ageMoneyPositive,
            moneyPerMonthPositive,
            moneyPerMonthNegative
        };
    }

    calculateNeededPremiumIncrease(baseCard, targetCard, premium) {
        const baseCardBenefit = this.baseBenefit(baseCard, premium);

        switch(targetCard.interestType) {
        case 'simple':
            return Decimal.sub(
                Decimal.div(
                    baseCardBenefit,
                    Decimal.add(
                        1,
                        Decimal.mul(
                            targetCard.rollup,
                            targetCard.rollupYears
                        )
                    )
                ),
                premium
            );
        case 'compound':
            return Decimal.sub(
                Decimal.div(
                    baseCardBenefit,
                    Decimal.pow(
                        Decimal.add(1, targetCard.rollup),
                        targetCard.rollupYears
                    )
                ),
                premium
            );
        default:
            throw 'Interest type must be either `simple` or `compound`';
        }
    }

    countCompletedCards(cards) {
        const completedCards = _.filter(cards, (card) => {
            return this.isComplete(card);
        });

        return completedCards.length;
    }
}

const cardsPlusUtil = new CardsPlusUtil();

export default cardsPlusUtil;