import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';
import NewComparisonPlusAPI from 'annuity_lab/tools/lbc/services/data/api/new_comparison_plus.js';

export function updateNewComparisonPlus(newComparisonPlus) {
    return (dispatch, getState) => {
        const currentUser = getState().auth.user;

        NewComparisonPlusAPI.update(
            {userId: currentUser.id},
            newComparisonPlus);
        dispatch({
            type: ACTION_TYPES.NEW_COMPARISON_PLUS_SET,
            payload: newComparisonPlus,
        });
    };
}