import 'annuity_lab/tools/imo/components/riders_to_model/riders_to_model.css.scss';

import RiderCard from 'annuity_lab/tools/imo/components/rider_card/rider_card.js.jsx';

const RidersToModel = (props) => {
    const {
        riderA,
        handleRiderAChange,
        riderAErroredFields,
        riderB,
        handleRiderBChange,
        riderBErroredFields,
        clientInformation} = props;

    return (
        <div className="imo-riders-to-model">
            <h3 className="title">Enter Riders to Model</h3>

            <div className="riders-container">
                <RiderCard
                    rider={riderA}
                    handleChange={handleRiderAChange}
                    erroredFields={riderAErroredFields}
                    clientInformation={clientInformation}
                    isPrimary />
                <RiderCard
                    rider={riderB}
                    handleChange={handleRiderBChange}
                    erroredFields={riderBErroredFields}
                    clientInformation={clientInformation}/>
            </div>
        </div>
    );
};

export default RidersToModel;