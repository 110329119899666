import {combineReducers} from 'redux';
import riderCards from 'annuity_lab/tools/common/reducers/rider_cards.js';
import share from 'annuity_lab/tools/common/reducers/share.js';
import contacts from 'annuity_lab/tools/common/reducers/contacts.js';

const reducer = combineReducers({
    riderCards,
    share,
    contacts
});

export default reducer;
