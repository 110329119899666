export default class ScrollFix {
    static fix() {
        // XXX: HACK: UGLY: BAD: Ugly iOS fix for scrolling issue:
        // When the scrolling container becomes non-scrollable the `scrollTop`
        // does not properly reset. This results in the content being cut off
        // with no way to scroll to correct this.
        window.setTimeout(() => {
            var viewContent = document.querySelector('.view-content');

            if (viewContent && viewContent.scrollHeight === viewContent.offsetHeight) {
                viewContent.scrollTop = 0;
            }
        }, 150);
    }
}
