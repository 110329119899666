import 'annuity_lab/components/loading_indicator/loading_indicator.css.scss';
import PropTypes from 'prop-types';
export default class LoadingIndicator extends React.Component {
    static propTypes = {
        loadingText: PropTypes.string,
        opacity: PropTypes.number,
    }

    render() {
        const style = {
            backgroundColor: `rgba(0, 0, 0, ${this.props.opacity || 1})`
        };

        return (
            <div style={style} className='loading-indicator'>
                <div className='loading-icon'>
                    <div className='loading-1'></div>
                    <div className='loading-2'></div>
                    <div className='loading-3'></div>
                    <div className='loading-4'></div>
                </div>
                <div className='loading-label'>{this.props.loadingText}</div>
            </div>
        );
    }
}
