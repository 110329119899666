import Decimal from 'decimal.js';

import CalculatorCommon from '../../../../common/services/calculator.js';

// typeof rider = {
//     premium
//     interestType
//     0 < rollup < 100
//     0 < cashValueEqualZeroRate < 100
//     0 < cashValueGreaterThanZeroRate < 100
//     0 < assumedReturnRate < 100
// }
// typeof clientInformation = {
//     0 < currentAge < ageAtWithdrawal
//     jointRider
// }

class Calculator {
    baseBenefit(rider, clientInformation) {
        const {premium, rollup, interestType} = rider;

        const deferralPeriod = this.calculateDeferralPeriod(clientInformation);
        const rollupNormalized = this.normalizePercentage(rollup);

        return CalculatorCommon
            .calculateBaseBenefit(premium, rollupNormalized, deferralPeriod, interestType);
    }

    normalizePercentage(percentage) {
        return Decimal.div(percentage, 100).toString();
    }

    calculateDeferralPeriod(clientInformation) {
        const {ageAtWithdrawal, currentAge} = clientInformation;

        const deferralPeriod = Number(ageAtWithdrawal) - Number(currentAge);

        return deferralPeriod;
    }

    /**
     * Calculates the first cash value after deferral period.
     */
    calculateFirstCashValue(rider, clientInformation) {
        const {assumedReturnRate, premium} = rider;

        const deferralPeriod = this.calculateDeferralPeriod(clientInformation);
        const assumedReturnRateNormalized = this.normalizePercentage(assumedReturnRate);

        return CalculatorCommon.calculateFirstCashValue(
            premium,
            deferralPeriod,
            assumedReturnRateNormalized
        );
    }

    calculateNextCashValue(previousCashValue, withdrawalPerYear, assumedReturnRateNormalized) {
        return Decimal.max(
            0,
            Decimal.mul(
                Decimal.sub(
                    previousCashValue,
                    withdrawalPerYear
                ),
                Decimal.add(
                    1,
                    assumedReturnRateNormalized
                )
            )
        );
    }

    // When cash value is equal $0
    calculateEqual0YearlyPayout(rider, clientInformation) {
        const {cashValueEqualZeroRate} = rider;

        const baseBenefit = this.baseBenefit(rider, clientInformation);
        const firstCashValue = this.calculateFirstCashValue(rider, clientInformation);
        const moneyAnnualIncome = Decimal.max(baseBenefit, firstCashValue);

        const cashValueEqualZeroRateNormalized = this.normalizePercentage(
            cashValueEqualZeroRate
        );

        return Decimal.mul(
            moneyAnnualIncome,
            cashValueEqualZeroRateNormalized
        );
    }

    // When cash value is above $0
    calculateAbove0YearlyPayout(rider, clientInformation) {
        const {cashValueGreaterThanZeroRate} = rider;

        const baseBenefit = this.baseBenefit(rider, clientInformation);
        const firstCashValue = this.calculateFirstCashValue(rider, clientInformation);
        const moneyAnnualIncome = Decimal.max(baseBenefit, firstCashValue);

        const cashValueGreaterThanZeroRateNormalized = this.normalizePercentage(
            cashValueGreaterThanZeroRate);

        return Decimal.mul(
            moneyAnnualIncome,
            cashValueGreaterThanZeroRateNormalized
        );
    }

    calculateStandardRiderSummary(rider, clientInformation) {
        const {ageAtWithdrawal} = clientInformation;
        const {assumedReturnRate} = rider;

        const firstCashValue = this.calculateFirstCashValue(rider, clientInformation);
        const assumedReturnRateNormalized = this.normalizePercentage(assumedReturnRate);

        const moneyPerYearPositive = this.calculateAbove0YearlyPayout(rider, clientInformation);
        const moneyPerYearNegative = this.calculateEqual0YearlyPayout(rider, clientInformation);

        if (moneyPerYearPositive.equals(moneyPerYearNegative)) {
            return {
                ageMoneyPositive: Decimal.sub(Decimal(ageAtWithdrawal), 1),
                moneyPerYearPositive,
                moneyPerYearNegative
            };
        }

        const calculateNextCashValue = (previousCashValue) => this.calculateNextCashValue(
            previousCashValue,
            moneyPerYearPositive,
            assumedReturnRateNormalized
        );

        const secondCashValue = calculateNextCashValue(firstCashValue);

        if (secondCashValue.greaterThan(firstCashValue)) {
            // Cash value will never reach 0
            return {
                ageMoneyPositive: Decimal.sub(Decimal(ageAtWithdrawal), 1),
                moneyPerYearPositive,
                moneyPerYearNegative: moneyPerYearPositive
            };
        }

        const cashValues = [firstCashValue, secondCashValue];

        // Returns the cash value index when cash values first reaches 0
        const calculateCashValuesUntil0 = () => {
            const lastCalculated = cashValues[cashValues.length - 1];

            if (lastCalculated.isZero()) {
                // Return the index of the one before
                return cashValues.length - 2;
            }

            const nextCashValue = calculateNextCashValue(lastCalculated);

            cashValues.push(nextCashValue);

            return calculateCashValuesUntil0();
        };

        const ageMoneyPositive = Decimal.add(
            ageAtWithdrawal,
            calculateCashValuesUntil0()
        );

        return {
            ageMoneyPositive,
            moneyPerYearPositive,
            moneyPerYearNegative
        };
    }

    calculateStandardTableValues(rider, clientInformation) {
        const {ageAtWithdrawal} = clientInformation;
        const {assumedReturnRate, cashValueGreaterThanZeroRate, cashValueEqualZeroRate} = rider;

        const assumedReturnRateNormalized = this.normalizePercentage(assumedReturnRate);
        const cashValueGreaterThanZeroRateNormalized = this.normalizePercentage(
            cashValueGreaterThanZeroRate);
        const cashValueEqualZeroRateNormalized = this.normalizePercentage(
            cashValueEqualZeroRate
        );

        const baseBenefit = this.baseBenefit(rider, clientInformation);
        const firstCashValue = this.calculateFirstCashValue(rider, clientInformation);
        const moneyAnnualIncome = Decimal.max(baseBenefit, firstCashValue);

        const result = {};

        const startYear = Number(ageAtWithdrawal);
        const endYear = startYear + 29;

        for (let i = startYear; i <= endYear; ++i) {
            result[i] = {};

            // Base Benefit

            result[i].baseBenefit = baseBenefit;

            // Cash Value

            if (i === startYear) {
                result[i].cashValue = firstCashValue;
            } else {
                result[i].cashValue = this.calculateNextCashValue(
                    result[i - 1].cashValue,
                    result[i - 1].annualIncome,
                    assumedReturnRateNormalized
                );
            }

            // Annual Income

            if (result[i].cashValue.greaterThan(0)) {
                result[i].annualIncome = Decimal.mul(
                    moneyAnnualIncome,
                    cashValueGreaterThanZeroRateNormalized
                );
            } else {
                result[i].annualIncome = Decimal.mul(
                    moneyAnnualIncome,
                    cashValueEqualZeroRateNormalized
                );
            }

            // Total Income

            if (i === startYear) {
                result[i].totalIncome = result[i].annualIncome;
            } else {
                result[i].totalIncome = Decimal.add(
                    result[i].annualIncome,
                    result[i - 1].totalIncome
                );
            }
        }

        return result;
    }

    calculateCarryForwardTableValues(rider, clientInformation, carryForward) {
        const standardTableValues = this.calculateStandardTableValues(rider, clientInformation);

        const {assumedReturnRate, cashValueGreaterThanZeroRate, cashValueEqualZeroRate} = rider;
        const {ageAtWithdrawal} = clientInformation;
        const {
            annualIncome: carryForwardAnnualIncome,
        } = carryForward;

        const baseBenefit = this.baseBenefit(rider, clientInformation);

        const assumedReturnRateNormalized = this.normalizePercentage(assumedReturnRate);
        const cashValueGreaterThanZeroRateNormalized = this.normalizePercentage(
            cashValueGreaterThanZeroRate);
        const cashValueEqualZeroRateNormalized = this.normalizePercentage(
            cashValueEqualZeroRate
        );

        let result = {};

        let privateData = {};

        const startYear = Number(ageAtWithdrawal);
        const endYear = startYear + 29;

        const carryForwardStartYear = startYear + carryForward.startYear - 1;
        const carryForwardEndYear = startYear + carryForward.endYear - 1;

        for (let i = startYear; i <= endYear; ++i) {
            result[i] = {standard: {}, carryForward: {}};
            privateData[i] = {};

            // Base Benefit
            result[i].baseBenefit = baseBenefit;

            // Cash Value
            result[i].standard.cashValue = standardTableValues[i].cashValue;

            if (i === startYear) {
                result[i].carryForward.cashValue = standardTableValues[i].cashValue;
            } else {
                result[i].carryForward.cashValue = this.calculateNextCashValue(
                    result[i - 1].carryForward.cashValue,
                    result[i - 1].carryForward.annualIncome,
                    assumedReturnRateNormalized
                );
            }

            // Private Lifetime Income
            const moneyCarryForward = Decimal.max(
                result[startYear].baseBenefit,
                result[startYear].carryForward.cashValue
            );

            if (result[i].carryForward.cashValue.greaterThan(0)) {
                privateData[i].lifetimeIncome = Decimal.mul(
                    moneyCarryForward,
                    cashValueGreaterThanZeroRateNormalized
                );
            } else {
                privateData[i].lifetimeIncome = Decimal.mul(
                    moneyCarryForward,
                    cashValueEqualZeroRateNormalized
                );
            }

            // Balance
            if (i === startYear) {
                result[i].balance = Decimal(0);
            } else {
                result[i].balance = privateData[i - 1].nextYearCarryForward;
            }

            // Available Income
            if (i === startYear) {
                result[i].availableIncome = privateData[i].lifetimeIncome;
            } else {
                result[i].availableIncome = Decimal.add(
                    privateData[i].lifetimeIncome,
                    result[i].balance
                );
            }

            // Annual Income
            result[i].standard.annualIncome = standardTableValues[i].annualIncome;
            if (i >= carryForwardStartYear && i <= carryForwardEndYear) {
                result[i].carryForward.annualIncome = Decimal.min(
                    Decimal(carryForwardAnnualIncome),
                    result[i].availableIncome
                );
            } else {
                result[i].carryForward.annualIncome = Decimal.min(
                    privateData[i].lifetimeIncome,
                    result[i].availableIncome
                );
            }

            // Total Income
            result[i].standard.totalIncome = standardTableValues[i].totalIncome;
            if (i === startYear) {
                result[i].carryForward.totalIncome = result[i].carryForward.annualIncome;
            } else {
                result[i].carryForward.totalIncome = Decimal.add(
                    result[i].carryForward.annualIncome,
                    result[i - 1].carryForward.totalIncome
                );
            }

            // Private Next Year Carry Forward
            if (i === startYear) {
                privateData[i].nextYearCarryForward = Decimal.sub(
                    privateData[i].lifetimeIncome,
                    result[i].carryForward.annualIncome
                );
            } else {
                privateData[i].nextYearCarryForward = Decimal.max(
                    Decimal(0),
                    Decimal.min(
                        privateData[startYear].lifetimeIncome,
                        Decimal.sub(
                            privateData[i].lifetimeIncome,
                            Decimal.sub(
                                result[i].carryForward.annualIncome,
                                Decimal.sub(
                                    result[i].availableIncome,
                                    privateData[i].lifetimeIncome
                                )
                            )
                        )
                    )
                );
            }
        }

        return result;
    }
}

const calculator = new Calculator();

export default calculator;