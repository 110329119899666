import 'annuity_lab/styles/modal_dialog';
import 'common/components/dialogs/message_dialog/message_dialog.css.scss';

import PropTypes from 'prop-types';

import Modal from 'react-modal';

const body = document.querySelector('body');

export default class MessageDialog extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    render() {
        const {className, title, content, onRequestClose} = this.props;

        return (
            <Modal
                isOpen
                appElement={body}
                {...this.props}
                className="modal-dialog message-dialog panel"
                overlayClassName={`modal-dialog-overlay ${className}`}>

                <div className="header">
                    <h4>{title}</h4>

                    <button className="close-button" onClick={onRequestClose} />
                </div>

                <div className="body">
                    {content}
                </div>

                <div className="footer">
                    <button className="btn close-button" onClick={onRequestClose}>
                        Okay
                    </button>
                </div>

            </Modal>
        );
    }
}
