import ACTION_TYPES from 'annuity_lab/tools/common/actions/action_types.js';
import LocalCardsApi from 'annuity_lab/tools/lbc/services/data/api/rider_cards.js';
import NwideCardsApi from 'annuity_lab/tools/lbc/services/nwide/rider_cards.js';
import ComparisonsAPI from 'annuity_lab/tools/lbc/services/data/api/comparisons.js';
import CardsUtil from 'common/services/cards_core_util.js';
import DataChangeRegistry from 'annuity_lab/services/data_change_registry.js';
import MigrationConsts from 'annuity_lab/tools/lbc/services/migration_consts.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import moment from 'moment';
import Promise from 'bluebird';

function createInitialCards(userId) {
    const cards = [];
    const timestamp = moment.utc().valueOf();

    cards.push(LocalCardsApi.create({
        userId,
        riderName: CardsUtil.generateRiderName(),
        createdAt: timestamp,
    }));

    cards.push(LocalCardsApi.create({
        userId,
        riderName: CardsUtil.generateRiderName(cards),
        createdAt: timestamp + 1,
    }));

    return cards;
}

export function loadRiderCards() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        let shouldCreateCards = false;

        let cards = LocalCardsApi.find({userId});

        if (!cards || !cards.length) {
            shouldCreateCards = true;
            cards = createInitialCards(userId);
        }

        dispatch({
            type: ACTION_TYPES.CARDS_LOADED,
            payload: {cards},
            meta: {
                sync: {
                    online: () => {
                        let creating = Promise.resolve();

                        if (shouldCreateCards) {
                            creating = Promise.map(cards, card => {
                                NwideCardsApi.createCard(userId, card);
                            });
                        }

                        return creating;
                    },
                    offline: () => {
                        if (shouldCreateCards) {
                            cards.forEach(card => {
                                DataChangeRegistry.add({
                                    entityType: MigrationConsts.entityTypes.CARDS,
                                    entity: card,
                                    operation: MigrationConsts.operations.CREATE,
                                    userId,
                                });
                            });
                        }
                    },
                }
            },
        });
    };
}

export function createNewCard() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const cards = _.values(getState().common.riderCards);
        const card = LocalCardsApi.create({
            userId,
            riderName: CardsUtil.generateRiderName(cards),
            createdAt: moment.utc().valueOf()
        });

        AnnuityLabAnalytics.trackCreateCard(card);

        dispatch({
            type: ACTION_TYPES.CARD_CREATED,
            payload: {card},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsApi.createCard(userId, card);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.CARDS,
                            entity: card,
                            operation: MigrationConsts.operations.CREATE,
                            userId,
                        });
                    },
                },
            },
        });
    };
}

export function updateCard(cardId, template) {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const card = LocalCardsApi.findOne({id: cardId});
        const wasComplete = CardsUtil.isComplete(card);

        const updated = LocalCardsApi.updateOne(
            {id: cardId},
            _.assign(template, {updatedAt: moment.utc().valueOf()})
        );

        AnnuityLabAnalytics.trackEditCard(updated, _.keys(template)[0]);

        if (!wasComplete && CardsUtil.isComplete(updated)) {
            AnnuityLabAnalytics.trackFillCard(updated);
        }

        dispatch({
            type: ACTION_TYPES.CARD_UPDATED,
            payload: {cardId, card: updated},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsApi.updateCard(userId, cardId, updated);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.CARDS,
                            entity: updated,
                            operation: MigrationConsts.operations.UPDATE,
                            userId,
                        });
                    },
                },
            },
        });
    };
}

export function removeCard(cardId) {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;
        const deleted = LocalCardsApi.remove({id: cardId});
        const card = deleted[0];

        const comparisons = ComparisonsAPI.find();
        const dependentComparisons = _.filter(comparisons, comparison => {
            return _.includes(comparison.cardIds, card.id);
        });

        dependentComparisons.forEach(comparison => {
            ComparisonsAPI.remove({id: comparison.id});
        });

        AnnuityLabAnalytics.trackRemoveCard(card);

        dispatch({
            type: ACTION_TYPES.CARD_REMOVED,
            payload: {card},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsApi.removeCard(userId, cardId);
                    },
                    offline: () => {
                        DataChangeRegistry.add({
                            entityType: MigrationConsts.entityTypes.CARDS,
                            entity: card,
                            operation: MigrationConsts.operations.DELETE,
                            userId,
                        });

                        dependentComparisons.forEach(comparison => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.COMPARISONS,
                                entity: comparison,
                                operation: MigrationConsts.operations.DELETE,
                                userId,
                            });
                        });
                    },
                }
            },
        });
    };
}

export function resetCards() {
    return (dispatch, getState) => {
        const userId = getState().auth.user.id;

        const previous = {
            cards: _.values(getState().common.riderCards),
            comparisons: ComparisonsAPI.find({userId}),
        };

        ComparisonsAPI.remove({userId});
        LocalCardsApi.remove({userId});
        const cards = createInitialCards(userId);

        AnnuityLabAnalytics.trackResetCards();

        dispatch({
            type: ACTION_TYPES.CARDS_RESET,
            payload: {cards},
            meta: {
                sync: {
                    online: () => {
                        return NwideCardsApi
                            .removeAllCards(userId)
                            .then(() => {
                                return Promise.map(cards, card => {
                                    NwideCardsApi.createCard(userId, card);
                                });
                            });
                    },
                    offline: () => {
                        previous.cards.forEach(card => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.CARDS,
                                entity: card,
                                operation: MigrationConsts.operations.DELETE,
                                userId,
                            });
                        });

                        previous.comparisons.forEach(comparison => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.COMPARISONS,
                                entity: comparison,
                                operation: MigrationConsts.operations.DELETE,
                                userId,
                            });
                        });

                        cards.forEach(card => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.CARDS,
                                entity: card,
                                operation: MigrationConsts.operations.CREATE,
                                userId,
                            });
                        });
                    },
                },
            },
        });
    };
}
