/* eslint-disable no-inline-comments */
/* eslint-disable react/no-multi-comp */

import 'annuity_lab/tools/imo/components/rider_select/rider_select.css.scss';

import {useEffect, useCallback, useRef} from 'react';

import Select from 'annuity_lab/components/select/select.js.jsx';
import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';

const RiderSelect = ({
    clientInformation,
    riderCards,
    riderCardsPlus,
    selectedRiderId,
    handleChange
}) => {
    // We need ref to the rider cards so that we don't trigger an update
    // to the effect below when they change.
    const riderCardsPlusRef = useRef(riderCardsPlus);

    useEffect(() => {
        riderCardsPlusRef.current = riderCardsPlus;
    }, [riderCardsPlus]);

    const {ageAtWithdrawal, jointRider} = clientInformation;

    const getCashValuesRatesForPlusCard = useCallback((plusCard) => {
        let relevantWithdrawalLine,
            cashValueGreaterThanZeroRate = '',
            cashValueEqualZeroRate = '';

        if (ageAtWithdrawal) {
            relevantWithdrawalLine =
                CardsPlusUtil.getPayoutPercentageLine(
                    plusCard.withdrawalValues,
                    ageAtWithdrawal);
        } else {
            relevantWithdrawalLine = plusCard.withdrawalValues
                .filter(wv => wv.rangeStart !== null)
                .sort((a, b) => a.rangeStart - b.rangeStart)[0];
        }

        if (jointRider === 'yes') {
            cashValueGreaterThanZeroRate = relevantWithdrawalLine.jointPositive || '';
            cashValueEqualZeroRate = relevantWithdrawalLine.jointNegative || '';
        } else if (jointRider === 'no') {
            cashValueGreaterThanZeroRate = relevantWithdrawalLine.singlePositive || '';
            cashValueEqualZeroRate = relevantWithdrawalLine.singleNegative || '';
        }

        return {
            cashValueGreaterThanZeroRate,
            cashValueEqualZeroRate
        };
    }, [ageAtWithdrawal, jointRider]);

    useEffect(() => {
        const selectedRiderIdDefined = !_.isNil(selectedRiderId);
        const selectedPlusCard = riderCardsPlusRef.current.find(c => c.id === selectedRiderId);
        const plusCardSelected = selectedRiderIdDefined &&
            selectedPlusCard !== undefined;

        if (plusCardSelected) {
            const {cashValueGreaterThanZeroRate, cashValueEqualZeroRate} =
                getCashValuesRatesForPlusCard(selectedPlusCard);

            handleChange({
                cashValueGreaterThanZeroRate,
                cashValueEqualZeroRate,
            });
        }
    },
    [getCashValuesRatesForPlusCard, selectedRiderId, handleChange]);

    const coreOptions = riderCards
        .map((c => ({
            value: c.id,
            label: `Core: ${c.displayName.toUpperCase()} (${Number(c.rollup * 100).toFixed(2)} %)`
        })))
        .sort((a, b) => a.label > b.label ? 1 : -1);

    const plusOptions = riderCardsPlus
        .map((c => ({
            value: c.id,
            label: `Plus: ${c.displayName.toUpperCase()} (${Number(c.rollup * 100).toFixed(2)} %)`
        })))
        .sort((a, b) => a.label > b.label ? 1 : -1);

    const groupedOptions = [
        {
            label: 'Core',
            options: coreOptions
        },
        {
            label: 'Plus',
            options: plusOptions
        }
    ];

    const formatGroupLabel = (data) => (
        <div>
            <span>{data.label}</span>
        </div>
    );

    const onChange = (riderOption) => {
        if (riderOption.label.startsWith('Core')) {
            const selectedCoreCard = riderCards.find(c => c.id === riderOption.value);

            const payoutRate = (Number(selectedCoreCard.payout) * 100).toFixed(2);

            handleChange({
                selectedRiderCardId: selectedCoreCard.id,
                interestType: selectedCoreCard.interestType,
                rollup: (Number(selectedCoreCard.rollup) * 100).toFixed(2),
                cashValueGreaterThanZeroRate: payoutRate,
                cashValueEqualZeroRate: payoutRate,
                assumedReturnRate: '0'
            });
        } else {
            // Plus card
            const selectedPlusCard = riderCardsPlus.find(c => c.id === riderOption.value);

            const {cashValueGreaterThanZeroRate, cashValueEqualZeroRate} =
                getCashValuesRatesForPlusCard(selectedPlusCard);

            handleChange({
                selectedRiderCardId: selectedPlusCard.id,
                interestType: selectedPlusCard.interestType,
                rollup: (Number(selectedPlusCard.rollup) * 100).toFixed(2),
                cashValueGreaterThanZeroRate,
                cashValueEqualZeroRate,
                assumedReturnRate: '0'
            });
        }
    };

    return (
        <div className="rider-select">
            <Select
                placeholder="Select a Previously Created Card"
                options={groupedOptions}
                value={[...coreOptions, ...plusOptions]
                    .find(o => o.value === selectedRiderId) ?? null}
                onChange={onChange}
                formatGroupLabel={formatGroupLabel} />
        </div>
    );
};

export default RiderSelect;