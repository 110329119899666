import 'annuity_lab/tools/lbc/components/rider_cards_hint/rider_cards_hint.css.scss';

const RiderCardsHint = () => (
    <div className="hint">
        <span className="arrow-down-icon"></span>
        <div className="hint-text">
            Start comparing riders by
            creating at least two rider cards.
        </div>
    </div>
);

export default RiderCardsHint;