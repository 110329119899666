import CurrentUserAPI from 'annuity_lab/services/data/api/current_user.js';
import AuthServiceAPI from 'annuity_lab/services/nwide_api/auth.js';
import Promise from 'bluebird';
// import AdvisorInformationAPI from './data/api/advisor_information';

class Authentication {
    authenticate(online) {
        if (!online) {
            const user = CurrentUserAPI.get();

            return Promise.resolve(user);
        }

        return AuthServiceAPI.authenticate()
            .then(user => {
                CurrentUserAPI.set(user);

                // if (user) {
                //     AdvisorInformationAPI.set();
                // }

                return user;
            });
    }

    login(email, password, rememberMe) {
        return AuthServiceAPI.login(email, password, rememberMe)
            .then(user => {
                CurrentUserAPI.set(user);
                // AdvisorInformationAPI.set();

                return user;
            });
    }

    logout() {
        return AuthServiceAPI.logout();
    }
}

export default new Authentication();
