import 'annuity_lab/tools/lbc/containers/footer/footer.css.scss';

export default class Footer extends React.Component {
    render() {
        return (
            <div className="phone-footer">
                {this.props.children}
            </div>
        );
    }
}
