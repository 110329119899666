import 'annuity_lab/tools/imo/components/model_select/model_select.css.scss';

import PropTypes from 'prop-types';

import Select from 'annuity_lab/components/select/select.js.jsx';

const ModelSelect = ({label, models, onModelChange}) => {
    if (models.length === 0) {
        return <h2 className="title">{label}</h2>;
    }

    return (
        <div className="model-select-container">
            <Select
                isSearchable={false}
                className="model-select"
                value={{label}}
                placeholder={label}
                options={models.map(model => ({value: model.id, label: model.name}))}
                onChange={onModelChange}/>
        </div>
    );
};

ModelSelect.propTypes = {
    models: PropTypes.array,
    lable: PropTypes.string,
    onModelChange: PropTypes.func
};

export default ModelSelect;