import Connection from 'common/services/connection.js';

const API_PATH = '/lbc/sync';

class SyncAPI {
    sync(userId, migrations) {
        return Connection.send(
            API_PATH,
            {userId, migrations},
            'POST'
        );
    }

    migrateLegacyData(userId, cards, comparisons) {
        const path = `${API_PATH}/migrate/legacy`;

        return Connection.send(
            path,
            {userId, legacy: {cards, comparisons}},
            'POST'
        );
    }
}

export default new SyncAPI();
