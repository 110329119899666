
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {updateRiderType} from
    'annuity_lab/tools/lbc/actions/select_rider.js';
import SelectRider from 'annuity_lab/tools/lbc/components/select_rider/select_rider.js.jsx';
import {TOOLS} from 'common/domain/tools.js';

function mapStateToProps({lbc}) {
    const {errorVisible} = lbc.selectRider;

    return {
        tool: TOOLS.LIVING_BENEFIT_RIDERS_CORE,
        errorVisible
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateRiderType: (riderType) => {
            dispatch(updateRiderType(riderType));
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectRider));
