import PropTypes from 'prop-types';

import CardsPlusUtil from 'annuity_lab/tools/lbc/services/cards_plus_util.js';
import ProductDifferentialPlus from
    'annuity_lab/tools/lbc/containers/product_differential_plus/product_differential_plus.js.jsx';
import ComparisonPlusChart from
    'annuity_lab/tools/lbc/components/comparison_plus_chart/comparison_plus_chart.js.jsx';

const StandardView = (props) => {
    const {cards, comparison} = props;
    const {ageAtWithdrawal, currentAge, premium} = comparison;

    const modifiedCards = cards.map(c => ({...c, rollupYears: ageAtWithdrawal - currentAge}));
    const sortedCards = CardsPlusUtil.sortByBaseBenefit(modifiedCards, premium);

    return (
        <>
            <ProductDifferentialPlus
                cards={sortedCards}
                comparison={comparison}/>

            <ComparisonPlusChart
                key={JSON.stringify([comparison, sortedCards])}
                comparison={comparison}
                cards={sortedCards}/>
        </>
    );
};

StandardView.propTypes = {
    cards: PropTypes.array,
    comparison: PropTypes.object,
};

export default StandardView;

