export function isPhone() {
    return window.innerWidth < 737;
}
export function isTablet() {
    return window.innerWidth >= 737 && window.innerWidth <= 991;
}
export function isMobile() {
    return isPhone() || isTablet();
}
export function isDesktop() {
    return window.innerWidth >= 992;
}
