import 'annuity_lab/tools/common/components/product_context_inputs/product_context_inputs.css.scss';

import WithLabel from 'annuity_lab/components/with_label/with_label.js.jsx';
import CurrencyInput from 'annuity_lab/components/currency_input/currency_input.js.jsx';
import Select from 'annuity_lab/components/select/select.js.jsx';
import PropTypes from 'prop-types';
import Constants from 'common/services/constants.js';

export default class ProductContextInputs extends React.Component {
    static propTypes = {
        minMoney: PropTypes.string,
        money: PropTypes.string.isRequired,
        moneyLabel: PropTypes.string.isRequired,
        deferralPeriod: PropTypes.number.isRequired,
        deferralPeriodLabel: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    }

    static defaultProps = {
        minMoney: '0'
    }

    onChange({money = this.props.money, deferralPeriod = this.props.deferralPeriod}) {
        this.props.onChange(money, deferralPeriod);
    }

    render() {
        return (
            <div className="product-context-inputs">
                <WithLabel label={this.props.moneyLabel}>
                    <CurrencyInput
                        min={this.props.minMoney}
                        value={this.props.money}
                        onChange={(value) => this.onChange({money: value})} />
                </WithLabel>

                <WithLabel label={this.props.deferralPeriodLabel}>
                    <Select
                        value={Constants.DEFERRAL_PERIOD_OPTIONS.find(o =>
                            o.value === this.props.deferralPeriod)}
                        options={Constants.DEFERRAL_PERIOD_OPTIONS}
                        onChange={(option) => this.onChange({deferralPeriod: option.value})} />
                </WithLabel>
            </div>
        );
    }
}
