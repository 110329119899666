import 'common/components/small_card/small_card.css.scss';
import CardsUtil from 'common/services/cards_core_util.js';
import NumberFormat from 'common/services/number_format';
import PropTypes from 'prop-types';
export default class SmallCard extends React.Component {
    static propTypes = {
        card: PropTypes.object.isRequired,
        isSelected: PropTypes.bool,
        amountLabel: PropTypes.string.isRequired,
        monetaryValue: PropTypes.object.isRequired,
        additionalClasses: PropTypes.string,

        onClick: PropTypes.func,
        aside: PropTypes.element
    }

    render() {
        const card = this.props.card;
        const selectedClassName = this.props.isSelected ? 'selected' : '';
        const cardName = card.displayName || card.riderName;

        return (
            <div className={`small-product-card panel ${selectedClassName}`}
                onClick={this.props.onClick}>
                <div className={
                    `card ${this.props.additionalClasses
                        ? this.props.additionalClasses
                        : ''}`
                }>
                    <div className="header">
                        <span className="product-name">
                            Rider {cardName.toUpperCase()}
                        </span>
                        <span className="product-stats">
                            ({CardsUtil.getStats(card)})
                        </span>
                    </div>
                    <div className="content">
                        <div className="main">
                            <div className="body">
                                {NumberFormat.currency(this.props.monetaryValue)}
                            </div>
                            <div className="remark">{this.props.amountLabel}</div>
                            {this.props.children}
                        </div>
                        {this.props.aside &&
                            <div className="aside">{this.props.aside}</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
