import 'annuity_lab/tools/lbc/components/email_chips/email_chips.css.scss';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import KEY_CODES from 'annuity_lab/services/key_codes.js.jsx';
import validateEmail from 'common/services/email_validator.js';

const SEPARATORS = [
    KEY_CODES.ENTER,
    KEY_CODES.SPACE,
    KEY_CODES.TAB,
];

const PLACEHOLDER_TEXT = 'Enter an email address and press enter';

export default class EmailChips extends React.Component {
    static propTypes = {
        onEmailsChanged: PropTypes.func,
        contacts: PropTypes.array,
        className: PropTypes.string,
    }

    constructor(props) {
        super(props);

        this.state = {
            currentEmail: '',
            emails: [],
            hasError: false,
            suggestions: [],
        };
    }

    processInput(value) {
        const isValidEmail = validateEmail(value);

        if (isValidEmail) {
            const emails = this.state.emails;

            emails.push(value);
            this.setState({
                emails,
                currentEmail: '',
                hasError: false,
            });
            this.props.onEmailsChanged(emails);
        } else {
            this.setState({hasError: true});
        }
    }

    onKeyDown = (event) => {
        const keyPressed = event.which;

        if (SEPARATORS.indexOf(keyPressed) !== -1) {
            event.preventDefault();
            this.processInput(this.state.currentEmail);
        }
    }

    onBlur = () => {
        const email = this.state.currentEmail;

        if (email) {
            this.processInput(email);
        }
    }

    deleteChip = (email) => {
        const index = this.state.emails.indexOf(email);

        if (index >= 0) {
            const emails = this.state.emails;

            emails.splice(index, 1);
            this.setState({emails});
            this.props.onEmailsChanged(emails);
        }
    }

    getSuggestions(value) {
        if (!value.trim()) {
            return [];
        }

        const remaining = _.difference(this.props.contacts, this.state.emails);

        return remaining.filter((email) => {
            return email.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1;
        });
    }

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    }

    renderSuggestion(suggestion) {
        return (
            <div>{suggestion}</div>
        );
    }

    /**
     * Gets the currently entered valid emails addresses.
     */
    getEmails() {
        return this.state.emails;
    }

    render() {
        const chips = this.state.emails.map((email, index) => {
            return (
                <span className="chip" key={index}>
                    <span className="chip-value">{email}</span>
                    <button
                        type="button"
                        className="chip-delete-button"
                        onClick={this.deleteChip.bind(null, email)}>
                        x
                    </button>
                </span>
            );
        });

        return (
            <div className={`chips ${this.props.className}`} onClick={this.focusInput}>
                {chips}
                <Autosuggest
                    suggestions={this.state.suggestions}
                    renderSuggestion={(suggestion) => this.renderSuggestion(suggestion)}
                    getSuggestionValue={(suggestion) => suggestion}
                    onSuggestionsFetchRequested={(value) => this.onSuggestionsFetchRequested(value)}
                    onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
                    ref={this.props.storeFocusInput}
                    onSuggestionSelected={(_event, {suggestion, method}) => {
                        // enter is already handled by keyDown event
                        if (method !== 'enter') {
                            this.processInput(suggestion);
                        }
                    }}
                    inputProps={{
                        placeholder: PLACEHOLDER_TEXT,
                        className: 'chips-input ' + (this.state.hasError ? 'invalid-input' : '' ),
                        value: this.state.currentEmail,
                        onKeyDown: (event) => {
                            this.onKeyDown(event);
                        },
                        onBlur: (event) => {
                            this.onBlur(event);
                        },
                        onChange: (_event,{newValue}) => {
                            this.setState({currentEmail: newValue});
                        },
                    }}/>

                {(this.state.emails.length === 1 && this.state.hasError &&
                    <div className="invalid-input-text">
                        You have to enter a valid email address.
                    </div>) ||
                (this.state.hasError &&
                    <div className="invalid-input-text">
                        You have to enter valid email addresses.
                    </div>)
                }
            </div>
        );
    }
}
