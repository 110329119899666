import 'annuity_lab/tools/mad/components/menu/menu.css.scss';

import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import _ from 'lodash';
import MenuSelect from 'annuity_lab/tools/common/components/menu_select/menu_select.js.jsx';

const compareRidersTabLabel = 'Compare Rider Cards';

export default class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.rootUrl = props.root;
        this.comparisonsUrl = props.root + '/comparisons';
    }

    openComparison(id) {
        if (id === null) {
            this.props.history.push(this.comparisonsUrl);

            return;
        }

        this.props.history.push(`${this.comparisonsUrl}/${id}`);
    }

    renderNewComparisonButton() {
        return (
            <NavLink to={this.comparisonsUrl} activeClassName="active">
                {compareRidersTabLabel}
            </NavLink>
        );
    }

    renderComparisonSelect(comparisons) {
        var options = _.map(comparisons, (comparison) => {
            return {value: comparison.id, label: comparison.name};
        });

        options.push({value: null, label: 'Start a New Comparison'});

        const openComparison = (option) => this.openComparison(option.value);

        return (
            <MenuSelect
                options={options}
                compareRidersTabLabel={compareRidersTabLabel}
                openComparison={openComparison}/>
        );
    }

    render() {
        const {comparisons, location} = this.props;

        const showComparisonDropdown = location.pathname.startsWith(this.comparisonsUrl)
            && comparisons.length > 0;

        return (
            <div className="menu mad-menu">
                <NavLink exact to={this.rootUrl} activeClassName="active">
                    Create Rider Cards
                </NavLink>

                {showComparisonDropdown ?
                    this.renderComparisonSelect(comparisons) :
                    this.renderNewComparisonButton()}
            </div>
        );
    }
}

Menu.propTypes = {
    comparisons: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    root: PropTypes.string.isRequired,
};
