import EntityStore from 'annuity_lab/services/data/base/entity_store.js';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';

const PERSISTENCE_KEY = 'rider_cards_plus';

class RiderCardsPlusAPI extends EntityStore {
    constructor() {
        super(PERSISTENCE_KEY);
    }

    create(template) {
        const now = moment.utc().valueOf();

        const {withdrawalValues} = template;

        const withdrawalValuesWithIds = withdrawalValues.map(wv => ({
            ...wv,
            id: wv.id || uuidv4()
        }));

        const entity = _.defaults({
            withdrawalValues: withdrawalValuesWithIds
        }, template, {
            id: uuidv4(),
            createdAt: now,
            updatedAt: now,
        });

        const entities = this._loadEntities();

        entities.push(entity);
        this._store(entities);

        return entity;
    }
}

export default new RiderCardsPlusAPI();
