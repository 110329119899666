import 'annuity_lab/tools/imo/components/new_model_form/new_model_form.css.scss';

import {useEffect} from 'react';

import ClientInformation from
    'annuity_lab/tools/imo/components/client_information/client_information.js.jsx';
import RidersToModel from 'annuity_lab/tools/imo/components/riders_to_model/riders_to_model.js.jsx';
import ModelSelect from 'annuity_lab/tools/imo/containers/model_select/model_select.js.jsx';
import ModalDialogs from 'annuity_lab/tools/lbc/services/modal_dialogs';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import {useClassState} from 'annuity_lab/hooks/useClassState.js';

const emptyRiderState = {
    selectedRiderCardId: null,
    premium: '',
    rollup: '',
    interestType: null,
    cashValueGreaterThanZeroRate: '',
    cashValueEqualZeroRate: '',
    assumedReturnRate: '',
};

const emptyErrorState = {
    currentAgeMoreThanAgeAtWithdrawal: '',
    clientInfoFilled: '',
    notFilledCard: '',
    isJointRider: ''
};

const emptyErrorFieldsState = {
    clientInformation: [],
    riderA: [],
    riderB: []
};

const neededToBeFilledFields = [
    'premium',
    'rollup',
    'interestType',
    'cashValueGreaterThanZeroRate',
    'cashValueEqualZeroRate',
    'assumedReturnRate',
];

const fieldsForError = {
    currentWithdrawalAge: [
        'currentAge',
        'ageAtWithdrawal'
    ],
    jointValue: [
        'cashValueGreaterThanZeroRate',
        'cashValueEqualZeroRate'
    ],
};

var CONFIRM_RESET_TITLE = 'Please confirm reset',
    CONFIRM_RESET_MESSAGE = 'This will reset riders data.';

const NewModelForm = (props) => {
    const {
        clientInformation: initialClientInformation,
        updateNewModel,
        history
    } = props;

    const [clientInformation, setClientInformation] = useClassState(initialClientInformation);
    const [riderA, setRiderA] = useClassState(emptyRiderState);
    const [riderB, setRiderB] = useClassState(emptyRiderState);
    const [errors, setErrors] = useClassState(emptyErrorState);
    const [errorFields, setErrorFields] = useClassState(emptyErrorFieldsState);

    const isFieldEmpty = (value) => !value;

    const getRiderEmptyFields = (rider) => {
        const objectThatNeedsToBeFilled = _.pick(rider, neededToBeFilledFields);

        // Assumed rate of return can be 0
        return Object.keys(objectThatNeedsToBeFilled)
            .filter((key) =>
                isFieldEmpty(objectThatNeedsToBeFilled[key]) ||
                (key !== 'assumedReturnRate' && Number(objectThatNeedsToBeFilled[key]) === 0));
    };

    const getEmptyClientInformationFields = () => Object.keys(clientInformation)
        .filter((key) => isFieldEmpty(clientInformation[key]));

    const isRiderEmpty = (rider) =>
        getRiderEmptyFields(rider).length === neededToBeFilledFields.length;

    const includeErrorFields = (key, fields) => {
        const newFields = fields.filter((field) => !errorFields[key].includes(field));

        setErrorFields({
            [key]: [
                ...errorFields[key],
                ...newFields
            ]
        });
    };

    const excludeErrorFields = (key, fields) => {
        const remainingFields = errorFields[key].filter((field) => !fields.includes(field));

        setErrorFields({
            [key]: remainingFields
        });
    };

    useEffect(() => {
        AnnuityLabAnalytics.trackView('New Model Form');
    }, []);

    useEffect(() => {
        const selectedRiderCardIdInA = !_.isNil(riderA.selectedRiderCardId);
        const selectedRiderCardIdInB = !_.isNil(riderB.selectedRiderCardId);

        const cashValueUndefinedInA =
            !riderA.cashValueGreaterThanZeroRate || !riderA.cashValueEqualZeroRate;
        const cashValueUndefinedInB =
            !riderB.cashValueGreaterThanZeroRate || !riderB.cashValueEqualZeroRate;

        const jointErrorInA = selectedRiderCardIdInA && cashValueUndefinedInA;
        const jointErrorInB = selectedRiderCardIdInB && cashValueUndefinedInB;

        if (jointErrorInA || jointErrorInB) {
            let errorMessage = '';

            if (!clientInformation.jointRider) {
                errorMessage = 'Please select if this is a joint rider.';
                includeErrorFields('clientInformation', ['jointRider']);
            } else if (clientInformation.jointRider === 'yes') {
                errorMessage = 'Selected card does not include a joint rider option.';
                if (jointErrorInA) {
                    includeErrorFields('riderA', fieldsForError.jointValue);
                } else {
                    includeErrorFields('riderB', fieldsForError.jointValue);
                }
            } else {
                // jointRider === 'no
                errorMessage = 'Selected card does not include a single rider option.';
                if (jointErrorInA) {
                    includeErrorFields('riderA', fieldsForError.jointValue);
                } else {
                    includeErrorFields('riderB', fieldsForError.jointValue);
                }
            }

            setErrors({
                isJointRider: errorMessage
            });
        } else {
            if (errors.isJointRider) {
                excludeErrorFields('clientInformation', ['jointRider']);
                excludeErrorFields('riderA', fieldsForError.jointValue);
                excludeErrorFields('riderB', fieldsForError.jointValue);
            }

            setErrors({
                isJointRider: ''
            });
        }
    }, [riderA, riderB, clientInformation]);

    useEffect(() => {
        const {currentAge, ageAtWithdrawal} = clientInformation;

        if (
            currentAge !== '' &&
            ageAtWithdrawal !== '' &&
            Number(currentAge) > Number(ageAtWithdrawal)
        ) {
            setErrors({
                currentAgeMoreThanAgeAtWithdrawal:
                    'Age at first withdrawal should be more than age at issue.'
            });
            includeErrorFields('clientInformation', fieldsForError.currentWithdrawalAge);
        } else {
            setErrors({
                currentAgeMoreThanAgeAtWithdrawal: ''
            });
            excludeErrorFields('clientInformation', fieldsForError.currentWithdrawalAge);
        }
    }, [clientInformation]);

    const handleButtonClick = () => {
        let submitErrors = {};

        const riderAEmptyFields = getRiderEmptyFields(riderA);
        const riderBEmptyFields = getRiderEmptyFields(riderB);

        if (riderAEmptyFields.length > 0) {
            submitErrors.notFilledCard = 'Rider A must be filled.';
            setErrorFields({riderA: riderAEmptyFields});
        } else if (!isRiderEmpty(riderB) && riderBEmptyFields.length > 0) {
            submitErrors.notFilledCard = 'Rider B must be empty or filled.';
            setErrorFields({riderA: []});
            setErrorFields({riderB: riderBEmptyFields});
        } else {
            submitErrors.notFilledCard = '';
            setErrorFields({
                riderA: [],
                riderB: []
            });
        }

        const clientInformationEmptyFields = getEmptyClientInformationFields();

        if (clientInformationEmptyFields.length === 0) {
            submitErrors.clientInfoFilled = '';
            // Exclude only jointRider as the age fields are controlled by the other effect
            excludeErrorFields('clientInformation', ['jointRider']);
        } else {
            submitErrors.clientInfoFilled = 'Client Information must be filled.';
            includeErrorFields('clientInformation', clientInformationEmptyFields);
        }

        if (!errors.currentAgeMoreThanAgeAtWithdrawal
            && !errors.isJointRider
            && !submitErrors.notFilledCard
            && !submitErrors.clientInfoFilled) {
            updateNewModel({
                clientInformation,
                riderA: _.omit(riderA, ['selectedRiderCardId']),
                riderB: isRiderEmpty(riderB) ? null : _.omit(riderB, ['selectedRiderCardId'])
            });
            history.push('/imo/models/new');
        } else {
            setErrors(submitErrors);
        }
    };

    const resetRiders = () => {
        ModalDialogs.confirm(CONFIRM_RESET_TITLE, CONFIRM_RESET_MESSAGE, () => {
            setRiderA(emptyRiderState);
            setRiderB(emptyRiderState);
            setErrors(emptyErrorState);
        });
    };

    const onModelChange = (o) => {
        history.push(`/imo/models/${o.value}/standard`);
    };

    return (
        <div className="imo-new-model-form">
            <div className="title-container">
                <ModelSelect label="Create a New Model" onModelChange={onModelChange}/>
            </div>

            <ClientInformation
                clientInformation={clientInformation}
                updateClientInformation={setClientInformation}
                erroredFields={errorFields.clientInformation} />

            <RidersToModel
                riderA={riderA}
                handleRiderAChange={setRiderA}
                riderAErroredFields={errorFields.riderA}
                riderB={riderB}
                handleRiderBChange={setRiderB}
                riderBErroredFields={errorFields.riderB}
                clientInformation={clientInformation} />

            <div className="button-container">
                <div>
                    {Object.values(errors).map(e => (
                        e && <p key={e} className="error">{e}</p>
                    ))}
                </div>
                <button
                    className="create-new-model-btn btn"
                    type="button"
                    onClick={handleButtonClick}>
                    Create New Model
                </button>
                <button
                    className="btn btn-block reset-riders-btn"
                    type="button"
                    onClick={resetRiders}>
                    Reset riders
                </button>
            </div>

        </div>
    );
};

export default NewModelForm;