import Decimal from 'decimal.js';

// Every percentage passes to this calculator
// must be normalized (between 0 <= percentage <= 1) beforehand.
class Calculator {
    calculateBaseBenefit(premium, rollup, deferralPeriod, interestType) {
        switch (interestType) {
        case 'simple':
            return Decimal.add(
                Decimal.mul(
                    Decimal.mul(premium, rollup),
                    deferralPeriod
                ),
                premium
            );
        case 'compound':
            return Decimal.mul(
                premium,
                Decimal.pow(
                    Decimal.add(1, rollup),
                    deferralPeriod
                )
            );
        default:
            throw 'Interest type must be either `simple` or `compound`';
        }
    }

    /**
     * Calculates the first cash value after deferral period.
     */
    calculateFirstCashValue(premium, deferralPeriod, assumedReturnRate) {
        return Decimal.mul(
            premium,
            Decimal.pow(
                Decimal.add(1, assumedReturnRate),
                deferralPeriod
            )
        );
    }
}

const calculator = new Calculator();

export default calculator;