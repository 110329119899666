import Connection from 'common/services/connection.js';

const API_PATH = '/auth';

class AuthService {
    authenticate() {
        const path = `${API_PATH}/authenticate`;

        return Connection.send(path, {}, 'POST')
            .then(data => {
                return data.user;
            });
    }

    login(email, password, rememberMe) {
        const path = `${API_PATH}/login`;

        return Connection.send(path, {email, password, rememberMe}, 'POST')
            .then(data => {
                return data.user;
            });
    }

    logout() {
        const path = `${API_PATH}/logout`;

        return Connection.send(path, {}, 'POST');
    }
}

window.AuthService = new AuthService();

export default new AuthService();
