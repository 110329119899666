import ACTION_TYPES from 'annuity_lab/tools/lbc/actions/action_types.js';
import COMMON_ACTION_TYPES from 'annuity_lab/tools/common/actions/action_types.js';
import BASE_ACTION_TYPES from 'annuity_lab/actions/action_types.js';

const initialState = {};

export default function comparisonReducer(state = initialState, action) {
    let result;

    switch (action.type) {
    case ACTION_TYPES.COMPARISONS_LOADED: {
        result = _.keyBy(action.payload.comparisons, 'id');
        break;
    }

    case ACTION_TYPES.COMPARISON_SAVED: {
        result = _.assign({}, state);
        const comparison = action.payload.comparison;

        result[comparison.id] = comparison;
        break;
    }

    case ACTION_TYPES.COMPARISON_REMOVED: {
        result = _.assign({}, state);
        delete result[action.payload.comparisonId];
        break;
    }

    case COMMON_ACTION_TYPES.CARD_REMOVED: {
        result = _.assign({}, state);
        Object.keys(result).forEach(comparisonId => {
            const comparison = result[comparisonId];

            if (_.includes(comparison.cardIds, action.payload.card.id)) {
                delete result[comparisonId];
            }
        });
        break;
    }

    case COMMON_ACTION_TYPES.CARDS_RESET: {
        result = {};
        break;
    }

    case BASE_ACTION_TYPES.LOGOUT: {
        result = initialState;
        break;
    }

    default: {
        result = state;
    }
    }

    return result;
}
