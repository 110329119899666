import 'annuity_lab/tools/common/components/new_comparison/new_comparison.css.scss';

import {withRouter} from 'react-router-dom';
import View from 'common/lib/view';

import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';

export default function NewComparisonHOC(NewComparisonComponent) {
    class PropsProxy extends View {
        constructor(props) {
            super(props);

            this.state = {
                selected: []
            };
        }

        viewEntered() {
            AnnuityLabAnalytics.trackView('New comparison');

            var allSelectedExist = _.every(this.state.selected, (card) => {
                return !!_.find(this.props.riderCards, card);
            });

            if (!allSelectedExist) {
                this.setState({selected: []});
            }
        }

        setCard(index, card) {
            this.state.selected[index] = card;
            this.forceUpdate();
        }

        cardOptionsFor(fieldIndex) {
            const available = _.difference(this.props.riderCards, this.state.selected);

            if (this.state.selected[fieldIndex]) {
                available.unshift(this.state.selected[fieldIndex]);
            }

            return available;
        }

        compareRiderCards(cards) {
            this.props.history.push(
                this.props.location.pathname + '/new',
                {
                    baseCardId: cards[0].id,
                    cardIds: _(cards).compact().map('id').value()
                }
            );
        }

        render() {
            const newProps = {
                selected: this.state.selected,
                setCard: this.setCard.bind(this),
                cardOptionsFor: this.cardOptionsFor.bind(this),
                compareRiderCards: this.compareRiderCards.bind(this),
            };

            return <NewComparisonComponent {...this.props} {...newProps}/>;
        }
    }

    return withRouter(PropsProxy);
}
