import {combineReducers} from 'redux';
import productContext from 'annuity_lab/tools/mad/reducers/product_context.js';
import comparisons from 'annuity_lab/tools/mad/reducers/comparisons.js';
import sync from 'annuity_lab/tools/lbc/reducers/sync.js';

const reducer = combineReducers({
    productContext,
    comparisons,
    sync,
});

export default reducer;
