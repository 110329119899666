import ACTION_TYPES from 'annuity_lab/tools/mad/actions/action_types.js';

import ComparisonsActions from 'annuity_lab/tools/common/actions/comparisons.js';
import {TOOLS} from 'common/domain/tools';

const comparisonActions = ComparisonsActions(ACTION_TYPES, TOOLS.MAKE_A_DIFFERENCE);

export const {
    loadComparisons,
    saveComparison,
    removeComparison,
} = comparisonActions;
