import keyMirror from 'common/services/key_mirror.js';
import {TOOLS} from 'common/domain/tools';

export default keyMirror([
    'PRODUCTS_LOADED',

    'PERIOD_UPDATED',

    'UPDATE_SELECTED_PRODUCTS',

    'COMPARISONS_LOADED',
], TOOLS.INDEX_COMPARISON_CALCULATOR.toUpperCase());
