const ControlButton = ({labels, onClick, classNames = ''}) => {
    return (
        <button className={'btn btn-block ' + classNames}
            onClick={onClick}>
            <span className="full-label">
                {labels.full}
            </span>
            <span className="short-label">
                {labels.short}
            </span>
        </button>
    );
};

export default ControlButton;