// eslint-disable-next-line
import indexeddbshim from 'indexeddbshim';
import Promise from 'bluebird';
import IndexAPI from 'annuity_lab/tools/fia/services/nwide/indexes.js';
import VersionStore from 'annuity_lab/tools/fia/services/data/api/version_store.js';
import moment from 'moment';
import _ from 'lodash';

const indexedDB = window.indexedDB || window.shimIndexedDB;
const cache = {};

function hasPoorSupport() {
    const userAgent = window.navigator.userAgent;
    const device = window.device;
    const isIE10 = userAgent.indexOf('MSIE') > 0;
    const isIE11 = userAgent.indexOf('Trident/') > 0;
    const isIOS9 = IS_MOBILE && device
        && device.platform.toLowerCase() === 'ios'
        && (parseInt(device.version, 10) === 9);

    return isIE10 || isIE11 || (IS_MOBILE && isIOS9);
}

if (hasPoorSupport()) {
    window.console.log('Poor IndexedDB support. Using indexeddbshim instead.');
    window.shimIndexedDB && window.shimIndexedDB.__useShim();
}

// TODO: remove indexed db logging. --georgi.ivanov@2016-02-14
window.shimIndexedDB.__debug(true);

const DB_NAME = 'fia_indexes';

const OBJECT_STORE_NAMES = {
    products: 'products',
    indexes: 'indexes',
};

class IndexStore {
    constructor() {
        this._db = null;
    }

    clearDatabase(db) {
        const stores = _.values(OBJECT_STORE_NAMES);

        stores.forEach(store => {
            try {
                db.deleteObjectStore(store);
            } catch (error) {
                if (error instanceof DOMException &&
                    error.name === 'NotFoundError') {
                    window.console.log(`Error while trying to delete object store ${store}`, error);
                }
            }
        });
    }

    upgradeDatabase(db, products, indexData) {
        const productStore = db.createObjectStore(OBJECT_STORE_NAMES.products,
            {autoIncrement: true});

        const indexStore = db.createObjectStore(OBJECT_STORE_NAMES.indexes,
            {autoIncrement: true});

        indexStore.createIndex('expiryDate', 'expiryDate', {unique: false});

        products.forEach(product => {
            productStore.add(product);
        });

        indexData.forEach(index => {
            indexStore.add(index);
        });
    }

    connect() {
        // TODO: Decrease complexity. Split into several methods.
        // --georgi.ivanov@2017-02-15
        const currentVersionInfo = VersionStore.get() || {};
        const currentVersion = currentVersionInfo.version;

        let version;

        let products = [];

        let indexData = [];

        return IndexAPI.getVersion().then(versionInfo => {
            version = versionInfo.version;
            if (!currentVersion || currentVersion < version) {
                VersionStore.set({version});

                const fetchingProducts = IndexAPI.loadProducts();
                const fetchingIndexData = IndexAPI.loadIndexData();

                return Promise.join(fetchingProducts, fetchingIndexData, (indexProducts, data) => {
                    products = indexProducts;
                    indexData = data;
                    window.data = data;
                });
            }

            return Promise.resolve([]);
        })
            .catch(() => {
            // proceed with connection in case of offline or error
            })
            .then(() => {
                return new Promise((resolve, reject) => {
                    const open = indexedDB.open(DB_NAME, version || currentVersion);

                    open.onerror = (error) => {
                        window.console.log('Error while trying to open IndexedDB database', error);
                        reject(error);
                    };

                    open.onsuccess = (event) => {
                        this._db = event.target.result;
                        resolve(this._db);
                    };

                    open.onupgradeneeded = (event) => {
                        const db = event.target.result;

                        this.clearDatabase(db);

                        this.upgradeDatabase(db, products, indexData);
                    };
                });
            });
    }

    getAllIndexProducts() {
        return new Promise((resolve, reject) => {
            const transaction = this._db.transaction([OBJECT_STORE_NAMES.products]);
            const store = transaction.objectStore(OBJECT_STORE_NAMES.products);

            const openCursor = store.openCursor();

            openCursor.onerror = (error) => {
                reject(error);
            };

            const result = [];

            openCursor.onsuccess = (event) => {
                const cursor = event.target.result;

                if (cursor) {
                    result.push(cursor.value);
                    cursor.continue();
                } else {
                    resolve(result);
                }
            };
        });
    }

    getIndexData(startYear, endYear, month) {
        const transaction = this._db.transaction([OBJECT_STORE_NAMES.indexes]);
        const indexStore = transaction.objectStore(OBJECT_STORE_NAMES.indexes);
        const expiryDateIndex = indexStore.index('expiryDate');
        const data = {};
        const yearsRange = _.range(startYear, endYear + 1);

        return Promise.all(yearsRange.map(year => {
            const key = `${year}-${month}`;

            if (cache[key]) {
                data[key] = cache[key];

                return;
            }

            return new Promise((resolve, reject) => {
                const start = moment.utc().year(year).month(month).startOf('month').valueOf();
                const end = moment.utc(start).endOf('month').valueOf();
                const range = IDBKeyRange.bound(start, end, false, false);
                const openCursor = expiryDateIndex.openCursor(range);

                let result = null;

                openCursor.onerror = (error) => {
                    reject(error);
                };

                openCursor.onsuccess = (event) => {
                    const cursor = event.target.result;

                    if (cursor) {
                        result = cursor.value;
                        cursor.continue();
                    } else {
                        data[key] = result;
                        cache[key] = result;
                        resolve();
                    }
                };
            });
        })).then(() => {
            return _.values(data);
        });
    }
}

export default new IndexStore();
