import LocalComparisonsAPI from 'annuity_lab/tools/lbc/services/data/api/comparisons.js';
import NwideComparisonsAPI from 'common/services/lbc/nwide/comparisons.js';
import DataChangeRegistry from 'annuity_lab/services/data_change_registry.js';
import MigrationConsts from 'annuity_lab/tools/lbc/services/migration_consts.js';
import AnnuityLabAnalytics from 'annuity_lab/services/analytics.js';
import Promise from 'bluebird';

export default function ComparisonActions(actionTypes, tool) {
    function loadComparisons() {
        return (dispatch, getState) => {
            const userId = getState().auth.user.id;

            let comparisons = LocalComparisonsAPI.find({userId});

            if (tool) {
                comparisons = _.filter(comparisons, (comparison) => comparison.tool === tool);
            }

            dispatch({
                type: actionTypes.COMPARISONS_LOADED,
                payload: {comparisons},
            });
        };
    }

    function saveComparison(template) {
        return (dispatch, getState) => {
            const userId = getState().auth.user.id;

            template.userId = userId;
            const comparison = LocalComparisonsAPI.create(template);

            AnnuityLabAnalytics.trackSaveComparison(comparison);

            dispatch({
                type: actionTypes.COMPARISON_SAVED,
                payload: {comparison},
                meta: {
                    sync: {
                        online: () => {
                            return NwideComparisonsAPI.createComparison(userId, comparison);
                        },
                        offline: () => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.COMPARISONS,
                                entity: comparison,
                                operation: MigrationConsts.operations.CREATE,
                                userId,
                            });
                        },
                    },
                    transition: (prevState, nextState, action) => {
                        // XXX: Delay transition so comparsions props passed
                        // to CardsComparioson are updated first.
                        // --georgi.ivanov@2016-11-27
                        return Promise.delay(150).then(() => {
                            return {
                                pathname: `/${tool}/comparisons/${action.payload.comparison.id}`,
                            };
                        });
                    },
                },
            });
        };
    }

    function removeComparison(comparisonId) {
        return (dispatch, getState) => {
            const userId = getState().auth.user.id;
            const removed = LocalComparisonsAPI.remove({id: comparisonId})[0];

            AnnuityLabAnalytics.trackRemoveComparison(removed);

            dispatch({
                type: actionTypes.COMPARISON_REMOVED,
                payload: {comparisonId},
                meta: {
                    sync: {
                        online: () => {
                            return NwideComparisonsAPI.removeComparison(userId, comparisonId);
                        },
                        offline: () => {
                            DataChangeRegistry.add({
                                entityType: MigrationConsts.entityTypes.COMPARISONS,
                                entity: removed,
                                operation: MigrationConsts.operations.DELETE,
                                userId,
                            });
                        },
                    },
                    transition: () => ({pathname: `/${tool}/comparisons`}),
                }
            });
        };
    }

    return {
        loadComparisons,
        saveComparison,
        removeComparison,
    };
}
