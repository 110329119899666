import {connect} from 'react-redux';

import ShareDialogs from 'annuity_lab/tools/common/components/share_dialogs/share_dialogs.js.jsx';
import {clearShare} from 'annuity_lab/tools/common/actions/share.js';

const mapStateToProps = ({common}) => ({
    share: common.share
});

const mapDispatchToProps = (dispatch) => {
    return {
        clearShare: () => {
            dispatch(clearShare());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareDialogs);