import Connection from 'common/services/connection.js';
import {
    transformBEModelToFEModel,
    transformFEModelToBEModel
} from 'annuity_lab/tools/imo/utils/transform.js';

const API_PATH = '/imo/models';

class ModelsAPI {
    loadModel(modelId) {
        const path = `${API_PATH}/${modelId}`;

        return Connection.send(path)
            .then(transformBEModelToFEModel);
    }

    loadModels(userId) {
        return Connection.send(API_PATH, {userId})
            .then(beModels => beModels.map(transformBEModelToFEModel));
    }

    createModel(userId, template) {
        const beModel = transformFEModelToBEModel(template);

        const data = _.merge({}, beModel, {userId});

        return Connection
            .send(API_PATH, data, 'PUT');
    }

    removeModel(userId, modelId) {
        const path = `${API_PATH}/${modelId}`;

        return Connection.send(path, {userId}, 'DELETE');
    }
}

export default new ModelsAPI();