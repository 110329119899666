import Connection from 'common/services/connection.js';

const API_PATH = '/fia';

class IndexesAPI {
    loadProducts(userId) {
        const path = `${API_PATH}/products`;

        return Connection.send(path, {userId});
    }

    loadIndexData(userId) {
        const path = `${API_PATH}/indexes`;

        return Connection.send(path, {userId});
    }

    getVersion(userId) {
        const path = `${API_PATH}/version`;

        return Connection.send(path, {userId});
    }
}

export default new IndexesAPI();
