import 'annuity_lab/tools/lbc/components/hurdle_rate_balloon/hurdle_rate_balloon.css.scss';
import PropTypes from 'prop-types';
import NumberFormat from 'common/services/number_format.js';
import InfoBalloon from 'annuity_lab/components/info_balloon/info_balloon.js.jsx';
import HurdleRateDescription
    from 'common/components/hurdle_rate_description/hurdle_rate_description.js.jsx';

export default class HurdleRateBalloon extends React.Component {
    static propTypes = {
        cardIndex: PropTypes.number.isRequired,
        card: PropTypes.object.isRequired,
        baseProduct: PropTypes.object.isRequired,
        hurdleRate: PropTypes.number.isRequired,
        deferralPeriod: PropTypes.number.isRequired
    }

    render() {
        const {cardIndex, deferralPeriod,
            card, baseProduct} = this.props;

        let hurdleRate = this.props.hurdleRate;

        if (_.isNaN(this.props.hurdleRate)) {
            hurdleRate = 0;
        }

        const target = this.props.target ||
            `.chart-column:nth-child(${cardIndex + 1})`;
        const arrowPosition = this.props.arrowPosition || 'bottom';

        return (
            <InfoBalloon
                pointsTo={target}
                arrowPosition={arrowPosition}
                className={'hurdle-rate-balloon product-' + cardIndex}>

                <div className="hurdle-rate">
                    {NumberFormat.percentage(hurdleRate)}
                </div>

                <div className="description">
                    <HurdleRateDescription
                        card={card}
                        baseProduct={baseProduct}
                        hurdleRate={hurdleRate}
                        deferralPeriod={deferralPeriod}/>
                </div>

            </InfoBalloon>
        );
    }
}
