import {useEffect} from 'react';
import {connect} from 'react-redux';
import RecentToolAPI from 'annuity_lab/services/data/api/recent_tool.js';
import Constants from 'common/services/constants.js';
import {withRouter} from 'react-router-dom';

const AdminRoutes = ({children, user, history}) => {
    useEffect(() => {
        if (!user.isAdmin) {
            const recentToolApi = new RecentToolAPI(user.id);
            const recentTool = recentToolApi.get() || Constants.DEFAULT_TOOL;

            history.replace(recentTool);
        }
    }, [user]);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(AdminRoutes));