import {useRef, useEffect, useLayoutEffect, useState} from 'react';

/**
 * Returns true if it is safe to continue to render the tree furthur.
 * @param deepRouteRedirect an instnce of @see deep_route_redirect.js
 * @param location react-router location object
 * @param history react-router history object
 */
export const useDeepRouteRedirect = (deepRouteRedirect, location, history) => {
    const [shouldRender, setShouldRender] = useState(false);

    const locationRef = useRef(location);

    useEffect(() => {
        locationRef.current = location;
    }, [location]);

    useLayoutEffect(() => {
        deepRouteRedirect.onEnter(locationRef.current, history.replace);
        setShouldRender(true);

        return () => {
            deepRouteRedirect.onLeave(locationRef.current);
        };
    }, [history]);

    return shouldRender;
};